import { Link, useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import styles from "./Navbar.module.css";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";

const Sidebar = (props) => {
    // Initializing state for search parameters
    const [searchParams, setSearchParams] = useSearchParams();

    // Retrieving the project ID from the search parameters
    const projectId = searchParams.get("project");

    // Initializing state for sidebar responsiveness
    const [openSIdebar, setOpenSidebar] = useState(false);

    // Function to open the sidebar
    const handleOpen = () => {
        setOpenSidebar(true);
    };

    // Function to close the sidebar
    const handleClose = () => {
        setOpenSidebar(false);
    };

    // Initializing state for window width
    const [width, setWidth] = useState(0);

    // UseEffect hook to set window width and update it on resize
    useEffect(() => {
        setWidth(window.innerWidth);  // Initial window width
        window.addEventListener("resize", () => setWidth(window.innerWidth));  // Update on resize
    }, []);

    // Getting the current location from the router
    const location = useLocation();

    // Function to check if the current path matches a given path
    const isActive = (to) => {
        return location.pathname === to;
    };

    // Retrieving user permissions from Redux store
    const userPermission = useSelector(state => state.organization.userPermission);


    return (
        <div className={styles.wrapper}>
            {!openSIdebar && width < 800 && (
                <button onClick={handleOpen} className={styles.hamburgerBtn}>
                    <HamburgerIcon className={styles.hamburger} />
                </button>
            )}
            {width > 800 && (
                <>
                    <button className={styles.item}>
                        <Link
                            className={`${styles.link} ${isActive("/settings") && styles.active
                                }`}
                            onClick={() => props.setIsPath("Connection")}
                            to={`/settings?project=${projectId}`}
                        >
                            <p>Connection</p>

                        </Link>
                    </button>

                    <button className={styles.item}>
                        <Link
                            className={`${styles.link} ${isActive("/settings/info") && styles.active
                                }`}
                            onClick={() => props.setIsPath("Information")}

                            to={`/settings/info?project=${projectId}`}
                        >
                            <p>Information</p>

                        </Link>
                    </button>

                    <div className={styles.item}>
                        <Link
                            to={`/settings/derivedChannels?project=${projectId}`}
                            className={`${styles.link} ${isActive("/settings/derivedChannels") && styles.active
                                }`}
                            onClick={() => props.setIsPath("Derived Channels")}
                        >
                            <p>Derived Channels</p>

                        </Link>
                    </div>

                    {props.currentRole === 'admin' ?
                        (<button className={styles.item}>
                            <Link
                                to={`/settings/managemembers?project=${projectId}`}
                                onClick={() => props.setIsPath("Manage Members")}
                                className={`${styles.link} ${isActive("/settings/managemembers") && styles.active
                                    }`}
                            >
                                <p>Manage Members</p>

                            </Link>
                        </button>)
                        : (<></>)}

                    <button className={styles.item}>
                        <Link
                            className={`${styles.link} ${isActive("/settings/securityanddata") && styles.active
                                }`}
                            onClick={() => props.setIsPath("Security and Data")}
                            to={`/settings/securityanddata?project=${projectId}`}
                        >
                            <p>Security and Data</p>

                        </Link>
                    </button>
                    {userPermission.dangerZone && (
                        <button className={styles.item} onClick={(e) => e.preventDefault()}>
                            <Link
                                className={`${styles.link} ${isActive("/settings/dangerzone") && styles.active
                                    }`}
                                onClick={() => props.setIsPath("Danger Zone")}
                                to={`/settings/dangerzone?project=${projectId}`}
                            >
                                <p>Danger Zone</p>

                            </Link>
                        </button>
                    )}
                    <button className={styles.item}>
                        <Link
                            className={`${styles.link} ${isActive("/settings/history") && styles.active
                                }`}
                            onClick={() => props.setIsPath("Historic Data")}
                            to={`/settings/history?project=${projectId}`}
                        >
                            <p>Historic Data</p>

                        </Link>
                    </button>



                    {<button className={styles.item}>
                        <Link
                            className={`${styles.link} ${isActive("/settings/reportautomation") && styles.active
                                }`}
                            onClick={() => props.setIsPath("Report Automation")}
                            to={`/settings/reportautomation?project=${projectId}`}
                        >
                            <p>Report Automation</p>

                        </Link>
                    </button>}
                </>
            )}

            {openSIdebar && width < 800 && (
                <aside className={styles.aside}>
                    <button onClick={handleClose} className={styles.cross}>
                        <CloseIcon />
                    </button>

                    <div className={styles.items}>
                        <button className={styles.item}>
                            <Link
                                className={styles.link}
                                to={`/settings?project=${projectId}`}
                            >
                                <p>Connection</p>

                            </Link>
                        </button>
                        <button className={styles.item}>
                            <Link
                                className={styles.link}
                                to={`/settings/info?project=${projectId}`}
                            >
                                <p>Information</p>

                            </Link>
                        </button>

                        <button className={styles.item}>
                            <Link
                                className={styles.link}
                                to={`/settings/derivedChannels?project=${projectId}`}
                            >
                                <p>Derived Channels</p>

                            </Link>
                        </button>
                        <button className={styles.item}>
                            <Link
                                to={`/settings/manageMembers?project=${projectId}`}
                                className={styles.link}
                            >
                                <p>Manage Members</p>

                            </Link>
                        </button>
                        <button className={styles.item}>
                            <Link
                                className={styles.link}
                                to={`/settings/securityanddata?project=${projectId}`}
                            >
                                <p>Security and Data</p>

                            </Link>
                        </button>
                        <button className={styles.item}>
                            <Link
                                className={styles.link}
                                to={`/settings/dangerzone?project=${projectId}`}
                            >
                                <p>Danger Zone</p>

                            </Link>
                        </button>
                        <button className={styles.item}>
                            <Link
                                className={styles.link}
                                to={`/settings/history?project=${projectId}`}
                            >
                                <p>Historic Data</p>

                            </Link>
                        </button>

                        <button className={styles.item}>
                            <Link
                                className={styles.link}
                                to={`/settings/reportautomation?project=${projectId}`}
                            >
                                <p>Report Automation</p>

                            </Link>
                        </button>

                    </div>
                </aside>
            )}
        </div>
    );
};

export default Sidebar;
