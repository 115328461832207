import React, { useState } from "react";
import DangerzoneModel from "./components/dangerzoneModel";
import styles from "./DangerZone.module.css";
import { useSelector } from "react-redux";


function DangerZone({ setPage }, props) {

  const [selectedModel, setSelectedModel] = useState(""); // State to store model type
  const [isModelOpen, setIsModelOpen] = useState(false); // State to open/close model
  const userPermission = useSelector(state => state.organization.userPermission);



  const handleButton = (val) => {
    setSelectedModel(val);
    setIsModelOpen(true);
  }


  return (

    <>
      <div>
        <div className={styles.hero}>
          <div className={styles.page_hero}>
            <h3>Danger Zone</h3>
            <div className={styles.wrapper}>
              <div className={styles.actions}>
                <label>Suspend Project</label>
                {userPermission?.suspendProject && (

                  <button className={styles.btn} onClick={() => handleButton("S")}>Suspend</button>
                )}
              </div>
              <div className={styles.actions}>
                <label>Delete Project</label>
                {userPermission?.deleteProject && (
                  <button className={styles.btn} onClick={() => handleButton("D")} >Delete</button>
                )}
              </div>
              <div className={styles.actions}>
                <label>Transfer Project</label>
                {userPermission?.transferProject && (
                  <button className={styles.btn} onClick={() => handleButton("T")} >Transfer</button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        isModelOpen && (
          <DangerzoneModel
            selectedModel={selectedModel}
            setIsModelOpen={setIsModelOpen}
          />
        )
      }

    </>



  );
}

export default DangerZone;
