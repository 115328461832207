import React, { useCallback, useState } from "react";
import styles from "./DeleteOrg.module.css";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteOrganization } from "../../../../../redux/slices/organizationSlice.js";
import openEye from "../../../../../assets/openEye.png";
import closeEye from "../../../../../assets/closeEye.png"

function DeleteOrganization({ setDeleteOrg }) {
  const toast = useToast();
  const [password, setPassword] = useState(""); // State to store password value
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false); // State to handle passowrd visibility


  // Function to handle Delete Organization
  const deleteOrg = useCallback(async () => {
    const apiBody = {
      userEmail: localStorage.getItem(`email`),
      organizationEmail: localStorage.getItem(`organizationEmail`),
      password: password.trim(),
    };

    try {
      dispatch(deleteOrganization(apiBody)).then((response) => {
        if (response.payload.status === 200) {
          toast({
            title: "Successful",
            description: response.payload.message,
            status: "success",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
          setDeleteOrg(false);
          localStorage.clear();
          navigate("/auth/login");
        } else {
          toast({
            title: "Unsuccessful",
            description: response.payload.message,
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Internal Server Error",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      })
    }
  },[]);

  return (
    <div className={styles.deleteModel}>
      <div className={styles.modelWrapper}>
        <div className={styles.modelContent}>
          <h2 className={styles.header}>Delete Organization</h2>
          <div className={styles.inputWrapper}>

            <input
              className={styles.inputField}
              type={isPasswordVisible ? "text" : "password"}
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
            />
            {isPasswordVisible ? (
              <img src={openEye} alt="open eye" className={styles.eyeImg} onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
            ) : (
              <img src={closeEye} alt="close eye" className={styles.eyeImg} onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
            )}
          </div>
        </div>
        <div className={styles.btnContainer}>
          <button
            className={styles.greyBtn}
            onClick={() => setDeleteOrg(false)}
          >
            Cancel
          </button>
          <button className={styles.blueBtn} onClick={deleteOrg}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteOrganization;
