import React, { useState, useEffect, useRef } from "react";
import proxy from "proxy";
import styles from "./Historical.module.css";
import { Button, Input, Table } from "@chakra-ui/react";
import { Tabs, TabList, Tab, TabPanel, TabPanels } from "@chakra-ui/react";
import { Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import GraphData from "./GraphData";
import WorkerBuilder from "Utils/builder.worker";
import restructureWorker from "Utils/Historicalhistory.worker";
import ModelPDFDetails from "./ModelPDFDetails";
import { useSelector } from "react-redux";
import GraphLabelsModel from "./GraphLabelsModel";

const Historical = ({ uniqueProjectId }) => {
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [dateTimesOneDay, setDateTimesOneDay] = useState([]); // DateTime labels for y-axis
  const [channelNames, setChannelNames] = useState([]); // Channel names
  const [chartDataDay, setChartDataDay] = useState({
    labels: [],
    datasets: [],
  }); // Data ready for display
  const [dataArrDay, setDataArrDay] = useState([]); // Real-time data received
  const [oneDayDataTs, setOneDayDataTS] = useState([]); // Data with timestamp for 1-day table
  const [activeTab, setActiveTab] = useState("table");
  const [isGraphLabels, setIsGraphLabels] = useState(false); // handle open/close graph label model
  const [openModel, setOpenModel] = useState(false);


  const graphRef = useRef(null); // Reference to the graph container element
  const restructure = new WorkerBuilder(restructureWorker);
  const userPermission = useSelector(state => state.organization.userPermission);
  const isMobile = window.matchMedia("(max-width: 768px)").matches;



  useEffect(() => {
    const copiedDataArrDay = [...dataArrDay];

    copiedDataArrDay.forEach((data) => {
      data.pointRadius = 0;
      data.borderWidth = 1.5;
    });

    setChartDataDay({
      labels: dateTimesOneDay,
      datasets: copiedDataArrDay,
    });
  }, [dateTimesOneDay]);

  const handleStartDateChange = (event) => {
    const { value } = event.target;
    if (value) {
      setStartDate(new Date(value).getTime());
    }
  };

  const handleEndDateChange = (event) => {
    const { value } = event.target;
    if (value) {
      setEndDate(new Date(value).getTime());
    }
  };

  const arrangeData = async (allData) => {
    if (allData && allData.length > 0 && startDate && endDate) {
      const tableDataArrayGen = async () => {
        const dataPromise = new Promise((resolve) => {
          restructure.onmessage = (e) => {
            resolve(e.data);
          };
        });
        restructure.postMessage({ data: allData });
        return await dataPromise;
      };
      const tableDataArray = await tableDataArrayGen();
      if (
        tableDataArray.headers.length > 0 &&
        tableDataArray.tableArray.length > 0
      ) {
        setDataArrDay(tableDataArray.chartArray);
        setDateTimesOneDay(tableDataArray.timeArray);
        setOneDayDataTS(tableDataArray.tableArray);
        setChannelNames(tableDataArray.headers);
      }
    }
  };

  const handleSubmit = async () => {

    try {
      const response = await fetch(
        `${proxy}/db/get-history?token=${localStorage.getItem(
          "token"
        )}&projectID=${uniqueProjectId}&startDate=${startDate}&endDate=${endDate}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            responseType: "json",
            Project_id: uniqueProjectId,
          }),
        }
      );
      const dataChunk = await response.json();
      // Now, allData will contain the entire JSON data received in chunks

      // Set the dataset in your state or do further processing
      arrangeData(dataChunk);

    } catch (error) {
      console.error("Error fetching project:", error);
    }
  };


  const handleDownloadCSV = () => {

    if (oneDayDataTs.length > 0) {
      const csvHeaders = ["Timestamp", ...channelNames];
      const csvRows = oneDayDataTs;
      const csvData = [csvHeaders, ...csvRows];

      const csvContent = csvData.map((row) => row.join(",")).join("\n");

      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "data.csv";
      link.click();
    }
  };



  // Add media query for mobile view

  const handleDownloadPDF = () => {
    setOpenModel(true);
  };

  const handleGraphLabelModel = () => {
    setIsGraphLabels(true);
  }


  return (
    <div className={styles.bodyWrapper}>
      <div className={styles.wrapperInternal}>
        <div className={styles.selectorContainer}>
          <Input
            size="sm"
            className={styles.datetimeselector}
            type="datetime-local"
            placeholder="Select start date and time"
            onInput={handleStartDateChange}
          />
          <p>to</p>
          <Input
            size="sm"
            className={styles.datetimeselector}
            type="datetime-local"
            placeholder="Select end date and time"
            onInput={handleEndDateChange}
          />
          <Button
            className={styles.selectorContainerButton}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>

        {isMobile ? (
          <div className={styles.mob_nav}>
            <Tabs isFitted size="sm" variant="enclosed">
              <TabList>
                <Tab onClick={() => setActiveTab("table")}>Table</Tab>
                <Tab onClick={() => setActiveTab("graph")}>Graph</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  {activeTab === "table" && (
                    <div className={styles.table_container}>
                      <Table>
                        <Thead>
                          <Tr>
                            <Th>Timestamp</Th>
                            {channelNames.map((header, index) => (
                              <Th key={index}>{header}</Th>
                            ))}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {oneDayDataTs.map((column, columnIndex) => {
                            return (
                              <Tr key={columnIndex}>
                                {column.map((value, rowIndex) => {
                                  return <Td key={rowIndex}>{value}</Td>;
                                })}
                              </Tr>
                            );
                          })}
                        </Tbody>
                      </Table>
                    </div>
                  )}
                </TabPanel>
                <TabPanel>
                  {activeTab === "graph" && (
                    <div className={styles.graph_container}>
                      <div id="DesktopGraph" ref={graphRef}>
                        <GraphData viewData={chartDataDay} historical={true} />
                      </div>
                    </div>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        ) : (
          // For desktop view, render the table and graph separately without tabs
          <div className={styles.desktopView}>
            <div className={styles.table_container}>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Timestamp</Th>
                    {channelNames.map((header, index) => (
                      <Th key={index}>{header}</Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {oneDayDataTs.map((column, columnIndex) => {
                    return (
                      <Tr key={columnIndex}>
                        {column.map((value, rowIndex) => {
                          return <Td key={rowIndex}>{value}</Td>;
                        })}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </div>

            <div className={styles.graph_container}>
              <div id="DesktopGraph" ref={graphRef}>
                <GraphData viewData={chartDataDay} historical={true} />
              </div>
            </div>
          </div>
        )}

        <div className={styles.data_container}>
          {userPermission.exportReport && (
            <div className={styles.historyActions}>
              <Button onClick={handleDownloadCSV}>Download CSV</Button>
              <Button onClick={handleGraphLabelModel}>Download Graph</Button>
              <Button onClick={handleDownloadPDF}>Download PDF</Button>
            </div>
          )}
        </div>
      </div>
      {openModel && (
        <ModelPDFDetails
          data={oneDayDataTs}
          header={["Timestamp", ...channelNames]}
          setOpenModel={setOpenModel}
        />
      )}
      {isGraphLabels && (
        <GraphLabelsModel
          graphRef={graphRef}
          setIsGraphLabels={setIsGraphLabels}
        />
      )}
    </div>
  );
};

export default Historical;
