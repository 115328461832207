/* eslint-disable import/no-anonymous-default-export */
export default () => {
    /*eslint-disable-next-line no-restricted-globals*/
    self.onmessage = function (e) {

        if (e.data.data.length > 0) {
            const allData = e.data.data;
            const timeArray = allData.map(dataPoint => {
                const date = new Date(dataPoint.time);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                const hour = String(date.getHours()).padStart(2, '0');
                const minute = String(date.getMinutes()).padStart(2, '0');
                
                return `${day}/${month}/${year} ${hour}:${minute}`;
            });
            const headers = [];
            allData.forEach(dataset => {
                const datasetData = dataset.data;
                datasetData.forEach(element => {
                    const header = element.Header;
                    if (headers.indexOf(header) === -1) {
                        headers.push(header);
                    }
                });
            });

            const rowMajorArrayOfValues = allData.map((dataPoint) => {
                const rowHeaders = dataPoint.data.map(item => item.Header);
                return headers.map(header => {
                    const headerIndex = rowHeaders.indexOf(header);
                    if (headerIndex > -1) {
                        return parseFloat(dataPoint.data[headerIndex].value)
                    }
                    else {
                        return null;
                    }
                });
            });

            const rowMajorTableArray = rowMajorArrayOfValues.map((dataPoint, index) => {
                const newRow = [timeArray[index]];
                dataPoint.forEach((dataPointValue) => newRow.push(dataPointValue))
                return newRow;
            });

            const columnMajorArrayOfValues = headers.map(header => {
                return allData.map(dataPoint => {
                    const rowHeaders = dataPoint.data.map(item => item.Header);
                    const headerIndex = rowHeaders.indexOf(header);
                    if (headerIndex > -1) {
                        return parseFloat(dataPoint.data[headerIndex].value)
                    }
                    else {
                        return null;
                    }
                });
            });

            const getRandomColor = () => {
                const letters = "0123456789ABCDEF";
                let color = "#";
                for (let i = 0; i < 6; i++) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
            };

            const channelWiseDataChartArray = columnMajorArrayOfValues.map((element, index) => {
                return {
                    label: headers[index],
                    data: element,
                    borderColor: getRandomColor(),
                    fill: false,
                }
            });

            postMessage({ headers: headers, timeArray: timeArray, tableArray: rowMajorTableArray, chartArray: channelWiseDataChartArray });
        }
    }
};