import React from "react";
import styles from "./ProfileCards.module.css";
import Auth2StepModel from "./TwoFactorAuth/Auth2StepModel";
import { useSelector } from "react-redux";

const PersonalInfo = ({ setModel }) => {
  const profileData = useSelector(state => state.profile.profileData); // Stored profile data from redux

  return (
    <div className={styles.cards}>
      <div className={styles.cardWrapper}>
        <div className={styles.profileWrapper}>
          <h3 className={styles.headerText}>Personal Information</h3>
          <div className={styles.columns}>
            <div className={styles.column1}>
              <div>
                <div className={styles.firstName}>Name</div>
                <div className={styles.fname}>{profileData?.firstName} {profileData?.lastName}</div>
              </div>
              <div>
                <div className={styles.emailAddress}>Email Address</div>
                <div className={styles.email}>{profileData?.email}</div>
              </div>
            </div>
            <div className={styles.column2}>
              <div>
                <div className={styles.phoneNum}>Phone No.</div>
                <div className={styles.num}>{profileData?.contactNumber}</div>
              </div>
            </div>
          </div>
        </div>

        {/* Location Info Card */}
        <div className={styles.locationWrappper}>
          <h3 className={styles.headerText}>Address</h3>
          <div className={styles.columns}>
            <div className={styles.column1}>
              <div className={styles.addressWrapper}>
                <div className={styles.address}>Full Address</div>
                <div className={styles.fullAddress}>{profileData?.fullAddress}</div>
              </div>
              <div>
                <div className={styles.state}>State</div>
                <div className={styles.gujarat}>{profileData?.state}</div>
              </div>
            </div>
            <div className={styles.column2}>
              <div>
                <div className={styles.city}>City</div>
                <div className={styles.ahmedbad}>{profileData?.city}</div>
              </div>
              <div>
                <div className={styles.country}>Country</div>
                <div className={styles.india}>{profileData?.country}</div>
              </div>
            </div>
          </div>
        </div>

        {/* Company Detail */}
        <div className={styles.detailWrapper}>
          <h3 className={styles.headerText}>Company Details</h3>
          <div className={styles.columns}>
            <div className={styles.column1}>
              <div>
                <div className={styles.name}>Name</div>
                <div className={styles.companyName}>{profileData?.name}</div>
              </div>
              <div>
                <div className={styles.gstNo}>Gst No.</div>
                <div className={styles.No}>{profileData?.gstNumber}</div>
              </div>
            </div>
            <div className={styles.column2}>
              <div>
                <div className={styles.contactNo}>Pincode</div>    
                <div className={styles.companyNum}>{profileData?.pincode}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.Auth2StepModel}>
        <Auth2StepModel  setModel={setModel} />
      </div>
    </div>
  );
};

export default PersonalInfo;
