import React, { useState } from 'react';
import styles from "./AdminSignin.module.css";
import dicot_img from "../../../assets/Logo.svg";
import openEye from "../../../assets/openEye.png";
import closeEye from "../../../assets/closeEye.png";
import { Link } from 'react-router-dom';
import proxy from '../../../proxy';
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";


const AdminSignin = () => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false); // State to handle password hide/unhide
    const [email, setEmail] = useState(""); // State to store email input value
    const [password, setPassword] = useState(""); // State to store password input value
    const toast = useToast();

    const navigate = useNavigate();

    // Handle password hide/unhide

    const togglePasswordVisibility = (e) => {
        e.preventDefault();
        const pwInput = document.getElementById("pwd");
        pwInput.type = isPasswordVisible ? "password" : "text";
        setIsPasswordVisible(!isPasswordVisible);
    };

    // Function handle sigin with api call
    const handleSignin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${proxy}/admin/signin`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email.trim(),
                    password: password.trim(),
                }),
            });
            const status = response.status;

            const responseBody = await response.json();

            if (status === 200) {

                toast({
                    title: responseBody.message || "Login Successfully",
                    status: "success",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                });
                if (responseBody?.data?.role === "distributor") {
                    navigate("/distributors");

                } else {
                    navigate("/adminUsers");
                }

                localStorage.setItem("adminEmail", responseBody?.data?.email);
                localStorage.setItem("discount", responseBody?.data?.discount);
                localStorage.setItem("authToken", responseBody?.data?.authToken);
                localStorage.setItem("payLater", responseBody?.data?.payLater.toString());
            } else {
                toast({
                    title: responseBody.message || "Error while signin",
                    status: "error",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.log("Error while admin signin", error)
            toast({
                title: "Internal Server Error",
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            })
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.factory}>
                <img className={styles.logoImage} src={dicot_img} alt="Dicot Logo" />
            </div>
            <div className={styles.formSection}>
                <div className={styles.visionWeb}>
                    Vision <span className={styles.w}>W</span>eb
                </div>
                <div className={styles.formContainer}>
                    <form className={styles.loginForm}>
                        <div>
                            <h1 className={styles.loginHeading}>Login</h1>
                            <p>Hi, Welcome back👋</p>
                        </div>

                        <input
                            type="text"
                            id="mail"
                            name="email"
                            className={styles.textInput}
                            placeholder="Email [Eg: john@dicot.tech]"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className={styles.passwordSection}>
                            <input
                                type="password"
                                id="pwd"
                                name="password"
                                className={styles.textInput}
                                placeholder="Enter Password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <div
                                className={styles.pwVisibilityToggle}
                                onClick={togglePasswordVisibility}
                            >
                                {!isPasswordVisible ? (
                                    <img src={closeEye} alt="Close Eye" srcSet="" />
                                ) : (
                                    <img src={openEye} alt="Open Eye" srcSet="" />
                                )}
                            </div>
                        </div>
                        <div className={styles.submitButtonContainer}>
                            <button
                                onClick={(e) => handleSignin(e)}
                                id="loginbtn"
                                className={styles.loginbtn}
                            >
                                Login
                            </button>
                        </div>

                        <div className={styles.Signup}>
                            <p>
                                New here?{" "}
                                <Link to="/admin/signup">
                                    <span className={styles.s}>Sign Up</span>
                                </Link>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AdminSignin;