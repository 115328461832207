import { React, useEffect, useState } from "react";
import styles from "./Batches.module.css";
import BatchCard from "./components/BatchCard";
import AddBatch from "./components/AddBatch";
import proxy from "proxy";
import { useSearchParams } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import tableInitWorker from "Utils/restructure.worker";
import WorkerBuilder from "Utils/builder.worker";
import valueExtractorWorker from "Utils/valueExtractor.worker";
import { useDispatch, useSelector } from "react-redux";
import { getBatches } from "../../../../redux/slices/batchSlice";


const tableInitializer = new WorkerBuilder(tableInitWorker);
const valueExtractor = new WorkerBuilder(valueExtractorWorker);

const Batches = ({ setPage }) => {
  setPage("dashboard");

  // Initializing states for various data
  const [channelNames, setChannelNames] = useState([]); // Channel names
  const [searchParams] = useSearchParams();
  const uniqueProjectId = searchParams.get("graphId");
  const [isOpen, setIsOpen] = useState(""); // State for modal visibility
  const [batchData, setBatchData] = useState([]); // State for batch data
  const [data, setData] = useState([]); // State for fetched data
  const [tableData, setTableData] = useState([]); // Data formatted for Table
  const [dateTimesLive, setDateTimesLive] = useState([]); // DateTime labels for y-axis
  const [init, setInit] = useState(false); // State for initialization flag
  const [dataArrLive, setDataArrLive] = useState([]); // Real-time data for live updates
  const [isWorkerBusy, setIsWorkerBusy] = useState(false); // State for worker status
  const [socketData, setSocketData] = useState(null); // State for socket data
  const [newCard, setNewCard] = useState(null); // State for new card
  const [cardBatchId, setCardBatchId] = useState(); // State for batch ID

  const dispatch = useDispatch();
  const batches = useSelector((state) => state.batch.batchData);

  // Fetch batches on component mount
  useEffect(() => {
    const token = localStorage.getItem("token");
    try {
      dispatch(getBatches(uniqueProjectId, token));
    } catch (error) {
      console.log("Get Batch:", error);
    }
  }, []);

  // Fetch data based on project ID
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(
          `${proxy}/db/get-data?token=${token}&projectID=${uniqueProjectId}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              Project_id: uniqueProjectId,
            }),
          }
        );
        const responseBody = await response.json();
        setData(responseBody);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [uniqueProjectId]);

  // Process data and update states for live data and table
  useEffect(() => {
    const copiedDataset = data;
    const fetchData = async () => {
      try {
        if (data.length > 0) {
          const currentTime = new Date();
          currentTime.setTime(currentTime.getTime() - 1 * 60 * 60 * 1000); // Subtracting 1 hour
          const liveDataFiltered = copiedDataset.filter(
            (dataset) => new Date(dataset.time) >= currentTime
          );

          const tableDataArrayGen = async () => {
            const dataPromise = new Promise((resolve) => {
              tableInitializer.onmessage = (e) => {
                resolve(e.data);
              };
            });
            tableInitializer.postMessage({ data: liveDataFiltered });
            return await dataPromise;
          };
          const tableDataArray = await tableDataArrayGen();
          let gotHeaders = [];
          if (tableDataArray.data) {
            gotHeaders = tableDataArray.headers;
            const arrayOfValueArrays = tableDataArray.channelWiseData;

            const getNewDataArr = () =>
              gotHeaders.map((header, index) => ({
                label: header,
                data: arrayOfValueArrays[index],
                fill: false,
              }));

            const newDataArr = getNewDataArr();

            setChannelNames(gotHeaders);
            setTableData(tableDataArray.data);
            setDataArrLive(newDataArr);
            setDateTimesLive(tableDataArray.dateTimeArray);
            setInit(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [data]);

  // Update data based on socket updates
  useEffect(() => {
    const updateData = async () => {
      if (socketData && init && !isWorkerBusy) {
        setIsWorkerBusy(true);
        const structuredData = async () => {
          const dataPromise = new Promise((resolve) => {
            valueExtractor.onmessage = (e) => {
              resolve(e.data);
            };
          });
          valueExtractor.postMessage({
            socketData: socketData,
            currentTable: tableData,
            dateTimeArray: dateTimesLive,
            dataArray: dataArrLive,
            headers: channelNames,
            duration: 1,
          });
          return await dataPromise;
        };

        const workerResponse = await structuredData();
        if (workerResponse.updatedTable) {
          setChannelNames(workerResponse.updatedHeaders);
          setTableData(workerResponse.updatedTable);
          setDataArrLive(workerResponse.updatedLiveArray);
          setDateTimesLive(workerResponse.updatedDateTimeArray);
        }
      }
    };
    updateData();
    setIsWorkerBusy(false);
  }, [socketData]);


  return (
    <>
      {isOpen === "addBatch" || isOpen === "editBatch" ? (
        <AddBatch
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          channelNames={channelNames}
          uniqueProjectId={uniqueProjectId}
          setBatchData={setBatchData}
          batchData={batchData}
          key={"AddBatchModel"}
          setNewBatch={setNewCard}
          cardBatchId={cardBatchId}
        />
      ) : null}

      <div className={styles.mainContainer}>
        <div className={styles.dash}>
          <div id={styles.projects}>
            {batches?.map((item, key) => (
              <BatchCard
                key={key}
                batchID={item.batchID}
                batchName={item.batchName}
                channelName={item.channelName}
                projectID={uniqueProjectId}
                status={item.status}
                startTime={item.startTime}
                endTime={item.endTime}
                batchData={batchData}
                setBatchData={setBatchData}
                setIsOpen={setIsOpen}
                setCardBatchId={setCardBatchId}
                cardBatchId={cardBatchId}
              />
            ))}
          </div>
          <button
            className={styles.addBatch}
            onClick={() => setIsOpen("addBatch")}
          >
            <AiOutlinePlus />
            Add Batch
          </button>
        </div>
      </div>
    </>
  );
};

export default Batches;
