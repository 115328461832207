import React, { useEffect, useState } from "react";
import close from "../../../../../assets/close.png";
import styles from "./AddBatch.module.css";
import proxy from "../../../../../proxy";
import { useToast } from "@chakra-ui/react";
import { addBatch, updateBatch } from "../../../../../redux/slices/batchSlice";
import { useDispatch } from "react-redux";

const AddBatch = ({
  isOpen,
  setIsOpen,
  channelNames,
  uniqueProjectId,
  cardBatchId
}) => {
  // Initializing the state for data and name
  const [data, setData] = useState({
    name: "",
    selectedItems: [],
    selectAll: false, // Default selectAll is false
    // Add other fields as needed
  });

  const [Name, setName] = useState("Name"); // Initializing Name state

  // Toast for notifications
  const toast = useToast();

  // Dispatch hook for Redux actions
  const dispatch = useDispatch();

  // Handle checkbox selection (for individual items)
  const handleCheckboxChange = (channelName) => {
    const updatedSelectedItems = [...data.selectedItems];

    // Add or remove the channelName from selectedItems
    if (updatedSelectedItems.includes(channelName)) {
      updatedSelectedItems.splice(updatedSelectedItems.indexOf(channelName), 1);
    } else {
      updatedSelectedItems.push(channelName);
    }

    // Update selectAll based on selected items
    const selectAll = updatedSelectedItems.length === channelNames.length;

    setData({
      ...data,
      selectedItems: updatedSelectedItems,
      selectAll: selectAll,
    });
  };

  // Handle selectAll checkbox change
  const handleSelectAllChange = () => {
    const updatedSelectedItems = data.selectAll ? [] : [...channelNames];
    const selectAll = updatedSelectedItems.length === channelNames.length;
    setData({
      ...data,
      selectedItems: updatedSelectedItems,
      selectAll: selectAll,
    });
  };

  // Handle batch creation
  const handleStartBatch = async () => {
    setIsOpen(""); // Close modal after batch creation
    const batchInput = document.getElementById("batchName").value;

    const token = localStorage.getItem("token");
    try {
      const apiBody = {
        token: token,
        batchName: batchInput,
        Project_id: uniqueProjectId,
        channelName: data.selectedItems,
        status: true,
      };

      dispatch(addBatch(apiBody)).then((response) => {
        // Show success or error toast based on response
        if (response.payload.responseBody.status === 200) {
          toast({
            title: `Batch created successfully`,
            status: "success",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: `Couldn't create the batch`,
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      console.log(error); // Log error if any
    }
  };

  // Handle batch update
  const handleEditBatch = async () => {
    setIsOpen(""); // Close modal after batch update
    const batchInput = document.getElementById("batchName").value;

    const token = localStorage.getItem("token");
    try {
      const apiBody = {
        token: token,
        batchID: cardBatchId,
        batchName: batchInput,
        channelName: data.selectedItems,
      };

      dispatch(updateBatch(apiBody)).then((response) => {
        // Show success or error toast based on response
        if (response.payload.responseBody.status === 200) {
          toast({
            title: `Batch updated successfully`,
            status: "success",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: `Couldn't update the batch`,
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      console.log(error); // Log error if any
    }
  };


  return (
    <>
      <div className={styles.AddBatch}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.text}>
              {isOpen === "addBatch" ? "Add Batch" : "Edit Batch"}
            </div>
            <button className={styles.closeBtn}>
              <img
                src={close}
                alt=""
                srcSet=""
                onClick={() => setIsOpen(false)}
              />
            </button>
          </div>
          <div className={styles.inputWrapper}>
            <div className={styles.name}>
              <input
                type={Name}
                placeholder="Name"
                id="batchName"
                className={styles.inputName}
              />
            </div>

            <div className={styles.select}>Select Channels</div>

            <div className={styles.check}>

              <div className={styles.selectChannel}>
                {/* "Select All" checkbox */}
                <input
                  type="checkbox"
                  checked={data.selectAll}
                  onChange={handleSelectAllChange}
                />
                <label htmlFor="selectAll">Select All</label>
              </div>
              {/* Map over the backend list to create checkboxes */}
              {channelNames.map((item, key) => (
                <div className={styles.checkBox} key={key}>
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange(item)}
                    checked={data.selectedItems.includes(item)}
                  />
                  <span>{item}</span>
                </div>
              ))}
            </div>
            <div className={styles.checkStart}>
              <button
                className={styles.start}
                onClick={
                  isOpen === "addBatch" ? handleStartBatch : handleEditBatch
                }
              >
                {isOpen === "addBatch" ? "Start" : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBatch;
