import React from "react";
import proxy from "proxy.js";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import openEye from "assets/openEye.png";
import closeEye from "assets/closeEye.png";
import { useSearchParams, useNavigate } from "react-router-dom";
import styles from "./ResetPassForm.module.css";

function ResetPassForm() {
  const [query] = useSearchParams();
  const toast = useToast();
  const navigator = useNavigate();
  const [isPasswordVisible1, setIsPasswordVisible1] = useState(false); // State to handle display of password
  const [isPasswordVisible2, setIsPasswordVisible2] = useState(false); // State to handle display of password
  const [newPassword, setNewPassword] = useState(""); // State to store new password value
  const [confirmPassword, setConfirmPassword] = useState(""); // State to store confirm password value

  // Function to store value of new password
  const handlePasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  // Function to store value of confirm password
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  // Function to decide the valid password
  const validatePassword = (password) => {
    // Password pattern: at least one uppercase, one lowercase, one number,
    // one special character, and length between 8 and 20.
    const passwordPattern =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
    return passwordPattern.test(password);
  };

  // Function to handle display of password 
  function togglePasswordVisibility(e) {
    e.preventDefault();
    if (e.target.id === "newPassword") {
      const pwInput = document.getElementById("newpwd");
      pwInput.type = isPasswordVisible1 ? "password" : "text";
      setIsPasswordVisible1(!isPasswordVisible1);
    } else {
      const pwInput = document.getElementById("confirmpwd");
      pwInput.type = isPasswordVisible2 ? "password" : "text";
      setIsPasswordVisible2(!isPasswordVisible2);
    }
  }

  // Function ti handle call of reset password api
  const HandlePasswordRecovery = () => {

    if (newPassword !== confirmPassword) {
      toast({
        title: "invalid password",
        description: `Password is not Matched`,
        status: "error",
        position: "top-right",
        duration: 7000,
        isClosable: true,
      });
    } else if (!validatePassword(newPassword)) {
      toast({
        title: "Invalid Password",
        description:
          "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be 8 to 20 characters long.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    } else {
      fetch(proxy + "/api/changepassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Specify the content type as JSON
        },
        body: JSON.stringify({
          email: query.get("email"),
          newPassword: newPassword,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data?.status === 200) {
            toast({
              title: "Successfully Password ",
              description: data.message,
              status: "success",
              position: "top-right",
              duration: 7000,
              isClosable: true,
            });
            navigator("/auth/login");
          } else {
            toast({
              title: "invalid email",
              description: `${data.message}`,
              status: "error",
              position: "top-right",
              duration: 7000,
              isClosable: true,
            });
          }
        }).catch((error) => {
          console.log(error);
          toast({
            title: "Internal Server Error",
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          })
        })
    }
  };
  return (
    <>
      <div className={styles.visionweb}>
        <h1 className={styles.visionWeb}>
          Vision <span className={styles.W}>W</span>eb
        </h1>
      </div>
      <div className={styles.mainInput}>
        <p className={styles.text} id="headerText">
          Reset Password
        </p>
        <p className={styles.text1}>Here enter your password!🔑</p>
        <br></br>
        <input
          type="password"
          className={styles.emailInput}
          id="newpwd"
          placeholder="Enter New Password"
          onChange={handlePasswordChange}
        />
        <div
          className={styles.pwVisibilityToggle}
          onClick={togglePasswordVisibility}
        >
          {!isPasswordVisible1 ? (
            <img src={closeEye} id="newPassword" alt="Close Eye" srcSet="" />
          ) : (
            <img src={openEye} id="newPassword" alt="Open Eye" srcSet="" />
          )}
        </div>
        <br></br>
        <input
          type="password"
          className={styles.emailInput}
          id="confirmpwd"
          placeholder="Enter Confirm Password"
          onChange={handleConfirmPasswordChange}
        />
        <div
          className={styles.pwVisibilityToggle}
          onClick={togglePasswordVisibility}
        >
          {!isPasswordVisible2 ? (
            <img
              src={closeEye}
              id="confirmPassword"
              alt="Close Eye"
              srcSet=""
            />
          ) : (
            <img src={openEye} id="confirmPassword" alt="Open Eye" srcSet="" />
          )}
        </div>{" "}
        <br />
        <div className={styles.submitbtncontainer}>
          <button
            type="button"
            className={styles.submit_button}
            onClick={HandlePasswordRecovery}
          >
            Reset Password
          </button>
        </div>
      </div>
    </>
  );
}

export default ResetPassForm;
