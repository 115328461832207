import React, { useState, useMemo, useEffect, useCallback } from "react";
import styles from "./DeleteConfirmationModel.module.css";
import close from "assets/close.png";
import openEye from "assets/openEye.png";
import { useToast } from "@chakra-ui/react";
import closeEye from "assets/closeEye.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteAccount, logoutAllDevice, toggleTwoStep, updateProfile } from "../../../../redux/slices/profileSlice.js";

const DeleteModelProfile = ({ setIsToggle, setOpenModel, isToggle, setDeleteModel, header, type, toggleAuth, logOutBody, setIsPasswordModal, setConfirmPwd, setConfirmPwdLogOut, updateProfileInfo }) => {
  const toast = useToast();
  const [password, setPassword] = useState(""); // State to store inout value of password
  const [isPasswordVisible, setIsPasswordVisible] = useState(false); // State to handle hide/unhide of password
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Calling multiple api
  const handleSave = useCallback(async () => {
    if (type === "delete") {
      const apiBody = {
        password: password.trim(),
        email: localStorage.getItem("email")?.trim(),
      }
      // Delete Account Api
      try {
        dispatch(deleteAccount(apiBody)).then((response) => {
          if (response.payload.status === 200) {
            toast({
              title: "Successfully",
              description: response.payload.message,
              status: "success",
              position: "top-right",
              duration: 1000,
              isClosable: true,
            });
            navigate("/auth/register");
            localStorage.removeItem("token");
          } else {
            toast({
              title: "Failed",
              description: response.payload.message,
              status: "error",
              position: "top-right",
              duration: 1000,
              isClosable: true,
            });
          }
        });
      } catch (error) {
        console.log(error);
        toast({
          title: "Internal Server Error",
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        })
      }
      setDeleteModel(false);
    }

    if (type === "logout") {

      const data = { ...logOutBody }
      data.password = password.trim()

      localStorage.clear();
      // Logout from all deivce api 
      try {
        dispatch(logoutAllDevice(data)).then((response) => {
          if (response.payload.status === 200) {
            toast({
              title: "Successfully",
              description: response.payload.message,
              status: "success",
              position: "top-right",
              duration: 1000,
              isClosable: true,
            });
            navigate("/auth/login");
            localStorage.removeItem("token");

          } else {
            toast({
              title: "Failed",
              description: response.payload.message,
              status: "error",
              position: "top-right",
              duration: 1000,
              isClosable: true,
            });
          }
        });
      } catch (error) {
        console.log(error);
        toast({
          title: "Internal Server Error",
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        })
      }
      setConfirmPwdLogOut(false);
    }

    if (type === "toggle") {

      setConfirmPwd(true);
      const toggledata = { ...toggleAuth }
      toggledata.password = password.trim();

      // Calling 2step auth change api
      try {
        dispatch(toggleTwoStep(toggledata)).then((response) => {
          if (response.payload.status === 200) {
            toast({
              title: "Successfully",
              description: response.payload.message,
              status: "success",
              position: "top-right",
              duration: 1000,
              isClosable: true,
            });
            setIsToggle(!isToggle);

          } else {
            toast({
              title: "Failed",
              description: response.payload.message,
              status: "error",
              position: "top-right",
              duration: 1000,
              isClosable: true,
            });
          }
        });
      } catch (error) {
        console.log(error);
        toast({
          title: "Internal Server Error",
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        })
      }
      setConfirmPwd(false);
    };


    if (type === "updateProfilePwd") {
      const token = localStorage.getItem("token");

      const infoData = { ...updateProfileInfo }
      infoData.password = password.trim()
      // Calling update profile info api
      try {
        dispatch(updateProfile({ token: token, valuesToUpdate: infoData })).then((response) => {

          if (response.payload.status === 200) {
            toast({
              title: "Success",
              description: "Profile updated successfully",
              status: "success",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Failed",
              description: "Couldn't update the profile",
              status: "error",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          }
        });
      } catch (error) {
        console.log(error);
        toast({
          title: "Internal Server Error",
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        })
      }
      setIsPasswordModal(false);
      setOpenModel(false);
    }
  }, []);

  // Function to handle hide/unhide of password
  const togglePasswordVisibility = useCallback((e) => {
    e.preventDefault();
    const pwInput = document.getElementById("pwd");
    pwInput.type = isPasswordVisible ? "password" : "text";
    setIsPasswordVisible(!isPasswordVisible);
  }, []);

  // Function to close model
  const handleCloseModel = () => {
    if (type === "delete") {
      setDeleteModel(false);
    }
    if (type === "logout") {
      setConfirmPwdLogOut(false);
    }
    if (type === "toggle") {
      setConfirmPwd(false);
    }
    if (type === "updateProfilePwd") {
      setIsPasswordModal(false);
    }
  };

  // Function to store new password
  const changeHandle = (event) => {
    setPassword(event.target.value);
  };
  return (
    <div className={styles.modelProfile}>
      <div className={styles.modelWrapper}>
        <div className={styles.modelHeader}>
          <h3 className={styles.headerText}>
            {header}
          </h3>
          <button className={styles.closeBtn} onClick={handleCloseModel}>
            <img src={close} alt="" />
          </button>
        </div>
        <div className={styles.inputWrapper}>
          <input
            onChange={changeHandle}
            className={styles.countrySelector}
            type="password"
            id="pwd"
            placeholder="Enter your password"
          />
          <div
            className={styles.pwVisibilityToggle}
            onClick={togglePasswordVisibility}
          >
            {!isPasswordVisible ? (
              <img src={closeEye} alt="Close Eye" srcSet="" className={styles.eyeImg} />
            ) : (
              <img src={openEye} alt="Open Eye" srcSet="" className={styles.eyeImg} />
            )}
          </div>
        </div>
        <button className={styles.editBtn} onClick={handleSave}>
          Confirm
        </button>

      </div>
    </div>
  );
};

export default DeleteModelProfile;
