import React, { useEffect, useMemo, useState } from "react";
import Edit from "assets/Edit.svg";
import styles from "./ProfileInfo.module.css";
import Profilebg from "assets/profilebg.png";
import PasswordModel from "./Others/PasswordModel";
import PictureModel from "./Others/PictureModel";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  chnagePassword,
  updateProfilePicture,
} from "../../../redux/slices/profileSlice.js";

const ProfileInfo = ({
  setOpenModel,
  handleImgChange,
  selectedImg,
  setSelectedImg,
}) => {
  const toast = useToast();
  const navigator = useNavigate();
  const [pictureModel, setPictureModel] = useState(false);// State to handle open/close picture model
  const [passModel, setPassModel] = useState(false); // State to handle open/close password model
  const [passwordValues, setPasswordValues] = useState(); //  
  const [newPicture, setNewPicture] = useState(); // State to store new picture
  const profileData = useSelector((state) => state.profile.profileData); // Stored profile data from redux
  const changePassMsg = useSelector((state) => state.profile.changePassMsg); // Stored api response of change pass api from redux
  const dispatch = useDispatch();

  const selectedAvatar = useMemo(
    () => profileData?.avatar || localStorage.getItem("selectedImage"),
    [profileData]
  );

  // Calling update profile picture api
  useEffect(() => {
    const email = localStorage.getItem("email");
    if (newPicture) {
      const newProfilePicture = {
        ProfilePhoto: newPicture,
      };
      try {
        dispatch(
          updateProfilePicture({
            email: email,
            newProfilePicture: newProfilePicture,
          })
        ).then((response) => {
          if (response.payload.status === 200) {
            localStorage.setItem("avatar", newProfilePicture.ProfilePhoto)
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, [newPicture]);


  // Calling change password api
  useEffect(() => {
    if (passwordValues) {
      try {
        dispatch(chnagePassword(passwordValues));
      } catch (error) {
        console.log(error);
      }
    }
  }, [passwordValues]);

  // Displaying toast message of according api response
  useEffect(() => {
    if (changePassMsg) {
      if (changePassMsg.status === 200) {
        toast({
          title: changePassMsg.message,
          status: "success",
          position: "top-right",
          duration: 4000,
          isClosable: true,
        });
        setPassModel(false);
        setPasswordValues(null);
      } else {
        toast({
          title: changePassMsg.message,
          status: "error",
          position: "top-right",
          duration: 4000,
          isClosable: true,
        });
      }
    }
  }, [changePassMsg]);

  if (localStorage.getItem("token")) {
    const handleEditModel = () => {
      setOpenModel(true);
    };

    return (
      <>
        <div className={styles.heroWrapper}>
          <div className={styles.profileCover}>
            <img src={Profilebg} alt="" srcset="" />
          </div>
          <div className={styles.editProfile}>
            <div className={styles.profileHeadWrap}>
              <div className={styles.infoWrapper}>
                <button
                  className={styles.profileInfoImg}
                  onClick={() => setPictureModel(true)}
                >
                  <img
                    src={selectedAvatar}
                    alt="Profile"
                    className={styles.profileImg}
                  />
                </button>
                <div className={styles.profileInfo}>
                  <span className={styles.profileName}>
                    {profileData?.firstName} {profileData?.lastName}
                  </span>
                  <span className={styles.city}>{profileData?.email}</span>
                </div>
              </div>
              <div className={styles.editOptions}>
                <button className={styles.editBtn} onClick={handleEditModel}>
                  <img className={styles.editImg} src={Edit} alt="" srcset="" />
                </button>
                <button
                  className={styles.changePasswordButton}
                  onClick={() => setPassModel(true)}
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>

        {pictureModel && (
          <PictureModel
            setPictureModel={setPictureModel}
            handleImgChange={handleImgChange}
            selectedImg={selectedImg}
            setNewPicture={setNewPicture}
            setSelectedImg={setSelectedImg}
          />
        )}
        {passModel && (
          <PasswordModel
            setPassModel={setPassModel}
            setPasswordValues={setPasswordValues}
          />
        )}
      </>
    );
  } else {
    navigator("/auth/login");
  }
};

export default ProfileInfo;
