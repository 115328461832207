import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineSetting, AiOutlineClockCircle } from "react-icons/ai";
import styles from "./ProjectCard.module.css";
import { useSelector } from "react-redux";

function ProjectCard(props) {
  const navigate = useNavigate();
  const [status, setStatus] = useState()
  const userPermission = useSelector(
    (state) => state.organization.userPermission
  );

  const days = props.lastModified ? props.lastModified.day : 0;

  const generateStatus = () => {
    const lastModifiedTime = props.lastModified
      ? props.lastModified.time
      : new Date(Date.now()).toISOString();


    const time = new Date(lastModifiedTime);
    const date = time.getDate();
    const month = time.getMonth() + 1;
    const year = time.getFullYear();
    const formatedDate = `${date}/${month}/${year}`;

    const hours = time.getHours();

    const minutes = time.getMinutes();
    const seconds = time.getSeconds();
    let Status = props.onlineStatus;
    const newTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    if (Status === -1) {

      setStatus(<span style={{ color: "grey", fontWeight: "600" }}>Suspended</span>)

    } else if (Status) {

      setStatus(<span style={{ color: "green", fontWeight: "600" }}>Connected</span>)

    } else {

      setStatus(<span style={{ color: "red", fontWeight: "600" }}>
        {newTime} {formatedDate} &nbsp; &nbsp;
      </span>)
    }
  }

  useEffect(() => {
    generateStatus();
  }, [])


  return (
    <div className={styles.project}>
      <div
        className={styles.projectInfo}
        style={
          userPermission?.readSettings
            ? { justifyContent: "space-between" }
            : { padding: "1.2rem .6rem" }
        }
      >
        <div className={styles.iconsWrapper}>
          <div className={styles.iconsWrapper}>
            {userPermission?.viewAlarm && (
              <div
                className={styles.alarmIconWrapper}
                data-uniqueprojectid={props.uniqueProjectId}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(
                    `alarms?project=${props.uniqueProjectId ?? "nullish"}`
                  );
                }}
              >
                <AiOutlineClockCircle
                  style={{ fontSize: "1.5rem", cursor: "pointer" }}
                />
              </div>
            )}
            {/* {props.adminEmail === props.userEmail ? ( */}
            {userPermission?.readSettings && (
              <div
                className={styles.settingIconContainer}
                data-uniqueprojectid={props.uniqueProjectId}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(
                    `settings?project=${props.uniqueProjectId ?? "nullish"}`
                  );
                }}
              >
                <AiOutlineSetting
                  style={{ fontSize: "1.5rem", cursor: "pointer" }}
                />
              </div>
            )}
            {/* ) : null} */}
          </div>
        </div>

        <div className={styles.projectDetails}>
          <p className={styles.projectName}>
            <strong>{props.projectName}</strong>
          </p>
          <p>
            <strong>Status:</strong> {status}
          </p>
          <p>
            <strong>Days ago:</strong> {days}
          </p>

          <p className={styles.projectDescription}>
            <strong>Description:</strong> {props.desc}
          </p>
        </div>
      </div>
      <div className={styles.projectButtons}>
        {userPermission?.projectData && (
          <button
            className={styles.projectDataButton}
            data-uniqueprojectid={props.uniqueProjectId}
            onClick={(e) => {
              e.preventDefault();
              navigate(
                `/project?graphId=${e.target.dataset.uniqueprojectid ?? "null"}`
              );
            }}
          >
            View Data
          </button>
        )}
        <button
          className={styles.batchButton}
          data-uniqueprojectid={props.uniqueProjectId}
          onClick={(e) => {
            e.preventDefault();
            navigate(
              `/batch?graphId=${e.target.dataset.uniqueprojectid ?? "null"}`
            );
          }}
        >
          Batch
        </button>
      </div>
    </div>
  );
}

export default memo(ProjectCard);
