import React, { useState, useEffect } from "react";
import styles from "./Information.module.css";
import ProjectModel from "./UpdateProjectModel";
import { useSearchParams } from "react-router-dom";
import MapComponent from "./InformationMap";
import { useDispatch, useSelector } from "react-redux";
import { updateProjectInfo } from "../../../../../../redux/slices/projectSetting";

const Info = () => {
  // State variables for handling modal, input values, data, and location info
  const [openModel, setOpenModel] = useState(false);
  const [searchParams] = useSearchParams(); // Get search params from the URL
  const ProjectID = searchParams.get("project"); // Extract project ID from search params
  const [data, setData] = useState({}); // State to store fetched data
  const [inputValues, setInputValues] = useState(); // State for holding user input values
  const [long, setLong] = useState(null); // Longitude state
  const [lat, setLat] = useState(null); // Latitude state
  const [location, setLocation] = useState(null); // Location state

  // Retrieving token and settings data from localStorage and Redux store
  const token = localStorage.getItem("token");
  const settingsData = useSelector((state) => state.projectSetting.settingData);
  const userPermission = useSelector(
    (state) => state.organization.userPermission
  );

  const dispatch = useDispatch(); // To dispatch actions

  // Effect to set location when data.metadata is available
  useEffect(() => {
    if (data.metadata) {
      setLocation(data.metadata.Location); // Set location from metadata
    }
  }, [data]); // Only runs when data changes

  // Effect to update project info when input values are changed
  useEffect(() => {
    if (inputValues) {
      try {
        // Dispatch updateProjectInfo action with the new input values
        dispatch(
          updateProjectInfo({ token: token, updatedProjectData: inputValues })
        );
      } catch (error) {
        console.log(error); // Log any errors
      }
    }
  }, [inputValues, data]); // Runs when inputValues or data changes

  return (
    <>
      {!openModel ? (
        <div className={styles.info}>
          <h2 className={styles.headerText}>Project Information</h2>
          {settingsData && Object.keys(settingsData).length > 0 ? (
            <div className={styles.container}>
              <div className={styles.Column}>
                <div className={styles.row1}>
                  <div className={styles.projectName}>Project Name:</div>
                  <div className={styles.projectName}>
                    {settingsData?.data?.metadata?.Project || ""}
                  </div>
                </div>
                <div className={styles.row2}>
                  <div className={styles.status}>Status:</div>
                  <div
                    className={styles.statusValue}
                    style={
                      settingsData?.data?.metadata &&
                        settingsData?.data?.metadata.isVerified === 0
                        ? { color: "red" }
                        : settingsData?.data?.metadata &&
                          settingsData?.data?.metadata.isVerified === 1
                          ? { color: "green" }
                          : {}
                    }
                  >
                    {settingsData?.data?.metadata &&
                      settingsData?.data?.metadata.isVerified === 0
                      ? "Not Connected"
                      : settingsData?.data?.metadata &&
                        settingsData?.data?.metadata.isVerified === 1
                        ? "Connected"
                        : ""}
                  </div>
                </div>
                <div className={styles.row3}>
                  <div className={styles.desc}>Description:</div>
                  <div className={styles.desc}>
                    {settingsData?.data?.metadata?.Description || ""}
                  </div>
                </div>
              </div>
              <div className={styles.Column}>
                <div className={styles.row4}>
                  <div className={styles.location}>Location:</div>
                  <div className={styles.mapStyle} id="map_">
                    <MapComponent
                      location={location ?? settingsData?.data?.metadata?.Location}
                      setLong={setLong}
                      setLat={setLat}
                    />
                  </div>
                </div>
                <div className={styles.sharingOptions}>
                  Open in:&nbsp;
                  <div>
                    <a
                      className={styles.mapLinks}
                      href={`https://www.google.com/maps/search/${lat},${long}?entry=tts`}
                      target="blank"
                    >
                      Google Map
                    </a>
                  </div>
                  &nbsp;|&nbsp;
                  <div>
                    <a
                      className={styles.mapLinks}
                      href={`https://www.mappls.com/@${lat}, ${long}`}
                      target="blank"
                    >
                      Map My India
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>Loading...</div>
          )}
          {userPermission.updateProject && (
            <button
              className={styles.editBtn}
              onClick={() => setOpenModel(true)}
            >
              Edit
            </button>
          )}
        </div>
      ) : (
        <ProjectModel
          setOpenModel={setOpenModel}
          ProjectID={ProjectID}
          setInputValues={setInputValues}
          data={data}
          setLocation={setLocation}
          location={location ? location : "23.0225, 72.5714"}
        />
      )}
    </>
  );
};

export default Info;
