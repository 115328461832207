import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Register.module.css";

import RegisterForm from "./components/RegisterForm";
import ConfirmPage from "./components/ConfirmPage";
import dicot_img from "assets/Logo.svg";

const Register = ({ setPageTitle }) => {
  const [page, setPage] = useState(1); // State to decide which page to display
  const [lastPage, setLastPage] = useState(false); // State to decide lastpage
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false); // State to decide mobile view

  // On page load deciding the mobileview
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);
  }, []);

  React.useLayoutEffect(() => {
    document.title = "Register";
    if (localStorage.getItem("token") !== null) {
      //condition if not logged in
      navigate("/auth/login");
    } // eslint-disable-next-line
  }, []);

  
  const handleGetStarted = () => {
    setIsMobile(false);
  }

  return (
    <>
      {lastPage ? (
        <ConfirmPage />
      ) : (
        <div className={styles.app}>
          <div className={styles.container}>
            <div
              className={`${styles.leftSide} ${isMobile ? styles.newLeftSide : styles.forMobileLeft
                }`}
            >
              <div className={styles.textWrapper}>
                <div className={styles.welcomeWrapper}>
                  <div className={styles.visionWebText}>Welcome to</div>
                  <div className={styles.titleTextText}>Vision Web</div>
                </div>

                <div className={styles.welcomeText}>Welcome to the future</div>
                <div className={styles.infoText}>
                  Join us as we integrate both IoT and AI, to create super
                  efficient and reliable factories for the world. You can get
                  started within no time by signing up.
                </div>
                <div className={styles.joinText}>Join the Revolution.</div>
              </div>
              {isMobile ? (
                <div className={styles.startedBtnWrapper}>
                  <button className={styles.startedBtn} onClick={handleGetStarted}>
                    Get Started &gt;
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>

            <div
              className={`${styles.rightSide} ${isMobile ? styles.newRightSide : styles.forMobileRight
                }`}
            >
              <div className={styles.imgContainer}>
                <img
                  src={dicot_img}
                  alt=""
                  srcset=""
                  className={styles.logoImg}
                />
              </div>
              <div className={styles.rightSideFormWrapper}>
                <div className={styles.form}>
                  <RegisterForm
                    page={page}
                    setPage={setPage}
                    setLastPage={setLastPage}
                  />
                </div>
                <div className={styles.footerText}>
                  <span>Already Signed Up?</span>
                  <button
                    className={styles.loginBtn}
                    onClick={() => navigate("/auth/login")}
                  >
                    Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Register;
