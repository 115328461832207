import React, { useCallback, useState } from 'react';
import styles from "./InviteMember.module.css";
import openEye from "../../../../../assets/openEye.png";
import closeEye from "../../../../../assets/closeEye.png";
import { useDispatch } from 'react-redux';
import { inviteMember } from '../../../../../redux/slices/organizationSlice';
import { useToast } from '@chakra-ui/react';

const InviteMember = ({ setIsInvite, token, adminEmail, userEmail, newMemberEmail, newMemberRole }) => {

  const dispatch = useDispatch();
  const toast = useToast();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false); // State to handle password visibility

  // Function to handle invite member api 
  const handleInvite = useCallback(() => {

    const apiBody = {
      token: token,
      toEmail: newMemberEmail,
      organizationEmail: adminEmail,
      role: newMemberRole,
      userEmail: userEmail
    }

    try {
      dispatch(inviteMember(apiBody)).then((response) => {
        if (response.payload.status === 200) {
          toast({
            title: "Successful",
            description: response.payload.message,
            status: "success",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });

        } else {
          toast({
            title: "Error",
            description: response.payload.message,
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Internal Server Error",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      })
    }

    setIsInvite(false);

  }, []);

  return (
    <div className={styles.inviteMemberModal}>
      <div className={styles.modelWrapper}>
        <div className={styles.infoContainer}>
          <h2 className={styles.header}>Invite Member</h2>
          <div className={styles.inputWrapper}>

            <input
              className={styles.inputField}
              type={isPasswordVisible ? "text" : "password"}
              id="password"
              placeholder="Enter your password"
            />
            {isPasswordVisible ? (
              <img src={openEye} alt="open eye" className={styles.eyeImg} onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
            ) : (
              <img src={closeEye} alt="close eye" className={styles.eyeImg} onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
            )}
          </div>
        </div>
        <div className={styles.btnContainer}>
          <button
            className={styles.greyBtn}
            onClick={() => setIsInvite(false)}
          >
            Discard
          </button>
          <button className={styles.blueBtn} onClick={handleInvite}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  )
}

export default InviteMember