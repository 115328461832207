import styles from "./Auth2Toggle.module.css";
import DeleteModelProfile from "../Others/DeleteConfirmationModel";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";


function Auth2StepModel({ setModel }) {
  const profileData = useSelector(state => state.profile.profileData); // Stored profile data redux
  const [isToggle, setIsToggle] = useState(); // State to decide step 2 auth on/off
  const [confirmPwd, setConfirmPwd] = useState(false); // State to open/close of confirm paasword model
  const [confirmPwdLogOut, setConfirmPwdLogOut] = useState(false);
  const [logOutBody, setLogOutBody] = useState({}); // State to store api body for logout api
  const [toggleAuth, setToggleAuth] = useState({}); // State to store api body for step 2 auth api

  // Storing saved strp@auth value on page load in state 
  useEffect(() => {
    setIsToggle(profileData?.Step2Auth);
  }, [profileData]);

  // Function to toggle value of step2auth
  const handleToggle = async () => {
    setConfirmPwd(true);

    const apiBody = {
      step2: !isToggle,
      email: localStorage.getItem("email"),
    };
    setToggleAuth(apiBody);
  };

  // Function to handle model
  async function DeleteAccount(event) {
    setModel(true);
  }

  // Function to handle logout from all device
  const logoutFromALlDevice = async () => {
    const logoutApiBody = {
      step2: !isToggle,
      email: localStorage.getItem("email"),
    };
    setLogOutBody(logoutApiBody);

    setConfirmPwdLogOut(true);
  }



  return (
    <>
      <div className={styles.Auth2Step}>
        <div className={styles.toggleWrapper}>
          <label className={styles.label}> 2 Step-Authentication</label>
          <div
            className={`${styles.toggleBox} ${isToggle ? styles.active : ""}`}
            onClick={handleToggle}
          >
            <span
              className={`${styles.toggle} ${isToggle ? styles.active : ""}`}
            ></span>
          </div>
        </div>
        <button className={styles.deleteAccountBtn} onClick={logoutFromALlDevice}>Logout from All Devices</button>
        <button className={styles.deleteAccountBtn} onClick={DeleteAccount}>Delete Account</button>
      </div>
      {confirmPwd && <DeleteModelProfile setConfirmPwd={setConfirmPwd} isToggle={isToggle} setIsToggle={setIsToggle} toggleAuth={toggleAuth} type="toggle" header="Confirm password to activate 2 step-authentication" />}
      {confirmPwdLogOut && <DeleteModelProfile setConfirmPwdLogOut={setConfirmPwdLogOut} logOutBody={logOutBody} type="logout" header="Confirm password to logout from all devices" />}
    </>
  );
}

export default Auth2StepModel;
