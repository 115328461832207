import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProjectCollection from "./components/Project/ProjectCollection";
import styles from "./Dashboard.module.css";
import { gapi } from "gapi-script";
import { fetchAccountEmail } from "../../redux/slices/accountInfoSlice.js";
import { useSelector, useDispatch } from "react-redux";

function Dashboard({ setPage, setPageTitle }) {
  setPage("dashboard");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isError = useSelector((state) => state.accountInfo.isError);

  // Calling fetch account email api on page load
  useEffect(() => {
    const loginToken = localStorage.getItem("token");
    if(loginToken){

      try {
        dispatch(fetchAccountEmail({ token: loginToken }));
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        document.title = "Dashboard";
        setPageTitle("Dashboard");
        const loginToken = localStorage.getItem("token");
        if (loginToken != null) {
          if (isError) {
            setTimeout(() => {
              // condition if not logged in
              localStorage.clear("token");
              localStorage.clear("username");
              localStorage.clear("avatarURL");
              localStorage.clear("projects");
              const auth2 = gapi.auth2.getAuthInstance();
              if (auth2 != null) {
                auth2.signOut().then(() => auth2.disconnect());
              }
              navigate("/auth/login");
            }, 50);
          }
        } else {
          setTimeout(() => {
            navigate("/auth/login");
          }, 50);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.dash}>
        <ProjectCollection />
      </div>
    </div>
  );
}

export default Dashboard;
