import React, { useCallback, useState } from "react";
import styles from "./CreateRole.module.css";
import { useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { createRoleOrg } from "../../../../../redux/slices/organizationSlice.js";
import openEye from "../../../../../assets/openEye.png";
import closeEye from "../../../../../assets/closeEye.png"

const CreateRole = ({
  setCreateRoleModel,
  createRole,
  setCreateRole,
  adminEmail,
}) => {
  const toast = useToast();

  const dispatch = useDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false); // State to handle password visibility
  const projectData = useSelector((state) => state.projectData.projectData); // Stored project data from redux


  // Function to handle calling of create new role api
  const handleConfirm = useCallback(async () => {
    const inputRole = document.getElementById("role").value;
    const inputPass = document.getElementById("password").value;

    if (inputRole === "") {
      toast({
        title: "Incomplete details",
        description: "Please enter a role name",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }

    const newProjectData = [];
    projectData?.forEach((data, index) => {
      const obj = {
        projectName: data.projectName,
        projectId: data.uniqueProjectId,
        allowed: false
      }
      newProjectData.push(obj)
    })

    const apiBody = {
      organizationEmail: adminEmail,
      userEmail: localStorage.getItem("email"),
      permissions: {
        [inputRole]: {
          viewAlarm: false,
          updateAlarm: false,
          deleteAlarm: false,
          addAlarm: false,
          toggleAlarm: false,

          deleteProject: false,
          transferProject: false,
          suspendProject: false,
          updateProject: false,
          projectData: false,

          updatePermissions: false,

          readSettings: false,
          editConnectionSettings: false,
          editDeviceSettings: false,

          editView: false,
          exportReport: false,

          virtualChannel: false,
          manageOrganization: false,
          writeFeedback: false,
          dangerZone: false,
          billingInfo: false,
          projectAccess: newProjectData
        },
      },
      password: inputPass.trim(),
    };

    try {
      dispatch(createRoleOrg(apiBody)).then((response) => {
        if (response.payload.status === 200) {
          toast({
            title: "Successful",
            description: "Role Added",
            status: "success",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
          const tempCreateRole = [...createRole];
          setCreateRole([...tempCreateRole, inputRole]);
          setCreateRoleModel(false);
        } else {
          toast({
            title: "Unsuccessful",
            description: response.payload.message,
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Internal Server Error",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      })
    }
  }, [])

  return (
    <div className={styles.createRoleModel}>
      <div className={styles.modelWrapper}>
        <div className={styles.infoContainer}>
          <h2 className={styles.header}>Create Role</h2>
          <input
            className={styles.inputField}
            type="text"
            id="role"
            placeholder="Enter Role"
          />
          <div className={styles.inputWrapper}>

            <input
              className={styles.inputField}
              type={isPasswordVisible ? "text" : "password"}
              id="password"
              placeholder="Enter your password"
            />
            {isPasswordVisible ? (
              <img src={openEye} alt="open eye" className={styles.eyeImg} onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
            ) : (
              <img src={closeEye} alt="close eye" className={styles.eyeImg} onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
            )}
          </div>
        </div>
        <div className={styles.btnContainer}>
          <button
            className={styles.greyBtn}
            onClick={() => setCreateRoleModel(false)}
          >
            Discard
          </button>
          <button className={styles.blueBtn} onClick={() => handleConfirm()}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};
export default CreateRole;
