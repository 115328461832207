import React, { useEffect } from "react";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import AddProjectMap from "./AddProjectMap";
import styles from "./AddProject.module.css";

import { addProject } from "../../../../redux/slices/projectSlice.js";
import { useDispatch } from "react-redux";
import { getUserPermission } from "../../../../redux/slices/organizationSlice.js";

const AddProject = ({ setIsAddProject }) => {
  const toast = useToast();
  const [data, setData] = useState({
    ptitle: "",
    pdescription: "",
    plocation: "",
  });

  const dispatch = useDispatch();

  const token = localStorage.getItem("token");
  const orgEmail = localStorage.getItem("organizationEmail");
  const userEmail = localStorage.getItem("email");
  const handleChange = async ({ currentTarget: input }) => {
    await setData({ ...data, [input.name]: input.value });
  };


  const onAdd = async () => {
    try {
      const newProject = {
        projectName: data.ptitle,
        projectOnlineStatus: false,
        location: data.plocation || "23.0225, 72.5714",
        desc: data.pdescription,
      }

      dispatch(addProject({ token, newProject, orgEmail }))
        .then((response) => {
          if (response.payload.responseMessage.status === 200) {
            const data = {
              organizationEmail: orgEmail,
              userEmail: userEmail
            }
            dispatch(getUserPermission(data))
            toast({
              title: "New project added successfully",
              description: "Yey, You successfully created a new project",
              status: "success",
              position: "top-right",
              duration: 5000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Can't add a new project!",
              description: `Something bad happened while creating a new project`,
              status: "error",
              position: "top-right",
              duration: 5000,
              isClosable: true,
            });
          }
          setIsAddProject(false);
        })
    } catch (error) {
      console.log(error);
      toast({
        title: "Internal Server Error",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      })
    }
  };


  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          <div className={styles.header}>
            Add Project
          </div>
          <div className={styles.createProject}>
            <div >
              <input
                className={styles.inputField}
                type="text"
                id="project-title"
                placeholder="Project Title"
                name="ptitle"
                onChange={handleChange} />
            </div>
            <div
              id="map_"
              className={styles.mapStyle}>
              <AddProjectMap setData={setData} />
            </div>
            <div className={styles.gap}>
              <textarea
                className={styles.textarea}
                id="project-desc"
                placeholder="Project Description"
                onChange={handleChange}
                name="pdescription" />
            </div>
            <div className={styles.footer}>
              <button className={styles.cancel} onClick={() => setIsAddProject(false)}>
                Cancel
              </button>
              <button className={styles.add} onClick={onAdd}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default AddProject
