import React from "react";
import styles from "./AlarmCard.module.css";
import Toggle from "./Toggle";
import { useDispatch } from "react-redux";
import { setTrigger } from "../../../../../../redux/slices/alarmSlice.js";


const AlarmCard = ({ deleteAlarm, alarm, setSelectedCard, openEditModel, setSelectedTriggerType }) => {

  const dispatch = useDispatch()

  // Function to open edit model
  const handleEdit = () => {
    setSelectedCard(alarm);
    openEditModel();
  };

  // Function to handle on/off trigger
  const handleSetTrigger = () => {
    try {
      const apiBody = {
        projectId: alarm.projectId,
        alarmId: alarm.alarmId,
        value: !alarm.triggerValue
      }
      dispatch(setTrigger(apiBody)).then((response) => {
        console.log(response);
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className={styles.alarmRows}>
        <div className={styles.alarmBodyWrapper}>
          <div className={styles.toggleWrapper}>
            <Toggle on={alarm.on !== undefined ? alarm.on : true} alarm={alarm} projectId={alarm.Project_id} />
          </div>
          <div className={styles.row}>
            <div className={styles.alname}>{alarm.alarmName}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.channelName}>Channel Name:</div>
            <div>{alarm.channel}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.value}>Value:</div>
            <div>{alarm.value}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.function}>Function:</div>
            <div>{alarm.operation}</div>
          </div>
          {/* <div className={styles.row}>
            <div className={styles.alarmType}>AlarmType:</div>
          </div> */}
          <div className={styles.alarmTypeWrapper}>
            <div className={styles.triggerSelect}>
              <div className="">Trigger:</div>
              <div className="">{alarm.triggerType}</div>
            </div>
            <div className={`${styles.toggleBox} ${alarm.triggerValue ? styles.active : ""}`} onClick={handleSetTrigger}>
              <span className={`${styles.toggle} ${alarm.triggerValue ? styles.active : ''}`} ></span>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.notiMedia}>Notification Media:</div>
            <div>{alarm.medium}</div>
          </div>
          <div className={styles.row}>
            <div className={styles.lastHit}>Last hit:</div>
            <div className={styles.timehit}>{alarm.timehit}</div>
          </div>
        </div>
        <div className={styles.buttonsWrapper}>
          <button className={styles.alarmBtn} onClick={handleEdit}>Edit</button>
          <button className={styles.deleteBtn} onClick={() => deleteAlarm(alarm.alarmId)}>Delete</button>
        </div>
      </div>
    </>
  );
};

export default AlarmCard;
