import React, { useEffect, useState } from "react";
import styles from "./LogCollection.module.css";
import LogData from "./LogData";
import { useLocation } from "react-router-dom";
import { Input } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { getAlarmLog } from "../../../../../../../redux/slices/alarmLogSlice.js";

const LogsCard = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uniqueProjectId = searchParams.get("project");
  const [selectedAlarm, setSelectedAlarm] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const dispatch = useDispatch();
  const data = useSelector((state) => state.alarmLogs.alarmLogData);
  const [filteredData, setFilteredData] = useState(data);
  const alarmNames = useSelector((state) => state.alarmLogs.alarmNames);

  //Request to Fetch Alarm Logs

  useEffect(() => {
    dispatch(getAlarmLog(uniqueProjectId));
  }, [uniqueProjectId]);

  const handleAlarmName = (e) => {
    const selectedValue = e.target.value;
    setSelectedAlarm(selectedValue);

    const filteredDataByName = data.filter((item) => {
      if (selectedValue === "All Alarm Logs") {
        return true; // Include all items
      }
      return item.alarmName === selectedValue;
    });

    setFilteredData(filteredDataByName);
  };

  const filterDataByDate = () => {
    const filteredDataByDate = data.filter((item) => {
      const logDate = new Date(item.time);
      return logDate >= startDate && logDate <= endDate;
    });

    setFilteredData(filteredDataByDate);
  };

  // Storing Start Date For Filter In State
  const handleStartChange = (e) => {
    const date = new Date(e.target.value);
    setStartDate(date);
  };

  // Storing End Date For Filter In State
  const handleEndChange = (e) => {
    const date = new Date(e.target.value);
    setEndDate(date);
  };

  useEffect(() => {
    filterDataByDate();
  }, [startDate, endDate]);

  // Unique Alarm Names
  const uniqueAlarmNames = [...new Set(alarmNames)];

  return (
    <>
      <div className={styles.options}>
        <div className={styles.filter}>
          <div className={styles.label}>Filters:</div>
          <select
            className={styles.filters}
            onChange={handleAlarmName}
            value={selectedAlarm}
          >
            {/* Alarm name options */}
            <option value="All Alarm Logs">All Alarm Logs</option>
            {uniqueAlarmNames.map((element, elIndex) => (
              <option value={element} key={elIndex}>
                {element}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.selectWrapper}>
          {/* Calender Input To Select Start date*/}
          <Input
            size="sm"
            style={{ border: "none", borderRadius: "0.525rem", backgroundColor: "#fff", boxShadow: " 0 0 5px 0 rgba(20, 20, 20, 0.5)" }}
            onChange={handleStartChange}
            type="datetime-local"
            placeholder="Select start date and time"
          />
          <div className={styles.toText}>to</div>
          {/* Calender Input To Select End date*/}
          <Input
            size="sm"
            style={{ border: "none", borderRadius: "0.525rem", backgroundColor: "#fff", boxShadow: " 0 0 5px 0 rgba(20, 20, 20, 0.5)" }}
            onChange={handleEndChange}
            type="datetime-local"
            placeholder="Select end date and time"
          />
        </div>
        <button className={styles.filterBtn}>Filter</button>
      </div>
      <div className={styles.logsCard}>
        <div className={styles.cards}>
          {/* Mapping and Rendering Data */}
          {filteredData.length > 0 ? (
            filteredData.map((log, index) => (
              <div className={styles.cardWrapper} key={index}>
                <LogData data={log} key={index} />
              </div>
            ))
          ) : data.length > 0 ? (
            data.map((log, index) => (
              <div className={styles.cardWrapper} key={index}>
                <LogData data={log} key={index} />
              </div>
            ))
          ) : (
            <div className={styles.noDataMessage}>Loading Alarm Logs...</div>
          )}
        </div>
      </div>
    </>
  );
};

export default LogsCard;
