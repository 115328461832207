import React, { useState, useMemo, useEffect, useCallback } from "react";
import styles from "./UpdateProfile.module.css";
import close from "assets/close.png";
import DeleteModelProfile from "./DeleteConfirmationModel";
import countryList from "react-select-country-list";
import { useSelector } from "react-redux";

const ModelProfile = ({ setOpenModel, setInputValues, updateProfileInfo, data }) => {
  // option of countries
  const options = useMemo(
    () =>
      countryList()
        .getLabels()
        .map((item) => item.toString()),
    []
  );

  const [isPasswordModal, setIsPasswordModal] = useState(false); // State to handle display of password model
  const [error, setError] = useState(null);
  const profileData = useSelector((state) => state.profile.profileData); // Stored profile data from redux
  const [country, setCountry] = useState(profileData?.country ?? ""); // State to store value of country option

  // Function to handle new data of profile
  const handleSave = useCallback(() => {

    const gstNumber = document.getElementById("gstNumber").value;

    if (gstNumber.length !== 15) {
      setError("*GST Number must be exactly 15 characters long");
      setTimeout(() => {
        setError(null);
      }, [2000]);
      return;
    } else {
      setError(null);
    }

    const isValidPhoneNumber = (phoneNumber) => {
      const phonePattern = /^\+\d{12}$/;

      return phonePattern.test(phoneNumber);
    };

    const phoneNumber = document.getElementById("phoneNumber").value;
    if (!isValidPhoneNumber(phoneNumber)) {
      setError(
        "*Phone number must start with an international dialing code (+) followed by exactly 10 digits."
      );
      setTimeout(() => {
        setError(null);
      }, [3000]);
      return;
    } else {
      setError(null);
    }

    const modifiedValues = {
      firstName: document.getElementById("firstName").value,
      lastName: document.getElementById("lastName").value,
      name: document.getElementById("companyName").value,
      country: country,
      city: document.getElementById("city").value,
      fullAddress: document.getElementById("address").value,
      state: document.getElementById("state").value,
      phoneNumber: phoneNumber,
      gstNumber: gstNumber,
      pincode: document.getElementById("pincode").value,
      email: data.email,
    };
    setInputValues(modifiedValues);

    setIsPasswordModal(true);

  }, []);

  // Function for closing of model
  const handleCloseModel = () => {
    setOpenModel(false);
  };

  // Function to store selected country option in state
  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption.target.value);
  };

  return (
    <>
      {isPasswordModal && (
        <DeleteModelProfile type="updateProfilePwd" updateProfileInfo={updateProfileInfo} header="Confirm password to update your profile" setIsPasswordModal={setIsPasswordModal} setOpenModel={setOpenModel} />
      )}
      <div className={styles.modelProfile}>
        <div className={styles.modelWrapper}>
          <div className={styles.modelHeader}>
            <h3 className={styles.headerText}>Edit Profile</h3>
            <button className={styles.closeBtn} onClick={handleCloseModel}>
              <img src={close} alt="" />
            </button>
          </div>
          <div className={styles.inputWrapper}>
            <input
              className={styles.countrySelector}
              type="text"
              defaultValue={profileData.firstName}
              placeholder="First Name"
              id="firstName"
            />
            <input
              className={styles.countrySelector}
              type="text"
              defaultValue={profileData.lastName}
              placeholder="Last Name"
              id="lastName"
            />
            <input
              className={styles.countrySelector}
              type="text"
              defaultValue={profileData.contactNumber}
              placeholder="Phone Number"
              id="phoneNumber"
            />
            <input
              className={styles.countrySelector}
              type="text"
              defaultValue={profileData.name}
              placeholder="Company Name"
              id="companyName"
            />
            <input
              className={styles.countrySelector}
              type="text"
              defaultValue={profileData.fullAddress}
              placeholder="Address"
              id="address"
            />
            <input
              className={styles.countrySelector}
              type="text"
              defaultValue={profileData.city}
              placeholder="City"
              id="city"
            />
            <input
              className={styles.countrySelector}
              type="text"
              defaultValue={profileData.state}
              placeholder="State"
              id="state"
            />
            <select
              className={styles.countrySelector}
              onChange={handleCountryChange}
              id="country"
              defaultValue={profileData.country}
            >
              {options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <input
              className={styles.countrySelector}
              type="text"
              defaultValue={profileData.pincode}
              placeholder="Pincode"
              id="pincode"
            />
            <input
              className={styles.countrySelector}
              type="text"
              defaultValue={profileData.gstNumber}
              placeholder="GST Number"
              id="gstNumber"
            />
          </div>
          <button className={styles.editBtn} onClick={handleSave} >
            Save
          </button>

          {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
      </div>

    </>
  );
};

export default ModelProfile;
