import React from "react";
import { useState, useMemo } from "react";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import proxy from "proxy.js";
import styles from "./RegisterForm.module.css";
import openEye from "assets/openEye.png";
import closeEye from "assets/closeEye.png";
import countryList from "react-select-country-list";


const InputForm = ({ page, setPage, setLastPage }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isClosedEye1, setIsClosedEye1] = useState(true); // State to handle display of eye icon
  const [isClosedEye2, setIsClosedEye2] = useState(true); // State to handle display of eye icon
  const [country, setCountry] = useState(); // State to store value of selected country
  const [passwordType1, setPasswordType1] = useState("password"); // State to handle display of password
  const [passwordType2, setPasswordType2] = useState("password"); // State to handle display of password
  const [data, setData] = useState({
    email: "",
    password: "",
    country: ""

  }); // State to store user information

  // Options of countries
  const options = useMemo(
    () =>
      countryList()
        .getLabels()
        .map((item) => item.toString()),
    []
  );

  // Storing the selected country value
  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  // Function to handle visibility of password
  const togglePasswordVisibility1 = () => {
    setIsClosedEye1(!isClosedEye1);
    setPasswordType1(passwordType1 === "password" ? "text" : "password");
  };

  const togglePasswordVisibility2 = () => {
    setIsClosedEye2(!isClosedEye2);
    setPasswordType2(passwordType2 === "password" ? "text" : "password");
  };

  // Function to add country value in user information
  const handleChange = ({ currentTarget: input }) => {

    setData({ ...data, country: country, [input.name]: input.value });
  };

  // Function to decide valid password
  const validatePassword = (password) => {
    // Password pattern: at least one uppercase, one lowercase, one number,
    // one special character, and length between 8 and 20.
    const passwordPattern =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
    return passwordPattern.test(password);
  };

  // Function to handle register api call
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validatePassword(data.password.trim())) {
      toast({
        title: "Invalid Password",
        description:
          "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be 8 to 20 characters long.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (data.password.trim() !== data.confirmPassword.trim()) {
      toast({
        title: "Passwords do not match",
        description:
          "Please make sure the password and confirm password are the same.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      const copiedData = { ...data };

      copiedData.email = copiedData.email.trim();
      copiedData.password = copiedData.password.trim();
      copiedData.confirmPassword = copiedData.confirmPassword.trim();

      const url = proxy + "/auth/register";
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(copiedData),
      };
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();
      if (responseData.status === 403) {
        setLastPage(true);
        toast({
          title: "Verify Email to login!",
          description: responseData.message,
          status: "warning",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });


      } else {
        if (responseData.status === 404) {
          toast({
            title: "Oops 🤖",
            description: responseData.message,
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
        } else if (!responseData.token) {
          toast({
            title: "Something bad happened!",
            description: "Incorrect email or password",
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
        } else {
          localStorage.setItem("token", responseData.token);
          toast({
            title: "Logged in!",
            description: "user logged in successfully",
            status: "success",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
        }
        navigate("/");
      }
    } catch (error) {
      console.log(error)
    }
  };

  // Function to go on next page
  const handleNextPage = () => {
    if (page < 3) {
      setPage((prev) => prev + 1);
    }
  };

  // Function to go on previous page
  const handleBackButton = () => {
    if (page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  return (
    <div className={styles.inputForm}>
      <div className={styles.container}>
        <div className={styles.pageNum}>
          {page}/{3}
        </div>
        <div className={styles.headerWrapper}>
          <div className={styles.title}>Register</div>
          <div className={styles.pageInfo}>
            {page === 1 && "You are a few steps from the awesomeness🤘"}
            {page === 2 && "Almost there 🏃"}
            {page === 3 && "Final Step!"}
          </div>
        </div>
        <div className={styles.inputWrapper}>
          {page === 1 && (
            <>
              <input
                type="text"
                placeholder="Enter Email ID"
                onChange={handleChange}
                name="email"
                value={data && data.email}
              />
              <div className={styles.passWrapper}>
                <input
                  type={passwordType1}
                  placeholder="Enter Password"
                  onChange={handleChange}
                  name="password"
                  value={data && data.password}
                />
                {!isClosedEye1 ? (
                  <img
                    src={openEye}
                    alt="Open Eye"
                    srcSet=""
                    onClick={togglePasswordVisibility1}
                  />
                ) : (
                  <img
                    src={closeEye}
                    alt="Close Eye"
                    srcSet=""
                    onClick={togglePasswordVisibility1}
                  />
                )}
              </div>
              <div className={styles.passWrapper}>
                <input
                  type={passwordType2}
                  placeholder="Confirm Password"
                  onChange={handleChange}
                  name="confirmPassword"
                  value={data && data.confirmPassword}
                />
                {!isClosedEye2 ? (
                  <img
                    src={openEye}
                    alt="Open Eye"
                    srcSet=""
                    onClick={togglePasswordVisibility2}
                  />
                ) : (
                  <img
                    src={closeEye}
                    alt="Close Eye"
                    srcSet=""
                    onClick={togglePasswordVisibility2}
                  />
                )}
              </div>
            </>
          )}
          {page === 2 && (
            <>
              <input
                type="text"
                placeholder="First Name"
                onChange={handleChange}
                name="firstName"
                value={data && data.firstName}
              />
              <input
                type="text"
                placeholder="Last Name"
                onChange={handleChange}
                name="lastName"
                value={data && data.lastName}

              />
              <input
                type="text"
                placeholder="Contact Number"
                onChange={handleChange}
                name="contactNumber"
                value={data && data.contactNumber}

              />
              <input
                type="text"
                placeholder="Organization"
                onChange={handleChange}
                name="organization"
                value={data && data.organization}

              />
            </>
          )}
          {page === 3 && (
            <>
              <select
                className={styles.countrySelector}
                onChange={handleCountryChange}
                id="country"
                name="country"
                value={country}
              >
                {options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <input
                type="text"
                placeholder="State"
                onChange={handleChange}
                name="state"
                value={data && data.state}

              />
              <input
                type="text"
                placeholder="Address"
                onChange={handleChange}
                name="address"
                value={data && data.address}

              />
            </>
          )}
        </div>
        <div
          className={`${styles.btnWrapper} ${page > 1 ? styles.additionalStyle : ""
            }`}
        >
          {page > 1 && (
            <button className={styles.backBtn} onClick={handleBackButton}>
              &lt; Back
            </button>
          )}
          <button
            className={styles.nextBtn}
            onClick={page === 3 ? handleSubmit : handleNextPage}
          >
            {page === 3 ? "Done" : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InputForm;
