import React, { useCallback, useState } from "react";
import styles from "./PasswordModel.module.css";
import close from "assets/close.png";
import openEye from "assets/openEye.png";
import closeEye from "assets/closeEye.png";

const PasswordModel = ({ setPassModel, setPasswordValues }) => {
  // States to display eye icon on input
  const [isClosedEye1, setIsClosedEye1] = useState(true);
  const [isClosedEye2, setIsClosedEye2] = useState(true);
  const [isClosedEye3, setIsClosedEye3] = useState(true);

  // States to handle display of password
  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");
  const [passwordType3, setPasswordType3] = useState("password");


  // Functions to handle password visibility 
  const togglePasswordVisibility1 = () => {
    setIsClosedEye1(!isClosedEye1);
    setPasswordType1(passwordType1 === "password" ? "text" : "password");
  };

  const togglePasswordVisibility2 = () => {
    setIsClosedEye2(!isClosedEye2);
    setPasswordType2(passwordType2 === "password" ? "text" : "password");
  };
  const togglePasswordVisibility3 = () => {
    setIsClosedEye3(!isClosedEye3);
    setPasswordType3(passwordType3 === "password" ? "text" : "password");
  };

  // Function to handle save password
  const handleSavePassword = useCallback(() => {
    const currentPassword = document.getElementById("currentPassword").value;
    const newPassword = document.getElementById("newPassword").value;
    const confirmNewPassword = document.getElementById("confirmNewPassword").value;

    const newValues = {
      email: localStorage.getItem("email"),
      currentPassword: currentPassword.trim(),
      confirmNewPassword: confirmNewPassword.trim(),
      newPassword: newPassword.trim(),
    };
    setPasswordValues(newValues);
  }, []);

  return (
    <div className={styles.passwordModel}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.headerText}>Change Password</h2>
          <button
            className={styles.closeBtn}
            onClick={() => setPassModel(false)}
          >
            <img src={close} alt="" srcSet="" />
          </button>
        </div>
        <div className={styles.inputWrapper}>
          <div className={styles.row1}>
            <input
              type={passwordType1}
              placeholder="Current Password"
              id="currentPassword"
              required
              className={styles.passwordInput}
            />
            {!isClosedEye1 ? (
              <img
                src={openEye}
                alt="Open Eye"
                srcSet=""
                onClick={togglePasswordVisibility1}
                className={styles.eyeImage}
              />
            ) : (
              <img
                src={closeEye}
                alt="Close Eye"
                srcSet=""
                onClick={togglePasswordVisibility1}
                className={styles.eyeImage}
              />
            )}
          </div>
          <div className={styles.row2}>
            <input
              type={passwordType2}
              placeholder="New Password"
              id="newPassword"
              required
              className={styles.passwordInput}

            />
            {!isClosedEye2 ? (
              <img
                src={openEye}
                alt="Open Eye"
                srcSet=""
                onClick={togglePasswordVisibility2}
                className={styles.eyeImage}
              />
            ) : (
              <img
                src={closeEye}
                alt="Close Eye"
                srcSet=""
                onClick={togglePasswordVisibility2}
                className={styles.eyeImage}
              />
            )}
          </div>
          <div className={styles.row3}>
            <input
              type={passwordType3}
              placeholder="Confirm Password"
              id="confirmNewPassword"
              required
              className={styles.passwordInput}

            />
            {!isClosedEye3 ? (
              <img
                src={openEye}
                alt="Open Eye"
                srcSet=""
                onClick={togglePasswordVisibility3}
                className={styles.eyeImage}
              />
            ) : (
              <img
                src={closeEye}
                alt="Close Eye"
                srcSet=""
                onClick={togglePasswordVisibility3}
                className={styles.eyeImage}
              />
            )}
          </div>
        </div>
        <button className={styles.saveBtn} onClick={handleSavePassword}>
          Save
        </button>
      </div>
    </div>
  );
};

export default PasswordModel;
