import { React, useState, useEffect, useMemo, useCallback } from "react";

import GraphData from "./GraphData";
import styles from "./Day1.module.css";
import { Tabs, TabList, Tab, TabPanel, TabPanels } from "@chakra-ui/react";
import WorkerBuilder from "Utils/builder.worker";
import oneDayDataRestructureWorker from "Utils/oneDay.worker";
import valueExtractorWorker from "Utils/oneDaySocket.worker";
import Day1Table from "./Day1Comp/Day1Table";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";

const restructureWorker = new WorkerBuilder(oneDayDataRestructureWorker);
const valueExtractor = new WorkerBuilder(valueExtractorWorker);
const Day1 = ({
  data,
  socketData,
  selectedTabIndex,
  openNewPage,
  tabIndexLastDayTable,
  tabIndexLastDayGraph,
  handleLastDayTableDrop,
  handleLastDayTableDimension,
  handleLastDayGraphDrop,
  handleLastDayGraphDimension,
  tempTabIndexLastDayGraph,
  tempTabIndexLastDayTable,
  isMobile,
  scaleValue
}) => {
  const [dateTimesLive, setDateTimesLive] = useState([]); // DateTime labels for y-axis
  const [channelNames, setChannelNames] = useState([]); //  Channel names
  const [isWorkerBusy, setIsWorkerBusy] = useState(false);

  const [chartDataLive, setChartDataLive] = useState({
    labels: [],
    datasets: [],
  }); // data ready for display

  const [dataArrLive, setDataArrLive] = useState([]); // Real-time data received
  const [init, setInit] = useState(false);

  const [oneDayDataTs, setOneDayDataTS] = useState([]); // Data with timestamp for 1 day table
  const graphData = useSelector((state) => state.viewData.graphData);


  useEffect(() => {
    // Parsing data from from get request in the parent component
    const fetchData = async () => {
      try {
        if (graphData.length > 0) {
          const copiedDataset = [...graphData];
          // Restructure Data for Table
          const tableDataArrayGen = async () => {
            const dataPromise = new Promise((resolve) => {
              restructureWorker.onmessage = (e) => {
                resolve(e.data);
              };
            });
            restructureWorker.postMessage({ data: copiedDataset });
            return await dataPromise;
          };
          const tableDataArray = await tableDataArrayGen();
          if (tableDataArray.headers.length > 0) {
            setChannelNames(tableDataArray.headers);
            setDataArrLive(tableDataArray.chartArray);
            setDateTimesLive(tableDataArray.timeArray);
            setOneDayDataTS(tableDataArray.decimatedTableArray);
            setInit(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [graphData]);

  useEffect(() => {
    setChartDataLive({
      labels: dateTimesLive.map((item) => {
        const date = new Date(item);
        if (isNaN(date)) {
          return 0;
        }
        return date.getTime();
      }),
      datasets: dataArrLive,
    });
  }, [dateTimesLive]);

  const memorizedTable = useMemo(() => {
    return oneDayDataTs;
  }, [oneDayDataTs]);

  useEffect(() => {
    try {
      const updateData = async () => {
        if (socketData && init && !isWorkerBusy) {
          setIsWorkerBusy(true);
          const structuredData = async () => {
            const dataPromise = new Promise((resolve) => {
              valueExtractor.onmessage = (e) => {
                resolve(e.data);
              };
            });
            valueExtractor.postMessage({
              socketData: socketData,
              currentTable: oneDayDataTs,
              dateTimeArray: dateTimesLive,
              dataArray: dataArrLive,
              headers: channelNames,
              duration: 24,
            });
            return await dataPromise;
          };

          const workerResponse = await structuredData();
          if (workerResponse.updatedTable) {
            setChannelNames(workerResponse.updatedHeaders);
            setOneDayDataTS(workerResponse.updatedTable);
            setDataArrLive(workerResponse.updatedLiveArray);
            setDateTimesLive(workerResponse.updatedDateTimeArray);
          }
        }
      };
      updateData();
    } catch (error) {
      console.log(error);
    }
    setIsWorkerBusy(false);
  }, [socketData]);

  const generateLastDayTable = useCallback(() => {
    const selectedTabDevices = tabIndexLastDayTable[selectedTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices?.map((device, index) => {
        const { x, y, id, width, height } = device;
        const deviceStyle = {
          position: "absolute",
          left: x ? x + "px" : "10px",
          top: y ? y + "px" : "135px",
          resize: openNewPage ? "both" : "",
          width: isMobile ? "100%" : width !== null ? width : "37%",
          height: height !== null ? height : "auto",
          scale: isMobile ? scaleValue.toString() : "1",
          zIndex: "1"
        };
        return (
          <>
            <div style={deviceStyle}>
              <Day1Table
                channelNames={channelNames}
                oneDayDataTs={oneDayDataTs}
              />
            </div>
          </>
        );
      });
    } else {
      const deviceStyle = {
        position: "absolute",
        left: "10px",
        top: "135px",
        width: isMobile ? "100%" : "37%",
        scale: isMobile ? scaleValue.toString() : "1",
        zIndex: "1"

      };
      return (
        <>
          <div style={deviceStyle}>
            <Day1Table
              channelNames={channelNames}
              oneDayDataTs={oneDayDataTs}
            />
          </div>
        </>
      );
    }
  }, [tabIndexLastDayTable, selectedTabIndex, channelNames, oneDayDataTs, openNewPage, scaleValue, isMobile]);

  const tempGenerateLastDayTable = useCallback(() => {
    const selectedTabDevices = tempTabIndexLastDayTable[selectedTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices?.map((device, index) => {
        const { x, y, id, width, height } = device;
        const deviceStyle = {
          position: "absolute",
          left: x ? x + "px" : "10px",
          top: y ? y + "px" : "135px",
          resize: openNewPage ? "both" : "",
          width: isMobile ? "100%" : width !== null ? width : "37%",
          height: height !== null ? height : "auto",
          scale: isMobile ? scaleValue.toString() : "1",
          zIndex: "1"

        };
        return (
          <>
            <Draggable bounds="parent"
              onStop={(e, data) => handleLastDayTableDrop(e, data, id, x, y)}
            >
              <div
                style={deviceStyle}
                id="lastDayTable"
                onContextMenu={(e) => handleLastDayTableDimension(e, id)}
              >
                <Day1Table
                  channelNames={channelNames}
                  oneDayDataTs={oneDayDataTs}
                />
              </div>
            </Draggable>
          </>
        );
      });
    } else {
      const deviceStyle = {
        position: "absolute",
        left: "10px",
        top: "135px",
        width: isMobile ? "100%" : "37%",
        scale: isMobile ? scaleValue.toString() : "1",
        zIndex: "1"
      };
      return (
        <>
          <Draggable bounds="parent"
            onStop={(e, data) => handleLastDayTableDrop(e, data)}>
            <div style={deviceStyle} onContextMenu={(e) => handleLastDayTableDimension(e)}>
              <Day1Table
                channelNames={channelNames}
                oneDayDataTs={oneDayDataTs}
              />
            </div>
          </Draggable>
        </>
      );
    }
  }, [tempTabIndexLastDayTable, channelNames, oneDayDataTs, handleLastDayTableDimension, handleLastDayTableDrop, selectedTabIndex, openNewPage, isMobile, scaleValue]);

  const generateLastDayGraph = useCallback(() => {
    const selectedTabDevices = tabIndexLastDayGraph[selectedTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices?.map((device, index) => {
        const { x, y, id, width, height } = device;
        console.log(x, y)
        const deviceStyle = {
          position: "absolute",
          left: x !== null ? x + "px" : "520px",
          top: y !== null ? y + "px" : "85px",
          width: isMobile ? "150%" : width !== null ? width : "53%",
          height: height !== null ?? height,
          resize: openNewPage ? "both" : "",
          scale: isMobile ? scaleValue.toString() : "1",
          zIndex: "1"

        };
        return (
          <>
            <div style={deviceStyle} id="lastDayGraph">
              <GraphData
                viewData={chartDataLive}
                historical={false}
                res={100}
              />
            </div>
          </>
        );
      });
    } else {
      const deviceStyle = {
        position: "absolute",
        left: "520px",
        top: "85px",
        width: isMobile ? "150%" : "53%",
        resize: openNewPage ?? "both",
        scale: isMobile ? scaleValue.toString() : "1",
        zIndex: "1"

      };
      return (
        <>
          <div style={deviceStyle}>
            <GraphData
              viewData={chartDataLive}
              historical={false}
              res={100}
            />
          </div>
        </>
      );
    }
  }, [tabIndexLastDayGraph, selectedTabIndex, chartDataLive, openNewPage, scaleValue,isMobile]);

  const tempGenerateLastDayGraph = useCallback(() => {
    const selectedTabDevices = tempTabIndexLastDayGraph[selectedTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices?.map((device, index) => {
        const { x, y, id, width, height } = device;

        const deviceStyle = {
          position: "absolute",
          left: x !== null ? x + "px" : "520px",
          top: y !== null ? y + "px" : "85px",
          width: isMobile ? "150%" : width !== null ? width : "53%",
          height: height !== null ?? height,
          resize: openNewPage ? "both" : "",
          scale: isMobile ? scaleValue.toString() : "1",
          zIndex: "1"
        };
        return (
          <>
            <Draggable bounds="parent"
              onStop={(e, data) => handleLastDayGraphDrop(e, data, id, x, y)}
            >
              <div
                style={deviceStyle}
                id="lastDayGraph"
                onContextMenu={(e) => handleLastDayGraphDimension(e, id)}
              >
                <GraphData
                  viewData={chartDataLive}
                  historical={false}
                  res={100}
                />
              </div>
            </Draggable>
          </>
        );
      });
    } else {
      const deviceStyle = {
        position: "absolute",
        left: "520px",
        top: "85px",
        width: isMobile ? "150%" : "53%",
        resize: openNewPage ?? "both",
        scale: isMobile ? scaleValue.toString() : "1",
        zIndex: "1"

      };
      return (
        <>

          <Draggable bounds="parent" onStop={(e, data) => handleLastDayGraphDrop(e, data)}>
            <div style={deviceStyle} id="lastDayGraph" onContextMenu={(e) => handleLastDayGraphDimension(e)}>
              <GraphData
                viewData={chartDataLive}
                historical={false}
                res={100}
              />
            </div>
          </Draggable>

        </>
      );
    }
  },[tempTabIndexLastDayGraph, chartDataLive, handleLastDayGraphDrop , handleLastDayGraphDimension, isMobile, scaleValue, selectedTabIndex ,openNewPage]);

  // Add media query for mobile view
  const isMobileView = window.matchMedia("(max-width: 768px)").matches;

  return (
    <div className={styles.wrapper}>

      <div className={styles.data_container}>

        {openNewPage ? (
          <>
            {tempGenerateLastDayTable()}
            {tempGenerateLastDayGraph()}
          </>
        ) : (
          <>
            {generateLastDayTable()}
            {generateLastDayGraph()}
          </>

        )}

      </div>

    </div>
  );
};

export default Day1;
