import React, { useCallback, useEffect, useState } from 'react'
import styles from "./ExplorePlan.module.css";
import { useDispatch, useSelector } from 'react-redux';
import proxy from 'proxy';
import { createOrder } from '../../../redux/slices/billingSlice.js';
import { useToast } from '@chakra-ui/react';

const loadScript = (src) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve();
        script.onerror = (error) => reject(error);
        document.body.appendChild(script);
    });
};

const ExplorePlan = () => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState("Plans"); // State to handle tab
    const [activeTimeOption, setActiveTimeOption] = useState("Yearly"); // State to handle time option
    const [clickedCardIndex, setClickedCardIndex] = useState(null); // State to store selected card index
    const [value, setValue] = useState(1);
    const [plans, setPlans] = useState([]);// State to store plan details
    const planDetails = useSelector((state) => state.billing.planDetails); // Stored plan details from redux
    const billingInfo = useSelector(state => state.billing.billingInfo); // Stored billing info from redux
    const [addOnPlans, setAddonPlans] = useState([]); // State to store addon plan details
    const toast = useToast();

    // On page load storing plan details in states
    useEffect(() => {

        let selectedPlan = '';
        if (billingInfo.currentPlan === "P") {
            selectedPlan = "Pro"
        } else if (billingInfo.currentPlan === "L") {
            selectedPlan = "Lite"
        } else if (billingInfo.currentPlan === "S") {
            selectedPlan = "Starter"
        } else {
            selectedPlan = ''
        }


        if (selectedPlan !== '') {

            const displayedPlan = planDetails.slice(0, 3);

            const selectedPlanObj = displayedPlan.find((card) => card.planName === selectedPlan)
            // Find the index of the received plan name
            const receivedPlanIndex = displayedPlan.findIndex((card) => card.planName === selectedPlan)

            // If the received plan name is not found, return the original array


            // Swap the received plan name with the plan name in the middle (which will always be the second element in a 3-element array)
            const middleIndex = 1;
            const middlePlanName = displayedPlan[middleIndex];
            displayedPlan[middleIndex] = selectedPlanObj;
            displayedPlan[receivedPlanIndex] = middlePlanName;

            setPlans(displayedPlan);
        } else {
            setPlans(planDetails.slice(0, 3))
        }


        setAddonPlans(planDetails.slice(3, 5));



    }, [planDetails, billingInfo])

    // function to increase number of plans
    const handleIncrease = () => {
        setValue(value + 1)
    }

    // function to decrease number of plans
    const handleDecrease = () => {
        if (value > 1) {
            setValue(value - 1)
        }
    }

    useEffect(() => {
        setValue(1);
    }, [clickedCardIndex])

    // Function to handle buy plan
    const handleBuyPlan = async (index) => {

        setClickedCardIndex(null);

        try {
            await loadScript("https://checkout.razorpay.com/v1/checkout.js");

            const planName = document.getElementById(`planName${index}`).innerHTML.charAt(0).toUpperCase();

            const planPrice = document.getElementById(`planPrice${index}`).innerHTML;
            const userEmail = localStorage.getItem("email");

            const orderBody = {
                amount: Number(planPrice),
                email: userEmail,
                planId: planName,
                // previousPlanAmount: currentPlanAmount
            }
            await dispatch(createOrder(orderBody)).then((response) => {
                const orderData = response.payload;
                if (response.payload.status === 409) {
                    toast({
                        title: "You cannot change plan before expire the plan",
                        status: "error",
                        position: "top-right",
                        duration: 5000,
                        isClosable: true,
                    });
                }


                const options = {
                    key: process.env.KEY_ID,
                    amount: orderData.amount,
                    currency: orderData.currency,
                    name: 'Dicot Innovation Private Limited',
                    description: 'Test Transaction',
                    image: 'https://dicot.tech/Assets/images/Logo.svg',
                    order_id: orderData.orderId,
                    prefill: {
                        name: "test",
                        email: userEmail,
                    },
                    notes: {
                        address: 'Razorpay Corporate Office',
                    },
                    theme: {
                        color: '#060623',
                    },
                    handler: async function (response) {
                        await fetch(`${proxy}/bill/payment/verify`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${localStorage.getItem("token")}`
                            },
                            body: JSON.stringify({
                                razorpay_payment_id: response.razorpay_payment_id,
                                order_id: orderData.orderId,
                                razorpay_signature: response.razorpay_signature,
                                planId: planName,
                                amount: orderData.amount / 100,
                                payCycle: activeTimeOption.toLowerCase(),
                                noOfDays: activeTimeOption === "Yearly" ? 365 : 28,
                                email: userEmail
                            })
                        }).then((responseBody) => {
                            if (responseBody.status === 200) {
                                toast({
                                    title: "Payment Successful",
                                    status: "success",
                                    position: "top-right",
                                    duration: 5000,
                                    isClosable: true,
                                });
                            } else {
                                toast({
                                    title: "Payment Unsuccessful",
                                    status: "error",
                                    position: "top-right",
                                    duration: 5000,
                                    isClosable: true,
                                });
                            }
                        });

                    },
                };

                const rzp = new window.Razorpay(options);
                // Open Razorpay modal
                rzp.open();
            })

        } catch (error) {
            console.log("Error :", error)
        }


    }

    // Function to handle add on plans
    const handleBuyAddOns = useCallback(async (index) => {
        setClickedCardIndex(index);

        try {
            let planId;
            await loadScript("https://checkout.razorpay.com/v1/checkout.js");

            const planName = document.getElementById(`addOn${index}`).innerHTML;
            const userEmail = localStorage.getItem("email");
            const planPrice = document.getElementById(`addOnPrice${index}`).innerHTML



            if (planName === "Add-on Project") {
                planId = "AOP"
            } else {
                planId = "AOU"
            }

            const orderBody = {
                amount: Number(planPrice * value),
                email: userEmail,
                planId: planId,
                numberOfAddon: Number(value)
            }

            await dispatch(createOrder(orderBody)).then((response) => {
                const orderData = response.payload;


                const options = {
                    key: process.env.KEY_ID,
                    amount: orderData.amount,
                    currency: orderData.currency,
                    name: 'Dicot Innovation Private Limited',
                    description: 'Test Transaction',
                    image: 'https://dicot.tech/Assets/images/Logo.svg',
                    order_id: orderData.orderId,
                    prefill: {
                        name: "test",
                        email: userEmail,
                    },
                    notes: {
                        address: 'Razorpay Corporate Office',
                    },
                    theme: {
                        color: '#060623',
                    },
                    handler: async function (response) {
                        await fetch(`${proxy}/bill/payment/verify`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${localStorage.getItem("token")}`
                            },
                            body: JSON.stringify({
                                razorpay_payment_id: response.razorpay_payment_id,
                                order_id: orderData.orderId,
                                razorpay_signature: response.razorpay_signature,
                                planId: planId,
                                amount: orderData.amount / 100,
                                payCycle: activeTimeOption.toLowerCase(),
                                noOfDays: activeTimeOption === "Yearly" ? 365 : 28,
                                email: userEmail,
                                numberOfAddon: Number(value)

                            }),
                        }).then((responseBody) => {
                            if (responseBody.status === 200) {
                                toast({
                                    title: "Payment Successful",
                                    status: "success",
                                    position: "top-right",
                                    duration: 5000,
                                    isClosable: true,
                                });

                                setClickedCardIndex(null)
                            } else {
                                toast({
                                    title: "Payment Unsuccessful",
                                    status: "error",
                                    position: "top-right",
                                    duration: 5000,
                                    isClosable: true,
                                });
                                setClickedCardIndex(null)
                            }
                        });
                    },
                };

                const rzp = new window.Razorpay(options);
                // Open Razorpay modal
                rzp.open();
            })
        } catch (error) {
            console.log(error);
        }

    },[])

    return (
        <div className={styles.explorePlan}>
            <div className={styles.optionsWrapper}>

                <div className={styles.options}>
                    <button className={styles.optionName} style={{ fontWeight: `${activeTab === "Plans" ? "700" : "400"}` }} onClick={() => setActiveTab("Plans")}>Plans</button>
                    <button className={styles.optionName} style={{ fontWeight: `${activeTab === "Addons" ? "700" : "400"}` }} onClick={() => setActiveTab("Addons")}>Add-ons</button>
                </div>

                <div className={styles.timeOptions}>
                    <button className={`${styles.timeOptionName} ${activeTimeOption === "Monthly" && styles.active}`} onClick={() => setActiveTimeOption("Monthly")}>Monthly</button>
                    <button className={`${styles.timeOptionName} ${activeTimeOption === "Yearly" && styles.active}`} onClick={() => setActiveTimeOption("Yearly")}>Yearly</button>
                </div>
            </div>

            {/* Displaying plans  */}
            {activeTab === "Plans" && (

                <div className={styles.cardsWrapper}>
                    {plans?.map((card, index) => (

                        <div className={`${index === 1 ? styles.mainCard : styles.card}`} >
                            <div className={styles.upperPart}>
                                <div className={`${index === 1 ? styles.mainCardTitle : styles.cardTitle}`} id={`planName${index}`}>{card.planName}</div>
                                <div className={`${index === 1 ? styles.mainCardTitlePrice : styles.cardPrice}`} > <span id={`planPrice${index}`}>{activeTimeOption === "Yearly" ? card.yearlyPrice : card.monthlyPrice}</span><span className={styles.slash} style={{ color: `${index === 1 && "#000"}` }}>{activeTimeOption === "Yearly" ? "/Year" : "/Month"}</span></div>

                            </div>
                            <button className={`${index === 1 ? styles.mainCardBtn : styles.cardBtn}`} onClick={() => handleBuyPlan(index)} >Buy Now</button>
                        </div>
                    ))}

                </div>
            )}

            {/* Displaying addons plan  */}
            {activeTab === "Addons" && (
                <div className={styles.cardContainer}>
                    {addOnPlans?.map((data, index) => (

                        <div className={`${styles.AddoneCard} ${clickedCardIndex === index && styles.activeCard}`} >
                            <div className={styles.AddoneCardUpperPart}>
                                <div className={`${styles.AddoneTitle} ${clickedCardIndex === index && styles.cardFont}`} id={`addOn${index}`}>{data.planName}</div>
                                <div className={`${styles.AddonePrice} ${clickedCardIndex === index && styles.cardFont}`} >₹<span id={`addOnPrice${index}`}>{activeTimeOption === "Yearly" ? data.yearlyPrice : data.monthlyPrice}</span><span className={`${styles.slash} ${clickedCardIndex === index && styles.slashFont}`}>{activeTimeOption === "Yearly" ? "/Year" : "/Month"}</span></div>
                            </div>

                            <div className={styles.btnWrapper}>

                                <button className={styles.AddoneBtn} style={{ display: `${clickedCardIndex === index ? "none" : "inline"}` }} onClick={() => setClickedCardIndex(index)}>Buy Now</button>

                                <button className={styles.AddoneBtn} style={{ display: `${clickedCardIndex !== index ? "none" : "inline"}` }} onClick={() => handleBuyAddOns(index)}>Buy Now</button>

                                {clickedCardIndex === index && (
                                    <div className={styles.valueWrapper}>
                                        <button className={styles.valueBtn} onClick={handleDecrease}>-</button>
                                        <button className={styles.valueBtn}>{value}</button>
                                        <button className={styles.valueBtn} onClick={handleIncrease}>+</button>
                                    </div>
                                )}
                            </div>
                        </div>

                    ))}
                </div>
            )}

        </div>
    )
}

export default ExplorePlan;
