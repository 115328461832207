import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import styles from "./dangerzoneModel.module.css";
import { useDispatch } from "react-redux";
import { deleteProject, suspendProject, transferProject } from "../../../../../../../redux/slices/dangerZoneSlice.js";

function DangerzoneModel({ selectedModel, setIsModelOpen }) {
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uniqueProjectId = searchParams.get("project");
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const [email, setEmail] = useState(""); // State for email input

  // Function to handle actions based on selected model (Delete, Suspend, Transfer)
  const handleButtonAction = () => {

    if (selectedModel === "D") {
      const deleteProjectData = {
        token: token,
        projectId: uniqueProjectId
      };

      try {
        dispatch(deleteProject(deleteProjectData)).then((response) => {
          if (response.payload.code === 200) {
            toast({
              title: "Project Deleted",
              description: `Project (${response.payload.project.Project_id}) has been deleted`,
              status: "warning",
              position: "top-right",
              duration: 5000,
              isClosable: true,
              Project_id: uniqueProjectId,
            });
            navigate("/"); // Redirect after delete
          }
        });
      } catch (error) {
        console.log(error);
        toast({
          title: "Internal Server Error",
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      }
    }

    if (selectedModel === "S") {
      const projectData = {
        projectId: uniqueProjectId,
        token: token,
      };

      try {
        dispatch(suspendProject(projectData)).then((response) => {
          if (response.payload.code === 200) {
            toast({
              title: `Project Suspended`,
              description: `Project has been suspended, visit settings to un-suspend the project`,
              status: "warning",
              position: "top-right",
              duration: 7000,
              isClosable: true,
            });
            navigate("/"); // Redirect after suspension
          }
        });
      } catch (error) {
        console.log(error);
        toast({
          title: "Internal Server Error",
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      }
    }

    if (selectedModel === "T") {
      const transferData = {
        email: email,
        token: token,
        projectId: uniqueProjectId,
      };

      try {
        dispatch(transferProject(transferData)).then((response) => {
          if (response.payload.status === 200) {
            toast({
              title: `Check ${response.payload.email}`,
              description: `Project confirmation link has been sent to ${response.payload.email}`,
              status: "info",
              position: "top-right",
              duration: 7000,
              isClosable: true,
            });
            navigate("/"); // Redirect after transfer
          }
        });
      } catch (error) {
        console.log(error);
        toast({
          title: "Internal Server Error",
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      }
    }

    setIsModelOpen(false); // Close the modal after action
  }


  return (
    <>
      <div className={styles.page}>
        <div className={styles.modelContainer}>
          <div className={styles.text}>Are you sure you want to {selectedModel === "S" ? "suspend" : selectedModel === "D" ? "delete" : "transfer"} the project?</div>

          {selectedModel === "T" && (
            <input type="text" placeholder="Enter Email Address" className={styles.input} onChange={(e) => setEmail(e.target.value)} />
          )}
          <div className={styles.btnWrapper}>
            <button className={styles.btn} onClick={() => setIsModelOpen(false)}>Cancel</button>
            <button className={styles.btn} onClick={handleButtonAction}>{selectedModel === "S" ? "Suspend" : selectedModel === "D" ? "Delete" : "Transfer"}</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default DangerzoneModel;
