import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Connection from './components/Connection/Connection';
import ManageMembers from './components/Manage Members/ManageMembers';
import DerivedChannels from './components/DerivedChannel/DerivedChannels';
import SecurityAndData from './components/Security And Data/SecurityData';
import DangerZone from './components/Danger Zone/DangerZone';
import Info from './components/Information/Information';
import styles from './Settings.module.css';
import Navbar from './components/Navbar';
import { useSearchParams } from 'react-router-dom';
import Historical from '../View Data/components/Historical';
import ReportAutomation from "../Settings/components/Report Automation/ReportAutomation"
import { getProjectSetting } from '../../../../redux/slices/projectSetting.js';
import { useDispatch, useSelector } from 'react-redux';

const ProjectSettings = ({ setPage }) => {
  // Set initial page to 'dashboard'
  setPage('dashboard');

  // Initialize state for current user role
  const [currentRole, setCurrentRole] = useState('');

  // Initialize navigate function for page redirection
  const navigate = useNavigate();

  // Initialize state for current page
  const [page, setpage] = useState('');

  // Initialize search parameters from the URL
  const [searchParams] = useSearchParams();

  // Initialize state for the path (Connection page by default)
  const [isPath, setIsPath] = useState('Connection');

  // Initialize Redux dispatch function
  const dispatch = useDispatch();

  // Retrieve settings data from Redux store
  const settingsData = useSelector((state) => state.projectSetting.settingData);

  // Retrieve ProjectID from search parameters
  const ProjectID = searchParams.get('project');

  // Fetch project settings when component mounts
  useEffect(() => {
    try {
      const token = localStorage.getItem('token');
      dispatch(getProjectSetting({ ProjectID, token }));
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Check if settings data is available and set the user role to 'admin' if applicable
  useEffect(() => {
    if (Object.keys(settingsData).length > 0) {
      if (settingsData.data.admin === settingsData.currentEmail) {
        setCurrentRole('admin');
      }
    }
  }, [settingsData]);

  // Set document title and check if user is logged in
  useEffect(() => {
    document.title = 'Settings';
    if (localStorage.getItem('token') === null) {
      navigate('/auth/login');
    }
  }, [navigate]);


  return (
    <div className={styles.superWrapper}>
      <div className={styles.wrap}> <a href='/'>Dashboard</a> &gt; Settings &gt; {isPath}</div>
      <div className={styles.app}>
        <div className={styles.settingsWrapper}>
          <Navbar currentRole={currentRole} setIsPath={setIsPath} />
          <div className={styles.optionsWrapper}>
            <Routes>
              <Route
                path="/"
                element={<Connection setPage={setpage} isPath={isPath} />}
              />
              <Route
                path="/connection"
                element={<Connection setPage={setpage} isPath={isPath} />}
              />
              <Route
                path="/info"
                element={<Info setPage={setpage} isPath={isPath} />}
              />
              <Route
                path="/managemembers"
                element={<ManageMembers setPage={setpage} isPath={isPath} currentRole={currentRole} />}
              />
              <Route
                path="/securityanddata"
                element={<SecurityAndData setPage={setpage} isPath={isPath} />}
              />
              <Route
                path="/dangerzone"
                element={<DangerZone setPage={setpage} isPath={isPath} />}
              />
              <Route
                path="/history"
                element={
                  <Historical
                    setPage={setpage}
                    isPath={isPath}
                    uniqueProjectId={ProjectID}
                  />
                }
              />
              <Route
                path="/derivedchannels"
                element={
                  <DerivedChannels
                    setPage={setpage}
                    isPath={isPath}
                    uniqueProjectId={ProjectID}
                  />
                }
              />
              <Route
                path="/virtualchannel"
                element={
                  <DerivedChannels
                    setPage={setpage}
                    isPath={isPath}
                    uniqueProjectId={ProjectID}
                  />
                }
              />

              <Route
                path="/reportautomation"
                element={
                  <ReportAutomation
                    setPage={setpage}
                    isPath={isPath}
                    uniqueProjectId={ProjectID}
                  />
                }
              />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectSettings;
