import styles from "./TwoFactorAuth.module.css";
import { useEffect, useState, useRef } from "react";
import proxy from "proxy";
import { useNavigate, useLocation } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import logoImg from "assets/Logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { getOTP, verifyOTP } from "../../../../redux/slices/profileSlice.js";

const TwoFactorAuth = ({ setPageTitle }) => {
  const email = localStorage.getItem("email");
  const toast = useToast();
  const dispatch = useDispatch();
  const isVerifyOTP = useSelector((state) => state.profile.isVerifyOTP);
  const isOTP = useSelector((state) => state.profile.isOTP);

  const data = {
    email_: email,
  };

  const [seconds, setSeconds] = useState(10 * 60); // 5 minutes in seconds
  const timerRef = useRef(null);

  const startTimer = () => {
    if (timerRef.current === null) {
      timerRef.current = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 0) {
            clearInterval(timerRef.current);
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }
  };

  const resetTimer = () => {
    clearInterval(timerRef.current);
    timerRef.current = null;
    setSeconds(10 * 60); // Reset to 5 minutes
  };
  const location = useLocation();

  const navigate = useNavigate();

  const handleClick = async () => {
    const input = document.querySelectorAll("input");

    let otp = "";
    input.forEach((currentVal) => {
      return (otp += currentVal.value);
    });

    const apiBody = {
      OTP_: otp,
    };
    try {
      dispatch(verifyOTP(apiBody));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isVerifyOTP) {
      const token = location.state.token;

      localStorage.setItem("token", token);
      toast({
        title: "Logged in!",
        description: "user logged in successfully",
        status: "success",
        position: "top-right",
        duration: 1000,
        isClosable: true,
      });
      navigate("/");
    } else {
      console.log("invalid OTP");
    }
  }, [isVerifyOTP]);

  useEffect(() => {
  
    try {
      dispatch(getOTP(data));
    } catch (error) {
      console.log(error);
    }
    startTimer();
  }, [100]);

  const handlePaste = (event) => {
    try {
      const pastedText = event.clipboardData.getData("text");

      // Split the pasted text into an array.
      const pastedTextArray = pastedText.split("");

      // Get the input boxes that the user wants to paste the text into.
      const inputBoxes = document.querySelectorAll("input");
      // Loop through the array and paste the text into each input box.
      for (let i = 0; i < pastedTextArray.length; i++) {
        inputBoxes[i].value = pastedTextArray[i];
        inputBoxes[i].style.borderBottom = "thin solid black";
      } // Focus input
      inputBoxes[inputBoxes.length - 1].focus();
      inputBoxes[inputBoxes.length - 1].style.borderBottom =
        "2px solid #2D5BFF";
    } catch (err) {}
  };

  const handleKeyUp = (event) => {
    try {
      const inputs = document.querySelectorAll("input");
      const index = event.target.getAttribute("index");
      inputs[index - 1].value = event.key;
      const elNext = inputs[index - 1].nextElementSibling;
      elNext.focus();
    } catch (err) {}
  };

  const handleKeyDown = (event) => {
    try {
      const inputs = document.querySelectorAll("input");
      if (event.keyCode == 8) {
        const index = event.target.getAttribute("index");
        inputs[index - 1].value = "";
        inputs[index - 1].previousElementSibling.focus();
      }
    } catch (err) {}
  };

  const resentOTP = async () => {
    try {
      dispatch(getOTP(data));
    } catch (error) {
      console.log(error);
    }
    // const response = await fetch(proxy + "/api/email2factorAuth", options);

 
  };
  useEffect(() => {
    if (isOTP) {
      resetTimer();
      startTimer();
    }
  },[isOTP])

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.headers}>
          <h1 className={styles.h1Header}>
            Vision <span className={styles.vwLogo}>W</span>eb
          </h1>
          <img className={styles.img} src={logoImg} />
        </div>
        <div className={styles.bodyWrapper}>
          <div className={styles.title}>
            <h1 className={styles.h1Header}>Verification Code</h1>
          </div>
          <div>
            <h3>Just making sure its you</h3>
          </div>
          <div></div>
          <div id="input">
            <input
              type="text"
              id="otp"
              index={1}
              name="pass[]"
              maxLength={1}
              onPaste={handlePaste}
              onKeyPress={handleKeyUp}
              onKeyDown={handleKeyDown}
              className={styles.inputField}
            />
            <input
              type="text"
              id="otp"
              index={2}
              maxLength={1}
              name="pass[]"
              onPaste={handlePaste}
              onKeyPress={handleKeyUp}
              onKeyDown={handleKeyDown}
              className={styles.inputField}
            />
            <input
              type="text"
              id="otp"
              index={3}
              maxLength={1}
              onPaste={handlePaste}
              onKeyPress={handleKeyUp}
              onKeyDown={handleKeyDown}
              name="pass[]"
              className={styles.inputField}
            />
            <input
              type="text"
              id="otp"
              index={4}
              maxLength={1}
              onPaste={handlePaste}
              onKeyPress={handleKeyUp}
              onKeyDown={handleKeyDown}
              name="pass[]"
              className={styles.inputField}
            />
            <input
              type="text"
              id="otp"
              index={5}
              maxLength={1}
              onPaste={handlePaste}
              onKeyPress={handleKeyUp}
              onKeyDown={handleKeyDown}
              name="pass[]"
              className={styles.inputField}
            />
            <input
              type="text"
              id="otp"
              index={6}
              maxLength={1}
              onPaste={handlePaste}
              onKeyPress={handleKeyUp}
              onKeyDown={handleKeyDown}
              name="pass[]"
              className={styles.inputField}
            />
          </div>
          <button
            className={styles.verifyBtn}
            onClick={handleClick}
            type="submit"
          >
            Verify
          </button>
          <div>
            <p className={styles.paraTag}>
              {Math.floor(seconds / 60)}:
              {seconds % 60 < 10 ? "0" + (seconds % 60) : seconds % 60}
            </p>
          </div>
          <div>
            <p className={styles.paraTag}>
              Didn’t Receive the code?{" "}
              <a href="#" onClick={resentOTP} className={styles.anchorTag}>
                Resend
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TwoFactorAuth;
