import React from "react";
import proxy from "proxy.js";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import styles from "./ForgotPassForm.module.css";


function Input() {
  const toast = useToast();
  const [email, setEmail] = useState(""); // State to store value of email input


  // Function to store value of email input
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // Function to call resetpassword api
  const HandlePasswordRecovery = () => {
    fetch(proxy + "/api/requestpasswordreset", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', // Specify the content type as JSON
      },
      body: JSON.stringify({
        email: email,
      })
    }).then(response => response.json()).then(data => {
      if (data?.status === 202) {
        toast({
          title: "Password Recovery",
          description: "Password Recovery link has been sent to the email address",
          status: "success",
          position: "top-right",
          duration: 7000,
          isClosable: true,
        })
      }
      else {
        toast({
          title: "invalid email",
          description: `${data.message}`,
          status: "error",
          position: "top-right",
          duration: 7000,
          isClosable: true,
        })
      }
    })
  }
  return (
    <>
      <div className={styles.visionweb}>
        <h1 className={styles.visionWeb}>Vision <span className={styles.W}>W</span>eb</h1>
      </div>
      <div className={styles.mainInput}>
        <p className={styles.text} id="headerText">
          Forgot Password?
        </p>
        <p className={styles.text1}>Don't worry! We've got you covered!🔫</p>
        <br></br>
        <input
          type="text"
          className={styles.emailInput}
          placeholder="Enter Email ID"
          onChange={handleEmailChange}
        />

        {" "}
        <br />
        <div className={styles.submitbtncontainer}>
          <button type="button" className={styles.submit_button} onClick={HandlePasswordRecovery}>
            Send Reset Link
          </button>
        </div>
        <p className={styles.text2}>
          Didn't receive link?{" "}
          <a className={styles.registerText} onClick={HandlePasswordRecovery}>
            Resend
          </a>
        </p>
      </div>
    </>
  );
}

export default Input;
