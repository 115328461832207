import styles from "./Connection.module.css";
import { RadioGroup, Button, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CopyIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import Close from "../../../../../../assets/close.png";
import { addMqttCredentials, addTopic, changeConnectionMethod, deleteTopic, getMqttCredentials, setVnetCredentials, updateMqttCredentials } from "../../../../../../redux/slices/projectSetting.js";
import openEye from "../../../../../../assets/openEye.png"
import closeEye from "../../../../../../assets/closeEye.png"
import deleteIcon from "../../../../../../assets/Delete.svg";

const Connection = () => {
  // Import necessary hooks, libraries, and components
  const [currentRole, setCurrentRole] = useState(null); // State for tracking current user role
  const [value, setValue] = useState("none");
  const navigate = useNavigate(); // React Router hook for navigation
  const [searchParams] = useSearchParams(); // Hook for working with URL search parameters
  const ProjectID = searchParams.get("project"); // Retrieve 'project' parameter from URL
  const toast = useToast(); // Chakra UI's toast for notifications

  // State variables for managing various inputs and visibility toggles
  const [isVnetCred, setIsVnetCred] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [deviceGroupName, setDeviceGroupName] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [tagGroupName, setTagGroupName] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [isClosedEye1, setIsClosedEye1] = useState(true);
  const [isClosedEye2, setIsClosedEye2] = useState(true);
  const [isClosedEye3, setIsClosedEye3] = useState(true);

  // State variables for password input visibility toggles
  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");
  const [passwordType3, setPasswordType3] = useState("password");

  // MQTT-related states
  const [mqttUsername, setMqttUsername] = useState("");
  const [mqttPassword, setMqttPassword] = useState("");
  const [isMqttCredentials, setIsMqttCredentials] = useState(false);
  const [mqttType, setMqttType] = useState("");
  const [topic, setTopic] = useState("");

  // Redux hooks to dispatch actions and access state
  const dispatch = useDispatch();
  const settingsData = useSelector((state) => state.projectSetting.settingData); // Settings data
  const userPermission = useSelector((state) => state.organization.userPermission); // User permissions
  const mqttData = useSelector((state) => state.projectSetting.mqttData); // MQTT data

  // Redirect to home if ProjectID is nullish
  if (ProjectID === "nullish") {
    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 200);
  }

  // Effect to set current role as "admin" if applicable
  useEffect(() => {
    if (Object.keys(settingsData).length > 0) {
      if (settingsData.data.admin === settingsData.currentEmail) {
        setCurrentRole("admin");
      }
    }
  }, [settingsData]);

  // Fetch MQTT credentials on component mount
  useEffect(() => {
    try {
      const email = localStorage.getItem("organizationEmail");
      dispatch(getMqttCredentials(email));
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Set MQTT credentials from retrieved data
  useEffect(() => {
    if (Object.keys(mqttData).length > 0) {
      setMqttUsername(mqttData.username);
      setMqttPassword(mqttData.password);
    }
  }, [mqttData]);

  // Save V-Net credentials
  const handleSaveCreadentials = () => {
    try {
      const apiBody = {
        v_username: username.trim(),
        v_password: password.trim(),
        email: localStorage.getItem("email"),
        password: loginPassword.trim(),
        projectId: ProjectID,
        operation: settingsData?.data?.metadata?.vnetUsername ? "edit" : "add",
        deviceGroupName,
        tagGroupName,
        deviceName,
      };

      dispatch(setVnetCredentials(apiBody)).then((response) => {
        if (response?.payload?.responseBody?.status === 200) {
          toast({
            title: response?.payload?.responseBody?.message || "Successfully saved v-net credentials",
            status: 'success',
            position: 'top-right',
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: response?.payload?.responseBody?.message || "Could not add v-net credentials",
            status: 'error',
            position: 'top-right',
            duration: 3000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Internal Server Error",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsVnetCred(false);
  };

  // Toggle password visibility for different inputs
  const togglePasswordVisibility1 = () => {
    setIsClosedEye1(!isClosedEye1);
    setPasswordType1(passwordType1 === "password" ? "text" : "password");
  };
  const togglePasswordVisibility2 = () => {
    setIsClosedEye2(!isClosedEye2);
    setPasswordType2(passwordType2 === "password" ? "text" : "password");
  };
  const togglePasswordVisibility3 = () => {
    setIsClosedEye3(!isClosedEye3);
    setPasswordType3(passwordType3 === "password" ? "text" : "password");
  };

  // Change connection method (MQTT, OPC, HTTP)
  const handleChangeMethod = (e, type) => {
    let data = {
      method: type,
      projectId: ProjectID,
      email: localStorage.getItem("organizationEmail"),
      token: localStorage.getItem("token"),
      value: !settingsData?.data?.metadata?.Settings?.connection?.[type],
    };

    try {
      dispatch(changeConnectionMethod(data)).then((response) => {
        if (response?.payload?.responseBody?.status === 200) {
          toast({
            title: response?.payload?.responseBody?.message || "Successfully changed connection status",
            status: 'success',
            position: 'top-right',
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: response?.payload?.responseBody?.message || "Could not change connection status",
            status: "error",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Internal Server Error",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Add a new topic
  const handleAddTopic = (e) => {
    e.preventDefault();
    const data = { projectId: ProjectID, topic };
    try {
      dispatch(addTopic(data)).then((response) => {
        if (response?.payload?.responseBody?.status === 200) {
          toast({
            title: "Topic Added Successfully",
            status: 'success',
            position: 'top-right',
            duration: 3000,
            isClosable: true,
          });
          setTopic('');
        } else {
          toast({
            title: "Could not add topic",
            status: "error",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Internal Server Error",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Open modal for MQTT credentials
  const handleOpenMqttModel = (e, type) => {
    e.preventDefault();
    setIsMqttCredentials(true);
    setMqttType(type);
  };

  // Save MQTT credentials
  const handleSaveMqttCredentials = (e) => {
    e.preventDefault();
    const data = { email: localStorage.getItem("organizationEmail"), username: mqttUsername, password: mqttPassword };
    const action = mqttType === "Add" ? addMqttCredentials : updateMqttCredentials;

    try {
      dispatch(action(data)).then((response) => {
        if (response?.payload?.responseBody?.status === 200) {
          toast({
            title: "Credentials successfully updated",
            status: "success",
            position: 'top-right',
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Could not update credentials",
            status: "error",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Internal Server Error",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsMqttCredentials(false);
  };

  // Delete a topic
  const handleDeleteTopic = (e, topicName) => {
    e.preventDefault();
    const data = { projectId: ProjectID, topic: topicName };

    try {
      dispatch(deleteTopic(data)).then((response) => {
        if (response?.payload?.responseBody?.status === 200) {
          toast({
            title: "Topic Deleted Successfully",
            status: 'success',
            position: 'top-right',
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Could not delete topic",
            status: "error",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Internal Server Error",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };




  return (
    <>
      <div className={styles.connectionApp}>
        <div className={styles.hero}>
          <div className={styles.connection_wrapper}>
            <h1 className={styles.headerText}>Connection Method</h1>
            <form>
              <RadioGroup value={value} className={styles.radiogroup}>
                <div className={styles.toggleWrapper}>
                  <label htmlFor="MQTT">MQTT</label>
                  {userPermission.editConnectionSettings && (
                    <div className={`${styles.toggleBox} ${settingsData?.data?.metadata?.Settings?.connection?.MQTT === true ? styles.active : ""}`} onClick={(e) => handleChangeMethod(e, "MQTT")}
                    >
                      <span className={`${styles.toggle} ${settingsData?.data?.metadata?.Settings?.connection?.MQTT === true ? styles.active : ''}`} ></span>
                    </div>
                  )}
                </div>

                <div className={styles.topicWrapper}
                >
                  {settingsData?.data?.metadata?.Settings?.connection?.MQTT === true && (

                    <div className={styles.topicInputWrapper}>
                      <input type="text" className={styles.topicInput} onChange={(e) => setTopic(e.target.value)} />
                      <button className={styles.addTopicBtn} onClick={(e) => handleAddTopic(e)}>Add Topic</button>
                    </div>
                  )}
                  {settingsData?.data?.metadata?.Settings?.connection?.topics?.length > 0 && (

                    <div className={styles.topicNames}>
                      <span className={styles.topicText}>Topics : </span>
                      {settingsData?.data?.metadata?.Settings?.connection?.topics?.map((topic, index) => (
                        <>
                          <label className={styles.topicLabel} key={index}>{topic}</label>
                          <button onClick={(e) => handleDeleteTopic(e, topic)}><img src={deleteIcon} alt="delete" /></button>
                        </>
                      ))}
                    </div>
                  )}
                </div>

                <div className={`${styles.inputgrp} ${styles.disabledInput}`}>
                  <label htmlFor="HTTP">HTTP</label>
                  {userPermission.editConnectionSettings && (
                    <div className={styles.radio}>
                      <input
                        type="checkbox"
                        name=""
                        className={styles.radioInput}
                        onClick={() => {
                          if (value === "HTTP") {
                            setValue("none");
                          } else {
                            setValue("HTTP");
                          }
                        }}
                        value="HTTP"
                        checked={true}
                        disabled={true}
                      />
                    </div>
                  )}
                </div>

                <div className={`${styles.inputgrp} ${styles.disabledInput}`}>
                  <label htmlFor="opc" className={styles.inputgrpLabel}>OPC Server</label>
                  {userPermission.editConnectionSettings && (
                    <div className={styles.radio}>
                      <input
                        type="checkbox"
                        name=""
                        className={styles.radioInput}
                        onClick={() => {
                          if (value === "opc") {
                            setValue("none");
                          } else {
                            setValue("opc");
                          }
                        }}
                        disabled={true}
                      />
                    </div>
                  )}
                </div>
              </RadioGroup>
            </form>

            <h2 className={styles.headerText}>MQTT Credentials</h2>
            <div className={styles.mqttInfoWrapper}>
              <div className={styles.mqttInfo}>
                <div className={styles.mqttText}>username : {mqttData?.username ? mqttData?.username : mqttData?.username}</div>
                <div className={styles.mqttText}>password : {mqttData?.password ? "******" : ""}</div>
              </div>
              <button className={styles.editBtn} onClick={(e) => handleOpenMqttModel(e, mqttData?.username ? "Edit" : "Add")}>
                {mqttData?.username ? "Edit" : "Add"}
              </button>
            </div>
            <h1 className={styles.headerText}>Device Configuration</h1>
            {userPermission.editDeviceSettings && (
              <div className={styles.device_config}>
                <div className={styles.id}>Project Id: {ProjectID}</div>
                <Button
                  style={{ backgroundColor: '#fff' }}
                  onClick={() => {
                    navigator.clipboard.writeText(ProjectID);
                    toast({
                      title: "Copied!",
                      description:
                        "The project ID has been copied to your clipboard",
                      status: "info",
                      position: "top-right",
                      duration: 5000,
                      isClosable: true,
                    });
                  }}
                  leftIcon={<CopyIcon />}
                  className={styles.copyButton}
                >
                  Copy
                </Button>
              </div>
            )}

            <div className={styles.vnetContainer}>
              <h1 className={styles.headerText}>V-Net Credentials</h1>
              <div className={styles.vnetCredWrapper}>
                <div className={styles.addedInfo}>
                  <div className={styles.credText}>
                    Username : {settingsData?.data?.metadata?.vnetUsername}
                  </div>
                  <div className={styles.credText}>
                    Password : **********
                  </div>
                  <div className={styles.credText}>
                    Device Group Name : {settingsData?.data?.metadata?.deviceGroupName}
                  </div>
                  <div className={styles.credText}>
                    Device Name : {settingsData?.data?.metadata?.deviceName}
                  </div>
                  <div className={styles.credText}>
                    Tag Group Name : {settingsData?.data?.metadata?.tagGroupName}
                  </div>
                </div>
                <button className={styles.editBtn} onClick={() => setIsVnetCred(true)}>
                  {settingsData?.data?.metadata?.vnetUsername ? "Edit" : "Add"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isVnetCred && (
        <div className={styles.projectModel}>
          <div className={styles.container}>
            <div className={styles.closeBtnWrapper}>
              <h2 className={styles.titleText}>V-Net Credentials</h2>
              <button
                className={styles.closeBtn}
                onClick={() => setIsVnetCred(false)}
              >
                <img src={Close} alt="close" className={styles.closePng} />
              </button>
            </div>

            <div className={styles.inputWrapper}>
              <input
                type="text"
                placeholder="Username"
                id="username"
                defaultValue={settingsData?.data?.metadata?.vnetUsername}
                className={styles.projectModalInput}
                onChange={(e) => setUsername(e.target.value)}
              />

              <div className={styles.row}>
                <input
                  type={passwordType1}
                  placeholder="Password"
                  id="password"
                  className={styles.projectModalInput}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {!isClosedEye1 ? (
                  <img
                    src={openEye}
                    alt="Open Eye"
                    srcSet=""
                    onClick={togglePasswordVisibility1}
                    className={styles.eyeImage}
                  />
                ) : (
                  <img
                    src={closeEye}
                    alt="Close Eye"
                    srcSet=""
                    onClick={togglePasswordVisibility1}
                    className={styles.eyeImage}
                  />
                )}
              </div>

              <input
                type="text"
                placeholder="Device Group Name"
                className={styles.projectModalInput}
                defaultValue={settingsData?.data?.metadata?.deviceGroupName}
                onChange={(e) => setDeviceGroupName(e.target.value)}
              />

              <input
                type="text"
                placeholder="Device Name"
                className={styles.projectModalInput}
                defaultValue={settingsData?.data?.metadata?.deviceName}
                onChange={(e) => setDeviceName(e.target.value)}
              />

              <input
                type="text"
                placeholder="Tag Group Name"
                className={styles.projectModalInput}
                defaultValue={settingsData?.data?.metadata?.tagGroupName}
                onChange={(e) => setTagGroupName(e.target.value)}
              />

              <div className={styles.row}>
                <input
                  type={passwordType2}
                  placeholder="Vision-web Login Password"
                  id="loginPassword"
                  className={styles.projectModalInput}
                  onChange={(e) => setLoginPassword(e.target.value)}
                />
                {!isClosedEye2 ? (
                  <img
                    src={openEye}
                    alt="Open Eye"
                    srcSet=""
                    onClick={togglePasswordVisibility2}
                    className={styles.eyeImage}
                  />
                ) : (
                  <img
                    src={closeEye}
                    alt="Close Eye"
                    srcSet=""
                    onClick={togglePasswordVisibility2}
                    className={styles.eyeImage}
                  />
                )}
              </div>
            </div>
            <button className={styles.saveBtn} onClick={handleSaveCreadentials}>
              Save
            </button>
          </div>
        </div>


      )}

      {isMqttCredentials && (
        <div className={styles.projectModel}>
          <div className={styles.container}>
            <div className={styles.closeBtnWrapper}>
              <h2 className={styles.titleText}>MQTT Credentials</h2>
              <button
                className={styles.closeBtn}
                onClick={() => setIsMqttCredentials(false)}
              >
                <img src={Close} alt="close" className={styles.closePng} />
              </button>
            </div>
            <input
              type="text"
              placeholder="Username"
              id="username"
              defaultValue={mqttType === "Edit" ? mqttData?.username : ""}
              className={styles.projectModalInput}
              onChange={(e) => setMqttUsername(e.target.value)}
            />
            <div className={styles.row}>
              <input
                type={passwordType3}
                placeholder="Password"
                id="password"
                className={styles.projectModalInput}
                onChange={(e) => setMqttPassword(e.target.value)}
              />
              {!isClosedEye3 ? (
                <img
                  src={openEye}
                  alt="Open Eye"
                  srcSet=""
                  onClick={togglePasswordVisibility3}
                  className={styles.eyeImage}
                />
              ) : (
                <img
                  src={closeEye}
                  alt="Close Eye"
                  srcSet=""
                  onClick={togglePasswordVisibility3}
                  className={styles.eyeImage}
                />
              )}
            </div>
            <button className={styles.saveBtn} onClick={handleSaveMqttCredentials}>
              Save
            </button>

          </div>
        </div>
      )}

    </>


  );
};

export default Connection;
