import React, { useEffect, useReducer, useState, useRef } from 'react';
import styles from './ReportAutomation.module.css';
import Picker from 'react-mobile-picker';
import Delete from '../../../../../../assets/deleteReport.svg';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import {
  getReportData,
  addSlot,
  deleteSlot,
  updateSlot,
  reportDuration,
} from '../../../../../../redux/slices/reportAutomationSlice.js';

const ReportAutomation = () => {
  // Refs for handling schedule and file inputs
  const scheduleRef = useRef(null);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const projectID = searchParams.get('project');
  const [toggledItems, setToggledItems] = useState({});
  const [showPicker, setShowPicker] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);
  const [selectedToggleId, setSelectedToggleId] = useState('');
  const toast = useToast();

  // Time options for pickers
  const [hours, setHours] = useState(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']);
  const [minutes, setMinutes] = useState(['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']);
  const [period, setPeriod] = useState(['A.M', 'P.M']);

  const [startSelectedValue, setStartSelectedValue] = useState({
    hours: '5',
    minutes: '20',
    period: 'A.M',
  });
  const [endSelectedValue, setEndSelectedValue] = useState({
    hours: '5',
    minutes: '20',
    period: 'A.M',
  });
  const [scheduleValue, setScheduleValue] = useState({
    hours: '8',
    period: 'A.M',
  });

  // Picker data
  const handleLefthoursPicker = {
    hours: hours,
    minutes: minutes,
    period: period,
  };

  const handleRighthoursPicker = {
    hours: hours,
    period: period,
  };

  const reportData = useSelector((state) => state.reportAutomation.reportData);

  // Fetch report data on component mount
  useEffect(() => {
    dispatch(getReportData(projectID));
  }, []);

  // Retrieve toggled items from localStorage
  useEffect(() => {
    const savedToggledItems = localStorage.getItem('toggledItems');
    if (savedToggledItems) {
      setToggledItems(JSON.parse(savedToggledItems));
    }
  }, []);

  // Update localStorage whenever toggledItems changes
  useEffect(() => {
    localStorage.setItem('toggledItems', JSON.stringify(toggledItems));
  }, [toggledItems]);

  // Close schedule picker if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (scheduleRef.current && !scheduleRef.current.contains(event.target)) {
        setShowSchedule(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [scheduleRef]);

  // Handle next button logic for time selection
  const handleNext = () => {
    setStartSelectedValue({ hours: startSelectedValue.hours, minutes: startSelectedValue.minutes, period: startSelectedValue.period });
    setEndSelectedValue({ hours: endSelectedValue.hours, minutes: endSelectedValue.minutes, period: endSelectedValue.period });

    setShowPicker(true);
    setShowSchedule(false);
  };

  // Handle cancel action on schedule picker
  const handleCancel = () => {
    setShowPicker(false);
  };

  // Set the schedule slot
  const handleSetSchedule = () => {
    // Convert time values to 24-hour format if needed
    let scheduleSlotHours = parseInt(startSelectedValue.hours);
    const scheduleSlotPeriod = startSelectedValue.period.trim();
    if (scheduleSlotPeriod === 'P.M') scheduleSlotHours += 12;

    let scheduleScheHours = parseInt(endSelectedValue.hours);
    const scheduleSchePeriod = endSelectedValue.period.trim();
    if (scheduleSchePeriod === 'P.M') scheduleScheHours += 12;

    let scheduleHours = parseInt(scheduleValue.hours);
    const schedulePeriod = scheduleValue.period.trim();
    if (schedulePeriod === 'P.M') scheduleHours += 12;

    // API body for setting schedule
    const apiBody = {
      onReport: true,
      projectID: projectID,
      setSlot: { hour: scheduleSlotHours, minute: Number(startSelectedValue.minutes) },
      setSchedule: { hour: scheduleScheHours, minute: Number(endSelectedValue.minutes) },
      timer: { hour: scheduleHours },
    };

    try {
      dispatch(addSlot(apiBody)).then((response) => {
        if (response.payload.responseBody.status === 200) {
          toast({
            title: response?.payload?.responseBody?.message,
            status: "success",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: response?.payload?.responseBody?.message,
            status: "error",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Internal Server Error",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
    setShowPicker(false);
  };

  // Update slot when toggled
  useEffect(() => {
    if (selectedToggleId !== '') {
      const apiBody = { slotID: selectedToggleId, projectID: projectID, onSlot: toggledItems[selectedToggleId] };
      try {
        dispatch(updateSlot(apiBody)).then((response) => {
          if (response.payload.status === 200) {
            toast({
              title: "Slot updated successfully",
              status: "success",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          } else {
            toast({
              title: "Couldn't update the slot",
              status: "error",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            });
          }
        });
      } catch (error) {
        console.log(error);
        toast({
          title: "Internal Server Error",
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  }, [toggledItems]);

  // Handle toggle change
  const handleToggle = (id) => {
    setSelectedToggleId(id);
    setToggledItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
      slotID: id,
    }));
  };

  // Handle slot deletion
  const handleDelete = (id) => {
    const apiBody = { projectID: projectID, slotID: id };
    try {
      dispatch(deleteSlot(apiBody)).then((response) => {
        if (response.payload.responseBody.status === 200) {
          toast({
            title: "Deleted slot successfully",
            status: "success",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: response?.payload?.responseBody?.message,
            status: "error",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Internal Server Error",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Handle schedule change
  const handleScheduleChange = (id, index) => {
    const duration = document.getElementById(`duration${index}`).value.toLowerCase();
    const apiBody = { projectID: projectID, slotID: id, duration: duration };
    try {
      dispatch(reportDuration(apiBody)).then((response) => {
        if (response.payload.status === 200) {
          toast({
            title: "Slot updated successfully",
            status: "success",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Couldn't update the slot",
            status: "error",
            position: "top-right",
            duration: 3000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Internal Server Error",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Handle file upload and report duration
  const handleFileUpload = (file, id) => {
    if (file) {
      const detaUrlReader = new FileReader();
      detaUrlReader.onload = (e) => {
        const detaUrl = e.target?.result;
        const apiBody = {
          fileName: file.name,
          fileSize: file.size,
          projectID: projectID,
          slotID: id,
          reportFormat: detaUrl
        };
        try {
          dispatch(reportDuration(apiBody));
        } catch (error) {
          console.log(error);
        }
      };
      detaUrlReader.readAsDataURL(file);
    }
  };


  return (
    <div className={styles.mainContainer}>
      <div className={styles.row}>
        <div className={styles.timeSlotText}>Slot </div>
        <div className={styles.TimerText}>Timers</div>
        <div className={styles.reportFormatText}>Report Format</div>
      </div>
      <div className={styles.slotWrapper}>
        <div className={styles.setReportSchedule}>
          {reportData?.length > 0 &&
            reportData?.map((item, index) => (
              <div className={styles.setReport} key={index}>
                <div className={styles.timeSlotWrapper}>
                  <div className={styles.setedSlot}>
                    {item.setSlot.hour < 10
                      ? '0' + item.setSlot.hour
                      : item.setSlot.hour}{' '}
                    to{' '}
                    {item.setSchedule.hour < 10
                      ? '0' + item.setSchedule.hour
                      : item.setSchedule.hour}
                  </div>
                </div>

                <div className={styles.timerWrapper}>
                  <div className={styles.setedTimer}>
                    {item?.timer?.hour < 10
                      ? '0' + item?.timer?.hour
                      : item?.timer?.hour}
                  </div>
                </div>
                <div className={styles.formatWrapper}>
                  <button
                    className={styles.chooseReportFormat}
                    onClick={() => fileInputRef.current.click()}
                  >
                    Choose Format
                  </button>

                  <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => handleFileUpload(e.target.files[0], item.id)}
                    ref={fileInputRef}
                  />
                </div>
                <div className={styles.scheduleToggleDeleteWrapper}>
                  <div className={styles.scheduleDays}>
                    <select
                      className={styles.scheduleDropdown}
                      defaultValue={item.duration}
                      id={`duration${index}`}
                      onChange={() => handleScheduleChange(item.id, index)}
                    >
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                    </select>
                  </div>
                  <div className={styles.toggleWrapper}>
                    <div
                      className={`${styles.toggleBox} ${toggledItems[item.id] ? styles.active : ''}`}
                      onClick={() => handleToggle(item.id)}
                    >
                      <span
                        className={`${styles.toggle} ${toggledItems[item.id] ? styles.active : ''}`}
                      ></span>
                    </div>
                  </div>
                  <div className={styles.deleteBtnWrapper}>
                    <button onClick={() => handleDelete(item.id)} className={styles.deleteIcon}>
                      <img src={Delete} alt={'Delete'}></img>
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className={styles.scheduleEmailBtnWrapper}>
        <button
          className={styles.scheduleEmailBtn}
          onClick={() => setShowSchedule(true)}
        >
          Set Schedule
        </button>
      </div>
      {showSchedule && (
        <div className={styles.schedule}>
          <div className={styles.scheduleWrapper} ref={scheduleRef}>
            <div className={styles.timerContainer}>
              <div className={styles.slotTitle}>Slot Start</div>
              <div className={styles.picker}>
                <Picker
                  value={startSelectedValue}
                  onChange={setStartSelectedValue}
                  className={styles.pickerWheels}
                  wheelMode="normal"
                  height={500}
                >
                  {Object.keys(handleLefthoursPicker).map((name) => (
                    <Picker.Column key={name} name={name}>
                      {handleLefthoursPicker[name].map((option) => (
                        <Picker.Item key={option} value={option}>
                          {option}
                        </Picker.Item>
                      ))}
                    </Picker.Column>
                  ))}
                </Picker>
              </div>
            </div>
            <div className={styles.timerContainer2}>
              <div className={styles.slotTitle}>Slot End</div>
              <div className={styles.picker}>
                <Picker
                  value={endSelectedValue}
                  onChange={setEndSelectedValue}
                  className={styles.pickerWheels}
                  wheelMode="normal"
                  height={500}
                >
                  {Object.keys(handleLefthoursPicker).map((name) => (
                    <Picker.Column key={name} name={name}>
                      {handleLefthoursPicker[name].map((option) => (
                        <Picker.Item key={option} value={option}>
                          {option}
                        </Picker.Item>
                      ))}
                    </Picker.Column>
                  ))}
                </Picker>
              </div>
              <button className={styles.slotNextBtn} onClick={handleNext}>
                Next
              </button>
            </div>
          </div>
        </div>
      )}
      {showPicker && (
        // Render picker conditionally based on showPicker state
        <div className={styles.schedule2}>
          <div className={styles.scheduleWrapper2}>
            <div className={styles.scheduleContainer}>
              <div className={styles.scheduleTitle}>Set Schedule</div>
              <Picker
                value={scheduleValue}
                onChange={setScheduleValue}
                className={styles.pickerWheels}
                wheelMode="normal"
                height={500}
              >
                {Object.keys(handleRighthoursPicker).map((name) => (
                  <Picker.Column key={name} name={name}>
                    {handleRighthoursPicker[name].map((option) => (
                      <Picker.Item key={option} value={option}>
                        {option}
                      </Picker.Item>
                    ))}
                  </Picker.Column>
                ))}
              </Picker>
              <div className={styles.ScheduleBtn}>
                <button
                  className={styles.cancelScheduleBtn}
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  className={styles.setScheduleBtn}
                  onClick={handleSetSchedule}
                >
                  Set
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportAutomation;
