import React, { useCallback, useEffect, useState } from 'react';
import styles from './ProjectData.module.css';
import { useNavigate } from 'react-router-dom';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import Overview from './components/Overview';
import { useLocation } from 'react-router-dom';
import LiveData from './components/LiveData';
import Day1 from './components/Day1';
import portlessProxy from 'bareproxy';
import Channels from './components/Channels';
import NewPage from './components/NewPage';
import { useToast } from '@chakra-ui/react';
import {
  DeviceController,
  DeviceEnergyMeter,
  DeviceFlowMeter,
  DeviceIO,
  Devices,
  DeviceDisplay
  // Indicator,
} from './components/Devices';
import tableInitWorker from 'Utils/restructure.worker';
import WorkerBuilder from 'Utils/builder.worker';
import valueExtractorWorker from 'Utils/valueExtractor.worker';
import oneDayDataRestructureWorker from 'Utils/oneDay.worker';
import { PieChart } from './components/PieChart';
import { BarChart } from './components/BarChart';
import { Histogram } from './components/Histogram';
import { LineChart } from './components/LineChart';
import { Gauge } from './components/Gauge';
import { ProgressBar } from './components/ProgressBar';
import { ToolBarGrid } from './components/ToolBarGrid';
import { MovableTable } from 'components/MovableTable/MovableTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGraphData,
  getWidgetData,
} from '../../../../redux/slices/viewDataSlice.js';
import motorgif from "../../../../assets/motorgif.gif"
import { getMqttCredentials, getProjectSetting } from '../../../../redux/slices/projectSetting';
const tableInitializer = new WorkerBuilder(tableInitWorker);
const valueExtractor = new WorkerBuilder(valueExtractorWorker);
const restructureWorker = new WorkerBuilder(oneDayDataRestructureWorker);

const io = require('socket.io-client');

function Project({ setPage }) {
  setPage('dashboard');
  const userPermission = useSelector(
    (state) => state.organization.userPermission,
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uniqueProjectId = searchParams.get('graphId');
  const [tableData, setTableData] = useState([]); // Data formatted for Table
  const [init, setInit] = useState(false);
  const [isWorkerBusy, setIsWorkerBusy] = useState(false);
  const [dateTimesLive, setDateTimesLive] = useState([]); // DateTime labels for y-axis
  const [dataArrLive, setDataArrLive] = useState([]); // Real-time data received for live
  const [channelNames, setChannelNames] = useState([]); //  Channel names
  const [data, setData] = useState([]);
  const [socket, setSocket] = useState(null); // Socket connection for real-time data
  const [socketData, setSocketData] = useState(null);
  const [isPath, setIsPath] = useState('Overview');
  const [tabNames, setTabNames] = useState([
    'Overview',
    'Grid View',
    'Live Data',
    '24 Hours',
    // "Historic Data",
  ]); // All The Tab Names
  const [openNewPage, setOpenNewPage] = useState(false); // For Opening and Closing of Update View Page
  const [images, setImages] = useState({}); // To store saved images
  const [tempImagePosition, setTempImagePosition] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabIndexImages, setTabIndexImages] = useState({}); // Store images as per tab index
  const [tabIndexDevices, setTabIndexDevices] = useState({}); // Store indicators as per tab index
  const [tabIndexDisplay, setTabIndexDisplay] = useState({}); // Store display as per tab index
  const [tabIndexController, setTabIndexController] = useState({}); // Store controller as per tab index
  const [tabIndexIO, setTabIndexIO] = useState({}); // Store digital IO as per tab index
  const [tabIndexFlowM, setTabIndexFlowM] = useState({}); // Store flow metetr as per tab index
  const [tabIndexEnergyM, setTabIndexEnergyM] = useState({}); // Store energy meter as per tab index
  const [tabIndexMotor, setTabIndexMotor] = useState({}); // Store motor as per tab index
  const [tabIndexText, setTabIndexText] = useState({});// Store text as per tab index
  const [tabIndexLineChart, setTabIndexLineChart] = useState({}); // Store Line chart as per tab index
  const [tabIndexPieChart, setTabIndexPieChart] = useState({}); // Store Pie chart as per tab index
  const [tabIndexBarChart, setTabIndexBarChart] = useState({}); // Store Bar chart as per tab index
  const [tabIndexHistogram, setTabIndexHistogram] = useState({}); // Store Histogram as per tab index
  const [tabIndexGauge, setTabIndexGauge] = useState({}); // Store Gauge as per tab index
  const [tabIndexProgress, setTabIndexProgress] = useState({}); // Store Progressbar as per tab index
  const [tabIndexGrid, setTabIndexGrid] = useState({}); // Store Grid view as per tab index
  const [tabIndexTable, setTabIndexTable] = useState({}); // Store Table as per tab index
  const [tabIndexOverviewInfo, setTabIndexOverviewInfo] = useState({}); // Store info component of overview
  const [tempTabIndexOverviewInfo, setTempTabIndexOverviewInfo] = useState({});
  const [tabIndexOverviewGraph, setTabIndexOverviewGraph] = useState({}); // Store graph component of overview
  const [tempTabIndexOverviewGraph, setTempTabIndexOverviewGraph] = useState({});
  const [tabIndexLiveDataGraph, setTabIndexLiveDataGraph] = useState({}); // Store graph component of live data 
  const [tempTabIndexLiveDataGraph, setTempTabIndexLiveDataGraph] = useState({});
  const [tabIndexLastDayTable, setTabIndexLastDayTable] = useState({}); //Store table of 24hour tab
  const [tempTabIndexLastDayTable, setTempTabIndexLastDayTable] = useState({});
  const [tabIndexLastDayGraph, setTabIndexLastDayGraph] = useState({});//Store graph of 24hour tab
  const [tempTabIndexLastDayGraph, setTempTabIndexLastDayGraph] = useState({});
  const [tabIndexDefaultGrid, setTabIndexDefaultGrid] = useState({}); //Store grid of grid view tab
  const [tempTabIndexDefaultGrid, setTempTabIndexDefaultGrid] = useState({});
  const [headerNames, setHeaderNames] = useState([]);
  const [chartDataLive, setChartDataLive] = useState({
    labels: [],
    datasets: [],
  }); // store live data to display in  line chart 

  const [barChartData, setBarChartData] = useState({
    labels: [],
    datasets: [],
  }); // store live data to display in bar chart

  const [histogramData, setHistogramData] = useState({
    labels: [],
    datasets: [],
  }); // store live data to display in bar chart

  const [isMobile, setIsMobile] = useState(false); // To check window size of mobile
  const [screenWidth, setScreenWidth] = useState();
  const [screenHeight, setScreenHeight] = useState();
  const [windowWidth, setWindowWidth] = useState();
  const [windowHeight, setWindowHeight] = useState();
  const [oneDayDataTs, setOneDayDataTS] = useState([]); // Store data of last 24hour
  const [scaleValue, setScaleValue] = useState(1); // To zoom in/out in mobile view
  const [documentId, setDocumentId] = useState('');
  const [vNetPassword, setVNetPassword] = useState(""); // store V-Net password
  const [vNetUsername, setVNetUsername] = useState(""); // store V-Net username
  const [deviceGroupName, setDeviceGroupName] = useState("");// store V-Net groupname 
  const [deviceName, setDeviceName] = useState("");
  const [tagGroupName, setTagGroupName] = useState("");
  const dispatch = useDispatch();
  const graphData = useSelector((state) => state.viewData.graphData);
  const widgetData = useSelector((state) => state.viewData.widgetData);


  const updateWidgetMsg = useSelector(
    (state) => state.viewData.updateWidgetMsg,
  );


  // Checking mobile view or not
  useEffect(() => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);

    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);


  // Calling API's 
  useEffect(() => {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem("organizationEmail");
    const data = {
      ProjectID: uniqueProjectId,
      token: token
    }
    try {
      dispatch(getProjectSetting(data));
      dispatch(getMqttCredentials(email));
    } catch (error) {
      console.log(error);
    }
  }, [uniqueProjectId])

  //Storing saved V-net info in states
  useEffect(() => {
    if (widgetData?.username) {
      setVNetUsername(widgetData?.username);
    }
    if (widgetData?.password) {
      setVNetPassword(widgetData?.password)
    }
    if (widgetData?.deviceGroupName) {
      setDeviceGroupName(widgetData?.deviceGroupName)
    }
    if (widgetData?.deviceName) {
      setDeviceName(widgetData?.deviceName);
    }
    if (widgetData?.tagGroupName) {
      setTagGroupName(widgetData?.tagGroupName);
    }

  }, [widgetData])

  const navigate = useNavigate();

  // Function to generate random color
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  React.useLayoutEffect(() => {
    document.title = 'Project Data';
    if (localStorage.getItem('token') == null) {
      //condition if not logged in
      navigate('/auth/login');
    } // eslint-disable-next-line
  }, []);

  // To Open Update View Page
  const handleNewPage = () => {
    setOpenNewPage(true);
  };


  // Storing current tab index in state
  const handleTabIndex = (tabIndex, tabName) => {
    setSelectedTabIndex(tabIndex);
    setIsPath(tabName);
  };

  // Function to increase scale value
  const increaseScale = () => {
    setScaleValue(scaleValue + 0.1);
  }

  // Function to decrease scale value
  const decreaseScale = () => {
    if (scaleValue > 0.2) {
      setScaleValue(scaleValue - 0.1);
    }
  }

  // Fetching Updated View Data
  useEffect(() => {
    const apiBody = {
      projectID: uniqueProjectId,
    };
    try {

      dispatch(getWidgetData(apiBody));
    } catch (error) {
      console.log(error);
    }
  }, [openNewPage]);


  // Arraging widget to display it according the saved informations

  useEffect(() => {
    if (Object.keys(widgetData).length > 0) {
      const tempWidgetData = { ...widgetData };

      if (tempWidgetData.data && tempWidgetData.data[0]) {
        setScreenHeight(tempWidgetData.data[0].screenHeight);
        setScreenWidth(tempWidgetData.data[0].screenWidth);
        setDocumentId(tempWidgetData.data[0].id)
      }

      if (
        tempWidgetData.data &&
        tempWidgetData.data[0] &&
        tempWidgetData.data[0].tabs
      ) {
        setTabNames(tempWidgetData.data[0].tabs);
      }
      if (
        tempWidgetData.data &&
        tempWidgetData.data[0] &&
        tempWidgetData.data[0].widget
      ) {

        setImages(tempWidgetData.data[0].widget.map((data) => data));
        const organizedImages = {};
        const organizedDevices = {};
        const organizedDisplay = {};
        const organizedController = {};
        const organizedIO = {};
        const organizedFlowMeter = {};
        const organizedEnergyMeter = {};
        const organizedMotor = {};
        const organizedText = {};
        const organizedLineChart = {};
        const organizedPieChart = {};
        const organizedBarChart = {};
        const organizedHistogram = {};
        const organizedGauge = {};
        const organizedProgressBar = {};
        const organizedGrid = {};
        const organizedTable = {};
        const organizedOverviewInfo = {};
        const organizedOverviewGraph = {};
        const organizedLiveDataGraph = {};
        const organizedLastDayTable = {};
        const organizedLastDayGraph = {};
        const organizedDefaultGrid = {};
        widgetData.data[0]?.widget.forEach((image) => {
          const {
            x,
            y,
            width,
            height,
            name,
            tabIndex,
            id,
            channelName,
            deviceType,
            unit,
            unitPv,
            unitSv,
            unitSv2,
            unitSv3,
          } = image;

          if (name && name.toLowerCase().includes('image')) {
            if (!organizedImages[tabIndex]) {
              organizedImages[tabIndex] = [];
            }
            organizedImages[tabIndex].push({
              x,
              y,
              width,
              height,
              name,
              tabIndex,
              id,
            });
            setTabIndexImages(organizedImages);
          }
          if (deviceType === 'Controller') {
            if (!organizedController[tabIndex]) {
              organizedController[tabIndex] = [];
            }
            organizedController[tabIndex].push({
              x,
              y,
              name,
              channelName,
              tabIndex,
              id,
              deviceType,
              unit,
            });
            setTabIndexController(organizedController);
          }
          if (deviceType === 'Indicator') {
            const { name, x, y, tabIndex, channelName, id, deviceType } = image;
            if (!organizedDevices[tabIndex]) {
              organizedDevices[tabIndex] = [];
            }
            organizedDevices[tabIndex].push({
              x,
              y,
              name,
              channelName,
              tabIndex,
              id,
              deviceType,
              unit,
            });
            setTabIndexDevices(organizedDevices);
          }

          if (deviceType === 'Display') {
            const { name, x, y, tabIndex, channelName, id, deviceType, width, height, bgColor, textColor, fontSize } = image;
            if (!organizedDisplay[tabIndex]) {
              organizedDisplay[tabIndex] = [];
            }
            organizedDisplay[tabIndex].push({
              x,
              y,
              name,
              channelName,
              tabIndex,
              id,
              deviceType,
              width,
              height,
              bgColor,
              textColor,
              fontSize
            });
            setTabIndexDisplay(organizedDisplay);
          }

          if (deviceType === 'DigitalIO') {
            const { name, x, y, tabIndex, id, deviceType, on } = image;

            if (!organizedIO[tabIndex]) {
              organizedIO[tabIndex] = [];
            }
            organizedIO[tabIndex].push({
              x,
              y,
              name,
              on,
              tabIndex,
              id,
              deviceType,
            });
            setTabIndexIO(organizedIO);
          }

          if (deviceType === 'FlowMeter') {
            const { name, x, y, tabIndex, id, deviceType, channelName } = image;

            if (!organizedFlowMeter[tabIndex]) {
              organizedFlowMeter[tabIndex] = [];
            }
            organizedFlowMeter[tabIndex].push({
              x,
              y,
              name,
              channelName,
              tabIndex,
              id,
              deviceType,
              unit,
              unitPv,
              unitSv,
            });
            setTabIndexFlowM(organizedFlowMeter);
          }

          if (deviceType === 'EnergyMeter') {
            const { name, x, y, tabIndex, id, deviceType, channelName } = image;

            if (!organizedEnergyMeter[tabIndex]) {
              organizedEnergyMeter[tabIndex] = [];
            }
            organizedEnergyMeter[tabIndex].push({
              x,
              y,
              name,
              channelName,
              tabIndex,
              id,
              deviceType,
              unit,
              unitPv,
              unitSv,
              unitSv2,
              unitSv3,
            });
            setTabIndexEnergyM(organizedEnergyMeter);
          }

          if (deviceType === 'Motor') {
            const { name, x, y, tabIndex, id, deviceType, width, height, on, channelName } = image;

            if (!organizedMotor[tabIndex]) {
              organizedMotor[tabIndex] = [];
            }
            organizedMotor[tabIndex].push({
              x,
              y,
              name,
              channelName,
              tabIndex,
              id,
              deviceType,
              width,
              height,
              on,
            });
            setTabIndexMotor(organizedMotor);
          }

          if (deviceType === 'Text') {
            const { name, x, y, tabIndex, id, deviceType, width, height, bgColor, textColor, fontSize, text, alignHorizontally, alignVertically } = image;
            if (!organizedText[tabIndex]) {
              organizedText[tabIndex] = [];
            }
            organizedText[tabIndex].push({
              x,
              y,
              name,
              tabIndex,
              id,
              deviceType,
              width,
              height,
              bgColor,
              textColor,
              fontSize,
              text,
              alignHorizontally,
              alignVertically
            });
            setTabIndexText(organizedText);
          }

          if (deviceType === 'LineChart') {
            const {
              name,
              x,
              y,
              tabIndex,
              id,
              deviceType,
              chartChannel,
              width,
              height,
              hour,
              minute,
            } = image;
            if (!organizedLineChart[tabIndex]) {
              organizedLineChart[tabIndex] = [];
            }
            organizedLineChart[tabIndex].push({
              name,
              x,
              y,
              tabIndex,
              id,
              deviceType,
              chartChannel,
              width,
              height,
              hour,
              minute,
            });
            setTabIndexLineChart(organizedLineChart);
          }

          if (deviceType === 'PieChart') {
            const {
              name,
              x,
              y,
              tabIndex,
              id,
              deviceType,
              chartChannel,
              width,
              height,
            } = image;
            if (!organizedPieChart[tabIndex]) {
              organizedPieChart[tabIndex] = [];
            }
            organizedPieChart[tabIndex].push({
              name,
              x,
              y,
              tabIndex,
              id,
              deviceType,
              chartChannel,
              width,
              height,
            });
            setTabIndexPieChart(organizedPieChart);
          }

          if (deviceType === 'BarChart') {
            const {
              name,
              x,
              y,
              tabIndex,
              id,
              deviceType,
              chartChannel,
              width,
              height,
            } = image;
            if (!organizedBarChart[tabIndex]) {
              organizedBarChart[tabIndex] = [];
            }
            organizedBarChart[tabIndex].push({
              name,
              x,
              y,
              tabIndex,
              id,
              deviceType,
              chartChannel,
              width,
              height,
            });

            setTabIndexBarChart(organizedBarChart);
          }

          if (deviceType === 'Histogram') {
            const {
              name,
              x,
              y,
              tabIndex,
              id,
              deviceType,
              chartChannel,
              width,
              height,
              hour,
              minute,
            } = image;
            if (!organizedHistogram[tabIndex]) {
              organizedHistogram[tabIndex] = [];
            }
            organizedHistogram[tabIndex].push({
              name,
              x,
              y,
              tabIndex,
              id,
              deviceType,
              chartChannel,
              width,
              height,
              hour,
              minute,
            });

            setTabIndexHistogram(organizedHistogram);
          }

          if (deviceType === 'Gauge') {
            const {
              name,
              x,
              y,
              tabIndex,
              id,
              deviceType,
              chartChannel,
              minVal,
              maxVal,
              width,
              height,
            } = image;
            if (!organizedGauge[tabIndex]) {
              organizedGauge[tabIndex] = [];
            }
            organizedGauge[tabIndex].push({
              name,
              x,
              y,
              tabIndex,
              id,
              deviceType,
              chartChannel,
              minVal,
              maxVal,
              width,
              height,
            });

            setTabIndexGauge(organizedGauge);
          }

          if (deviceType === 'ProgressBar') {
            const {
              name,
              x,
              y,
              tabIndex,
              id,
              deviceType,
              chartChannel,
              minVal,
              maxVal,
              rotate,
              width,
              height,
            } = image;
            if (!organizedProgressBar[tabIndex]) {
              organizedProgressBar[tabIndex] = [];
            }
            organizedProgressBar[tabIndex].push({
              name,
              x,
              y,
              tabIndex,
              id,
              deviceType,
              chartChannel,
              minVal,
              maxVal,
              rotate,
              width,
              height,
            });

            setTabIndexProgress(organizedProgressBar);
          }

          if (deviceType === 'Grid') {
            const {
              name,
              deviceType,
              tabIndex,
              chartChannel,
              id,
              x,
              y,
              width,
              height,
            } = image;
            if (!organizedGrid[tabIndex]) {
              organizedGrid[tabIndex] = [];
            }
            organizedGrid[tabIndex].push({
              name,
              tabIndex,
              id,
              deviceType,
              chartChannel,
              x,
              y,
              width,
              height,
            });
            setTabIndexGrid(organizedGrid);
          }

          if (deviceType === 'Table') {
            const {
              name,
              x,
              y,
              tabIndex,
              id,
              deviceType,
              width,
              height,
              chartChannel,
              column,
            } = image;
            if (!organizedTable[tabIndex]) {
              organizedTable[tabIndex] = [];
            }
            organizedTable[tabIndex].push({
              name,
              tabIndex,
              id,
              deviceType,
              x,
              y,
              width,
              height,
              chartChannel,
              column,
            });
            setTabIndexTable(organizedTable);
          }

          if (name === 'overviewInfo') {
            const { name, tabIndex, x, y, id, width, height } = image;
            if (!organizedOverviewInfo[tabIndex]) {
              organizedOverviewInfo[tabIndex] = [];
            }
            organizedOverviewInfo[tabIndex].push({
              name,
              id,
              x,
              y,
              width,
              height,
              tabIndex,
            });
            setTempTabIndexOverviewInfo(organizedOverviewInfo);
            setTabIndexOverviewInfo(organizedOverviewInfo);
          }

          if (name === 'overviewGraph') {
            const { name, tabIndex, x, y, id, width, height } = image;
            if (!organizedOverviewGraph[tabIndex]) {
              organizedOverviewGraph[tabIndex] = [];
            }
            organizedOverviewGraph[tabIndex].push({
              name,
              id,
              x,
              y,
              width,
              height,
              tabIndex,
            });
            setTempTabIndexOverviewGraph(organizedOverviewGraph);
            setTabIndexOverviewGraph(organizedOverviewGraph);
          }

          if (name === 'liveDataGraph') {
            const { name, tabIndex, x, y, id, width, height } = image;
            if (!organizedLiveDataGraph[tabIndex]) {
              organizedLiveDataGraph[tabIndex] = [];
            }
            organizedLiveDataGraph[tabIndex].push({
              name,
              id,
              x,
              y,
              width,
              height,
              tabIndex,
            });

            setTabIndexLiveDataGraph(organizedLiveDataGraph);
            setTempTabIndexLiveDataGraph(organizedLiveDataGraph);
          }

          if (name === 'lastDayTable') {
            const { name, tabIndex, x, y, id } = image;
            if (!organizedLastDayTable[tabIndex]) {
              organizedLastDayTable[tabIndex] = [];
            }
            organizedLastDayTable[tabIndex].push({
              name,
              id,
              x,
              y,
              width,
              height,
              tabIndex,
            });
            setTabIndexLastDayTable(organizedLastDayTable);
            setTempTabIndexLastDayTable(organizedLastDayTable);
          }

          if (name === 'lastDayGraph') {
            const { name, tabIndex, x, y, id } = image;
            if (!organizedLastDayGraph[tabIndex]) {
              organizedLastDayGraph[tabIndex] = [];
            }
            organizedLastDayGraph[tabIndex].push({
              name,
              id,
              x,
              y,
              width,
              height,
              tabIndex,
            });
            setTabIndexLastDayGraph(organizedLastDayGraph);
            setTempTabIndexLastDayGraph(organizedLastDayGraph);
          }

          if (name === 'DefaultGrid') {
            const { name, tabIndex, x, y, id, width, height } = image;
            if (!organizedDefaultGrid[tabIndex]) {
              organizedDefaultGrid[tabIndex] = [];
            }
            organizedDefaultGrid[tabIndex].push({
              name,
              id,
              x,
              y,
              width,
              height,
              tabIndex,
            });
            setTempTabIndexDefaultGrid(organizedDefaultGrid);
            setTabIndexDefaultGrid(organizedDefaultGrid);
          }
        });
      } else {
        setImages([]);
      }
    }
  }, [widgetData]);

  // Function to generate saved IMAGES according saved information

  const generateImage = () => {
    const selectedTabImages = tabIndexImages[selectedTabIndex];
    if (Array.isArray(selectedTabImages)) {
      return tabIndexImages[selectedTabIndex].map((image, index) => {
        const { x, y, width, height, name } = image;
        const imageStyle = {
          display: 'flex',
          position: 'absolute',
          top: y + 'px',
          left: x + 'px',
          width,
          height,
          scale: isMobile ? scaleValue.toString() : "1",
          zIndex: "1"
        };
        return (
          <div style={imageStyle} key={index}>
            <img src={name} alt="" />
          </div>
        );
      });
    }
  };

  // Function to generate saved INDICATORS according saved information

  const generateDevice = () => {
    const selectedTabDevices = tabIndexDevices[selectedTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, name, id, channelName, tabIndex, deviceType, unit } =
          device;
        const deviceStyle = {

          backgroundColor: 'rgb(20, 17, 15)',
          width: isMobile ? "55%" : '20%',
          position: 'absolute',
          top: y + 'px',
          left: x + 'px',
          borderRadius: '0.625rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          padding: '0.6rem',
          scale: isMobile ? scaleValue.toString() : "1",
          zIndex: "1"

        };

        return (
          <>
            <div style={deviceStyle} key={index}>
              <Devices
                openNewPage={openNewPage}
                tabIndexDevices={tabIndexDevices}
                device={device}
                name={name}
                tableData={tableData}
                channelName={channelName.PV}
                tabIndex={tabIndex}
                id={id}
                selectedTabIndex={selectedTabIndex}
                deviceType={deviceType}
                unit={unit}
              />
            </div>
          </>
        );
      });
    }
  };

  // Function to generate saved DISPLAY according saved information

  const generateDisplay = () => {
    const selectedTabDevices = tabIndexDisplay[selectedTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, channelName, width, height, bgColor, textColor, fontSize } =
          device;

        const deviceStyle = {
          position: "absolute",
          top: y + 'px',
          left: x + 'px',
          borderRadius: "0.625rem",
          backgroundColor: bgColor,
          color: textColor,
          padding: "0.6rem 1rem",
          fontSize: fontSize ? fontSize + "px" : "30px",
          width: width ? width : "12%",
          height: height ? height : undefined,
          scale: isMobile ? scaleValue.toString() : "1",
          zIndex: "1"
        };

        return (
          <>
            <div style={deviceStyle} key={index}>
              <DeviceDisplay
                openNewPage={openNewPage}
                tableData={tableData}
                channelName={channelName.PV}
              />
            </div>
          </>
        );
      });
    }
  };

  // Function to generate saved CONTROLLERS according saved information
  const generateController = () => {
    const selectedTabDevices = tabIndexController[selectedTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, name, id, channelName, tabIndex, deviceType, unit } =
          device;
        const deviceStyle = {
          backgroundColor: 'rgb(20, 17, 15)',
          width: isMobile ? "55%" : '20%',
          position: 'absolute',
          top: y + 'px',
          left: x + 'px',
          borderRadius: '0.625rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          padding: '0.6rem',
          scale: isMobile ? scaleValue.toString() : "1",
          zIndex: "1"

        };

        return (
          <>
            <div style={deviceStyle} key={index}>
              <DeviceController
                openNewPage={openNewPage}
                tabIndexController={tabIndexController}
                device={device}
                name={name}
                tableData={tableData}
                channelName={channelName}
                tabIndex={tabIndex}
                id={id}
                selectedTabIndex={selectedTabIndex}
                deviceType={deviceType}
                unit={unit}
              />
            </div>
          </>
        );
      });
    }
  };

  // Function to generate saved DIGITAL I/O according saved information
  const generateIO = () => {
    const selectedTabDevices = tabIndexIO[selectedTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, name, on } = device;
        const deviceStyle = {
          width: isMobile ? "57%" : '190px',
          height: '160px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '0.5rem',
          position: 'absolute',
          top: y + 'px',
          left: x + 'px',
          scale: isMobile ? scaleValue.toString() : "1",
          zIndex: "1"

        };

        return (
          <>
            <div style={deviceStyle} key={index}>
              <DeviceIO name={name} on={on} />
            </div>
          </>
        );
      });
    }
  };

  // Function to generate saved FLOWMETERS according saved information
  const generateFlowMeter = () => {
    const selectedTabDevices = tabIndexFlowM[selectedTabIndex];

    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const {
          x,
          y,
          name,
          id,
          channelName,
          tabIndex,
          deviceType,
          unit,
          unitPv,
          unitSv,
        } = device;
        const deviceStyle = {
          backgroundColor: 'rgb(20, 17, 15)',
          width: isMobile ? "55%" : '25%',
          position: 'absolute',
          top: y + 'px',
          left: x + 'px',
          borderRadius: '0.625rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
          padding: '0.6rem',
          scale: isMobile ? scaleValue.toString() : "1",
          zIndex: "1"

        };

        return (
          <>
            <div style={deviceStyle} key={index}>
              <DeviceFlowMeter
                openNewPage={openNewPage}
                tabIndexFlowM={tabIndexFlowM}
                device={device}
                name={name}
                tableData={tableData}
                channelName={channelName}
                tabIndex={tabIndex}
                id={id}
                selectedTabIndex={selectedTabIndex}
                deviceType={deviceType}
                unit={unit}
                unitPv={unitPv}
                unitSv={unitSv}
              />
            </div>
          </>
        );
      });
    }
  };

  // Function to generate saved ENERGYMETERS according saved information
  const generateEnergyMeter = () => {
    const selectedTabDevices = tabIndexEnergyM[selectedTabIndex];

    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const {
          x,
          y,
          name,
          id,
          channelName,
          tabIndex,
          deviceType,
          unit,
          unitPv,
          unitSv,
          unitSv2,
          unitSv3,
        } = device;
        const deviceStyle = {
          backgroundColor: 'rgb(20, 17, 15)',
          width: isMobile ? "55%" : '25%',
          position: 'absolute',
          top: y + 'px',
          left: x + 'px',
          borderRadius: '0.625rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          padding: '0.6rem',
          scale: isMobile ? scaleValue.toString() : "1",
          zIndex: "1"

        };

        return (
          <>
            <div style={deviceStyle} key={index}>
              <DeviceEnergyMeter
                openNewPage={openNewPage}
                tabIndexEnergyM={tabIndexEnergyM}
                device={device}
                name={name}
                tableData={tableData}
                channelName={channelName}
                tabIndex={tabIndex}
                id={id}
                selectedTabIndex={selectedTabIndex}
                deviceType={deviceType}
                unit={unit}
                unitPv={unitPv}
                unitSv={unitSv}
                unitSv2={unitSv2}
                unitSv3={unitSv3}
              />
            </div>
          </>
        );
      });
    }
  };

  // Function to generate saved TEXTS according saved information
  const generateText = () => {

    const selectedTabDevices = tabIndexText[selectedTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, width, height, bgColor, textColor, text, fontSize, alignHorizontally, alignVertically } =
          device;

        const deviceStyle = {
          position: "absolute",
          top: y + 'px',
          left: x + 'px',
          backgroundColor: bgColor,
          color: textColor,
          padding: "0.6rem 1rem",
          fontSize: fontSize ? fontSize + "px" : "30px",
          width: width ? width : undefined,
          height: height ? height : undefined,
          scale: isMobile ? scaleValue.toString() : "1",
          display: "flex",
          wordBreak: "break-word",
          justifyContent: alignHorizontally ? alignHorizontally : "start",
          alignItems: alignVertically ? alignVertically : "start",
          zIndex: "1"
        };

        return (
          <>
            <div style={deviceStyle} key={index}>
              {text}
            </div>
          </>
        );
      });
    }
  };

  // Function to generate saved MOTORS according saved information
  const generateMotor = () => {
    const selectedTabImages = tabIndexMotor[selectedTabIndex];
    if (Array.isArray(selectedTabImages)) {
      return selectedTabImages.map((image, index) => {
        const { x, y, width, height, name } = image;
        const imageStyle = {
          display: 'flex',
          position: 'absolute',
          top: y + 'px',
          left: x + 'px',
          width: width ? width : "250px",
          height: height ? height : "150px",
          scale: isMobile ? scaleValue.toString() : "1",
          zIndex: "1"
        };
        return (
          <div style={imageStyle} key={index}>
            {image.on ? (

              <img src={motorgif} alt='MotorGif' />
            ) : (

              <img src={name} alt="" />
            )}
          </div>
        );
      });
    }
  };


  // Function to generate saved LINE-CHARTS according saved information
  const generateLineChart = () => {
    const selectedTabDevices = tabIndexLineChart[selectedTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, id, chartChannel, width, hour, minute } = device;
        const deviceStyle = {
          position: 'absolute',
          top: y + 'px',
          left: x + 'px',
          backgroundColor: '#fff',
          width: isMobile ? "120%" : width ? width : '40%',
          scale: isMobile ? scaleValue.toString() : "1",
          borderRadius: ".625rem",
          zIndex: "1"
        };

        return (
          <>
            <div style={deviceStyle} key={index}>
              <LineChart
                openNewPage={openNewPage}
                dateTimesLive={dateTimesLive}
                channelNames={channelNames}
                dataArrLive={dataArrLive}
                getRandomColor={getRandomColor}
                chartChannel={chartChannel}
                selectedTabIndex={selectedTabIndex}
                id={id}
                data={data}
                hour={hour}
                minute={minute}
                oneDayDataTs={oneDayDataTs}
              />
            </div>
          </>
        );
      });
    }
  };

  // Function to generate saved PIE-CHARTS according saved information
  const generatePieChart = () => {
    const selectedTabDevices = tabIndexPieChart[selectedTabIndex];

    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, id, chartChannel, width } = device;

        const deviceStyle = {
          position: 'absolute',
          top: y + 'px',
          left: x + 'px',
          width: isMobile ? "100%" : width ? width : '27%',
          scale: isMobile ? scaleValue.toString() : "1",
          zIndex: "1"
        };

        return (
          <>
            <div style={deviceStyle} key={index}>
              <PieChart
                openNewPage={openNewPage}
                dateTimesLive={dateTimesLive}
                channelNames={channelNames}
                dataArrLive={dataArrLive}
                getRandomColor={getRandomColor}
                chartChannel={chartChannel}
                tabIndexPieChart={tabIndexPieChart}
                selectedTabIndex={selectedTabIndex}
                id={id}
              />
            </div>
          </>
        );
      });
    }
  };

  // Function to generate saved BAR-CHARTS according saved information
  const generateBarChart = () => {
    const selectedTabDevices = tabIndexBarChart[selectedTabIndex];

    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, chartChannel, width, height } = device;
        const deviceStyle = {
          backgroundColor: '#fff',
          position: 'absolute',
          top: y + 'px',
          left: x + 'px',
          width: isMobile ? "120%" : width ? width : '40%',
          height: height,
          borderRadius: ".625rem",
          scale: isMobile ? scaleValue.toString() : "1",
          zIndex: "1"
        };

        return (
          <>
            <div style={deviceStyle} key={index}>
              <BarChart
                openNewPage={openNewPage}
                dateTimesLive={dateTimesLive}
                channelNames={channelNames}
                dataArrLive={dataArrLive}
                getRandomColor={getRandomColor}
                setBarChartData={setBarChartData}
                chartChannel={chartChannel}
                tabIndexBarChart={tabIndexBarChart}
                selectedTabIndex={selectedTabIndex}
                barChartData={barChartData}
              />
            </div>
          </>
        );
      });
    }
  };

  // Function to generate saved HISTOGRAMS according saved information
  const generateHistogram = () => {
    const selectedTabDevices = tabIndexHistogram[selectedTabIndex];

    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, chartChannel, width, hour, minute } = device;

        const deviceStyle = {
          backgroundColor: '#fff',
          position: 'absolute',
          top: y + 'px',
          left: x + 'px',
          width: isMobile ? "120%" : width ? width : '40%',
          borderRadius: ".625rem",
          scale: isMobile ? scaleValue.toString() : "1",
          zIndex: "1"
        };

        return (
          <>
            <div style={deviceStyle} key={index}>
              <Histogram
                openNewPage={openNewPage}
                dateTimesLive={dateTimesLive}
                channelNames={channelNames}
                dataArrLive={dataArrLive}
                getRandomColor={getRandomColor}
                setHistogramData={setHistogramData}
                chartChannel={chartChannel}
                tabIndexBarChart={tabIndexBarChart}
                selectedTabIndex={selectedTabIndex}
                histogramData={histogramData}
                oneDayDataTs={oneDayDataTs}
                hour={hour}
                minute={minute}
              />
            </div>
          </>
        );
      });
    }
  };

  // Function to generate saved GUAGES according saved information
  const generateGauge = () => {
    const selectedTabDevices = tabIndexGauge[selectedTabIndex];

    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, minVal, maxVal, chartChannel, width } = device;

        const chartChannelArray =
          typeof chartChannel === 'string' ? [chartChannel] : chartChannel;

        const deviceStyle = {
          position: 'absolute',
          top: y + 'px',
          left: x + 'px',
          width: isMobile ? "70%" : width !== null ? width : "20%",
          scale: isMobile ? scaleValue.toString() : "1",
          zIndex: "1"
        };

        return (
          <>
            <div style={deviceStyle} key={index}>
              <Gauge
                openNewPage={openNewPage}
                dateTimesLive={dateTimesLive}
                channelNames={channelNames}
                dataArrLive={dataArrLive}
                getRandomColor={getRandomColor}
                chartChannel={chartChannelArray}
                tabIndexBarChart={tabIndexBarChart}
                selectedTabIndex={selectedTabIndex}
                minVal={minVal}
                maxVal={maxVal}
                isMobile={isMobile}
              />
            </div>
          </>
        );
      });
    }
  };

  // Function to generate saved PROGRESSBARS according saved information
  const generateProgressBar = () => {
    const selectedTabDevices = tabIndexProgress[selectedTabIndex];

    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {

        const { x, y, minVal, maxVal, chartChannel, rotate, height, width } =
          device;

        const chartChannelArray =
          typeof chartChannel === 'string' ? [chartChannel] : chartChannel;

        const deviceStyle = {
          position: 'absolute',
          top: y + 'px',
          left: x + 'px',
          display: rotate ? 'flex' : null,
          justifyContent: rotate ? 'center' : null,
          alignItems: rotate ? 'center' : null,
          height: height ? height : "",
          width: width ? width : '20%',
          scale: isMobile ? scaleValue.toString() : "1",
          zIndex: "1"
        };

        return (
          <>
            <div style={deviceStyle} key={index}>
              <ProgressBar
                openNewPage={openNewPage}
                dateTimesLive={dateTimesLive}
                channelNames={channelNames}
                dataArrLive={dataArrLive}
                getRandomColor={getRandomColor}
                chartChannel={chartChannelArray}
                tabIndexProgress={tabIndexProgress}
                selectedTabIndex={selectedTabIndex}
                minVal={minVal}
                maxVal={maxVal}
                rotate={rotate}
              />
            </div>
          </>
        );
      });
    }
  };

  // Function to generate saved GRIDS according saved information
  const generateGrid = () => {
    const selectedTabDevices = tabIndexGrid[selectedTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, chartChannel, width, height } = device;

        const deviceStyle = {
          position: 'absolute',
          top: y + 'px',
          left: x + 'px',
          width: width,
          height: height,
          scale: isMobile ? scaleValue.toString() : "1",
          zIndex: "1"
        };

        return (
          <>
            <div style={deviceStyle} key={index}>
              <ToolBarGrid chartChannel={chartChannel} tableData={tableData} />
            </div>
          </>
        );
      });
    }
  };

  // Function to generate saved TABLES according saved information
  const generateTable = () => {
    const selectedTabDevices = tabIndexTable[selectedTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, width, chartChannel, column, height } = device;

        const deviceStyle = {
          position: 'absolute',
          top: y + 'px',
          left: x + 'px',
          width: width,
          height: height,
          scale: isMobile ? scaleValue.toString() : "1",
          zIndex: "1"
        };

        return (
          <>
            <div style={deviceStyle} key={index}>
              <MovableTable
                openNewPage={openNewPage}
                dateTimesLive={dateTimesLive}
                channelNames={channelNames}
                chartChannel={chartChannel}
                dataArrLive={dataArrLive}
                getRandomColor={getRandomColor}
                tabIndexProgress={tabIndexProgress}
                selectedTabIndex={selectedTabIndex}
                column={column}
              />
            </div>
          </>
        );
      });
    }
  };

  // Calling API to get graph data
  useEffect(() => {
    const token = localStorage.getItem('token');
    const graphDataBody = {
      token: token,
      projectId: uniqueProjectId,
      ind: 1,
    };

    try {
      dispatch(getGraphData(graphDataBody));
    } catch (error) {
      console.log(error);
    }
  }, []);


  useEffect(() => {

    const newSocket = io(portlessProxy, {
      cors: {
        origin: '*',
        allowedHeaders: ['*'],
        allowedMethods: ['GET', 'POST'],
        insecure: true,
      },
    });
    setSocket(newSocket);
    return () => newSocket.close();
  }, [uniqueProjectId]);

  // Starts socket connection
  useEffect(() => {
    if (socket) {
      // Joining the stream of project
      socket.emit('joinRoom', String(uniqueProjectId));
      // On message storing values in state
      socket.on('message', (msg) => {
        const parsedData = JSON.parse(msg);
        if (parsedData.time && parsedData.Devices) {
          setSocketData(parsedData);
        } else if (!parsedData.time && !parsedData.Devices) {
          parsedData.time = new Date();
          parsedData.Devices = parsedData
          setSocketData(parsedData);
        } else if (!parsedData.time && parsedData.Devices) {
          parsedData.time = new Date();
          setSocketData(parsedData);
        } else {
          parsedData.Devices = parsedData
          setSocketData(parsedData);
        }

      });

      socket.on('error', (error) => {
        console.error('Socket error:', error);
      });
    }
    return () => {
      if (socket) {
        socket.off('Connection Closed');
      }
    };
  }, [uniqueProjectId, socket]);

  useEffect(() => {
    if (graphData.length > 0) {
      // Parsing data from from get request in the parent component
      const copiedDataset = [...graphData];
      const fetchData = async () => {
        try {
          if (graphData.length > 0) {
            // Live Data Chart
            const currentTime = new Date();
            currentTime.setTime(currentTime.getTime() - 1 * 60 * 60 * 1000); // Subtracting 1 hour
            const liveDataFiltered = copiedDataset.filter(
              (dataset) => new Date(dataset.time) >= currentTime,
            );
            // Restructure Data for Table
            const tableDataArrayGen = async () => {
              const dataPromise = new Promise((resolve) => {
                tableInitializer.onmessage = (e) => {
                  resolve(e.data);
                };
              });
              tableInitializer.postMessage({ data: liveDataFiltered });
              return await dataPromise;
            };
            const tableDataArray = await tableDataArrayGen();
            let gotHeaders = [];

            if (tableDataArray.data) {
              gotHeaders = tableDataArray.headers;
              const arrayOfValueArrays = tableDataArray.channelWiseData;

              const getNewDataArr = () =>
                gotHeaders.map((header, index) => ({
                  label: header,
                  data: arrayOfValueArrays[index],
                  borderColor: getRandomColor(),
                  fill: false,
                }));
              const newDataArr = getNewDataArr();

              setChannelNames(gotHeaders);
              setTableData(tableDataArray.data);
              setDataArrLive(newDataArr);
              setDateTimesLive(tableDataArray.dateTimeArray);
              setInit(true);
            }
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }
  }, [graphData]);

  useEffect(() => {
    tableData.map((data, index) => setHeaderNames(data));
  }, [tableData, graphData]);

  useEffect(() => {
    try {
      const updateData = async () => {
        if (socketData && init && !isWorkerBusy) {

          setIsWorkerBusy(true);
          const structuredData = async () => {
            const dataPromise = new Promise((resolve) => {
              valueExtractor.onmessage = (e) => {
                resolve(e.data);
              };
            });
            valueExtractor.postMessage({
              socketData: socketData,
              currentTable: tableData,
              dateTimeArray: dateTimesLive,
              dataArray: dataArrLive,
              headers: channelNames,
              duration: 1,
            });
            return await dataPromise;
          };

          const workerResponse = await structuredData();
          if (workerResponse.updatedTable) {

            setChannelNames(workerResponse.updatedHeaders);
            setTableData(workerResponse.updatedTable);
            setDataArrLive(workerResponse.updatedLiveArray);
            setDateTimesLive(workerResponse.updatedDateTimeArray);
          }
        }
      };
      updateData();
    } catch (error) {
      console.log(error);
    }
    setIsWorkerBusy(false);
  }, [socketData]);

  useEffect(() => {
    // Parsing data from from get request in the parent component
    const fetchData = async () => {
      try {
        if (graphData.length > 0) {
          const copiedDataset = [...graphData];
          // Restructure Data for Table
          const tableDataArrayGen = async () => {
            const dataPromise = new Promise((resolve) => {
              restructureWorker.onmessage = (e) => {
                resolve(e.data);
              };
            });
            restructureWorker.postMessage({ data: copiedDataset });
            return await dataPromise;
          };
          const tableDataArray = await tableDataArrayGen();

          if (tableDataArray.headers.length > 0) {
            setChannelNames(tableDataArray.headers);
            setDataArrLive(tableDataArray.chartArray);
            setOneDayDataTS(tableDataArray.timeArray);
            setInit(true);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [graphData]);

  return (
    <>
      {openNewPage ? (
        <NewPage
          setOpenNewPage={setOpenNewPage}
          openNewPage={openNewPage}
          tabNames={tabNames}
          setTabNames={setTabNames}
          data={data}
          socketData={socketData}
          uniqueProjectId={uniqueProjectId}
          images={images}
          setImages={setImages}
          tempImagePosition={tempImagePosition}
          setTempImagePosition={setTempImagePosition}
          tabIndexImages={tabIndexImages}
          setTabIndexImages={setTabIndexImages}
          tableData={tableData}
          tabIndexDevices={tabIndexDevices}
          setTabIndexDevices={setTabIndexDevices}
          headerNames={headerNames}
          tabIndexController={tabIndexController}
          setTabIndexController={setTabIndexController}
          tabIndexIO={tabIndexIO}
          setTabIndexIO={setTabIndexIO}
          setTabIndexFlowM={setTabIndexFlowM}
          tabIndexFlowM={tabIndexFlowM}
          tabIndexEnergyM={tabIndexEnergyM}
          setTabIndexEnergyM={setTabIndexEnergyM}
          chartDataLive={chartDataLive}
          tabIndexLineChart={tabIndexLineChart}
          setTabIndexLineChart={setTabIndexLineChart}
          tabIndexPieChart={tabIndexPieChart}
          setTabIndexPieChart={setTabIndexPieChart}
          dateTimesLive={dateTimesLive}
          channelNames={channelNames}
          dataArrLive={dataArrLive}
          getRandomColor={getRandomColor}
          setChartDataLive={setChartDataLive}
          tabIndexBarChart={tabIndexBarChart}
          setTabIndexBarChart={setTabIndexBarChart}
          barChartData={barChartData}
          setBarChartData={setBarChartData}
          tabIndexHistogram={tabIndexHistogram}
          setTabIndexHistogram={setTabIndexHistogram}
          histogramData={histogramData}
          setHistogramData={setHistogramData}
          tabIndexGauge={tabIndexGauge}
          setTabIndexGauge={setTabIndexGauge}
          tabIndexProgress={tabIndexProgress}
          setTabIndexProgress={setTabIndexProgress}
          windowHeight={windowHeight}
          windowWidth={windowWidth}
          screenHeight={screenHeight}
          screenWidth={screenWidth}
          tabIndexGrid={tabIndexGrid}
          setTabIndexGrid={setTabIndexGrid}
          oneDayDataTs={oneDayDataTs}
          tabIndexTable={tabIndexTable}
          setTabIndexTable={setTabIndexTable}
          tabIndexOverviewInfo={tabIndexOverviewInfo}
          setTabIndexOverviewInfo={setTabIndexOverviewInfo}
          tabIndexOverviewGraph={tabIndexOverviewGraph}
          setTabIndexOverviewGraph={setTabIndexOverviewGraph}
          tabIndexLiveDataGraph={tabIndexLiveDataGraph}
          setTabIndexLiveDataGraph={setTabIndexLiveDataGraph}
          tabIndexLastDayTable={tabIndexLastDayTable}
          setTabIndexLastDayTable={setTabIndexLastDayTable}
          tabIndexLastDayGraph={tabIndexLastDayGraph}
          setTabIndexLastDayGraph={setTabIndexLastDayGraph}
          setTabIndexDefaultGrid={setTabIndexDefaultGrid}
          tabIndexDefaultGrid={tabIndexDefaultGrid}
          updateWidgetMsg={updateWidgetMsg}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
          tempTabIndexLiveDataGraph={tempTabIndexLiveDataGraph}
          tempTabIndexOverviewInfo={tempTabIndexOverviewInfo}
          tempTabIndexOverviewGraph={tempTabIndexOverviewGraph}
          tempTabIndexLastDayTable={tempTabIndexLastDayTable}
          tempTabIndexLastDayGraph={tempTabIndexLastDayGraph}
          tempTabIndexDefaultGrid={tempTabIndexDefaultGrid}
          isMobile={isMobile}
          scaleValue={scaleValue}
          tabIndexMotor={tabIndexMotor}
          setTabIndexMotor={setTabIndexMotor}
          tabIndexDisplay={tabIndexDisplay}
          setTabIndexDisplay={setTabIndexDisplay}
          tabIndexText={tabIndexText}
          setTabIndexText={setTabIndexText}
          documentId={documentId}
          vNetPassword={vNetPassword}
          vNetUsername={vNetUsername}
          deviceName={deviceName}
          deviceGroupName={deviceGroupName}
          tagGroupName={tagGroupName}
        />
      ) : (
        <div className={styles.swrap}>
          <div className={styles.navigates}>
            <a href="/"> Dashboard</a> &gt; View Data &gt; {isPath}
          </div>
          <div className={styles.superWrapper} >
            <div className={styles.wrapperRight} >
              <div className={styles.wrapper} style={{ overflow: `${isMobile ? "auto" : ""}` }}>
                {generateImage()}
                {generateDevice()}
                {generateDisplay()}
                {generateController()}
                {generateIO()}
                {generateFlowMeter()}
                {generateEnergyMeter()}
                {generateMotor()}
                {generateLineChart()}
                {generatePieChart()}
                {generateBarChart()}
                {generateHistogram()}
                {generateGauge()}
                {generateProgressBar()}
                {generateGrid()}
                {generateTable()}
                {generateText()}
                <Tabs
                  isFitted
                  size="sm"
                  variant="enclosed"
                  defaultIndex={selectedTabIndex}
                  style={isMobile ? { minWidth: "920px", position: "static" } : {}}
                >
                  <div className={styles.tabList}>
                    <TabList
                      style={
                        isMobile
                          ? {
                            border: 'none',
                            display: 'flex',
                            flexWrap: 'wrap',
                          }
                          : { border: 'none' }
                      }
                    >
                      {tabNames && tabNames.length > 0 ? (
                        tabNames.map((tabName, tabIndex) => {
                          return (
                            <>
                              <Tab
                                style={{ border: 'none' }}
                                key={tabIndex}
                                onClick={() => handleTabIndex(tabIndex, tabName)}
                              >
                                {tabName}
                              </Tab>

                            </>
                          );
                        })
                      ) : (
                        <Tab style={{ border: 'none' }}>No Tabs Available</Tab>
                      )}
                    </TabList>
                    <div className={styles.gridContainer}>
                    </div>
                  </div>

                  <TabPanels>
                    <TabPanel>
                      <Overview
                        isPath={isPath}
                        uniqueProjectId={uniqueProjectId}
                        data={data}
                        socketData={socketData}
                        images={images}
                        openNewPage={openNewPage}
                        tabIndexOverviewInfo={tabIndexOverviewInfo}
                        tempTabIndexOverviewInfo={tempTabIndexOverviewInfo}
                        selectedTabIndex={selectedTabIndex}
                        tabIndexOverviewGraph={tabIndexOverviewGraph}
                        tempTabIndexOverviewGraph={tempTabIndexOverviewGraph}
                        isMobile={isMobile}
                        scaleValue={scaleValue}

                      />
                    </TabPanel>
                    <TabPanel style={{ height: '75vh' }}>
                      <Channels
                        isPath={isPath}
                        data={data}
                        socketData={socketData}
                        tabIndexDefaultGrid={tabIndexDefaultGrid}
                        tempTabIndexDefaultGrid={tempTabIndexDefaultGrid}
                        selectedTabIndex={selectedTabIndex}
                        openNewPage={openNewPage}
                        isMobile={isMobile}
                        scaleValue={scaleValue}
                      />
                    </TabPanel>
                    <TabPanel style={{ height: '75vh' }}>
                      <LiveData
                        isPath={isPath}
                        uniqueProjectId={uniqueProjectId}
                        data={data}
                        socketData={socketData}
                        tabIndexLiveDataGraph={tabIndexLiveDataGraph}
                        setTabIndexLiveDataGraph={setTabIndexLiveDataGraph}
                        selectedTabIndex={selectedTabIndex}
                        openNewPage={openNewPage}
                        tempTabIndexLiveDataGraph={tempTabIndexLiveDataGraph}
                        scaleValue={scaleValue}
                        isMobile={isMobile}
                      />
                    </TabPanel>
                    <TabPanel>
                      <Day1
                        isPath={isPath}
                        data={data}
                        socketData={socketData}
                        openNewPage={openNewPage}
                        selectedTabIndex={selectedTabIndex}
                        tabIndexLastDayTable={tabIndexLastDayTable}
                        tabIndexLastDayGraph={tabIndexLastDayGraph}
                        tempTabIndexLastDayGraph={tempTabIndexLastDayGraph}
                        tempTabIndexLastDayTable={tempTabIndexLastDayTable}
                        isMobile={isMobile}
                        scaleValue={scaleValue}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </div>
            </div>
          </div>
          {(userPermission.editView && !isMobile) && (
            <button className={styles.updateViewBtn} onClick={handleNewPage}>
              Update View
            </button>
          )}
          {isMobile && (
            <div className={styles.scaleBtnWrapper}>
              <button className={styles.zoomInBtn} onClick={increaseScale}>
                +
              </button>
              <button className={styles.zoomOutBtn} onClick={decreaseScale}>
                -
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Project;
