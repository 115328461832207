import React, { useEffect, useState } from 'react'
import styles from "./DistributorUsers.module.css";
import dicotLogo from "../../../assets/Logo.svg";
import Edit from "../../../assets/Edit.svg";
import deleteIcon from "../../../assets/Delete.svg";
import DistributorModal from './components/DistributorModal';
import { useToast } from '@chakra-ui/react';
import proxy from '../../../proxy';

const DistributorUsers = () => {

    const [isModal, setIsModal] = useState(false); // State to handle open/close model
    const [selectedUser, setSelectedUser] = useState(); // State to store value of clicked user
    const [modelTitle, setModelTitle] = useState(""); // State to identify model type
    const [userList, setUserList] = useState([]); // State to store users
    const toast = useToast();

    useEffect(() => {
        try {
            const email = localStorage.getItem("adminEmail");
            const getUsers = async () => {
                if (email) {
                    const response = await fetch(
                        `${proxy}/admin/getUsers?email=${email}`,
                        {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    );

                    const status = response.status;
                    const responseBody = await response.json();

                    if (status === 200) {
                        setUserList(responseBody.data);
                    }
                }
            };

            getUsers();
        } catch (error) {
            console.log(error);
            toast({
                title: "Internal Server Error",
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            })
        }
    }, []);

    // Function to open add user model 
    const handleAddModalOpen = () => {
        setIsModal(true);
        setModelTitle("Add");
    }

    // Function to open edit user model 
    const handleEditModalOpen = (e, user) => {
        e.preventDefault();
        setIsModal(true);
        setSelectedUser(user);
        setModelTitle("Edit");

    }


    // Function to handle delete user
    const handleDeleteUser = async (e, id) => {
        e.preventDefault();

        try {
            const userTodelete = {
                id: id,
                email: localStorage.getItem("adminEmail"),
            };

            const response = await fetch(`${proxy}/admin/deleteUser`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userTodelete),
            });

            const status = response.status;

            const responseBody = await response.json();

            if (status === 200) {
                toast({
                    title: responseBody.message || "Successfully deleted user",
                    status: "success",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                });
                setUserList(responseBody.data);
            } else {
                toast({
                    title: responseBody.message || "Error while delete user",
                    status: "error",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.log(error);
            toast({
                title: "Internal Server Error",
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <>
            <div className={styles.page}>
                <div className={styles.topWrapper}>
                    <div className={styles.logoWrapper}>
                        <img src={dicotLogo} alt="Logo" className={styles.dicotLogo} />
                        <label className={styles.logoLabel}>Vision Web</label>
                    </div>
                    <button
                        className={styles.addUserBtn}
                        onClick={handleAddModalOpen}
                    >
                        Add user
                    </button>
                </div>
                <div className={styles.container}>
                    <div className={styles.infoWrapper}>

                        <div className={styles.headerInfo}>
                            <div className={styles.labelInfo}>Full Name</div>
                            <div className={styles.labelInfo}>Email</div>
                            <div className={styles.labelInfo}>Discount</div>
                        </div>
                        {userList?.map((user, index) => (
                            <div className={styles.userList} key={user._id}>
                                <div className={styles.userInfoText}>{user.name}</div>
                                <div className={styles.userInfoText}>
                                    {user.userEmail}
                                </div>
                                <div className={styles.userInfoText}>
                                    {user.discount}
                                </div>
                                <button
                                    className={styles.infoBtn}
                                    onClick={(e) => handleEditModalOpen(e, user)}
                                >
                                    <img src={Edit} alt="Edit Icon" srcset="" />
                                </button>
                                <button
                                    className={styles.infoBtn}
                                    onClick={(e) => handleDeleteUser(e, user._id)}
                                >
                                    <img src={deleteIcon} alt="Delete Icon" srcset="" />
                                </button>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
            {isModal && (
                <DistributorModal
                    setIsModal={setIsModal}
                    selectedUser={selectedUser}
                    modelTitle={modelTitle}
                    setUserList={setUserList}
                />
            )}
        </>

    )
}

export default DistributorUsers