import React, { useState } from 'react'
import styles from "./GraphLabelModel.module.css"
import close from "../../../../../assets/Close.svg"
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const GraphLabelsModel = ({ graphRef, setIsGraphLabels }) => {
    const [header, setHeader] = useState("");
    const [footer, setFooter] = useState("");
    const [xLabel, setXLabel] = useState("");
    const [yLabel, setYLabel] = useState("");
    const [fileName, setFileName] = useState("");


    const handleDownloadGraph = () => {
        if (graphRef.current) {
            html2canvas(graphRef.current).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const pdf = new jsPDF("landscape");

                // PDF dimensions
                const pageWidth = pdf.internal.pageSize.getWidth();
                const pageHeight = pdf.internal.pageSize.getHeight();

                // Graph image dimensions (fit to PDF width)
                const imgWidth = pageWidth * 0.8; // 80% of the page width
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                const imgX = (pageWidth - imgWidth) / 2; // Center horizontally
                const imgY = 30; // Start Y position below the header

                // Add Header
                if (header) {
                    pdf.setFontSize(18);
                    pdf.text(header, pageWidth / 2, 20, { align: "center" });
                }

                // Add Y-Label (Rotated on the left side)
                if (yLabel) {
                    pdf.setFontSize(14);
                    pdf.text(yLabel, 20, pageHeight / 2, {
                        align: "center",
                        angle: 90 // Rotate text 90 degrees
                    });
                }

                // Add the graph image
                pdf.addImage(imgData, "PNG", imgX, imgY, imgWidth, imgHeight);

                // Add X-Label (Below the graph)
                if (xLabel) {
                    pdf.setFontSize(14);
                    pdf.text(xLabel, pageWidth / 2, imgY + imgHeight + 10, { align: "center" });
                }

                // Add Footer
                if (footer) {
                    pdf.setFontSize(18);
                    pdf.text(footer, pageWidth / 2, pageHeight - 20, { align: "center" });
                }

                const suggestedFileName = fileName ? `${fileName}.pdf` : "graph.pdf";
                pdf.save(suggestedFileName);
            });
        }
        setIsGraphLabels(false);
    };
    return (
        <div className={styles.page}>
            <div className={styles.container}>
                <div className={styles.topWrapper}>
                    <h2 className={styles.title}>Add Labels</h2>
                    <button className={styles.closeBtn} onClick={() => setIsGraphLabels(false)}>
                        <img src={close} alt="close" />
                    </button>
                </div>
                <div className={styles.midWrapper}>
                    <input type="text" className={styles.input} placeholder='File Name' onChange={(e) => setFileName(e.target.value)} />
                    <input type="text" className={styles.input} placeholder='Header' onChange={(e) => setHeader(e.target.value)} />
                    <input type="text" className={styles.input} placeholder='Footer' onChange={(e) => setFooter(e.target.value)} />
                    <input type="text" className={styles.input} placeholder='X-Label' onChange={(e) => setXLabel(e.target.value)} />
                    <input type="text" className={styles.input} placeholder='Y-Label' onChange={(e) => setYLabel(e.target.value)} />
                </div>
                <button className={styles.saveBtn} onClick={handleDownloadGraph}>
                    Download Graph
                </button>
            </div>
        </div>
    )
}

export default GraphLabelsModel