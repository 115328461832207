import React from "react";
import styles from "./SecurityData.module.css";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import proxy from "proxy";
import { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { saveSecurityUpdate, setEncryptionKey } from "../../../../../../redux/slices/projectSetting";
function SecurityAndData({ setPage }, props) {
  const toast = useToast();

  // Initializing state for current role
  const [currentRole, setCurrentRole] = useState(null);

  // Initializing search parameters
  const [searchParams] = useSearchParams();

  // Initializing state for webhook selection
  const [webhookselected, setWebhookselected] = useState(null);

  // Initializing state for database URL
  const [databaseUrl, setDatabaseUrl] = useState("");

  // Initializing state for encryption disabling
  const [isDisableEncryption, setIsDisableEncryption] = useState(false);

  // Initializing state for database URL disabling
  const [isDisableDatabaseUrl, setIsDisableDatabaseUrl] = useState(false);

  // Initializing state for redundancy toggle
  const [isToggleRedundancy, setIsToggleRedundancy] = useState(false);

  // Initializing state for data collection toggle
  const [isToggleDataCollection, setIsToggleDataCollection] = useState(false);

  // Getting Project ID from search parameters
  const ProjectID = searchParams.get("project");

  // Getting settings data from Redux store
  const settingsData = useSelector((state) => state.projectSetting.settingData);
  const dispatch = useDispatch();

  // Sets page title when component is mounted
  React.useEffect(() => {
    return () => {
      setPage("Security and Data");
    };
  }, []);

  useEffect(() => {
    // Set role and security settings from settingsData
    if (settingsData?.data?.admin === settingsData?.currentEmail) {
      setCurrentRole("admin");
    }
    setIsToggleRedundancy(settingsData?.data?.metadata?.Settings?.security?.redundancy)
    setIsToggleDataCollection(settingsData?.data?.metadata?.Settings?.security?.dataCollection)
  }, [settingsData]);

  // Initializing state for selected option
  const [selected, setSelected] = useState(null);

  // Initializing state for encryption key
  const [encryption, setEncryption] = useState("");

  // Toggle function for selected state
  const toggle = () => {
    if (selected === this) {
      return setSelected(null);
    }
    setSelected(this);
  };

  // Save encryption key and handle success/failure
  const saveEncryption = async () => {
    try {
      const apiBody = { projectID: ProjectID, encryptionKey: encryption.trim() };
      if (encryption.trim() !== "") {
        dispatch(setEncryptionKey(apiBody)).then((response) => {
          if (response.payload.responseBody.status === 200) {
            toast({ title: "Successfully Updated", description: response.payload.responseBody.message, status: "success", duration: 3000, isClosable: true, position: 'top-right' });
            setIsDisableEncryption(true);
          } else {
            toast({ title: "Failed", description: response.payload.responseBody.message, status: "error", duration: 3000, isClosable: true, position: 'top-right' });
          }
        })
      }
    } catch (error) {
      toast({ title: "Internal Server Error", status: "error", position: "top-right", duration: 5000, isClosable: true });
    }
  };

  // Save database URL and handle success/failure
  const handleSaveDatabaseUrl = async () => {
    const apiBody = { Project_id: ProjectID, databaseURL: databaseUrl.trim(), datacollection: undefined, redundancy: undefined };
    if (databaseUrl.trim() !== "") {
      try {
        dispatch(saveSecurityUpdate(apiBody)).then((response) => {
          if (response.payload.responseBody.status === 200) {
            toast({ title: "Successfully Updated", description: response.payload.responseBody.message, status: "success", duration: 3000, isClosable: true, position: 'top-right' });
            setIsDisableDatabaseUrl(true);
          } else {
            toast({ title: "Failed", description: response.payload.responseBody.message, status: "error", duration: 3000, isClosable: true, position: 'top-right' });
          }
        })
      } catch (error) {
        toast({ title: "Internal Server Error", status: "error", position: "top-right", duration: 5000, isClosable: true });
      }
    }
  }

  // Handle redundancy toggle and save update
  const handleEnableRedundancy = () => {
    const apiBody = { Project_id: ProjectID, databaseURL: undefined, datacollection: undefined, redundancy: !isToggleRedundancy };
    try {
      dispatch(saveSecurityUpdate(apiBody)).then((response) => {
        if (response.payload.responseBody.status === 200) {
          toast({ title: "Successfully Updated", description: response.payload.responseBody.message, status: "success", duration: 3000, isClosable: true, position: 'top-right' });
          setIsToggleRedundancy(!isToggleRedundancy);
        } else {
          toast({ title: "Failed", description: response.payload.responseBody.message, status: "error", duration: 3000, isClosable: true, position: 'top-right' });
        }
      })
    } catch (error) {
      toast({ title: "Internal Server Error", status: "error", position: "top-right", duration: 5000, isClosable: true });
    }
  }

  // Handle data collection toggle and save update
  const handleEnableDataCollection = () => {
    const apiBody = { Project_id: ProjectID, databaseURL: undefined, datacollection: !isToggleDataCollection, redundancy: undefined };
    try {
      dispatch(saveSecurityUpdate(apiBody)).then((response) => {
        if (response.payload.responseBody.status === 200) {
          toast({ title: "Successfully Updated", description: response.payload.responseBody.message, status: "success", duration: 3000, isClosable: true, position: 'top-right' });
          setIsToggleDataCollection(!isToggleDataCollection);
        } else {
          toast({ title: "Failed", description: response.payload.responseBody.message, status: "error", duration: 3000, isClosable: true, position: 'top-right' });
        }
      })
    } catch (error) {
      toast({ title: "Internal Server Error", status: "error", position: "top-right", duration: 5000, isClosable: true });
    }
  }

  // Toggle for webhook selection
  const webtoggle = () => {
    if (webhookselected === this) {
      return setWebhookselected(null);
    }
    setWebhookselected(this);
  };


  return (
    <div className={styles.mainContainer}>
      <div className={styles.hero}>
        <div className={styles.page_hero}>
          <h3 className={styles.headerText}>Security and Data</h3>
          <div className={styles.wrapper}>
            <div className={styles.cover}></div>
            <div className={styles.security_options}>
              <div
                className={styles.security_option_heading}
                onClick={() => toggle()}
              >
                <div className={styles.h2Wrapper}>
                  <h2>Encryption :</h2>
                  <input
                    type="text"
                    placeholder="Encryption Key"
                    onChange={(e) => {
                      setEncryption(e.target.value.trim());
                    }}
                    style={{ background: "transparent", width: "80%" }}
                    value={settingsData?.data?.encryptionKey ?? encryption}
                    disabled={isDisableEncryption ? true : settingsData?.data?.encryptionKey ? true : false}
                  />
                </div>
                <button
                  onClick={saveEncryption}
                  className={styles.saveBtn}
                  disabled={isDisableEncryption ? true : settingsData?.data?.encryptionKey ? true : false}>
                  save
                </button>
              </div>
              <div
                className={
                  // selected === this
                  //   ? `${styles.content} ${styles.show}`
                  //   :
                  `${styles.content}`
                }
              >
                <div className="encryption_inputs">
                  <input type="text" placeholder="Encryption Key" />{" "}
                  <button className={styles.saveBtn}>Save</button>
                </div>
              </div>
            </div>

            <div className={styles.security_options}>
              <div
                className={styles.security_option_heading}
                onClick={() => webtoggle()}
              >
                <div className={styles.h2Wrapper}>
                  <h2 className={styles.h2WrapperHead}>Database URL :</h2>
                  <input
                    type="text"
                    style={{ background: "transparent" }}
                    placeholder="Database URL"
                    onChange={(e) => setDatabaseUrl(e.target.value.trim())}
                    value={settingsData?.data?.metadata?.Settings?.security?.databaseURL ?? databaseUrl}
                    disabled={isDisableDatabaseUrl ? true : settingsData?.data?.metadata?.Settings?.security?.databaseURL ? true : false}
                  />
                </div>
                <button
                  className={styles.saveBtn}
                  onClick={handleSaveDatabaseUrl}
                  disabled={isDisableDatabaseUrl ? true : settingsData?.data?.metadata?.Settings?.security?.databaseURL ? true : false}>
                  save
                </button>
              </div>
              <div
                className={
                  // webhookselected === this
                  //   ? `${styles.webhook_content} ${styles.webhook_show}`
                  //   :
                  `${styles.webhook_content}`
                }
              >
                <div className="encryption_inputs">
                  <input type="text" placeholder="Encryption Key" />{" "}
                  <button className={styles.saveBtn}>Save</button>
                </div>
              </div>
            </div>

            <div className={styles.security_options}>
              <div className={styles.security_option_heading}>
                <div className={styles.h2Wrapper}>
                  <h2 className={styles.h2WrapperHead}>Redundancy</h2>
                </div>
                <div className={`${styles.toggleBox} ${isToggleRedundancy ? styles.active : ""}`} onClick={handleEnableRedundancy} >
                  <span className={`${styles.toggle} ${isToggleRedundancy ? styles.active : ''}`} ></span>
                </div>
              </div>
            </div>

            <div className={styles.security_options}>
              <div className={styles.security_option_heading}>
                <div className={styles.h2Wrapper}>
                  <h2 className={styles.h2WrapperHead}>Data Collection By DICOT</h2>
                </div>
                <div className={`${styles.toggleBox} ${isToggleDataCollection ? styles.active : ""}`} onClick={handleEnableDataCollection} >
                  <span className={`${styles.toggle} ${isToggleDataCollection ? styles.active : ''}`} ></span>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
}

export default SecurityAndData;
