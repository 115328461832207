import React, { useEffect, useState } from "react";
import styles from "./BatchCard.module.css";
import Delete from "assets/Delete.svg";
import proxy from "proxy";
import EditImg from "assets/Edit.svg";
import WorkerBuilder from "Utils/builder.worker";
import restructureWorker from "Utils/history.worker";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { deleteBatch, endBatch } from "../../../../../redux/slices/batchSlice";

const BatchModel = ({
  batchName,
  batchID,
  channelName,
  projectID,
  status,
  setIsOpen,
  setCardBatchId,
  startTime,
  endTime,
}) => {

  // Getting user permissions from the Redux store
  const userPermission = useSelector(state => state.organization.userPermission);

  // Initializing a Worker for restructuring data
  const restructure = new WorkerBuilder(restructureWorker);

  // Toast hook for notifications
  const toast = useToast();

  // Dispatch hook for Redux actions
  const dispatch = useDispatch();

  // Handle the batch status change (end or download)
  const handleStatus = async () => {
    if (status) {
      const apiBody = {
        Project_id: projectID,
        batchID: batchID,
      };
      dispatch(endBatch(apiBody)); // Ending the batch
    } else {
      handleDownloadCSV(); // Trigger CSV download
    }
  };

  // Handle batch deletion
  const handleDeleteBatch = async () => {
    const token = localStorage.getItem("token");
    try {
      const apiBody = {
        token: token,
        batchID: batchID,
        Project_id: projectID
      };
      dispatch(deleteBatch(apiBody)).then((response) => {
        // Show success or error toast based on response
        if (response.payload.responseBody.status === 200) {
          toast({
            title: `Batch deleted successfully`,
            status: "success",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: `Couldn't delete the batch`,
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      console.log(error); // Log error if any
    }
  };

  // Handle opening the edit batch modal and setting the batch ID
  const handleEditModel = () => {
    setIsOpen("editBatch"); // Open the edit batch modal
    setCardBatchId(batchID); // Set the batch ID for editing
  };

  // Handle CSV file download
  const handleDownloadCSV = async (event) => {
    try {
      // Fetch project history data for CSV download
      const response = await fetch(
        `${proxy}/db/get-history?token=${localStorage.getItem("token")}&projectID=${projectID}&startDate=${startTime}&endDate=${endTime}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            responseType: "json",
            Project_id: projectID,
            batch: 1,
          }),
        }
      );
      const responseBody = await response.json();

      if (responseBody && responseBody.length > 0 && startTime && endTime) {
        // Restructure data for CSV format
        const tableDataArrayGen = async () => {
          const dataPromise = new Promise((resolve) => {
            restructure.onmessage = (e) => {
              resolve(e.data);
            };
          });
          restructure.postMessage({ data: responseBody, channelNames: channelName });
          return await dataPromise;
        };
        const tableDataArray = await tableDataArrayGen();
        const csvTableData = tableDataArray.tableArray;

        if (csvTableData[0]?.length > 0) {
          // Format the CSV headers and rows
          const csvHeaders = ["Timestamp", ...channelName];
          const csvRows = csvTableData;
          const csvData = [csvHeaders, ...csvRows];

          // Generate CSV content and trigger download
          const csvContent = csvData.map((row) => row.join(",")).join("\n");

          const blob = new Blob([csvContent], { type: "text/csv" });
          const url = URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          link.download = "data.csv";
          link.click();

          window.alert("CSV file Downloaded successfully");
        }
      } else {
        toast({
          title: `${responseBody.message}`,
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching project:", error); // Log fetch error
    }
  };


  return (
    <>
      <div className={styles.batch}>
        <div className={styles.icons}>
          <div className={styles.edit}>
            <button onClick={handleEditModel}>
              <img src={EditImg} alt="editImage" srcSet="" />
            </button>
          </div>
          <div className={styles.space}>
            <span
              className={styles.ledPointer}
              style={{ backgroundColor: status ? "green" : "red" }}
            ></span>
            <button onClick={handleDeleteBatch} className={styles.img}>
              <img src={Delete} alt="" srcSet="" />
            </button>
          </div>
        </div>
        <div className={styles.batchInfo}>
          <div className={styles.batchDetails}>
            <p className={styles.batchName}>
              <strong>{batchName}</strong>
            </p>
            <p>
              <strong>Status:</strong>{" "}
              <span style={{ color: status ? "green" : "red" }}>
                {status ? "Running" : "Stopped"}
              </span>
            </p>
          </div>
        </div>

        <div className={styles.batchButtons}>
          <button
            type="button"
            className={styles.batchButton}
            style={{ color: status ? "red" : "blue" }}
            onClick={handleStatus}
          >
            {status ? "Stop" : userPermission.exportReport ? "Download" : ""}
          </button>
        </div>
      </div>
    </>
  );
};

export default BatchModel;
