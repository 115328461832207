import React, { useState, useEffect, useRef } from 'react';
import styles from "./AddChannelModal.module.css";
import close from "../../../../../../assets/cancel.svg";
import { addChannel, updateChannel } from '../../../../../../redux/slices/derivedChannelSlice.js';
import { useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
const AddChannelModal = ({
    dataArrLive,
    channelNames,
    calculateValue,
    setExpression,
    expression,
    editingChannel,
    onSave,
    modalType,
    setIsModalOpen,
    value,
    uniqueProjectId,
    email,
    selectedCardId,
}) => {
    const [channelName, setChannelName] = useState(''); // Channel name input state
    const [filteredSuggestions, setFilteredSuggestions] = useState([]); // Filtered suggestions for channel names
    const [channelExpression, setChannelExpression] = useState([]); // Stores current expression (e.g., mathematical operations)
    const [keyPressed, setKeyPressed] = useState(""); // Tracks the last key pressed
    const dispatch = useDispatch(); // Redux dispatch
    const toast = useToast(); // Toast notifications
    const inputRef = useRef(null); // Input field reference

    // Set initial data if editing an existing channel
    useEffect(() => {
        if (editingChannel) {
            setChannelName(editingChannel.name);
            setExpression(editingChannel.value);
            calculateValue(editingChannel.value); // Initialize with current value
        }
    }, [editingChannel]);

    // Filter suggestions based on input channel name
    const filterSuggestions = (input) => {
        const filtered = channelNames.filter(name => name.toLowerCase().includes(input.toLowerCase()));
        setFilteredSuggestions(filtered);
    };

    // Handle changes in the input field and update the expression
    const handleInputChange = (e) => {
        const value = e.target.value.trim();
        const valueLength = value.length;

        // Add operator to the expression if not a backspace
        if (keyPressed !== "Backspace") {
            if (['+', '-', '*', '/', '(', ')'].includes(value[valueLength - 1])) {
                const copiedChannelExpression = [...channelExpression];
                copiedChannelExpression.push(value[valueLength - 1]);
                setChannelExpression(copiedChannelExpression);
            }
        }

        // Remove last expression item on backspace
        if (keyPressed === "Backspace") {
            const copiedChannelExpression = [...channelExpression];
            copiedChannelExpression.pop();
            setChannelExpression(copiedChannelExpression);
        }

        setExpression(value); // Update the current expression
        calculateValue(value); // Recalculate value

        // Show suggestions if '@' is typed
        if (value.includes('@')) {
            const input = value.split('@').pop();
            const regex = /@(?![^(]*\))/g;
            const match = value.match(regex);
            if (match && match.length > 0) {
                filterSuggestions(input);
            } else {
                setFilteredSuggestions([]);
            }
        } else {
            setFilteredSuggestions([]); // Clear suggestions if '@' is not present
        }

        // Ensure cursor is at the end of the input field
        if (inputRef.current) {
            inputRef.current.setSelectionRange(value.length, value.length);
        }
    };

    // Handle suggestion click: insert selected channel into expression
    const handleSuggestionClick = async (name) => {
        const atIndex = expression.lastIndexOf('@');
        if (atIndex !== -1) {
            const selectedIndex = channelNames.findIndex(channel => channel === name);
            const copiedChannelExpression = [...channelExpression];
            copiedChannelExpression.push(name);
            setChannelExpression(copiedChannelExpression);

            // Get the last value of the selected channel
            const lastValue = selectedIndex !== -1 ? dataArrLive[selectedIndex].data.slice(-1)[0] : '';
            const newExpression = expression.substring(0, atIndex) + lastValue;
            setExpression(newExpression);
            calculateValue(newExpression);
        }
    };

    // Insert value of selected channel into expression
    const insertValueOfSelectedChannel = (selectedChannelName) => {
        const selectedChannel = channelNames.find(channel => channel.name === selectedChannelName);
        if (selectedChannel) {
            const newValue = expression + selectedChannel.value;
            setExpression(newValue);
            calculateValue(newValue);
        }
    };

    // Prevent disallowed keys and handle backspace logic
    const handleKeyDown = (e) => {
        setKeyPressed(e.key);
        const disallowedKeys = ['ArrowLeft', 'ArrowRight', "ArrowUp", "ArrowDown", 'Home', 'End'];
        if (disallowedKeys.includes(e.key)) {
            e.preventDefault();
        }

        // Handle backspace for expressions, removing operators if necessary
        if (e.key === 'Backspace' && expression.length > 0) {
            const operators = ['+', '-', '(', ')', '*', '/'];
            const lastValueExpression = expression[expression.length - 1];

            if (!operators.includes(lastValueExpression)) {
                let i;
                for (i = expression.length - 1; i >= 0; i--) {
                    if (operators.includes(expression[i])) {
                        break;
                    }
                }
                const slicedExpression = expression.slice(0, i + 2);
                setExpression(slicedExpression);
            }

            if (expression.length === 0) {
                setChannelExpression([]);
            }
        }
    };

    // Ensure cursor is at the end on mouse down/up
    const handleMouseDown = (e) => {
        if (inputRef.current) {
            inputRef.current.setSelectionRange(expression.length, expression.length);
        }
    };

    const handleMouseUp = (e) => {
        if (inputRef.current) {
            inputRef.current.setSelectionRange(expression.length, expression.length);
        }
    };

    // Keep cursor at the end of the input field on expression change
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.setSelectionRange(expression.length, expression.length);
        }
    }, [expression]);

    // Focus input field on mount
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(expression.length, expression.length);
        }
    }, []);

    // Save the channel data
    const handleSave = () => {
        const editData = {
            _id: selectedCardId,
            channelName: channelName,
            channelValue: value,
            email: email,
            projectId: uniqueProjectId,
            expression: channelExpression
        };

        const AddData = {
            channelName: channelName,
            channelValue: value,
            email: email,
            projectId: uniqueProjectId,
            expression: channelExpression
        };

        if (modalType === "Edit") {
            try {
                dispatch(updateChannel(editData)).then((response) => {
                    if (response.payload.status === 200) {
                        toast({
                            title: `Derived channel updated successfully`,
                            status: "success",
                            position: "top-right",
                            duration: 3000,
                            isClosable: true,
                        });
                    } else {
                        toast({
                            title: `Channel has not been updated.`,
                            status: "error",
                            position: "top-right",
                            duration: 3000,
                            isClosable: true,
                        });
                    }
                });
            } catch (error) {
                console.log(error);
                toast({
                    title: "Internal Server Error",
                    status: "error",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                })
            }
        }

        if (modalType === "Add") {
            try {
                dispatch(addChannel(AddData)).then((response) => {
                    if (response.payload.status === 201) {
                        toast({
                            title: `${response.payload.message}`,
                            status: "success",
                            position: "top-right",
                            duration: 3000,
                            isClosable: true,
                        });
                    } else {
                        toast({
                            title: `${response.payload.message}`,
                            status: "error",
                            position: "top-right",
                            duration: 3000,
                            isClosable: true,
                        });
                    }
                });
            } catch (error) {
                console.log(error);
                toast({
                    title: "Internal Server Error",
                    status: "error",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                })
            }
        }
        onSave({ name: channelName, value: expression });
        setIsModalOpen(false);
    };

    return (
        <div className={styles.container}>
            <div className={styles.mainDiv}>
                <div className={styles.header}>
                    <div className={styles.headerName}>
                        {modalType === "Add" ? "Add Channel" : "Edit Channel"}
                    </div>
                    <button className={styles.closeImg} onClick={() => setIsModalOpen(false)}>
                        <img src={close} alt='cancel' />
                    </button>
                </div>
                <div className={styles.input}>
                    <input type='text' placeholder='Channel Name' className={styles.inputName} value={channelName} onChange={(e) => setChannelName(e.target.value)} />
                </div>
                <div className={styles.textArea}>
                    <textarea
                        ref={inputRef}
                        type='textarea'
                        placeholder='Write your expression here'
                        className={styles.textAreaInput}
                        value={expression}
                        onChange={handleInputChange}
                        onBlur={() => insertValueOfSelectedChannel(expression.split('@').pop())}
                        onKeyDown={handleKeyDown}
                        onMouseDown={handleMouseDown}
                        onMouseUp={handleMouseUp}
                    />

                    <ul className={styles.suggestions}>
                        {filteredSuggestions.map((name, index) => (
                            <li key={index} onClick={() => handleSuggestionClick(name)}>{name}</li>
                        ))}
                    </ul>
                </div>
                <button className={styles.save} onClick={handleSave}>Save</button>
            </div>
        </div>
    );
};

export default AddChannelModal;
