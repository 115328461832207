import React, { useCallback, useState } from "react";
import styles from "./RemovePeople.module.css";
import { useToast } from "@chakra-ui/react";
import proxy from "proxy";
import { useDispatch } from "react-redux";
import { removePeople } from "../../../../../redux/slices/organizationSlice.js";
import openEye from "../../../../../assets/openEye.png";
import closeEye from "../../../../../assets/closeEye.png"

const RemovePeople = ({
  removeMemberEmail,
  setRemove,
  memberList,
  setMemberList,
  setSelectedMembers,
}) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [password, setPassword] = useState(""); // State to store value of password
  const [isPasswordVisible, setIsPasswordVisible] = useState(false); // State to handle password visibility


  // Function to handle Remove Members
  const removeMembers = useCallback(async () => {
    const apiBody = {
      removeUserEmail: removeMemberEmail,
      organizationEmail: localStorage.getItem(`organizationEmail`),
      userEmail: localStorage.getItem(`email`),
      password: password.trim(),
    };

    try {
      dispatch(removePeople(apiBody)).then((response) => {
        if (response.payload.status === 200) {
          toast({
            title: "Successful",
            description: response.payload.message,
            status: "success",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
          const updatedMemberList = memberList.filter(
            (member) => !removeMemberEmail.includes(member.email)
          );
          setMemberList(updatedMemberList);
          setSelectedMembers([]);
          setRemove(false);
        } else {
          toast({
            title: "Unsuccessful",
            description: response.payload.message,
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
        }
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Internal Server Error",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      })
    }

  }, []);

  return (
    <div className={styles.removeModel}>
      <div className={styles.modelWrapper}>
        <div className={styles.infoContainer}>
          <h2 className={styles.header}>Remove People</h2>
          <div className={styles.inputWrapper}>

            <input
              className={styles.inputField}
              type={isPasswordVisible ? "text" : "password"}
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
            />
            {isPasswordVisible ? (
              <img src={openEye} alt="open eye" className={styles.eyeImg} onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
            ) : (
              <img src={closeEye} alt="close eye" className={styles.eyeImg} onClick={() => setIsPasswordVisible(!isPasswordVisible)} />
            )}
          </div>
        </div>
        <div className={styles.btnContainer}>
          <button className={styles.greyBtn} onClick={() => setRemove(false)}>
            Cancel
          </button>
          <button className={styles.blueBtn} onClick={removeMembers}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemovePeople;
