import React from "react";
import styles from "./Login.module.css";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import dicot_img from "assets/Logo.svg";
import { useNavigate } from "react-router-dom";
import proxy from "proxy.js";
import openEye from "assets/openEye.png";
import closeEye from "assets/closeEye.png";

function Login({ setPage }) {
  setPage("login");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false); // State to handle display of password
  const navigate = useNavigate();

  // On page load redirecting to dashboard if token is available
  React.useLayoutEffect(() => {

    document.title = "Login";
    if (localStorage.getItem("token")) {
      //condition if already logged in
      navigate("/");
    } // eslint-disable-next-line
  }, []);
  const toast = useToast();
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  //can show the occurred error to the user
  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value.trim() });
  };

  // Function to handle calling the login api
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      const response = await fetch(`${proxy}/auth/userLogin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: data.email,
          password: data.password,
          grantType: "benzen@24",
          successType: "online",
        }),
      });
      const Data = await response.json();

      const { token, TwoFA, organizationEmail } = Data;

      if (!token) {
        toast({
          title: "Incorrect details",
          description: Data.message,
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });

        const mail = document.getElementById("mail");
        mail.style.border = "1px solid red";
        const pass = document.getElementById("pwd");
        pass.style.border = "1px solid red";
        pass.value = "";
      } else {
        if (TwoFA) {
          localStorage.setItem("email", data.email);
          localStorage.setItem("organizationEmail", Data.organizationEmail)

          navigate("/2-Factor-Authentication", { state: { token: token, email: organizationEmail } });
        } else {
          localStorage.setItem("token", token);
          localStorage.setItem("email", data.email);
          localStorage.setItem("organizationEmail", Data.organizationEmail)
          navigate("/");
        }
      }
    } catch (error) {
      console.log(error);

    }
  };

  // Function to handle visibility of password
  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    const pwInput = document.getElementById("pwd");
    pwInput.type = isPasswordVisible ? "password" : "text";
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.factory}>
        <img className={styles.logoImage} src={dicot_img} alt="Dicot Logo" />
      </div>
      <div className={styles.formSection}>
        <div className={styles.visionWeb}>
          Vision <span className={styles.w}>W</span>eb
        </div>
        <div className={styles.formContainer}>
          <form className={styles.loginForm}>
            <div>
              <h1 className={styles.loginHeading}>Login</h1>
              <p>Hi, Welcome back👋</p>
            </div>

            <input
              type="text"
              id="mail"
              name="email"
              className={styles.textInput}
              value={data.email}
              placeholder="Email [Eg: john@dicot.tech]"
              onChange={handleChange}
            />
            <div className={styles.passwordSection}>
              <input
                type="password"
                id="pwd"
                name="password"
                className={styles.textInput}
                value={data.password}
                placeholder="Enter Password"
                onChange={handleChange}
              />
              <div
                className={styles.pwVisibilityToggle}
                onClick={togglePasswordVisibility}
              >
                {!isPasswordVisible ? (
                  <img src={closeEye} alt="Close Eye" srcSet="" />
                ) : (
                  <img src={openEye} alt="Open Eye" srcSet="" />
                )}
              </div>
            </div>
            <div className={styles.parchuran}>
              <label>
                <input type="checkbox" id="check" defaultChecked /> Remember Me
              </label>
              <Link to="/auth/forgotpassword">

                Forgot Password?
              </Link>
            </div>
            <div className={styles.submitButtonContainer}>
              <button
                onClick={handleSubmit}
                id="loginbtn"
                className={styles.loginbtn}
              >
                Login
              </button>
            </div>

            <div className={styles.Signup}>
              <p>
                New here?{" "}
                <Link to="/auth/register">
                  <span className={styles.s}>Sign Up</span>
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
