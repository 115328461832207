import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import styles from "./AcceptMember.module.css";
import dicot_img from "assets/Logo.svg";
import proxy from "proxy";
import { useToast } from "@chakra-ui/react";

function AcceptMember() {
  const toast = useToast();
  const location = useLocation();
  const [adminEmail, setAdminEmail] = useState(""); // State to store value of admin email
  const [role, setRole] = useState(""); // State to store value of role

  const searchParams = new URLSearchParams(location.search);
  const inviteToken = searchParams.get("token");


  // Calling inviteMemberLanding on page load
  useEffect(() => {
    try {
      const inviteMemberLanding = async () => {
        const response = await fetch(`${proxy}/org/inviteMemberLanding`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: inviteToken,
          }),
          redirect: "follow",
        });
        const responseBody = await response.json();

        const data = responseBody.data;
        setAdminEmail(data.organizationEmail);
        setRole(data.role);
      };

      inviteMemberLanding();
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Function to handle accept invite api
  const handleAccept = useCallback(async () => {
    try {
      const response = await fetch(
        `${proxy}/org/acceptInviteMembers?token=${inviteToken}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
        }
      );
      const responseBody = await response.json();
      if (response.ok) {
        toast({
          title: "Successful",
          description: responseBody.message,
          status: "success",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: responseBody.message,
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Internal Server Error",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      })
    }
  }, []);

  // Function to handle decline invite member api
  const handleDecline = useCallback(async () => {
    try {
      const response = await fetch(
        `${proxy}/org/declineInviteMembers?token=${inviteToken}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
        }
      );
      const responseBody = await response.json();
      if (response.ok) {
        toast({
          title: "Successful",
          description: responseBody.message,
          status: "success",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: responseBody.message,
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Internal Server Error",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      })
    }
  }, []);

  return (
    <div className={styles.page}>
      <div className={styles.imageContainer}>
        <img className={styles.logo} src={dicot_img} alt="Logo" />
      </div>
      <div className={styles.superContainer}>
        <div className={styles.invite}>
          <div className={styles.title}>Organization Invite</div>
          <div className={styles.question}>
            {`${adminEmail} has invited you to join their Organization as ${role}`}
          </div>
          <div className={styles.options}>
            <button className={styles.declineButton} onClick={handleDecline}>
              Decline
            </button>
            <button className={styles.acceptButton} onClick={handleAccept}>
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AcceptMember;
