import styles from "./FeedbackForm.module.css";
import { useToast } from "@chakra-ui/react";
import { useState, useRef } from "react";
import React from "react";
import { sendFeedback } from "../../redux/slices/feedbackSlice.js";
import { useDispatch } from "react-redux";

function Input() {
  let file;
  const [buttonState, setButtonState] = useState(false);

  const dispatch = useDispatch();

  // Function to handle file upload
  function initFile() {
    const fileInfo = document.getElementById("file-upload").files[0];
    const reader = new FileReader();
    reader.onload = function (event) {
      file = { name: fileInfo.name, content: event.target.result };
    };
    reader.readAsDataURL(fileInfo);
  }

  // Function to handle submit feedback form
  const handleSubmit = async () => {
    try {
      const name = ipt1.current.value;
      const subject = ipt2.current.value;
      const contact = ipt3.current.value;
      const feedback = ipt4.current.value;
      const file = ipt5.current.files[0];
      setButtonState(true);

      const email = localStorage.getItem("email");

      if (file) {
        const reader = new FileReader();
        reader.addEventListener("load", (event) => {

          const data = {
            name: name,
            email: email,
            subject: subject,
            contact: contact,
            feedback: feedback,
            token: localStorage.getItem("token"),
            attachedFile: { content: event.target.result, name: file.name },
          };

          try {
            dispatch(sendFeedback(data));
          } catch (error) {
            console.log(error);
          }

        });
        reader.readAsDataURL(file);
      } else {
        const data = {
          name: name,
          email: email,
          subject: subject,
          contact: contact,
          feedback: feedback,
          token: localStorage.getItem("token"),
          attachedFile: file,
        };
        try {
          dispatch(sendFeedback(data)).then((response) => {
            if (response.payload.status === 200) {
              toast({
                title: "Feedback submitted!",
                description:
                  "We've notified our team, we'll shortly evaluate your feedback/support",
                status: "success",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
            } else {
              toast({
                title: "Something went wrong!",
                description: "Something is missing from input fields",
                status: "error",
                position: "top-right",
                duration: 5000,
                isClosable: true,
              });
            }
          });
        } catch (error) {
          console.log(error);
          toast({
            title: "Internal Server Error",
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          })
        }
      }
      // reader.readAsDataURL(ipt5.current.files[0])
    } catch (error) {
      console.error("Error:", error);
      // Handle any other errors here
    }
  };

  const [data, setData] = useState(null);
  const toast = useToast();
  const ipt1 = useRef(null);
  const ipt2 = useRef(null);
  const ipt3 = useRef(null);
  const ipt4 = useRef(null);
  const ipt5 = useRef(null);

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input]: input.value });
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.formDiv}>
          <input
            type="text"
            onChange={handleChange}
            ref={ipt1}
            className={styles.input1}
            placeholder="Name"
          />
          <input
            type="text"
            onChange={handleChange}
            ref={ipt2}
            className={styles.input3}
            placeholder="Subject"
          />
          <input
            type="contact"
            onChange={handleChange}
            ref={ipt3}
            className={styles.input4}
            placeholder="Contact"
          />
          <textarea
            onChange={handleChange}
            ref={ipt4}
            className={styles.input5}
            placeholder="Feedback"
          />
          <div className={styles.buttons}>
            <input
              name="proof"
              ref={ipt5}
              id="file-upload"
              type="file"
              onChange={initFile}
            />
            <button
              disabled={buttonState}
              type="submit"
              className={styles.submitBtn}
              onClick={handleSubmit}
            >
              Send Feedback
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default Input;
