import React, { useEffect, useState } from "react";
import styles from "./ModelAlarm.module.css";
import close from "assets/close.png";

const ModelAlarm = ({
  setOpenModel,
  setInputValue,
  alarm,
  titleText,
  setNewAlarm,
  setChatId,
  setBotToken,
  selectedTriggerType,
  setSelectedTriggerType
}) => {

  const [selectedOption , setSelectedOption] = useState(); // State to store selected medium value
  const [openOptionModel , setOpenOptionModel] = useState(false); // State to handle telegram option model

  // Updating the state on page load
  useEffect(() => {
    if (alarm.medium) {
      setSelectedOption(alarm.medium);
    }

    if(alarm.triggerType){
      setSelectedTriggerType(alarm.triggerType ? alarm.triggerType : "onTrigger")
    }
  }, []);

  // Function to store selected media option
  const handleOptions = (e) => {
    setSelectedOption(e.target.value);
  };

  // To open Telegram Model
  const handleOptionModel = () => {
    if (selectedOption === "telegram") {
      setOpenOptionModel(true);
    } else {
      setOpenOptionModel(false);
    }
  };

  // Function for closing model
  const handleCloseModel = () => {
    setOpenModel(false);
  };

  
  // Function to handle saving new values
  const handleSaveModel = () => {
    setOpenModel(false);
    const modifiedValues = {
      alarmName: document.getElementById("alarmNameInput").value,
      alarmId: alarm.alarmId,
      projectId: alarm.projectId,
      channel: document.getElementById("channelNameInput").value,
      value: document.getElementById("valueInput").value,
      alarmType: document.getElementById("alarmType").value,
      operation: document.getElementById("operationOption").value,
      medium: selectedOption,
      triggerType : selectedTriggerType,
      triggerValue : alarm.triggerValue ? alarm.triggerValue : false ,
    };
    if (!alarm.alarmId) {
      setNewAlarm(modifiedValues);
    } else {
      setInputValue(modifiedValues);
    }
  };
  return (
    <div className={styles.modelAlarm}>
      <div className={styles.inputWrapper}>
        <div className={styles.closeBtnWrapper}>
          <h2 className={styles.titleText}>{titleText}</h2>
          <button className={styles.closeBtn} onClick={handleCloseModel}>
            <img src={close} alt="Close" className={styles.closePng} />
          </button>
        </div>
        <input
          className={styles.alarmNameInput}
          id="alarmNameInput"
          type="text"
          placeholder="Alarm Name"
          defaultValue={alarm.alarmName}
        />
        <input
          className={styles.channelNameInput}
          type="text"
          id="channelNameInput"
          placeholder="Channel Name (Case Sensitive)"
          defaultValue={alarm.channel}
        />
        <input
          className={styles.valueInput}
          type="text"
          id="valueInput"
          placeholder="Alarm Value"
          defaultValue={alarm.value}
        />

        <div className={styles.functionOptions}>
          <label htmlFor="functionOptions" className={styles.labelOptions}>
            Function :
          </label>
          <select className={styles.selectOptions} id="operationOption">
            <option selected disabled hidden>
              {alarm.operation}
            </option>
            <option value=">">&gt;</option>
            <option value="<">&lt;</option>
            <option value="=">=</option>
          </select>
        </div>

        <div className={styles.alarmType}>
          <div className={styles.alarmTypeWrapper}>
            <label htmlFor="Trigger Alarm Type" className={styles.labelOptions}>
              Trigger Alarm Type :
            </label>
            <select className={styles.selectOptions} id="triggerAlarm"value={selectedTriggerType} onChange={(e) => setSelectedTriggerType(e.target.value)}>
              <option value="onTrigger">On Trigger</option>
              <option value="offTrigger">Off Trigger</option>
              <option value="betweenTrigger">Between Trigger</option>
              <option value="afterTrigger">After Trigger</option>
            </select>
          </div>
        </div>

        <div className={styles.alarmType}>
          <label htmlFor="alarmType" className={styles.labelOptions}>
            Alarm Type :
          </label>
          <select className={styles.selectOptions} id="alarmType">
            <option value="normalAlarm">Normal Alarm</option>
            <option value="offlineChannelAlarm">Offline Channel Alarm</option>
          </select>
        </div>

        <div className={styles.mediaOptions}>
          <label htmlFor="functionOptions" className={styles.labelOptions}>
            Notification Media :
          </label>
          <select
            id="mediaOption"
            onClick={handleOptionModel}
            value={selectedOption}
            onChange={handleOptions}
            className={styles.selectOptions}
          >
            <option value="whatsapp">WhatsApp</option>
            <option value="email">Email</option>
            <option value="telegram">Telegram</option>
            <option value="sms">SMS</option>
          </select>
          {/* For Open Telegram Model */}
          {openOptionModel ? (
            <>
              <div className={styles.telegramModel}>
                <input
                  className={styles.botTokenInput}
                  id="botToken"
                  type="text"
                  placeholder="Bot Token"
                  onChange={(e) => setBotToken(e.target.value)}
                />
                <input
                  className={styles.chatIdInput}
                  type="text"
                  id="chatid"
                  placeholder="Chat Id"
                  onChange={(e) => setChatId(e.target.value)}
                />
              </div>
            </>
          ) : (
            ""
          )} 
        </div>
        <div className={styles.saveModel}>
          <button onClick={handleSaveModel} className={styles.saveBtn}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModelAlarm;
