import React, { useState, useEffect } from "react";
import ProjectCard from "./ProjectCard";
import AddProject from "./AddProject";
import styles from "./ProjectCollection.module.css";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProjectData,
} from "../../../../redux/slices/projectSlice.js";

function ProjectCollection(props) {
  const [isAddProject, setIsAddProject] = useState(false);

  const dispatch = useDispatch();
  const projectData = useSelector((state) => state.projectData.projectData);
  const isLoading = useSelector((state) => state.projectData.isLoading);
  const userPermission = useSelector(state => state.organization.userPermission);
  const token = localStorage.getItem("token");
  const orgEmail = localStorage.getItem("organizationEmail");

  useEffect(() => {
    try {
      if (token) {
        dispatch(fetchProjectData({ token: token, orgEmail: orgEmail }));
      }
    } catch (error) {
      console.log(error);
    }

  }, []);


  return (
    <div className={styles.projectContainer}>
      <div id="projects" className={isLoading ? "" : `${styles.projects}`}>
        <>
          {isLoading ? (
            <div className={styles.fetchProjects}>Loading Projects...</div>
          ) : (
            projectData
              ?.filter((project) =>
                userPermission?.projectAccess?.some(
                  (access) =>
                    access?.projectId === project?.uniqueProjectId && access?.allowed
                )
              )
              .map((project, key) => (
                <ProjectCard
                  uniqueProjectId={project.uniqueProjectId}
                  key={key}
                  projectName={project.projectName}
                  onlineStatus={project.projectOnlineStatus}
                  desc={project.desc}
                  location={project.location}
                  adminEmail={project.admin}
                  lastModified={project.LastModified}
                />
              ))
          )}
        </>
      </div>
      <button
        className={styles.addProject}
        onClick={() => setIsAddProject(true)}
      >
        <AiOutlinePlus />
        Add Project
      </button>
      {isAddProject && <AddProject setIsAddProject={setIsAddProject} />}
    </div>
  );
}

export default ProjectCollection;
