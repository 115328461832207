import React, { useCallback, useRef, useState } from "react";
import styles from "./NewPage.module.css";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { AiOutlinePlus } from "react-icons/ai";
import CryptoJS from "crypto-js";
import close from "assets/close.png";
import closeSvg from "assets/closeSvg.svg";
import floopyDisk from "assets/floopyDisk.png";
import increase from "assets/increase.svg";
import decrease from "assets/decrease.svg";
import Overview from "./Overview";
import LiveData from "./LiveData";
import Day1 from "./Day1";
import Channels from "./Channels";
import ContextMenu from "./ContextMenu";
import { useEffect } from "react";
import ToolBar from "./ToolBar";
import Draggble from "react-draggable";
import motorgif from "../../../../../assets/motorgif.gif"
import {
  Controller,
  DeviceController,
  DeviceEnergyMeter,
  DeviceFlowMeter,
  DeviceIO,
  Devices,
  DigitalIO,
  EnergyMeter,
  FlowMeter,
  Indicator,
  Display,
  DeviceDisplay
} from "./Devices";
import { PieChart, NewPieChart } from "./PieChart";
import { BarChart, NewBarChart } from "./BarChart";
import { Histogram, NewHistogram } from "./Histogram";
import { LineChart, NewLineChart } from "./LineChart";
import { Gauge, NewGauge } from "./Gauge";
import { NewProgressBar, ProgressBar } from "./ProgressBar";
import { NewToolBarGrid, ToolBarGrid } from "./ToolBarGrid";
import {
  MovableTable,
  NewMovableTable,
} from "../../../../../components/MovableTable/MovableTable";
import { addWidget } from "../../../../../redux/slices/viewDataSlice.js";
import { useDispatch } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { updateSV } from "../../../../../redux/slices/viewDataSlice.js";
import { getRealTimeTags, getVnetBoxes, getVnetRealGroups, updateTagValue, vNetLogin } from "../../../../../redux/slices/vNetSlice.js"


const NewPage = ({
  documentId,
  setOpenNewPage,
  data,
  uniqueProjectId,
  socketData,
  tabNames,
  images,
  setImages,
  setTempImagePosition,
  tabIndexImages,
  setTabIndexImages,
  tableData,
  tabIndexDevices,
  setTabIndexDevices,
  openNewPage,
  tabIndexController,
  setTabIndexController,
  setTabIndexIO,
  tabIndexIO,
  setTabIndexFlowM,
  tabIndexFlowM,
  tabIndexEnergyM,
  setTabIndexEnergyM,
  tabIndexLineChart,
  setTabIndexLineChart,
  setTabIndexPieChart,
  tabIndexPieChart,
  dateTimesLive,
  channelNames,
  dataArrLive,
  getRandomColor,
  tabIndexBarChart,
  setTabIndexBarChart,
  barChartData,
  setBarChartData,
  tabIndexHistogram,
  setTabIndexHistogram,
  histogramData,
  setHistogramData,
  tabIndexGauge,
  setTabIndexGauge,
  tabIndexProgress,
  setTabIndexProgress,
  windowHeight,
  windowWidth,
  tabIndexGrid,
  setTabIndexGrid,
  oneDayDataTs,
  tabIndexTable,
  setTabIndexTable,
  tabIndexOverviewInfo,
  tabIndexOverviewGraph,
  setTabIndexOverviewInfo,
  setTabIndexOverviewGraph,
  setTabIndexLiveDataGraph,
  tabIndexLiveDataGraph,
  tabIndexLastDayTable,
  setTabIndexLastDayTable,
  tabIndexLastDayGraph,
  setTabIndexLastDayGraph,
  tabIndexDefaultGrid,
  setTabIndexDefaultGrid,
  selectedTabIndex,
  setSelectedTabIndex,
  tempTabIndexLiveDataGraph,
  tempTabIndexOverviewInfo,
  tempTabIndexOverviewGraph,
  tempTabIndexLastDayTable,
  tempTabIndexLastDayGraph,
  tempTabIndexDefaultGrid,
  scaleValue,
  tabIndexMotor,
  setTabIndexMotor,
  tabIndexDisplay,
  setTabIndexDisplay,
  tabIndexText,
  setTabIndexText,
  vNetPassword,
  vNetUsername,
  deviceName,
  deviceGroupName,
  tagGroupName
}) => {
  // Images and Tabs State
  const [isPath, setIsPath] = useState("Overview");
  const [addNewTag, setAddNewTag] = useState(false); // To Handle Opening and Closing Of Add Tab Name Modal
  const [tabNameInput, setTabNameInput] = useState("");
  const [inputValues, setInputValues] = useState(tabNames); // Input Values for Add New Tab Modal
  const [RenameInputValues, setRenameInputValues] = useState([]);
  const [deletedTabs, setDeletedTabs] = useState([]);
  const [openContextMenu, setOpenContextMenu] = useState(false); // To Handle Opening and Closing Of Context Menu
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  }); // Position of Context Menu
  const [isRenameModel, setIsRenameModel] = useState(false); //To Handle Opening and Closing Of Rename Tab Modal
  const [clickedTabIndex, setClickedTabIndex] = useState(null); // Index of Tab
  const [selectedTab, setSelectedTab] = useState(""); // selected Tab Name
  const [tempTabNames, setTempTabNames] = useState(tabNames); // copy of tabNames
  const [tempImages, setTempImages] = useState([...images]); // copy of all data
  const [tempTabIndexImages, setTempTabIndexImages] = useState(tabIndexImages); // copy of tabIndexImages
  const [multipleImages, setMultipleImages] = useState([]); // to store new  images
  const [indexesToRaname, setIndexesToRename] = useState([]);
  const [displayImage, setDisplayImage] = useState();
  const [imagePosition, setImagePosition] = useState([]); // new images position
  const [imageDimensions, setImageDimensions] = useState([]); // new images width, height
  const [deleteContextMenu, setDeleteContextMenu] = useState(false); // to open delete context menu
  const [deleteContextPosi, setDeleteContextPosi] = useState({ x: 0, y: 0 }); // position of context menu
  const [clickedImgIndex, setClickedImgIndex] = useState(null);
  const [indexesToDeleteImg, setIndexesToDeleteImg] = useState([]);
  const [selectedImg, setSelectedImg] = useState();
  const [imagesToUpdate, setImagesToUpdate] = useState([]);
  const [storeTabIndexes, setStoreTabIndexes] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(selectedTabIndex); // current active tab index

  // Devices States

  const [tempTabIndexDevices, setTempTabIndexDevices] = useState(
    Object.assign({}, tabIndexDevices)
  ); // Indicators for this page
  const [tempTabIndexController, setTempTabIndexController] = useState(
    Object.assign({}, tabIndexController)
  ); // Comtrollers for this page
  const [deviceMenuPosition, setDeviceMenuPosition] = useState({ x: 0, y: 0 }); // Position of context menu
  const [isDeviceMenu, setIsDeviceMenu] = useState(false);
  const [multipleDevices, setMultipleDevices] = useState([
    { deviceName: "", type: "" },
  ]); // New indicators
  const [devicePosition, setDevicePosition] = useState([]); // New indicators position
  const [isChannels, setIsChannels] = useState(false);
  const [renameDeviceModal, setRenameDeviceModal] = useState(false);
  const [devicesName, setDevicesName] = useState([]);
  const [indexesDeviceName, setIndexesDeviceName] = useState([]);
  const [clickedDeviceIndex, setClickedDeviceIndex] = useState(); // particular device index
  const [tabIndexForDevice, setTabIndexForDevices] = useState([]); // tab index to add any new device
  const [selectedDevice, setSelectedDevice] = useState(); // paticular device that's been right clciked
  const [pvSpecificChannel, setPVSpecificChannel] = useState([]);
  const [indexesOfDevicePosi, setIndexesOfDevicePosi] = useState([]);
  const [currentPVChannel, setCurrentPVChannel] = useState("");
  const [currentPVController, setCurrentPVController] = useState("");
  const [currentSVContoller, setCurrentSVController] = useState("");
  const [currentPVEnergyM, setCurrentPVEnergyM] = useState("");
  const [currentSVEnergyM, setCurrentSVEnergyM] = useState("");
  const [currentPVFlowM, setCurrentPVFlowM] = useState("");
  const [currentSVFlowM, setCurrentSVFlowM] = useState("");
  const [pvControllerChannel, setPVControllerChannel] = useState([]); // pv channels for controller
  const [svControllerChannel, setSVControllerChannel] = useState([]); // sv channels for controller
  const [currentDevice, setCurrentDevice] = useState("");
  const [multipleController, setMultipleController] = useState([
    { deviceName: "", type: "" },
  ]); // to store new controllers

  const [controllerPosition, setControllerPosition] = useState([]); // new controllers position
  const [multipleIO, setMultipleIO] = useState([{ deviceName: "", type: "" }]); //  to store new IO
  const [tempTabIndexIO, setTempTabIndexIO] = useState(
    Object.assign({}, tabIndexIO)
  ); // copy of tabIndexIO
  const [ioPosition, setIOPosition] = useState([]); // new IO position
  const [multipleFlowM, setMultipleFlowM] = useState([
    { deviceName: "", type: "" },
  ]); // to store new flow meter
  const [tempTabIndexFlowM, setTempTabIndexFlowM] = useState(
    Object.assign({}, tabIndexFlowM)
  ); // copy of tabIndexFlowM
  const [flowMPosition, setFlowMPosition] = useState([]); // new flow meters posiiton
  const [pvFlowMChannels, setPVFlowMChannels] = useState([]); // pv channels for flow meter
  const [svFlowMChannels, setSVFlowMChannels] = useState([]); // sv channels for flow meter

  const [multipleEnergyM, setMultipleEnergyM] = useState([
    { deviceName: "", type: "" },
  ]); // to store new energy meter
  const [tempTabIndexEnergyM, setTempTabIndexEnergyM] = useState(
    Object.assign({}, tabIndexEnergyM)
  ); // copy of tabIndexEnergyM
  const [energyMPosition, setEnergyMPosition] = useState([]); // new energy meter position
  const [pvEnergyMChannels, setPVEnergyMChannels] = useState([]); // pv channels for energy meter
  const [svEnergyMChannels, setSVEnergyMChannels] = useState([]); // sv channels for energy meter

  // Charts states
  const [multipleLineCharts, setMultipleLineCharts] = useState([
    { deviceName: "", type: "" },
  ]); //to store new line charts
  const [lineChartPosition, setLineChartPosition] = useState([]); // new line charts position
  const [tempTabIndexLineChart, setTempTabIndexLineChart] = useState(
    Object.assign({}, tabIndexLineChart)
  ); // copy of tabIndexLineChart

  const [multiplePieChart, setMultiplePieChart] = useState([
    { deviceName: "", type: "", x: 0, y: 0 },
  ]); //to store new pie charts
  const [tempTabIndexPieChart, setTempTabIndexPieChart] = useState(
    Object.assign({}, tabIndexPieChart)
  ); // copy of tabIndexPieChart
  const [pieChartPosition, setPieChartPosition] = useState([]); // new pie charts position
  const [pieChartChannels, setPieChartChannels] = useState([]); // channels for pie charts
  const [lineChartChannels, setLineChartChannels] = useState([]); // channels for line charts

  const [plusIcon, setPlusIcon] = useState(false);
  const [displaySelectTag, setDisplaySelectTag] = useState([]);

  const [multipleBarChart, setMultipleBarChart] = useState([
    { deviceName: "", type: "" },
  ]); // to store new bar charts
  const [tempTabIndexBarChart, setTempTabIndexBarChart] = useState(
    Object.assign({}, tabIndexBarChart)
  ); // copy of tabIndexBarChart
  const [barChartChannels, setBarChartChannels] = useState([]); // channels for bar charts
  const [barChartPosition, setBarChartPosition] = useState([]); // new bar charts position

  const [multipleHistogram, setMultipleHistogram] = useState([
    { deviceName: "", type: "" },
  ]); // to store new histogram
  const [histogramPosition, setHistogramPosition] = useState([]); // new histogram position
  const [tempTabIndexHistogram, setTempTabIndexHistogram] = useState(
    Object.assign({}, tabIndexHistogram)
  ); // copy of tabIndexHistogram
  const [histogramChannels, setHistogramChannels] = useState([]); // channels for histogram
  const [multipleGauge, setMultipleGauge] = useState([
    { deviceName: "", type: "" },
  ]);
  const [tempTabIndexGauge, setTempTabIndexGauge] = useState(
    Object.assign({}, tabIndexGauge)
  ); // Gauges for this page
  const [gaugePosition, setGaugePosition] = useState([]); // To track gauge position
  const [gaugeChannels, setGaugeChannels] = useState([]); // To store gauge channels
  const [isRangeModel, setIsRangeModal] = useState(false); //Handle Range model
  const [startPoint, setStartPoint] = useState([]);
  const [endPoint, setEndPoint] = useState([]);
  const [multipleProgress, setMultipleProgress] = useState([
    { deviceName: "", type: "" },
  ]); // To store new progressbar
  const [tempTabIndexProgress, setTempTabIndexProgress] = useState(
    Object.assign({}, tabIndexProgress)
  ); // Progressbar for this page
  const [progressPosition, setProgressPosition] = useState([]);
  const [progressChannels, setProgressChannels] = useState([]);
  const [maximumVal, setMaximumVal] = useState([]);
  const [minimumVal, setMinimumVal] = useState([]);
  const [rotateBar, setRotateBar] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [changeRange, setChangeRange] = useState(false);
  const [specificMinVal, setSpecificMinVal] = useState();
  const [specificMaxVal, setSpecificMaxVal] = useState();

  const [multipleGrid, setMultipleGrid] = useState([
    { deviceName: "", type: "" },
  ]); // Grid for this page
  const [tempTabIndexGrid, setTempTabIndexGrid] = useState(
    Object.assign({}, tabIndexGrid)
  ); // copy of tabIndexHistogram

  const [gridChannels, setGridChannels] = useState([]);
  const [gridPositions, setGridPosition] = useState([]);
  const [isChannelCheckBox, setIsChannelCheckBox] = useState(false);
  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [isTimePeriod, setIsTimePeriod] = useState(false);
  const [endSelect, setEndSelect] = useState("Hours");
  const [endDays, setEndDays] = useState(0);
  const [endHours, setEndHours] = useState(0);
  const [endMinutes, setEndMinutes] = useState(0);
  const [multipleTable, setMultipleTable] = useState([
    { deviceName: "", type: "" },
  ]);

  const [tempTabIndexTable, setTempTabIndexTable] = useState(
    Object.assign({}, tabIndexTable)
  );
  const [tablePosition, setTablePosition] = useState([]);
  const [tableChannels, setTableChannels] = useState([]);
  const [tableFormat, setTableFormat] = useState(false);
  const [overviewInfoPosi, setOverviewInfoPosi] = useState();

  const [overviewGraphPosi, setOverviewGraphPosi] = useState();
  const [overviewGraphDimension, setOverviewGraphDimension] = useState();
  const [liveDataGraphPosi, setLiveDataGraphPosi] = useState();
  const [liveDataGraphDimension, setLiveDataGraphDimension] = useState();
  const [lastDayTablePosi, setLastDayTablePosi] = useState();
  const [lastDayTableDimension, setLastDayTableDimension] = useState();
  const [lastDayGraphPosi, setLastDayGraphPosi] = useState();
  const [lastDayGraphDimension, setLastDayGraphDimension] = useState();
  const [defaultGridPosi, setDefaultGridPosi] = useState();
  const [defaultGridDimension, setDefaultGridDimension] = useState();
  const [selectedDeviceId, setSelectedDeviceId] = useState();

  // States to save data for updated widget

  const [updatedTabIndexImages, setUpdatedTabIndexImages] = useState(
    Object.assign({}, tabIndexImages)
  );

  const [updatedTabIndexDevices, setUpdatedTabIndexDevices] = useState(
    Object.assign({}, tabIndexDevices)
  );
  const [updatedTabIndexController, setUpdatedTabIndexController] = useState(
    Object.assign({}, tabIndexController)
  );
  const [updatedTabIndexIO, setUpdatedTabIndexIO] = useState(
    Object.assign({}, tabIndexIO)
  );
  const [updatedTabIndexFlowM, setUpdatedTabIndexFlowM] = useState(
    Object.assign({}, tabIndexFlowM)
  );
  const [updatedTabIndexEnergyM, setUpdatedTabIndexEnergyM] = useState(
    Object.assign({}, tabIndexEnergyM)
  );
  const [updatedTabIndexLineChart, setUpdatedTabIndexLineChart] = useState(
    Object.assign({}, tabIndexLineChart)
  );
  const [updatedTabIndexPieChart, setUpdatedTabIndexPieChart] = useState(
    Object.assign({}, tabIndexPieChart)
  );
  const [updatedTabIndexBarChart, setUpdatedTabIndexBarChart] = useState(
    Object.assign({}, tabIndexBarChart)
  );
  const [updatedTabIndexHistogram, setUpdatedTabIndexHistogram] = useState(
    Object.assign({}, tabIndexHistogram)
  );
  const [updatedTabIndexGauge, setUpdatedTabIndexGauge] = useState(
    Object.assign({}, tabIndexGauge)
  );
  const [updatedTabIndexProgress, setUpdatedTabIndexProgress] = useState(
    Object.assign({}, tabIndexProgress)
  );
  const [updatedTabIndexGrid, setUpdatedTabIndexGrid] = useState(
    Object.assign({}, tabIndexGrid)
  );
  const [updatedTabIndexTable, setUpdatedTabIndexTable] = useState(
    Object.assign({}, tabIndexTable)
  );

  const [updatedMultipleImages, setUpdatedMultipleImages] = useState([
    ...multipleImages,
  ]);

  const [updatedMultipleDevices, setUpdatedMultipleDevices] = useState([
    ...multipleDevices,
  ]);

  const [updatedMultipleController, setUpdatedMultipleController] = useState([
    ...multipleController,
  ]);

  const [updatedMultipleIO, setUpdatedMultipleIO] = useState([...multipleIO]);

  const [updatedMultipleFlowM, setUpdatedMultipleFlowM] = useState([
    ...multipleFlowM,
  ]);
  const [updatedMultipleEnergyM, setUpdatedMultipleEnergyM] = useState([
    ...multipleEnergyM,
  ]);
  const [updatedMultipleLineChart, setUpdatedMultipleLineChart] = useState([
    ...multipleLineCharts,
  ]);
  const [updatedMultiplePieChart, setUpdatedMultiplePieChart] = useState([
    ...multiplePieChart,
  ]);
  const [updatedMultipleBarChart, setUpdatedMultipleBarChart] = useState([
    ...multipleBarChart,
  ]);
  const [updatedMultipleHistogram, setUpdatedMultipleHistogram] = useState([
    ...multipleHistogram,
  ]);
  const [updatedMultipleGauge, setUpdatedMultipleGauge] = useState([
    ...multipleGauge,
  ]);
  const [updatedMultipleProgress, setUpdatedMultipleProgress] = useState([
    ...multipleProgress,
  ]);
  const [updatedMultipleGrid, setUpdatedMultipleGrid] = useState([
    ...multipleGrid,
  ]);
  const [updatedMultipleTable, setUpdatedMultipleTable] = useState([
    ...multipleTable,
  ]);

  const [tempTabIndexMotor, setTempTabIndexMotor] = useState(Object.assign({}, tabIndexMotor))
  const [updatedTabIndexMotor, setUpdatedTabIndexMotor] = useState(
    Object.assign({}, tabIndexMotor)
  );

  const [multipleMotor, setMultipleMotor] = useState([
    { deviceName: "", type: "" },
  ]);

  const [updatedMultipleMotor, setUpdatedMultipleMotor] = useState([...multipleMotor]);
  const [motorPosition, setMotorPosition] = useState([]);
  const [isMotorOptions, setIsMotorOptions] = useState(false);
  const [isDeviceOption, setIsDeviceOption] = useState(false);

  const [multipleDisplay, setMultipleDisplay] = useState([
    { deviceName: "", type: "" }
  ]);

  const [updatedMultipleDisplay, setUpdatedMultipleDisplay] = useState([...multipleDisplay]);

  const [tempTabIndexDisplay, setTempTabIndexDisplay] = useState(
    Object.assign({}, tabIndexDisplay)
  );

  const [updatedTabIndexDisplay, setUpdatedTabIndexDisplay] = useState(
    Object.assign({}, tabIndexDisplay)
  )

  const [displayPosition, setDisplayPosition] = useState([]);

  const [multipleText, setMultipleText] = useState([
    { deviceName: "", type: "" }
  ]);

  const [updatedMultipleText, setUpdatedMultipleText] = useState([...multipleText]);

  const [updatedTabIndexText, setUpdatedTabIndexText] = useState(Object.assign({}, tabIndexText));

  const [tempTabIndexText, setTempTabIndexText] = useState(Object.assign({}, tabIndexText));

  const [textPosition, setTextPosition] = useState([]);

  const [isBgColor, setIsBgColor] = useState(false);
  const [bgColor, setBgColor] = useState("#542A2A");
  const [textColor, setTextColor] = useState("#D90211");
  const [fontSize, setFontSize] = useState(30);

  const [textBgColor, setTextBgColor] = useState("#ffffff");
  const [widgetTextColor, setWidgetTextColor] = useState("#000000");
  const [textFontSize, setTextFontSize] = useState(30);
  const [addedText, setAddedText] = useState("");
  const [textAlignVertically, setTextAlignVertically] = useState("start");
  const [textAlignHorizontally, setTextAlignHorizontally] = useState("start");
  const [loginSid, setLoginSid] = useState("");

  const [isChangeSV, setIsChangeSV] = useState(false);
  const [updatedImages, setUpdatedImages] = useState([...images])
  const indicatorRefs = useRef([]);
  const displayRefs = useRef([]);
  const controllerRefs = useRef([]);
  const imagesRefs = useRef([]);
  const flowMRefs = useRef([]);
  const ioRefs = useRef([]);
  const energyMRefs = useRef([]);
  const motorRefs = useRef([]);
  const lineChartRefs = useRef([]);
  const pieChartRefs = useRef([]);
  const barChartRefs = useRef([]);
  const histogramRefs = useRef([]);
  const gaugeRefs = useRef([]);
  const progressBarRefs = useRef([]);
  const gridRefs = useRef([]);
  const tableRefs = useRef([]);
  const textRefs = useRef([]);

  const newControllerRefs = useRef([]);

  const toast = useToast();
  const dispatch = useDispatch();

  let widgetsData = [];
  let deviceData = [];
  let displayData = [];
  let controllerData = [];
  let ioData = [];
  let flowMeterData = [];
  let energyMeterData = [];
  let lineChartData = [];
  let pieChartData = [];
  let toolbarChartData = [];
  let toolBarhistogramData = [];
  let gaugeData = [];
  let progressBarData = [];
  let gridData = [];
  let toolBarTableData = [];
  let overviewInfoData = [];
  let overviewGraphData = [];
  let liveGraphData = [];
  let lastDayTableData = [];
  let lastDayGraphData = [];
  let defaultGridData = [];
  let motorData = [];
  let textData = [];

  const comid = "100";
  const compvtkey = "8919244ff15d441bae4b944b112a33ea";
  const key = "f1cd9351930d4e589922edbcf3b09a7c";

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [updatedTabIndexDisplay]);

  const removeDuplicates = (array) => {
    const uniqueArray = [];
    for (const item of array) {
      if (!uniqueArray.includes(item)) {
        uniqueArray.push(item);
      }
    }
    return uniqueArray;
  };

  // Function to Handle Context Menu for Tabs
  const handleContextMenu = useCallback((e, tabIndex, tabName) => {
    e.preventDefault();
    const x = e.clientX;
    const y = e.clientY;
    setContextMenuPosition({ x, y });
    setOpenContextMenu(true);
    setClickedTabIndex(tabIndex);
    setSelectedTab(tabName);
  }, []);

  // storing active tab index
  const handleTabClick = useCallback((tabIndex, name) => {
    setActiveTabIndex(tabIndex);
    setIsPath(name);
  }, []);

  // Function to Close Context Menu
  const closeContextMenu = () => {
    setOpenContextMenu(false);
    setDeleteContextMenu(false);
    setIsDeviceMenu(false);
  };

  //Function To Open Rename Tab
  const handleRenameTab = () => {
    setIsRenameModel(true);
    setRenameDeviceModal(false);
    setAddNewTag(false);
    closeContextMenu();
  };

  // Function To Save Rename Tab
  const handleSaveRenameTab = () => {
    if (tabNameInput !== selectedTab) {
      const updatedTabs = [...tempTabNames];
      updatedTabs[clickedTabIndex] = tabNameInput;
      setRenameInputValues(RenameInputValues.concat(tabNameInput));
      setIndexesToRename(indexesToRaname.concat(clickedTabIndex));
      setTempTabNames(updatedTabs);
      setTabNameInput("");
    }
    setIsRenameModel(false);
  };

  // When Click Anywhere In Page Close ContextMenu
  useEffect(() => {
    document.addEventListener("click", closeContextMenu);
    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("click", closeContextMenu);
    };
  }, []);

  // Add New Tab Modal Open
  const handleAddNewTab = () => {
    setIsRenameModel(false);
    setRenameDeviceModal(false);
    setAddNewTag(true);
  };

  // Back To View Data Page
  const handleDiscardPage = () => {
    setOpenNewPage(false);
  };

  const handleClosePage = () => {
    setSelectedTabIndex(activeTabIndex);
    setOpenNewPage(false);
  };

  // Saving the New Tab
  const handleSaveTab = () => {
    if (tabNameInput) {
      setTempTabNames(tempTabNames.concat(tabNameInput));
      setInputValues(inputValues.concat(tabNameInput)); // Add the current input value to the array
      setTabNameInput("");
      setAddNewTag(false);
    }
  };

  //Delete Tab
  const handleDeleteTab = () => {
    const originalTabIndex = tabNames.findIndex((tab) => tab === selectedTab);
    setDeletedTabs(deletedTabs.concat(originalTabIndex));
    if (
      clickedTabIndex !== null &&
      clickedTabIndex >= 0 &&
      clickedTabIndex < tempTabNames.length
    ) {
      const updatedTabs = tempTabNames.filter(
        (tab, index) => index !== clickedTabIndex
      );
      setTempTabNames(updatedTabs);
    }
  };

  // Update the image's position after dragging stops
  const handleDragStop = (e, data, imgIndex) => {
    const newPosition = { x: data.x, y: data.y };
    setImagePosition((prevPositions) => {
      const newPositions = prevPositions.slice();
      newPositions[imgIndex] = newPosition;
      return newPositions;
    });
  };

  // Update saved image position after draagging stops
  const TempHandleDragStop = (e, data, imgIndex, tempImage, x, y, id) => {
    const newPosition = { x: data.x, y: data.y };

    setTempImagePosition((prevPositions) => {
      const newPositions = prevPositions.slice();
      newPositions[imgIndex] = newPosition;
      return newPositions;
    });

    setImagesToUpdate(imagesToUpdate.concat(tempImage));
    const updatedTempImages = [...tempImages];

    const originalDeviceIndex = tempImages.findIndex((data) => data.id === id);

    const updatedData = {
      ...updatedTempImages[originalDeviceIndex],
      x: x + data.x,
      y: y + data.y,
    };

    updatedTempImages[originalDeviceIndex] = updatedData;
    setTempImages(updatedTempImages);

    let oldtabIndexItem = [...tempTabIndexImages[activeTabIndex]];
    const organizedDeviceIndex = oldtabIndexItem.findIndex(
      (data) => data.id === id
    );

    let newXPosition = x + data.x;
    let newYPosition = y + data.y;

    const newUpdatedData = {
      ...oldtabIndexItem[organizedDeviceIndex],
      x: newXPosition,
      y: newYPosition,
    };

    oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;

    const newTempTabIndexDevices = {
      [activeTabIndex]: oldtabIndexItem,
    };

    setUpdatedTabIndexImages(newTempTabIndexDevices);

  };

  // Getting height and width of image when resize
  const getDimensions = (e, imgIndex) => {
    e.preventDefault();
    const selectedImg = document.getElementById(`displayImage-${imgIndex}`);
    const width = selectedImg.style.width;
    const height = selectedImg.style.height;

    setImageDimensions((prevDimensions) => {
      const newDimensions = prevDimensions.slice();
      newDimensions[imgIndex] = { width, height };
      return newDimensions;
    });
  };

  // Getting height and width of image when resize saved images
  const TempGetDimensions = (e, imgIndex, id) => {
    e.preventDefault();
    const selectedImg = document.getElementById(`tempImg-${imgIndex}`);
    const newWidth = selectedImg.style.width;
    const newHeight = selectedImg.style.height;

    const updatedTempImages = [...tempImages];

    const originalDeviceIndex = tempImages.findIndex((data) => data.id === id);

    const updatedData = {
      ...updatedTempImages[originalDeviceIndex],
      width: newWidth,
      height: newHeight,
    };

    updatedTempImages[originalDeviceIndex] = updatedData;
    setTempImages(updatedTempImages);
  };

  const HandleDeleteContextMenu = (e, imgIndex, tempImage) => {
    e.preventDefault();
    setDeleteContextMenu(true);
    const x = e.clientX;
    const y = e.clientY;
    setDeleteContextPosi({ x, y });
    setClickedImgIndex(imgIndex);
    setSelectedImg(tempImage);
  };

  //For temp Images
  const handleDeleteImage1 = () => {
    setDeleteContextMenu(false); // Close the delete context menu

    // Perform null/undefined check on selectedImg
    const updatedImages = tempTabIndexImages[activeTabIndex].filter(
      (image, index) => image.name !== selectedImg.name
    );

    // Create a copy of the original state and update the specific tab index
    const newTabIndexImages = { ...tempTabIndexImages };
    newTabIndexImages[activeTabIndex] = updatedImages;
    setTempTabIndexImages(newTabIndexImages);

    const originalImageIndex = images.find(
      (image) => image.id === selectedImg.id
    );

    const filteredData = tempImages.filter(
      (data) => data.id !== selectedImg.id
    );
    setTempImages(filteredData);
    setIndexesToDeleteImg(indexesToDeleteImg.concat(originalImageIndex.id));
  };

  // For new Images/Multiple Images
  const handleDeleteImage2 = () => {
    setDeleteContextMenu(false); // Close the delete context menu
    const updatedImages = multipleImages.filter(
      (image, index) => index !== clickedImgIndex
    );
    setMultipleImages(updatedImages);
  };

  // Displaying Saved Images

  const TempGenerateImage = () => {
    const selectedTabImages = tempTabIndexImages[activeTabIndex];
    if (Array.isArray(selectedTabImages)) {
      return selectedTabImages.map((tempImage, imgIndex) => {
        const { x, y, width, height, name, id } = tempImage;
        const imageStyle = {
          display: "flex",
          position: "absolute",
          top: y + "px",
          left: x + "px",
          resize: "both",
          width: width,
          height: height,
        };

        // const imagePositions = tempImagePosition[imgIndex];

        return (
          <Draggble
            onStop={(e, data) =>
              TempHandleDragStop(e, data, imgIndex, tempImage, x, y, id)
            }
            bounds="parent"
          // position={imagePositions}
          >
            <div
              style={imageStyle}
              id={`tempImg-${imgIndex}`}
              onContextMenu={(e) => TempGetDimensions(e, imgIndex, id)}
              className={styles.addImg}
            >
              <img
                src={name}
                alt=""
                key={imgIndex}
                onContextMenu={(event) =>
                  HandleDeleteContextMenu(event, imgIndex, tempImage)
                }
              />
            </div>
          </Draggble>
        );
      });
    }
  };

  // Saving Whole Page
  const handleSavePage = async () => {
    // Fetch Request to Add New Tab/ Add New Image/ Add Update Image Position

    {
      indexesToRaname.length > 0 &&
        RenameInputValues.forEach((inputValue, index) => {
          tempTabNames[indexesToRaname[index]] = inputValue;
        });
    }

    // storing new images/devices/charts to previous data
    widgetsData = multipleImages.map((image, imgIndex) => ({
      id: Math.floor(100000 + Math.random() * 900000),
      tabIndex: storeTabIndexes[imgIndex],
      name: image,
      x: imagePosition.length > 0 ? imagePosition[imgIndex]?.x : 0,
      y: imagePosition.length > 0 ? imagePosition[imgIndex]?.y : 0,
      width:
        imageDimensions.length > 0 ? imageDimensions[imgIndex].width : "100px",
      height:
        imageDimensions.length > 0 ? imageDimensions[imgIndex].height : "100px",
    }));

    widgetsData.map((item) => tempImages.push(item));
    widgetsData.map((item) => updatedImages.push(item));

    displayData = multipleDisplay.slice(1).map((device, index) => ({
      id: Math.floor(100000 + Math.random() * 900000),
      name: multipleDisplay[index + 1].deviceName,
      deviceType: multipleDisplay[index + 1].type,
      x: displayPosition.length > 0 ? displayPosition[index + 1]?.x : 0,
      y: displayPosition.length > 0 ? displayPosition[index + 1]?.y + 40 : 0,
      tabIndex: device.tabIndex,
      width: device.width ? device.width : null,
      height: device.height ? device.height : null,
      channelName: {
        PV: device.pvChannel ? device.pvChannel : "",
        SV: "",
      },
      bgColor: device.bgColor ? device.bgColor : "#542A2A",
      textColor: device.textColor ? device.textColor : "#D90211",
      fontSize: device.fontSize ? device.fontSize : "30"
    }));

    displayData.map((item) => tempImages.push(item));
    displayData.map((item) => updatedImages.push(item));


    deviceData = multipleDevices.slice(1).map((device, index) => ({
      id: Math.floor(100000 + Math.random() * 900000),
      name: multipleDevices[index + 1].deviceName,
      deviceType: multipleDevices[index + 1].type,
      x: devicePosition.length > 0 ? devicePosition[index + 1]?.x : 0,
      y: devicePosition.length > 0 ? devicePosition[index + 1]?.y + 40 : 0,
      tabIndex: device.tabIndex,
      channelName: {
        PV: device.pvChannel ? device.pvChannel : "",
        SV: "",
      },
      unit: device.unit !== "" ? device.unit : "--",
    }));

    deviceData.map((item) => tempImages.push(item));
    deviceData.map((item) => updatedImages.push(item));

    controllerData = multipleController.slice(1).map((controller, index) => ({
      id: Math.floor(100000 + Math.random() * 900000),
      name: multipleController[index + 1].deviceName,
      deviceType: multipleController[index + 1].type,
      x: controllerPosition.length > 0 ? controllerPosition[index + 1]?.x : 0,
      y: controllerPosition.length > 0 ? controllerPosition[index + 1]?.y + 40 : 0,
      tabIndex: controller.tabIndex,
      channelName: {
        PV: controller.pvChannel ? controller.pvChannel : "",
        SV: controller.svChannel ? controller.svChannel : "",
      },
      unit: controller.unit !== "" ? controller.unit : "--",
    }));

    controllerData.map((item) => tempImages.push(item));
    controllerData.map((item) => updatedImages.push(item));

    ioData = multipleIO.slice(1).map((IO, index) => ({
      id: Math.floor(100000 + Math.random() * 900000),
      name: multipleIO[index + 1].deviceName,
      deviceType: multipleIO[index + 1].type,
      tabIndex: IO.tabIndex,
      x: ioPosition.length > 0 ? ioPosition[index + 1]?.x : 0,
      y: ioPosition.length > 0 ? ioPosition[index + 1]?.y + 40 : 0,
      on: 0,
    }));

    ioData.map((item) => tempImages.push(item));
    ioData.map((item) => updatedImages.push(item));

    flowMeterData = multipleFlowM.slice(1).map((flowMeter, index) => ({
      id: Math.floor(100000 + Math.random() * 900000),
      name: multipleFlowM[index + 1].deviceName,
      deviceType: multipleFlowM[index + 1].type,
      tabIndex: flowMeter.tabIndex,
      x: flowMPosition.length > 0 ? flowMPosition[index + 1]?.x : 0,
      y: flowMPosition.length > 0 ? flowMPosition[index + 1]?.y + 40 : 0,
      channelName: {
        PV: flowMeter.pvChannel ? flowMeter.pvChannel : "",
        SV: flowMeter.svChannel ? flowMeter.svChannel : "",
      },
      unitPv: flowMeter.unitPv !== "" ? flowMeter.unitPv : "--",
      unitSv: flowMeter.unitSv !== "" ? flowMeter.unitSv : "--",
    }));

    flowMeterData.map((item) => tempImages.push(item));
    flowMeterData.map((item) => updatedImages.push(item));

    energyMeterData = multipleEnergyM.slice(1).map((energyMeter, index) => ({
      id: Math.floor(100000 + Math.random() * 900000),
      name: multipleEnergyM[index + 1].deviceName,
      deviceType: multipleEnergyM[index + 1].type,
      tabIndex: energyMeter.tabIndex,
      x: energyMPosition.length > 0 ? energyMPosition[index + 1]?.x : 0,
      y: energyMPosition.length > 0 ? energyMPosition[index + 1]?.y + 40 : 0,
      channelName: {
        PV: energyMeter.pvChannel ? energyMeter.pvChannel : "",
        SV: energyMeter.svChannel ? energyMeter.svChannel : "",
        SV2: energyMeter.svChannel2 ? energyMeter.svChannel2 : "",
        SV3: energyMeter.svChannel3 ? energyMeter.svChannel3 : "",
        SV4: energyMeter.svChannel4 ? energyMeter.svChannel4 : "",
      },
      unitPv: energyMeter.unitPv !== "" ? energyMeter.unitPv : "--",
      unitSv: energyMeter.unitSv !== "" ? energyMeter.unitSv : "--",
      unitSv2: energyMeter.unitSv2 !== "" ? energyMeter.unitSv2 : "--",
      unitSv3: energyMeter.unitSv3 !== "" ? energyMeter.unitSv3 : "--",
    }));

    energyMeterData.map((item) => tempImages.push(item));
    energyMeterData.map((item) => updatedImages.push(item));

    textData = multipleText.slice(1).map((text, index) => ({
      id: Math.floor(100000 + Math.random() * 900000),
      name: text.deviceName,
      deviceType: text.type,
      tabIndex: text.tabIndex,
      text: text.text ? text.text : "Text",
      x: textPosition.length > 0 ? textPosition[index + 1]?.x : 0,
      y: textPosition.length > 0 ? textPosition[index + 1]?.y + 40 : 0,
      bgColor: text.bgColor ? text.bgColor : "#000000",
      textColor: text.textColor ? text.textColor : "#ffffff",
      fontSize: text.fontSize ? text.fontSize : "30",
      width: text.width ? text.width : null,
      height: text.height ? text.height : null,
      alignVertically: text.alignVertically ? text.alignVertically : textAlignVertically,
      alignHorizontally: text.alignHorizontally ? text.alignHorizontally : textAlignHorizontally
    }))

    textData.map((item) => tempImages.push(item));
    textData.map((item) => updatedImages.push(item));

    motorData = multipleMotor.slice(1).map((motor, index) => ({
      id: Math.floor(100000 + Math.random() * 900000),
      name: motor.image,
      deviceType: motor.type,
      tabIndex: motor.tabIndex,
      x: motorPosition.length > 0 ? motorPosition[index + 1]?.x : 0,
      y: motorPosition.length > 0 ? motorPosition[index + 1]?.y + 40 : 0,
      channelName: {
        PV: motor.pvChannel ? motor.pvChannel : "",
        SV: "",
      },
      width: motor.width ? motor.width : null,
      height: motor.height ? motor.height : null,
      on: motor.on ? motor.on : 0
    }))

    motorData.map((item) => tempImages.push(item));
    motorData.map((item) => updatedImages.push(item));


    lineChartData = multipleLineCharts.slice(1).map((chart, index) => ({
      id: Math.floor(100000 + Math.random() * 900000),
      name: multipleLineCharts[index + 1].deviceName,
      deviceType: multipleLineCharts[index + 1].type,
      tabIndex: chart.tabIndex,
      x: lineChartPosition.length > 0 ? lineChartPosition[index + 1]?.x : 0,
      y: lineChartPosition.length > 0 ? lineChartPosition[index + 1]?.y + 40 : 0,
      chartChannel: chart.chartChannel ? chart.chartChannel : [],
      width: chart.width ? chart.width : null,
      height: chart.height ? chart.height : null,
      hour: chart.hour ? chart.hour : 0,
      minute: chart.minute ? chart.minute : 0,
    }));

    lineChartData.map((item) => tempImages.push(item));
    lineChartData.map((item) => updatedImages.push(item));


    pieChartData = multiplePieChart.slice(1).map((chart, index) => ({
      id: Math.floor(100000 + Math.random() * 900000),
      name: multiplePieChart[index + 1].deviceName,
      deviceType: multiplePieChart[index + 1].type,
      tabIndex: chart.tabIndex,
      x: pieChartPosition.length > 0 ? pieChartPosition[index + 1]?.x : 0,
      y: pieChartPosition.length > 0 ? pieChartPosition[index + 1]?.y + 40 : 0,
      chartChannel: chart.chartChannel ? chart.chartChannel : [],
      width: chart.width ? chart.width : null,
      height: chart.height ? chart.height : null,
    }));

    pieChartData.map((item) => tempImages.push(item));
    pieChartData.map((item) => updatedImages.push(item));

    toolbarChartData = multipleBarChart.slice(1).map((chart, index) => ({
      id: Math.floor(100000 + Math.random() * 900000),
      name: multipleBarChart[index + 1].deviceName,
      deviceType: multipleBarChart[index + 1].type,
      tabIndex: chart.tabIndex,
      x: barChartPosition.length > 0 ? barChartPosition[index + 1]?.x : 0,
      y: barChartPosition.length > 0 ? barChartPosition[index + 1]?.y + 40 : 0,
      chartChannel: chart.chartChannel ? chart.chartChannel : [],
      width: chart.width ? chart.width : null,
      height: chart.width ? chart.width : null,
    }));

    toolbarChartData.map((item) => tempImages.push(item));
    toolbarChartData.map((item) => updatedImages.push(item));

    toolBarhistogramData = multipleHistogram.slice(1).map((chart, index) => ({
      id: Math.floor(100000 + Math.random() * 900000),
      name: multipleHistogram[index + 1].deviceName,
      deviceType: multipleHistogram[index + 1].type,
      tabIndex: chart.tabIndex,
      x: histogramPosition.length > 0 ? histogramPosition[index + 1]?.x : 0,
      y: histogramPosition.length > 0 ? histogramPosition[index + 1]?.y + 40 : 0,
      chartChannel: chart.chartChannel ? chart.chartChannel : [],
      width: chart.width ? chart.width : null,
      height: chart.width ? chart.width : null,
      hour: chart.hour ? chart.hour : 0,
      minute: chart.minute ? chart.minute : 0,
    }));

    toolBarhistogramData.map((item) => tempImages.push(item));
    toolBarhistogramData.map((item) => updatedImages.push(item));

    gaugeData = multipleGauge.slice(1).map((chart, index) => ({
      id: Math.floor(100000 + Math.random() * 900000),
      name: multipleGauge[index + 1].deviceName,
      deviceType: multipleGauge[index + 1].type,
      tabIndex: chart.tabIndex,
      x: gaugePosition.length > 0 ? gaugePosition[index + 1]?.x : 0,
      y: gaugePosition.length > 0 ? gaugePosition[index + 1]?.y + 40 : 0,
      chartChannel: chart.chartChannel ? chart.chartChannel : [],
      minVal: startPoint.length > 0 ? startPoint[index] : 0,
      maxVal: endPoint.length > 0 ? endPoint[index] : 100,
      width: chart.width ? chart.width : null,
      height: chart.width ? chart.width : null,
    }));

    gaugeData.map((item) => tempImages.push(item));
    gaugeData.map((item) => updatedImages.push(item));

    setImages(tempImages);

    progressBarData = multipleProgress.slice(1).map((progressBar, index) => ({
      id: Math.floor(100000 + Math.random() * 900000),
      name: multipleProgress[index + 1].deviceName,
      deviceType: multipleProgress[index + 1].type,
      tabIndex: progressBar.tabIndex,
      x: progressPosition.length > 0 ? progressPosition[index + 1]?.x : 0,
      y: progressPosition.length > 0 ? progressPosition[index + 1]?.y + 40 : 0,
      chartChannel: progressBar.chartChannel ? progressBar.chartChannel : [],
      minVal: minimumVal.length > 0 ? minimumVal[index] : 0,
      maxVal: maximumVal.length > 0 ? maximumVal[index] : 100,
      rotate: rotateBar,
      width: progressBar.width ? progressBar.width : null,
      height: progressBar.width ? progressBar.width : null,
    }));

    progressBarData.map((item) => tempImages.push(item));
    progressBarData.map((item) => updatedImages.push(item));

    gridData = multipleGrid.slice(1).map((grid, index) => ({
      id: Math.floor(100000 + Math.random() * 900000),
      name: multipleGrid[index + 1].deviceName,
      deviceType: multipleGrid[index + 1].type,
      tabIndex: grid.tabIndex,
      x: gridPositions.length > 0 ? gridPositions[index + 1]?.x : 0,
      y: gridPositions.length > 0 ? gridPositions[index + 1]?.y + 40 : 0,
      chartChannel: grid.chartChannel ? grid.chartChannel : [],
      width: grid.width ? grid.width : null,
      height: grid.height ? grid.height : null,
    }));

    gridData.map((item) => tempImages.push(item));
    gridData.map((item) => updatedImages.push(item));

    setImages(tempImages);

    toolBarTableData = multipleTable.slice(1).map((table, index) => ({
      id: Math.floor(100000 + Math.random() * 900000),
      name: multipleTable[index + 1].deviceName,
      deviceType: multipleTable[index + 1].type,
      tabIndex: table.tabIndex,
      x: tablePosition.length > 0 ? tablePosition[index + 1]?.x : 0,
      y: tablePosition.length > 0 ? tablePosition[index + 1]?.y + 40 : 0,
      width: table.width ? table.width : null,
      height: table.width ? table.width : null,
      chartChannel: table.chartChannel ? table.chartChannel : [],
      column: table.column ? table.column : null,
    }));

    toolBarTableData.map((item) => tempImages.push(item));
    toolBarTableData.map((item) => updatedImages.push(item));

    setImages(tempImages);

    if (Object.keys(tabIndexOverviewInfo).length <= 0) {
      overviewInfoData = [
        {
          name: "overviewInfo",
          x: overviewInfoPosi ? overviewInfoPosi.x + 20 : null,
          y: overviewInfoPosi ? overviewInfoPosi.y : null,
          tabIndex: 0,
        },
      ];

      overviewInfoData.map((item) => tempImages.push(item));
      overviewInfoData.map((item) => updatedImages.push(item));

      setImages(tempImages);

    }

    if (Object.keys(tabIndexOverviewGraph).length <= 0) {
      overviewGraphData = [
        {
          name: "overviewGraph",
          x: overviewGraphPosi ? overviewGraphPosi.x + 750 : null,
          y: overviewGraphPosi ? overviewGraphPosi.y + 20 : null,
          tabIndex: 0,
          width: overviewGraphDimension ? overviewGraphDimension.width : null,
          height: overviewGraphDimension ? overviewGraphDimension.height : null,
        },
      ];

      overviewGraphData.map((item) => tempImages.push(item));
      overviewGraphData.map((item) => updatedImages.push(item));

      setImages(tempImages);
    }

    if (Object.keys(tabIndexLiveDataGraph).length <= 0) {
      liveGraphData = [
        {
          name: "liveDataGraph",
          x: liveDataGraphPosi ? liveDataGraphPosi.x + 300 : null,
          y: liveDataGraphPosi ? liveDataGraphPosi.y + 150 : null,
          tabIndex: 2,
          width: liveDataGraphDimension ? liveDataGraphDimension.width : null,
          height: liveDataGraphDimension ? liveDataGraphDimension.height : null,
        },
      ];

      liveGraphData.map((item) => tempImages.push(item));
      liveGraphData.map((item) => updatedImages.push(item));

      setImages(tempImages);
    }

    if (Object.keys(tabIndexLastDayTable).length <= 0) {
      lastDayTableData = [
        {
          name: "lastDayTable",
          x: lastDayTablePosi ? lastDayTablePosi.x + 10 : null,
          y: lastDayTablePosi ? lastDayTablePosi.y + 200 : null,
          tabIndex: 3,
          width: lastDayTableDimension ? lastDayTableDimension.width : null,
          height: lastDayTableDimension ? lastDayTableDimension.height : null,
        },
      ];

      lastDayTableData.map((item) => tempImages.push(item));
      lastDayTableData.map((item) => updatedImages.push(item));

      setImages(tempImages);
    }

    if (Object.keys(tabIndexLastDayGraph).length <= 0) {
      lastDayGraphData = [
        {
          name: "lastDayGraph",
          x: lastDayGraphPosi ? lastDayGraphPosi.x + 500 : null,
          y: lastDayGraphPosi ? lastDayGraphPosi.y : null,
          tabIndex: 3,
          width: lastDayGraphDimension ? lastDayGraphDimension.width : null,
          height: lastDayGraphDimension ? lastDayGraphDimension.height : null,
        },
      ];

      lastDayGraphData.map((item) => tempImages.push(item));
      lastDayGraphData.map((item) => updatedImages.push(item));

      setImages(tempImages);
    }

    if (Object.keys(tabIndexDefaultGrid).length <= 0) {
      defaultGridData = [
        {
          name: "DefaultGrid",
          x: defaultGridPosi ? defaultGridPosi.x : null,
          y: defaultGridPosi ? defaultGridPosi.y + 70 : null,
          tabIndex: 1,
          width: defaultGridDimension ? defaultGridDimension.width : null,
          height: defaultGridDimension ? defaultGridDimension.height : null,
        },
      ];

      defaultGridData.map((item) => tempImages.push(item));
      defaultGridData.map((item) => updatedImages.push(item));

      setImages(tempImages);
    }

    // removing the element with index of -1
    let removeIndex = tempImages[-1];

    if (removeIndex) {
      tempImages.splice(-1, 1);
    }

    const filteredTempImages = await removeDuplicates(tempImages);
    await dispatch(
      addWidget({
        filteredTempImages,
        tempTabNames,
        windowHeight,
        windowWidth,
        uniqueProjectId,
      })
    ).then(async (response) => {
      if (response.payload.responseBody.status === 200) {

        toast({
          title: response.payload.responseBody.message,
          status: "success",
          position: "top-right",
          duration: 3000,
          isClosable: true,
        });

        const newWidgets = response.payload.responseBody.widgetId[0]?.widget;

        let widgetWithID = [];
        newWidgets.forEach((widget, index) => {
          const currentTempWidget = updatedImages[index];

          const currentWidget = {
            ...currentTempWidget,
            id: widget.id,
          }

          widgetWithID.push(currentWidget)
        })

        setUpdatedImages(widgetWithID);
        // setTempImages(widgetWithID);

        if (deviceData.length > 0) {

          deviceData?.forEach((device, index) => {

            const { name, x, y, tabIndex, id, channelName, deviceType, unit } =
              device;
            setTempTabIndexDevices(prevState => {
              const allDeviceData = { ...prevState }; // Copy previous state

              if (deviceType === "Indicator") {
                if (!allDeviceData[tabIndex]) {
                  allDeviceData[tabIndex] = [];
                }
                allDeviceData[tabIndex].push({
                  id,
                  x,
                  y,
                  deviceType,
                  name,
                  tabIndex,
                  channelName,
                  unit,
                });
              }

              return allDeviceData; // Update state with the new data
            });

          })
        }


        const newArrayDevice = multipleDevices.slice(0, 1);
        setMultipleDevices(newArrayDevice);

        if (displayData.length > 0) {

          displayData?.forEach((device, index) => {

            const { name, x, y, tabIndex, id, channelName, deviceType, width, height, bgColor, textColor, fontSize } =
              device;
            setTempTabIndexDisplay(prevState => {
              const allDeviceData = { ...prevState }; // Copy previous state

              if (deviceType === "Display") {
                if (!allDeviceData[tabIndex]) {
                  allDeviceData[tabIndex] = [];
                }
                allDeviceData[tabIndex].push({
                  id,
                  x,
                  y,
                  deviceType,
                  name,
                  tabIndex,
                  channelName,
                  width,
                  height,
                  bgColor,
                  textColor,
                  fontSize
                });
              }

              return allDeviceData; // Update state with the new data
            });
          })
        }

        const newArrayDisplay = multipleDisplay.slice(0, 1);
        setMultipleDisplay(newArrayDisplay);

        // adding new controller with previous controller
        // const allControllerData = {};
        if (controllerData.length > 0) {

          controllerData?.forEach((device, index) => {

            const { name, x, y, tabIndex, id, channelName, deviceType, unit } = device;

            setTempTabIndexController(prevState => {
              const allControllerData = { ...prevState }; // Copy previous state
              if (deviceType === "Controller") {
                if (!allControllerData[tabIndex]) {
                  allControllerData[tabIndex] = [];
                }
                allControllerData[tabIndex].push({
                  id,
                  x,
                  y,
                  deviceType,
                  name,
                  tabIndex,
                  channelName,
                  unit,
                });
              }

              return allControllerData; // Update state with the new data
            });

          });
        }

        const newArrayController = multipleController.slice(0, 1);
        setMultipleController(newArrayController);

        // adding new digital IO with previous Digital IO

        if (ioData.length > 0) {

          ioData?.forEach((device, index) => {

            const { name, x, y, id, tabIndex, channelName, deviceType, on } = device;

            setTempTabIndexIO(prevState => {
              const allIOData = { ...prevState }; // Copy previous state

              if (deviceType === "DigitalIO") {
                if (!allIOData[tabIndex]) {
                  allIOData[tabIndex] = [];
                }
                allIOData[tabIndex].push({
                  id,
                  x,
                  y,
                  deviceType,
                  name,
                  tabIndex,
                  channelName,
                  on
                });
              }

              return allIOData; // Update state with the new data
            });

          });
        }

        const newArrayIo = multipleIO.slice(0, 1);
        setMultipleIO(newArrayIo);

        // adding new Flow meter with previous Flow meter

        if (flowMeterData.length > 0) {

          flowMeterData?.forEach((device, index) => {

            const { name, x, y, tabIndex, id, channelName, deviceType, unitPv, unitSv } =
              device;

            setTempTabIndexFlowM(prevState => {
              const allFlowMData = { ...prevState }; // Copy previous state

              if (deviceType === "FlowMeter") {
                if (!allFlowMData[tabIndex]) {
                  allFlowMData[tabIndex] = [];
                }
                allFlowMData[tabIndex].push({
                  id,
                  x,
                  y,
                  deviceType,
                  name,
                  tabIndex,
                  channelName,
                  unitPv,
                  unitSv
                });
              }


              return allFlowMData; // Update state with the new data
            });

          });
        }

        const newArrayFlow = multipleFlowM.slice(0, 1);
        setMultipleFlowM(newArrayFlow);

        // adding new Energy meter with previous Energy Meter

        if (energyMeterData.length > 0) {

          energyMeterData?.forEach((device, index) => {

            const { name, x, y, tabIndex, id, channelName, deviceType, unitPv, unitSv, unitSv2, unitSv3 } =
              device;

            setTempTabIndexEnergyM(prevState => {
              const allEnergyMData = { ...prevState }; // Copy previous state

              if (deviceType === "EnergyMeter") {
                if (!allEnergyMData[tabIndex]) {
                  allEnergyMData[tabIndex] = [];
                }
                allEnergyMData[tabIndex].push({
                  id,
                  x,
                  y,
                  deviceType,
                  name,
                  tabIndex,
                  channelName,
                  unitPv,
                  unitSv,
                  unitSv2,
                  unitSv3
                });
              }

              return allEnergyMData; // Update state with the new data
            });

          });
        }

        const newArrayEnergyM = multipleEnergyM.slice(0, 1);
        setMultipleEnergyM(newArrayEnergyM);




        if (motorData.length > 0) {

          motorData?.forEach((device, index) => {

            const { name, x, y, tabIndex, id, deviceType, width, height, on, channelName } =
              device;

            setTempTabIndexMotor(prevState => {
              const allMotorData = { ...prevState }; // Copy previous state

              if (deviceType === "Motor") {
                if (!allMotorData[tabIndex]) {
                  allMotorData[tabIndex] = [];
                }
                allMotorData[tabIndex].push({
                  id,
                  x,
                  y,
                  deviceType,
                  name,
                  tabIndex,
                  width,
                  height,
                  on,
                  channelName
                });
              }

              return allMotorData; // Update state with the new data
            });

          });
        }

        const newArrayMotor = multipleMotor.slice(0, 1);
        setMultipleMotor(newArrayMotor);

        // For Text

        if (textData.length > 0) {

          textData?.forEach((device, index) => {

            const { name, x, y, tabIndex, id, text, channelName, deviceType, width, height, bgColor, textColor, fontSize, alignHorizontally, alignVertically } =
              device;
            setTempTabIndexText(prevState => {
              const allDeviceData = { ...prevState }; // Copy previous state

              if (deviceType === "Text") {
                if (!allDeviceData[tabIndex]) {
                  allDeviceData[tabIndex] = [];
                }
                allDeviceData[tabIndex].push({
                  id,
                  x,
                  y,
                  deviceType,
                  name,
                  tabIndex,
                  channelName,
                  width,
                  height,
                  bgColor,
                  textColor,
                  fontSize,
                  text,
                  alignHorizontally,
                  alignVertically
                });
              }

              return allDeviceData; // Update state with the new data
            });
          })
        }

        const newArrayText = multipleText.slice(0, 1);
        setMultipleText(newArrayText);

        // adding new line charts with previous line charts

        if (lineChartData.length > 0) {

          lineChartData?.forEach((device, index) => {

            const {
              id,
              name,
              x,
              y,
              tabIndex,
              chartChannel,
              deviceType,
              width,
              height,
              hour,
              minute,
            } = device;

            setTempTabIndexLineChart(prevState => {
              const allLineChartsData = { ...prevState }; // Copy previous state

              if (deviceType === "LineChart") {
                if (!allLineChartsData[tabIndex]) {
                  allLineChartsData[tabIndex] = [];
                }
                allLineChartsData[tabIndex].push({
                  id,
                  name,
                  x,
                  y,
                  tabIndex,
                  chartChannel,
                  deviceType,
                  width,
                  height,
                  hour,
                  minute,
                });
              }

              return allLineChartsData; // Update state with the new data
            });

          });
        }

        const newArrayLineChart = multipleLineCharts.slice(0, 1);
        setMultipleLineCharts(newArrayLineChart);


        // adding new pie charts with previous pie charts 

        if (pieChartData.length > 0) {

          pieChartData?.forEach((device, index) => {

            const {
              id,
              name,
              x,
              y,
              tabIndex,
              chartChannel,
              deviceType,
              width,
              height,
            } = device;

            setTempTabIndexPieChart(prevState => {
              const allPieChartData = { ...prevState }; // Copy previous state

              if (deviceType === "PieChart") {
                if (!allPieChartData[tabIndex]) {
                  allPieChartData[tabIndex] = [];
                }

                allPieChartData[tabIndex].push({
                  id,
                  name,
                  x,
                  y,
                  tabIndex,
                  chartChannel,
                  deviceType,
                  width,
                  height,
                });
              }

              return allPieChartData; // Update state with the new data
            });

          });
        }

        const newArrayPieChart = multiplePieChart.slice(0, 1);
        setMultiplePieChart(newArrayPieChart);

        // adding new bar charts with previous bar charts

        if (toolbarChartData.length > 0) {

          toolbarChartData?.forEach((device, index) => {

            const {
              id,
              name,
              x,
              y,
              tabIndex,
              chartChannel,
              deviceType,
              width,
              height,

            } = device;

            setTempTabIndexBarChart(prevState => {
              const allBarChartData = { ...prevState }; // Copy previous state

              if (deviceType === "BarChart") {
                if (!allBarChartData[tabIndex]) {
                  allBarChartData[tabIndex] = [];
                }
                allBarChartData[tabIndex].push({
                  id,
                  name,
                  x,
                  y,
                  tabIndex,
                  chartChannel,
                  deviceType,
                  width,
                  height,
                });
              }

              return allBarChartData; // Update state with the new data
            });

          });
        }


        const newArrayBarChart = multipleBarChart.slice(0, 1);
        setMultipleBarChart(newArrayBarChart);


        // adding new histogram with previous histogram

        if (toolBarhistogramData.length > 0) {

          toolBarhistogramData?.forEach((device, index) => {

            const {
              id,
              name,
              x,
              y,
              tabIndex,
              chartChannel,
              deviceType,
              width,
              height,
              hour,
              minute,
            } = device;

            setTempTabIndexHistogram(prevState => {
              const allHistogramData = { ...prevState }; // Copy previous state

              if (deviceType === "Histogram") {
                if (!allHistogramData[tabIndex]) {
                  allHistogramData[tabIndex] = [];
                }
                allHistogramData[tabIndex].push({
                  id,
                  name,
                  x,
                  y,
                  tabIndex,
                  chartChannel,
                  deviceType,
                  width,
                  height,
                  hour,
                  minute,
                });
              }

              return allHistogramData; // Update state with the new data
            });

          });
        }

        const newArrayHistogram = multipleHistogram.slice(0, 1);
        setMultipleHistogram(newArrayHistogram);

        // adding new gauge with previous gauge

        if (gaugeData.length > 0) {

          gaugeData?.forEach((device, index) => {

            const {
              id,
              name,
              x,
              y,
              tabIndex,
              chartChannel,
              deviceType,
              maxVal,
              minVal,
              width,
              height,
            } = device;

            setTempTabIndexGauge(prevState => {
              const allGaugeData = { ...prevState }; // Copy previous state

              if (deviceType === "Gauge") {
                if (!allGaugeData[tabIndex]) {
                  allGaugeData[tabIndex] = [];
                }
                allGaugeData[tabIndex].push({
                  id,
                  name,
                  x,
                  y,
                  tabIndex,
                  chartChannel,
                  deviceType,
                  maxVal,
                  minVal,
                  width,
                  height,
                });
              }

              return allGaugeData; // Update state with the new data
            });

          });
        }

        const newArrayGauge = multipleGauge.slice(0, 1);
        setMultipleGauge(newArrayGauge);


        // adding new progress bar with previous progress bar

        if (progressBarData.length > 0) {

          progressBarData?.forEach((device, index) => {

            const {
              id,
              name,
              x,
              y,
              tabIndex,
              chartChannel,
              deviceType,
              maxVal,
              minVal,
              rotate,
              width,
              height,
            } = device;

            setTempTabIndexProgress(prevState => {
              const allProgressBarData = { ...prevState }; // Copy previous state

              if (deviceType === "ProgressBar") {
                if (!allProgressBarData[tabIndex]) {
                  allProgressBarData[tabIndex] = [];
                }
                allProgressBarData[tabIndex].push({
                  id,
                  name,
                  x,
                  y,
                  tabIndex,
                  chartChannel,
                  deviceType,
                  maxVal,
                  minVal,
                  rotate,
                  width,
                  height,
                });
              }


              return allProgressBarData; // Update state with the new data
            });

          });
        }


        const newArrayProgress = multipleProgress.slice(0, 1);
        setMultipleProgress(newArrayProgress);


        if (gridData.length > 0) {

          gridData?.forEach((device, index) => {

            const { id,
              name,
              x,
              y,
              tabIndex,
              chartChannel,
              deviceType,
              width,
              height,
            } = device;

            setTempTabIndexGrid(prevState => {
              const allGridData = { ...prevState }; // Copy previous state

              if (deviceType === "Grid") {
                if (!allGridData[tabIndex]) {
                  allGridData[tabIndex] = [];
                }
                allGridData[tabIndex].push({
                  id,
                  name,
                  x,
                  y,
                  tabIndex,
                  chartChannel,
                  deviceType,
                  width,
                  height,
                });
              }

              return allGridData; // Update state with the new data
            });

          });
        }


        const newArrayGrid = multipleGrid.slice(0, 1);
        setMultipleGrid(newArrayGrid);


        if (toolBarTableData.length > 0) {

          toolBarTableData?.forEach((device, index) => {

            const {
              id,
              name,
              x,
              y,
              tabIndex,
              chartChannel,
              deviceType,
              width,
              height,
              column,
            } = device;

            setTempTabIndexTable(prevState => {
              const allTableData = { ...prevState }; // Copy previous state

              if (deviceType === "Table") {
                if (!allTableData[tabIndex]) {
                  allTableData[tabIndex] = [];
                }
                allTableData[tabIndex].push({
                  id,
                  name,
                  x,
                  y,
                  tabIndex,
                  chartChannel,
                  deviceType,
                  width,
                  height,
                  column,
                });
              }


              return allTableData; // Update state with the new data
            });

          });
        }

        const newArrayTable = multipleTable.slice(0, 1);
        setMultipleTable(newArrayTable);


        // updating position of images
        const newPositionDataImages = {};

        tempImages.forEach((data, index) => {

          const {
            x,
            y,
            width,
            height,
            name,
            tabIndex,
            id,
          } = data;

          if (name && name.toLowerCase().includes("image")) {
            if (!newPositionDataImages[tabIndex]) {
              newPositionDataImages[tabIndex] = [];
            }
            newPositionDataImages[tabIndex].push({
              x,
              y,
              name,
              height,
              tabIndex,
              id,
              width,
            });
          }
        });

        setTabIndexImages(newPositionDataImages);
        // updating position of indicator
        const newPositionDataIndicator = {};
        tempImages.forEach((data, index) => {
          const {
            x,
            y,
            name,
            tabIndex,
            id,
            channelName,
            deviceType,
            unit,
          } = data;
          if (deviceType === "Indicator") {
            if (!newPositionDataIndicator[tabIndex]) {
              newPositionDataIndicator[tabIndex] = [];
            }
            newPositionDataIndicator[tabIndex].push({
              x,
              y,
              name,
              channelName,
              tabIndex,
              id,
              deviceType,
              unit,
            });
          }
        });
        setTabIndexDevices(newPositionDataIndicator);
        setUpdatedTabIndexDevices(newPositionDataIndicator);

        const newPositionDataDisplay = {};
        tempImages.forEach((data, index) => {
          const {
            x,
            y,
            width,
            height,
            name,
            tabIndex,
            id,
            channelName,
            deviceType,
            bgColor,
            textColor,
            fontSize
          } = data;
          if (deviceType === "Display") {
            if (!newPositionDataDisplay[tabIndex]) {
              newPositionDataDisplay[tabIndex] = [];
            }
            newPositionDataDisplay[tabIndex].push({
              x,
              y,
              name,
              channelName,
              tabIndex,
              id,
              deviceType,
              width,
              height,
              bgColor,
              textColor,
              fontSize
            });
          }
        });

        setTabIndexDisplay(newPositionDataDisplay);
        setUpdatedTabIndexDisplay(newPositionDataDisplay);

        //updating position of Controller
        const newPositionDataController = {};
        tempImages.forEach((data, index) => {
          const {
            x,
            y,
            name,
            tabIndex,
            id,
            channelName,
            deviceType,
            unit,
          } = data;
          if (deviceType === "Controller") {
            if (!newPositionDataController[tabIndex]) {
              newPositionDataController[tabIndex] = [];
            }
            newPositionDataController[tabIndex].push({
              x,
              y,
              name,
              channelName,
              tabIndex,
              id,
              deviceType,
              unit,
            });
          }
        });

        setTabIndexController(newPositionDataController);
        setUpdatedTabIndexController(newPositionDataController);

        //updating position of Digital I/O
        const newPositionDataIO = {};
        tempImages.forEach((data, index) => {
          const { x, y, name, tabIndex, id, on, deviceType } = data;
          if (deviceType === "DigitalIO") {
            if (!newPositionDataIO[tabIndex]) {
              newPositionDataIO[tabIndex] = [];
            }
            newPositionDataIO[tabIndex].push({

              x,
              y,
              name,
              on,
              tabIndex,
              id,
              deviceType,
            });
          }
        });

        setTabIndexIO(newPositionDataIO);
        setUpdatedTabIndexIO(newPositionDataIO);

        //updating position of flow meter
        const newPositionDataFlowM = {};
        tempImages.forEach((data, index) => {
          const {
            id,
            x,
            y,
            name,
            tabIndex,
            channelName,
            deviceType,
            unit,
          } = data;
          if (deviceType === "FlowMeter") {
            if (!newPositionDataFlowM[tabIndex]) {
              newPositionDataFlowM[tabIndex] = [];
            }
            newPositionDataFlowM[tabIndex].push({

              x,
              y,
              name,
              channelName,
              tabIndex,
              id,
              deviceType,
              unit,
            });
          }
        });
        setTabIndexFlowM(newPositionDataFlowM);
        setUpdatedTabIndexFlowM(newPositionDataFlowM);

        //updating position of energy meter
        const newPositionDataEnergyM = {};
        tempImages.forEach((data, index) => {
          const {
            x,
            y,
            name,
            tabIndex,
            id,
            channelName,
            deviceType,
            unit,
          } = data;
          if (deviceType === "EnergyMeter") {
            if (!newPositionDataEnergyM[tabIndex]) {
              newPositionDataEnergyM[tabIndex] = [];
            }
            newPositionDataEnergyM[tabIndex].push({
              x,
              y,
              name,
              channelName,
              tabIndex,
              id,
              deviceType,
              unit,
            });
          }
        });
        setTabIndexEnergyM(newPositionDataEnergyM);
        setUpdatedTabIndexEnergyM(newPositionDataEnergyM);


        const newPositionDataMotor = {};
        tempImages.forEach((data, index) => {
          const {
            x,
            y,
            width,
            height,
            name,
            tabIndex,
            id,
            deviceType,
            on,
            channelName
          } = data;
          if (deviceType === "Motor") {
            if (!newPositionDataMotor[tabIndex]) {
              newPositionDataMotor[tabIndex] = [];
            }
            newPositionDataMotor[tabIndex].push({
              x,
              y,
              name,
              width,
              height,
              tabIndex,
              id,
              deviceType,
              on,
              channelName
            });
          }
        });
        setTabIndexMotor(newPositionDataMotor);
        setUpdatedTabIndexMotor(newPositionDataMotor);

        //For text

        const newPositionDataText = {};
        tempImages.forEach((data, index) => {
          const {
            x,
            y,
            width,
            height,
            name,
            tabIndex,
            id,
            channelName,
            text,
            deviceType,
            bgColor,
            textColor,
            fontSize,
            alignHorizontally,
            alignVertically
          } = data;
          if (deviceType === "Text") {
            if (!newPositionDataText[tabIndex]) {
              newPositionDataText[tabIndex] = [];
            }
            newPositionDataText[tabIndex].push({
              x,
              y,
              name,
              channelName,
              tabIndex,
              id,
              deviceType,
              width,
              height,
              bgColor,
              textColor,
              fontSize,
              text,
              alignHorizontally,
              alignVertically
            });
          }
        });

        setTabIndexText(newPositionDataText);
        setUpdatedTabIndexText(newPositionDataText);

        //updating position of Line Chart
        const newPositionDataLineChart = {};
        tempImages.forEach((data, index) => {
          const {
            x,
            y,
            width,
            height,
            name,
            tabIndex,
            id,
            chartChannel,
            deviceType,
            hour,
            minute,
          } = data;
          if (deviceType === "LineChart") {
            if (!newPositionDataLineChart[tabIndex]) {
              newPositionDataLineChart[tabIndex] = [];
            }
            newPositionDataLineChart[tabIndex].push({
              x,
              y,
              name,
              chartChannel,
              tabIndex,
              id,
              deviceType,
              width,
              height,
              hour,
              minute,
            });
          }
        });
        setTabIndexLineChart(newPositionDataLineChart);
        setUpdatedTabIndexLineChart(newPositionDataLineChart);

        //Updating position of Pie chart
        const newPositionDataPieChart = {};

        tempImages.forEach((data, index) => {
          const {
            x,
            y,
            width,
            height,
            name,
            tabIndex,
            id,
            chartChannel,
            deviceType,
          } = data;
          if (deviceType === "PieChart") {
            if (!newPositionDataPieChart[tabIndex]) {
              newPositionDataPieChart[tabIndex] = [];
            }
            newPositionDataPieChart[tabIndex].push({
              x,
              y,
              name,
              chartChannel,
              tabIndex,
              id,
              deviceType,
              width,
              height,
            });
          }
        });

        setTabIndexPieChart(newPositionDataPieChart);
        setUpdatedTabIndexPieChart(newPositionDataPieChart);

        //Updating position of Bar chart
        const newPositionDataBarChart = {};
        tempImages.forEach((data, index) => {
          const {
            x,
            y,
            width,
            height,
            name,
            tabIndex,
            id,
            chartChannel,
            deviceType,
          } = data;
          if (deviceType === "BarChart") {
            if (!newPositionDataBarChart[tabIndex]) {
              newPositionDataBarChart[tabIndex] = [];
            }
            newPositionDataBarChart[tabIndex].push({
              x,
              y,
              name,
              chartChannel,
              tabIndex,
              id,
              deviceType,
              width,
              height,
            });
          }
        });

        setTabIndexBarChart(newPositionDataBarChart);
        setUpdatedTabIndexBarChart(newPositionDataBarChart);

        //Updating position of Histogram
        const newPositionDataHistogram = {};
        tempImages.forEach((data, index) => {
          const {
            x,
            y,
            width,
            height,
            name,
            tabIndex,
            id,
            chartChannel,
            deviceType,
            hour,
            minute,
          } = data;
          if (deviceType === "Histogram") {
            if (!newPositionDataHistogram[tabIndex]) {
              newPositionDataHistogram[tabIndex] = [];
            }
            newPositionDataHistogram[tabIndex].push({
              x,
              y,
              name,
              chartChannel,
              tabIndex,
              id,
              deviceType,
              width,
              height,
              hour,
              minute,
            });
          }
        });
        setTabIndexHistogram(newPositionDataHistogram);
        setUpdatedTabIndexHistogram(newPositionDataHistogram);

        //Updating position of Gauge
        const newPositionDataGauge = {};
        tempImages.forEach((data, index) => {
          const {
            x,
            y,
            name,
            tabIndex,
            id,
            chartChannel,
            deviceType,
            maxVal,
            minVal,
            width,
            height,
          } = data;
          if (deviceType === "Gauge") {
            if (!newPositionDataGauge[tabIndex]) {
              newPositionDataGauge[tabIndex] = [];
            }
            newPositionDataGauge[tabIndex].push({
              x,
              y,
              name,
              chartChannel,
              tabIndex,
              id,
              deviceType,
              minVal,
              maxVal,
              width,
              height,
            });
          }
        });
        setTabIndexGauge(newPositionDataGauge);
        setUpdatedTabIndexGauge(newPositionDataGauge);

        //Updating position of Progress Bar
        const newPositionDataProgress = {};
        tempImages.forEach((data, index) => {
          const {
            x,
            y,
            name,
            tabIndex,
            id,
            chartChannel,
            deviceType,
            maxVal,
            minVal,
            rotate,
            width,
            height,
          } = data;
          if (deviceType === "ProgressBar") {
            if (!newPositionDataProgress[tabIndex]) {
              newPositionDataProgress[tabIndex] = [];
            }
            newPositionDataProgress[tabIndex].push({
              x,
              y,
              name,
              chartChannel,
              tabIndex,
              id,
              deviceType,
              maxVal,
              minVal,
              rotate,
              width,
              height,
            });
          }
        });
        setTabIndexProgress(newPositionDataProgress);
        setUpdatedTabIndexProgress(newPositionDataProgress);

        //Updating position of Grid
        const newPositionDataGrid = {};
        tempImages.forEach((data, index) => {
          const {
            x,
            y,
            name,
            tabIndex,
            id,
            chartChannel,
            deviceType,
            width,
            height,
          } = data;
          if (deviceType === "Grid") {
            if (!newPositionDataGrid[tabIndex]) {
              newPositionDataGrid[tabIndex] = [];
            }
            newPositionDataGrid[tabIndex].push({
              x,
              y,
              name,
              chartChannel,
              tabIndex,
              id,
              deviceType,
              width,
              height,
            });
          }
        });
        setTabIndexGrid(newPositionDataGrid);
        setUpdatedTabIndexGrid(newPositionDataGrid);

        const newPositionDataTable = {};
        tempImages.forEach((data, index) => {
          const {
            x,
            y,
            name,
            tabIndex,
            id,
            chartChannel,
            deviceType,
            width,
            height,
            column,
          } = data;
          if (deviceType === "Table") {
            if (!newPositionDataTable[tabIndex]) {
              newPositionDataTable[tabIndex] = [];
            }
            newPositionDataTable[tabIndex].push({
              x,
              y,
              name,
              chartChannel,
              tabIndex,
              id,
              deviceType,
              width,
              height,
              column,
            });
          }
        });
        setTabIndexTable(newPositionDataTable);
        setUpdatedTabIndexTable(newPositionDataTable);

        const newPositionDataOverviewGraph = {};
        tempImages.forEach((data, index) => {
          const { x, y, name, tabIndex, id, width, height } = data;
          if (name === "overviewGraph") {
            if (!newPositionDataOverviewGraph[tabIndex]) {
              newPositionDataOverviewGraph[tabIndex] = [];
            }
            newPositionDataOverviewGraph[tabIndex].push({
              x,
              y,
              tabIndex,
              id,
              name,
              width,
              height,
            });
          }
        });
        setTabIndexOverviewGraph(newPositionDataOverviewGraph);

        const newPositionDataOverviewInfo = {};

        tempImages.forEach((data, index) => {
          const { x, y, name, tabIndex, id, width, height } = data;
          if (name === "overviewInfo") {
            if (!newPositionDataOverviewInfo[tabIndex]) {
              newPositionDataOverviewInfo[tabIndex] = [];
            }
            newPositionDataOverviewInfo[tabIndex].push({
              x,
              y,
              tabIndex,
              id,
              name,
              width,
              height,
            });
          }
        });
        setTabIndexOverviewInfo(newPositionDataOverviewInfo);

        const newPositionDataLiveDataGraph = {};
        tempImages.forEach((data, index) => {
          const { x, y, tabIndex, name, id, width, height } = data;
          if (name === "liveDataGraph") {
            if (!newPositionDataLiveDataGraph[tabIndex]) {
              newPositionDataLiveDataGraph[tabIndex] = [];
            }
            newPositionDataLiveDataGraph[tabIndex].push({
              x,
              y,
              tabIndex,
              id,
              width,
              height,
              name,
            });
          }
        });
        setTabIndexLiveDataGraph(newPositionDataLiveDataGraph);

        const newPositionDataLastDayTable = {};
        tempImages.forEach((data, index) => {
          const { x, y, tabIndex, name, id, width, height } = data;
          if (name === "lastDayTable") {
            if (!newPositionDataLastDayTable[tabIndex]) {
              newPositionDataLastDayTable[tabIndex] = [];
            }
            newPositionDataLastDayTable[tabIndex].push({
              x,
              y,
              tabIndex,
              id,
              width,
              height,
              name,
            });
          }
        });
        setTabIndexLastDayTable(newPositionDataLastDayTable);

        const newPositionDataLastDayGraph = {};
        tempImages.forEach((data, index) => {
          const { x, y, tabIndex, name, id, width, height } = data;
          if (name === "lastDayGraph") {
            if (!newPositionDataLastDayGraph[tabIndex]) {
              newPositionDataLastDayGraph[tabIndex] = [];
            }
            newPositionDataLastDayGraph[tabIndex].push({
              x,
              y,
              tabIndex,
              id,
              width,
              height,
              name,
            });
          }
        });
        setTabIndexLastDayGraph(newPositionDataLastDayGraph);

        const newPositionDataDefaultGrid = {};
        tempImages.forEach((data, index) => {
          const { x, y, tabIndex, name, id, width, height } = data;
          if (name === "DefaultGrid") {
            if (!newPositionDataDefaultGrid[tabIndex]) {
              newPositionDataDefaultGrid[tabIndex] = [];
            }
            newPositionDataDefaultGrid[tabIndex].push({
              x,
              y,
              tabIndex,
              id,
              width,
              height,
              name,
            });
          }
        });
        setTabIndexDefaultGrid(newPositionDataDefaultGrid);
      }
    });

  };


  useEffect(() => {

    // saved widgets
    setTempTabIndexImages(updatedTabIndexImages);
    setTempTabIndexDevices(updatedTabIndexDevices);
    setTempTabIndexController(updatedTabIndexController);
    setTempTabIndexIO(updatedTabIndexIO);
    setTempTabIndexFlowM(updatedTabIndexFlowM);
    setTempTabIndexEnergyM(updatedTabIndexEnergyM);
    setTempTabIndexLineChart(updatedTabIndexLineChart);
    setTempTabIndexPieChart(updatedTabIndexPieChart);
    setTempTabIndexBarChart(updatedTabIndexBarChart);
    setTempTabIndexHistogram(updatedTabIndexHistogram);
    setTempTabIndexGauge(updatedTabIndexGauge);
    setTempTabIndexProgress(updatedTabIndexProgress);
    setTempTabIndexGrid(updatedTabIndexGrid);
    setTempTabIndexTable(updatedTabIndexTable);
    setTempTabIndexMotor(updatedTabIndexMotor);
    setTempTabIndexDisplay(updatedTabIndexDisplay);
    setTempTabIndexText(updatedTabIndexText);

  }, [activeTabIndex]);

  useEffect(() => {
    console.log(tempImages);
  }, [tempImages])

  // For Devices

  // Functions to generate saved devices/charts

  // To generate indicator widget in this page according saved values

  const TempGenerateDevice = () => {
    const selectedTabDevices = tempTabIndexDevices[activeTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, name, channelName, id, deviceType, unit, } =
          device;

        const deviceStyle = {
          backgroundColor: "rgb(20, 17, 15)",
          width: "20%",
          position: "absolute",
          top: y + "px",
          left: x + "px",
          borderRadius: "0.625rem",
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          padding: "0.6rem",
        };

        return (
          <Draggble
            bounds="parent"
            onStop={(e, data) =>
              tempHandleDeviceDrop(e, data, index, id, x, y, deviceType)
            }

          >
            <div
              style={deviceStyle}
              key={index}
              ref={el => indicatorRefs.current[index] = el}
              onContextMenu={(event) =>
                handleDeviceMenu(event, index, device, deviceType)
              }
              className={styles.addImg}
            >
              <Devices
                name={name}
                openNewPage={openNewPage}
                pvSpecificChannel={pvSpecificChannel}
                tableData={tableData}
                channelName={channelName.PV}
                tempTabIndexDevices={tempTabIndexDevices}
                clickedDeviceIndex={clickedDeviceIndex}
                selectedDevice={selectedDevice}
                activeTabIndex={activeTabIndex}
                currentPVChannel={currentPVChannel}
                unit={unit}
                index={index}
                tempImages={tempImages}
                setTempImages={setTempImages}
                id={id}
                isMobile={isMobile}
              />
            </div>
          </Draggble>
        );
      });
    }
  };

  // To generate display widget in this page according saved values

  const TempGenerateDisplay = () => {
    const selectedTabDevices = tempTabIndexDisplay[activeTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, channelName, id, deviceType, width, height, bgColor, textColor, fontSize } =
          device;

        const deviceStyle = {
          position: "absolute",
          top: y + "px",
          left: x + "px",
          borderRadius: "0.625rem",
          backgroundColor: bgColor,
          padding: "0.6rem 1rem",
          color: textColor,
          fontSize: fontSize ? fontSize + "px" : "30px",
          width: width ? width : "12%",
          height: height ? height : undefined,
          resize: "both"
        };

        return (
          <Draggble
            bounds="parent"
            onStop={(e, data) =>
              tempHandleDeviceDrop(e, data, index, id, x, y, deviceType)
            }

          >
            <div
              style={deviceStyle}
              key={index}
              ref={el => displayRefs.current[index] = el}
              onContextMenu={(event) =>
                handleDeviceMenu(event, index, device, deviceType, id, bgColor, textColor, fontSize)
              }
              className={styles.addImg}
              id={`display-${index}`}
            >
              <DeviceDisplay
                openNewPage={openNewPage}
                tableData={tableData}
                channelName={channelName.PV}

              />
            </div>
          </Draggble>
        );
      });
    }
  };

  // To generate controller widget in this page according saved values

  const TempGenerateController = () => {
    const selectedTabDevices = tempTabIndexController[activeTabIndex];

    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, name, channelName, tabIndex, id, deviceType, unit } =
          device;
        const deviceStyle = {
          backgroundColor: "rgb(20, 17, 15)",
          width: "20%",
          position: "absolute",
          top: y + "px",
          left: x + "px",
          borderRadius: "0.625rem",
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          padding: "0.6rem",
        };

        return (
          <Draggble
            bounds="parent"
            onStop={(e, data) =>
              tempHandleDeviceDrop(e, data, index, id, x, y, deviceType)
            }
          >
            <div
              style={deviceStyle}
              key={index}
              ref={el => controllerRefs.current[index] = el}
              onContextMenu={(event) =>
                handleDeviceMenu(event, index, device, deviceType)
              }
              className={styles.addImg}
              onDoubleClick={(e) => handleDoubleClick(e, deviceType, id)}
            >
              <DeviceController
                images={images}
                name={name}
                openNewPage={openNewPage}
                tabIndexController={tabIndexController}
                tempTabIndexController={tempTabIndexController}
                setTempTabIndexController={setTempTabIndexController}
                activeTabIndex={activeTabIndex}
                clickedDeviceIndex={clickedDeviceIndex}
                selectedDevice={selectedDevice}
                tableData={tableData}
                pvSpecificChannel={pvSpecificChannel}
                channelName={channelName}
                deviceType={deviceType}
                currentPVChannel={currentPVController}
                currentSVChannel={currentSVContoller}
                unit={unit}
                index={index}
                id={id}
                tabIndex={tabIndex}
                isMobile={isMobile}
                tempImages={tempImages}
                setTempImages={setTempImages}
              />
            </div>
          </Draggble>
        );
      });
    }
  };

  // To generate digital i/o widget in this page according saved values

  const tempGenerateIO = () => {
    const selectedTabDevices = tempTabIndexIO[activeTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, name, id, deviceType, on } =
          device;
        const deviceStyle = {
          width: "190px",
          height: "160px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.5rem",
          position: "absolute",
          top: y + "px",
          left: x + "px",
        };

        return (
          <Draggble
            bounds="parent"
            onStop={(e, data) =>
              tempHandleDeviceDrop(e, data, index, id, x, y, deviceType)
            }
          >
            <div
              style={deviceStyle}
              key={index}
              onContextMenu={(event) =>
                handleDeviceMenu(event, index, device, deviceType)
              }
              ref={el => ioRefs.current[index] = el}
              className={styles.addImg}
              onDoubleClick={(e) => handleDoubleClick(e, deviceType, id, on)}
            >
              <DeviceIO
                name={name}
                openNewPage={openNewPage}
                tabIndexController={tabIndexController}
                activeTabIndex={activeTabIndex}
                clickedDeviceIndex={clickedDeviceIndex}
                selectedDevice={selectedDevice}
                tableData={tableData}
                deviceType={deviceType}
                on={on}
              />
            </div>
          </Draggble>
        );
      });
    }
  };

  // To generate flow meter widget in this page according saved values

  const TempGenerateFlowMeter = () => {
    const selectedTabDevices = tempTabIndexFlowM[activeTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, name, channelName, id, deviceType, unitPv, unitSv } =
          device;
        const deviceStyle = {
          backgroundColor: "rgb(20, 17, 15)",
          width: "25%",
          position: "absolute",
          top: y + "px",
          left: x + "px",
          borderRadius: "0.625rem",
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          padding: "0.6rem",
        };

        return (
          <Draggble
            bounds="parent"
            onStop={(e, data) =>
              tempHandleDeviceDrop(e, data, index, id, x, y, deviceType)
            }
          >
            <div
              style={deviceStyle}
              key={index}
              ref={el => flowMRefs.current[index] = el}
              onContextMenu={(event) =>
                handleDeviceMenu(event, index, device, deviceType)
              }
              className={styles.addImg}
            >
              <DeviceFlowMeter
                name={name}
                openNewPage={openNewPage}
                tempTabIndexFlowM={tempTabIndexFlowM}
                index={index}
                unitPv={unitPv}
                unitSv={unitSv}
                images={images}
                activeTabIndex={activeTabIndex}
                clickedDeviceIndex={clickedDeviceIndex}
                selectedDevice={selectedDevice}
                tableData={tableData}
                pvSpecificChannel={pvSpecificChannel}
                channelName={channelName}
                deviceType={deviceType}
                currentPVChannel={currentPVFlowM}
                currentSVChannel={currentSVFlowM}
                id={id}
                isMobile={isMobile}
                tempImages={tempImages}
                setTempImages={setTempImages}
              />
            </div>
          </Draggble>
        );
      });
    }
  };

  // To generate energy meter widget in this page according saved values

  const TempGenerateEnergyNeter = () => {
    const selectedTabDevices = tempTabIndexEnergyM[activeTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, name, channelName, id, deviceType, unitPv, unitSv, unitSv2, unitSv3 } =
          device;
        const deviceStyle = {
          backgroundColor: "rgb(20, 17, 15)",
          width: "25%",
          position: "absolute",
          top: y + "px",
          left: x + "px",
          borderRadius: "0.625rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          padding: "0.6rem",
        };

        return (
          <Draggble
            bounds="parent"
            onStop={(e, data) =>
              tempHandleDeviceDrop(e, data, index, id, x, y, deviceType)
            }
          >
            <div
              style={deviceStyle}
              key={index}
              ref={el => energyMRefs.current[index] = el}

              onContextMenu={(event) =>
                handleDeviceMenu(event, index, device, deviceType)
              }
              className={styles.addImg}
            >
              <DeviceEnergyMeter
                name={name}
                openNewPage={openNewPage}
                images={images}
                tempTabIndexEnergyM={tempTabIndexEnergyM}
                activeTabIndex={activeTabIndex}
                clickedDeviceIndex={clickedDeviceIndex}
                selectedDevice={selectedDevice}
                tableData={tableData}
                pvSpecificChannel={pvSpecificChannel}
                channelName={channelName}
                deviceType={deviceType}
                currentPVChannel={currentPVEnergyM}
                currentSVChannel={currentSVEnergyM}
                index={index}
                id={id}
                unitPv={unitPv}
                unitSv={unitSv}
                unitSv2={unitSv2}
                unitSv3={unitSv3}
                tempImages={tempImages}
                setTempImages={setTempImages}
              />
            </div>
          </Draggble>
        );
      });
    }
  };

  // To generate motor widget in this page according saved values

  const TempGenerateMotor = () => {
    const selectedTabImages = tempTabIndexMotor[activeTabIndex];
    if (Array.isArray(selectedTabImages)) {
      return selectedTabImages.map((device, index) => {
        const { x, y, width, height, name, id, deviceType } = device;
        const imageStyle = {
          display: 'flex',
          position: 'absolute',
          top: y + 'px',
          left: x + 'px',
          width: width ? width : "250px",
          height: height ? height : "150px",
          scale: isMobile ? scaleValue.toString() : "1",
          resize: "both"
        };

        return (
          <Draggble
            bounds="parent"
            onStop={(e, data) =>
              tempHandleDeviceDrop(e, data, index, id, x, y, deviceType)
            }>

            <div
              style={imageStyle} key={index}
              className={styles.addImg}
              ref={el => motorRefs.current[index] = el}
              onContextMenu={(event) =>
                handleDeviceMenu(event, index, device, deviceType, id)
              }
              id={`motor-${index}`}
            >
              {device.on ? (

                <img
                  src={motorgif}
                  alt="MotorImg"
                />

              ) : (

                <img
                  src={name}
                  alt="MotorImg"
                />

              )}

            </div>
          </Draggble>
        );
      });
    }
  };

  // To generate text widget in this page according saved values

  const TempGenerateText = () => {

    const selectedTabDevices = tempTabIndexText[activeTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, id, deviceType, width, height, bgColor, textColor, text, fontSize, alignHorizontally, alignVertically } =
          device;

        const deviceStyle = {
          position: "absolute",
          top: y + 'px',
          left: x + 'px',
          backgroundColor: bgColor,
          color: textColor,
          padding: "0.6rem 1rem",
          fontSize: fontSize ? fontSize + "px" : "30px",
          width: width ? width : undefined,
          height: height ? height : undefined,
          scale: isMobile ? scaleValue.toString() : "1",
          resize: "both",
          display: "flex",
          wordBreak: "break-word",
          justifyContent: alignHorizontally ? alignHorizontally : "start",
          alignItems: alignVertically ? alignVertically : "start"
        };

        return (
          <Draggble
            bounds="parent"
            onStop={(e, data) =>
              tempHandleDeviceDrop(e, data, index, id, x, y, deviceType)
            }
          >

            <div style={deviceStyle}
              className={styles.addImg}
              ref={el => textRefs.current[index] = el}
              key={index}
              onContextMenu={(event) =>
                handleDeviceMenu(event, index, device, deviceType, id, bgColor, textColor, fontSize, text, alignVertically, alignHorizontally)
              }
              id={`text-${index}`}
            >
              {text}
            </div>
          </Draggble>
        );
      });
    }
  };

  // To generate line chart widget in this page according saved values

  const TempGenerateLineChart = () => {
    const selectedTabDevices = tempTabIndexLineChart[activeTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const {
          x,
          y,
          id,
          deviceType,
          chartChannel,
          width,
          hour,
          minute,
        } = device;

        const deviceStyle = {
          position: "absolute",
          top: y + "px",
          left: x + "px",
          backgroundColor: "#fff",
          width: width ? width : "40%",
          resize: "both",
          borderRadius: ".625rem",
        };

        return (
          <Draggble
            bounds="parent"
            onStop={(e, data) =>
              tempHandleDeviceDrop(e, data, index, id, x, y, deviceType)
            }
          >
            <div
              style={deviceStyle}
              key={index}
              ref={el => lineChartRefs.current[index] = el}
              onContextMenu={(event) =>
                handleDeviceMenu(event, index, device, deviceType, id)
              }
              className={styles.addImg}
              id={`lineChart-${index}`}
            >
              <LineChart
                dateTimesLive={dateTimesLive}
                channelNames={channelNames}
                dataArrLive={dataArrLive}
                getRandomColor={getRandomColor}
                chartChannel={chartChannel}
                tabIndexBarChart={tabIndexBarChart}
                selectedTabIndex={activeTabIndex}
                openNewPage={openNewPage}
                selectedDevice={selectedDevice}
                clickedDeviceIndex={clickedDeviceIndex}
                data={data}
                hour={hour}
                minute={minute}
                oneDayDataTs={oneDayDataTs}
              />
            </div>
          </Draggble>
        );
      });
    }
  };

  // To generate pie chart widget in this page according saved values

  const TempGeneratePieChart = () => {
    const selectedTabDevices = tempTabIndexPieChart[activeTabIndex];

    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, id, deviceType, chartChannel, width } = device;
        const deviceStyle = {
          position: "absolute",
          top: y + "px",
          left: x + "px",
          resize: "both",
          width: width ? width : "27%",
        };
        return (
          <Draggble
            bounds="parent"
            onStop={(e, data) =>
              tempHandleDeviceDrop(e, data, index, id, x, y, deviceType)
            }
          >
            <div
              style={deviceStyle}
              key={index}
              ref={el => pieChartRefs.current[index] = el}
              onContextMenu={(event) =>
                handleDeviceMenu(event, index, device, deviceType, id)
              }
              className={styles.addImg}
              id={`pieChart-${index}`}
            >
              <PieChart
                dateTimesLive={dateTimesLive}
                channelNames={channelNames}
                dataArrLive={dataArrLive}
                getRandomColor={getRandomColor}
                chartChannel={chartChannel}
                tabIndexPieChart={tabIndexPieChart}
                selectedTabIndex={activeTabIndex}
                openNewPage={openNewPage}
                selectedDevice={selectedDevice}
                clickedDeviceIndex={clickedDeviceIndex}
              />
            </div>
          </Draggble>
        );
      });
    }
  };

  // To generate bar chart widget in this page according saved values

  const TempGenerateBarChart = () => {
    const selectedTabDevices = tempTabIndexBarChart[activeTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, id, deviceType, chartChannel, width } = device;
        const deviceStyle = {

          backgroundColor: "#fff",
          position: "absolute",
          top: y + "px",
          left: x + "px",
          width: width ? width : "40%",
          resize: "both",
          borderRadius: ".625rem",

        };

        return (
          <Draggble
            bounds="parent"
            onStop={(e, data) =>
              tempHandleDeviceDrop(e, data, index, id, x, y, deviceType)
            }
          >
            <div
              style={deviceStyle}
              key={index}
              ref={el => barChartRefs.current[index] = el}

              onContextMenu={(event) =>
                handleDeviceMenu(event, index, device, deviceType, id)
              }
              className={styles.addImg}
              id={`barChart-${index}`}
            >
              <BarChart
                dateTimesLive={dateTimesLive}
                channelNames={channelNames}
                dataArrLive={dataArrLive}
                getRandomColor={getRandomColor}
                setBarChartData={setBarChartData}
                chartChannel={chartChannel}
                tabIndexBarChart={tabIndexBarChart}
                selectedTabIndex={activeTabIndex}
                barChartData={barChartData}
                openNewPage={openNewPage}
                selectedDevice={selectedDevice}
                clickedDeviceIndex={clickedDeviceIndex}
              />
            </div>
          </Draggble>
        );
      });
    }
  };

  // To generate histogram widget in this page according saved values

  const TempGenerateHistogram = () => {
    const selectedTabDevices = tempTabIndexHistogram[activeTabIndex];

    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {

        const {
          x,
          y,
          id,
          deviceType,
          chartChannel,
          width,
          hour,
          minute,
        } = device;

        const deviceStyle = {
          backgroundColor: "#fff",
          position: "absolute",
          top: y + "px",
          left: x + "px",
          width: width ? width : "40%",
          resize: "both",
          borderRadius: ".625rem",

        };

        return (
          <Draggble
            bounds="parent"
            onStop={(e, data) =>
              tempHandleDeviceDrop(e, data, index, id, x, y, deviceType)
            }
          >
            <div
              style={deviceStyle}
              key={index}
              ref={el => histogramRefs.current[index] = el}

              onContextMenu={(event) =>
                handleDeviceMenu(event, index, device, deviceType, id)
              }
              className={styles.addImg}
              id={`histogram-${index}`}
            >
              <Histogram
                dateTimesLive={dateTimesLive}
                channelNames={channelNames}
                dataArrLive={dataArrLive}
                getRandomColor={getRandomColor}
                setHistogramData={setHistogramData}
                chartChannel={chartChannel}
                tabIndexBarChart={tabIndexBarChart}
                selectedTabIndex={activeTabIndex}
                histogramData={histogramData}
                openNewPage={openNewPage}
                selectedDevice={selectedDevice}
                clickedDeviceIndex={clickedDeviceIndex}
                hour={hour}
                minute={minute}
                oneDayDataTs={oneDayDataTs}
              />
            </div>
          </Draggble>
        );
      });
    }
  };

  // To generate gauge widget in this page according saved values

  const TempGenerateGauge = () => {
    const selectedTabDevices = tempTabIndexGauge[activeTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const {
          x,
          y,
          id,
          deviceType,
          chartChannel,
          minVal,
          maxVal,
          width,
          height,
        } = device;
        const chartChannelArray =
          typeof chartChannel === "string" ? [chartChannel] : chartChannel;

        const deviceStyle = {
          position: "absolute",
          top: y + "px",
          left: x + "px",
          width: width ? width : "20%",
          height: height,
          resize: "both",
        };

        return (
          <Draggble
            bounds="parent"
            onStop={(e, data) =>
              tempHandleDeviceDrop(e, data, index, id, x, y, deviceType)
            }
          >
            <div
              style={deviceStyle}
              key={index}
              ref={el => gaugeRefs.current[index] = el}

              onContextMenu={(event) =>
                handleDeviceMenu(event, index, device, deviceType, id)
              }
              className={styles.addImg}
              id={`gauge-${index}`}
            >
              <Gauge
                dateTimesLive={dateTimesLive}
                channelNames={channelNames}
                dataArrLive={dataArrLive}
                getRandomColor={getRandomColor}
                chartChannel={chartChannelArray}
                tabIndexBarChart={tabIndexBarChart}
                selectedTabIndex={activeTabIndex}
                openNewPage={openNewPage}
                selectedDevice={selectedDevice}
                clickedDeviceIndex={clickedDeviceIndex}
                minVal={minVal}
                maxVal={maxVal}
                specificMaxVal={specificMaxVal}
                specificMinVal={specificMinVal}
                changeRange={changeRange}
                id={id}
              />
            </div>
          </Draggble>
        );
      });
    }
  };

  // To generate progressbar widget in this page according saved values

  const TempGenerateProgressBar = () => {
    const selectedTabDevices = tempTabIndexProgress[activeTabIndex];

    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const {
          x,
          y,
          id,
          deviceType,
          chartChannel,
          minVal,
          maxVal,
          rotate,
          width,
          height,
        } = device;

        const chartChannelArray =
          typeof chartChannel === "string" ? [chartChannel] : chartChannel;
        const deviceStyle = {
          position: "absolute",
          top: y + "px",
          left: x + "px",
          display: rotate ? "flex" : null,
          justifyContent: rotate ? "center" : null,
          alignItems: rotate ? "center" : null,
          height: height ? height : "",
          width: width ? width : "20%",
          resize: "both",
        };

        return (
          <Draggble
            bounds="parent"
            onStop={(e, data) =>
              tempHandleDeviceDrop(e, data, index, id, x, y, deviceType)
            }
          >
            <div
              style={deviceStyle}
              key={index}
              ref={el => progressBarRefs.current[index] = el}

              onContextMenu={(event) =>
                handleDeviceMenu(event, index, device, deviceType, id)
              }
              className={styles.addImg}
              id={`progressBar-${index}`}
            >
              <ProgressBar
                dateTimesLive={dateTimesLive}
                channelNames={channelNames}
                dataArrLive={dataArrLive}
                getRandomColor={getRandomColor}
                chartChannel={chartChannelArray}
                tabIndexBarChart={tabIndexBarChart}
                selectedTabIndex={activeTabIndex}
                openNewPage={openNewPage}
                selectedDevice={selectedDevice}
                clickedDeviceIndex={clickedDeviceIndex}
                minVal={minVal}
                maxVal={maxVal}
                rotate={rotate}
              />
            </div>
          </Draggble>
        );
      });
    }
  };

  // To generate grid widget in this page according saved values

  const TempGenerateGrid = () => {
    const selectedTabDevices = tempTabIndexGrid[activeTabIndex];
    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, id, deviceType, chartChannel, width, height } = device;

        const deviceStyle = {
          position: "absolute",
          top: y + "px",
          left: x + "px",
          resize: "both",
          width: width ? width : "auto",
          height: height ? height : "auto",
        };

        return (
          <Draggble
            bounds="parent"
            onStop={(e, data) =>
              tempHandleDeviceDrop(e, data, index, id, x, y, deviceType)
            }
          >
            <div
              style={deviceStyle}
              key={index}
              ref={el => gridRefs.current[index] = el}

              onContextMenu={(event) =>
                handleDeviceMenu(event, index, device, deviceType, id)
              }
              className={styles.addImg}
              id={`grid-${index}`}
            >
              <ToolBarGrid tableData={tableData} chartChannel={chartChannel} />
            </div>
          </Draggble>
        );
      });
    }
  };

  // To generate table widget in this page according saved values

  const TempGenerateTable = () => {
    const selectedTabDevices = tempTabIndexTable[activeTabIndex];

    if (Array.isArray(selectedTabDevices)) {
      return selectedTabDevices.map((device, index) => {
        const { x, y, deviceType, id, width, chartChannel, column, height } =
          device;
        const deviceStyle = {
          position: "absolute",
          top: y + "px",
          left: x + "px",
          resize: "both",
          width: width ? width : "auto",
          height: height ? height : "auto",
        };

        return (
          <Draggble
            bounds="parent"
            onStop={(e, data) =>
              tempHandleDeviceDrop(e, data, index, id, x, y, deviceType)
            }
          >
            <div
              style={deviceStyle}
              key={index}
              ref={el => tableRefs.current[index] = el}
              onContextMenu={(event) =>
                handleDeviceMenu(event, index, device, deviceType, id)
              }
              className={styles.addImg}
              id={`table-${index}`}
            >
              <MovableTable
                dateTimesLive={dateTimesLive}
                channelNames={channelNames}
                dataArrLive={dataArrLive}
                getRandomColor={getRandomColor}
                tabIndexBarChart={tabIndexBarChart}
                selectedTabIndex={activeTabIndex}
                openNewPage={openNewPage}
                selectedDevice={selectedDevice}
                clickedDeviceIndex={clickedDeviceIndex}
                chartChannel={chartChannel}
                column={column}
              />
            </div>
          </Draggble>
        );
      });
    }
  };

  // Function to set width/height in stats annd handle contenxt menu
  const handleDeviceMenu = (event, index, device, deviceType, id, bgColor, textColor, fontSize, text, alignVertically, alignHorizontally) => {
    event.preventDefault();

    if (text) {
      deviceType === "Text" ? setAddedText(text) : setAddedText("");
    } else {
      setAddedText("");
    }

    if (bgColor) {
      deviceType === "Display" ? setBgColor(bgColor) : setTextBgColor(bgColor);
    } else {
      deviceType === "Display" ? setBgColor("#542A2A") : setTextBgColor("#ffffff");
    }

    if (textColor) {
      deviceType === "Display" ? setTextColor(textColor) : setWidgetTextColor(textColor);
    } else {
      deviceType === "Display" ? setTextColor('#D90211') : setWidgetTextColor("#000000");
    }

    if (fontSize) {
      deviceType === "Display" ? setFontSize(fontSize) : setTextFontSize(fontSize);
    } else {
      deviceType === "Display" ? setFontSize(30) : setTextFontSize(30);

    }

    if (alignVertically) {
      setTextAlignVertically(alignVertically);
    } else {
      setTextAlignVertically('')
    }

    if (alignHorizontally) {
      setTextAlignHorizontally(alignHorizontally);
    } else {
      setTextAlignHorizontally('')
    }

    const x = event.clientX;
    const y = event.clientY;
    setDeviceMenuPosition({ x, y });
    setClickedDeviceIndex(index);
    setIsDeviceMenu(true);
    if (id) {
      setSelectedDeviceId(id);
    }

    setSelectedDevice(device);
    setCurrentDevice(deviceType);
    const updatedTempImages = [...tempImages];

    if (deviceType === "Display") {

      if (Object.keys(tempTabIndexDisplay).length > 0) {
        const particularDevice = tempTabIndexDisplay[activeTabIndex];
        if (particularDevice !== undefined) {
          const selectedChart = document.getElementById(`display-${index}`);
          if (device?.id) {

            if (
              tempTabIndexDisplay[activeTabIndex].some(
                (device) => device.id === id
              )
            ) {
              if (particularDevice[index].id === id) {
                const originalDeviceIndex = tempImages.findIndex(
                  (data) => data.id === id
                );

                const newWidth = selectedChart?.style?.width;
                const newHeight = selectedChart?.style?.height;

                const updatedData = {
                  ...updatedTempImages[originalDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };

                updatedTempImages[originalDeviceIndex] = updatedData;

                setTempImages(updatedTempImages);
                setUpdatedImages(updatedTempImages)

                let oldtabIndexItem = [...updatedTabIndexDisplay[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexItem.findIndex(
                  (data) => data.id === id
                );
                const newUpdatedData = {
                  ...oldtabIndexItem[organizedDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };

                oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexItem,
                };

                setUpdatedTabIndexDisplay(newTempTabIndexDevices);
              }
            }
          }
        }
      }
      if (multipleDisplay.length > 1) {
        if (!device?.id) {
          const selectedChart = document.getElementById(`newDisplay-${index}`);
          const newWidth = selectedChart.style.width;
          const newHeight = selectedChart.style.height;
          const updatedDeviceName = [...multipleDisplay];
          const deviceToUpdate = multipleDisplay.find(
            (element, chartIndex) => chartIndex === index
          );
          deviceToUpdate.width = newWidth;
          deviceToUpdate.height = newHeight;

          updatedDeviceName[index] = deviceToUpdate;
          setMultipleDisplay(updatedDeviceName);
        }
      }
    }

    // Applying width and height for the text
    if (deviceType === "Text") {

      if (Object.keys(tempTabIndexText).length > 0) {
        const particularDevice = tempTabIndexText[activeTabIndex];
        if (particularDevice !== undefined) {
          const selectedChart = document.getElementById(`text-${index}`);
          if (device?.id) {

            if (
              tempTabIndexText[activeTabIndex].some(
                (device) => device.id === id
              )
            ) {
              if (particularDevice[index].id === id) {
                const originalDeviceIndex = tempImages.findIndex(
                  (data) => data.id === id
                );

                const newWidth = selectedChart?.style?.width;
                const newHeight = selectedChart?.style?.height;

                const updatedData = {
                  ...updatedTempImages[originalDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };

                updatedTempImages[originalDeviceIndex] = updatedData;

                setTempImages(updatedTempImages);
                setUpdatedImages(updatedTempImages)

                let oldtabIndexItem = [...updatedTabIndexText[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexItem.findIndex(
                  (data) => data.id === id
                );
                const newUpdatedData = {
                  ...oldtabIndexItem[organizedDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };

                oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexItem,
                };

                setUpdatedTabIndexText(newTempTabIndexDevices);
              }
            }
          }
        }
      }

      if (multipleText.length > 1) {
        if (!device?.id) {
          const selectedChart = document.getElementById(`newText-${index}`);
          const newWidth = selectedChart.style.width;
          const newHeight = selectedChart.style.height;
          const updatedDeviceName = [...multipleText];
          const deviceToUpdate = multipleText.find(
            (element, chartIndex) => chartIndex === index
          );
          deviceToUpdate.width = newWidth;
          deviceToUpdate.height = newHeight;

          updatedDeviceName[index] = deviceToUpdate;
          setMultipleText(updatedDeviceName);
        }
      }
    }

    if (deviceType === "Motor") {

      if (Object.keys(tempTabIndexMotor).length > 0) {
        const particularDevice = tempTabIndexMotor[activeTabIndex];
        if (particularDevice !== undefined) {
          const selectedChart = document.getElementById(`motor-${index}`);
          const newWidth = selectedChart.style.width;
          const newHeight = selectedChart.style.height;
          if (device?.id) {

            if (
              tempTabIndexMotor[activeTabIndex].some(
                (device) => device.id === id
              )
            ) {
              if (particularDevice[index].id === id) {
                const originalDeviceIndex = tempImages.findIndex(
                  (data) => data.id === id
                );

                const updatedData = {
                  ...updatedTempImages[originalDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };

                updatedTempImages[originalDeviceIndex] = updatedData;

                setTempImages(updatedTempImages);
                setUpdatedImages(updatedTempImages)


                let oldtabIndexItem = [...updatedTabIndexMotor[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexItem.findIndex(
                  (data) => data.id === id
                );
                const newUpdatedData = {
                  ...oldtabIndexItem[organizedDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };

                oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexItem,
                };

                setUpdatedTabIndexMotor(newTempTabIndexDevices);
              }
            }
          }
        }
      }
      if (multipleMotor.length > 1) {
        if (!device?.id) {
          const selectedChart = document.getElementById(`motor-${index}`);
          const newWidth = selectedChart.style.width;
          const newHeight = selectedChart.style.height;
          const updatedDeviceName = [...multipleMotor];
          const deviceToUpdate = multipleMotor.find(
            (element, chartIndex) => chartIndex === index
          );
          deviceToUpdate.width = newWidth;
          deviceToUpdate.height = newHeight;

          updatedDeviceName[index] = deviceToUpdate;
          setMultipleMotor(updatedDeviceName);
        }
      }
    }

    if (deviceType === "PieChart") {
      if (Object.keys(tempTabIndexPieChart).length > 0) {
        const particularDevice = tempTabIndexPieChart[activeTabIndex];
        if (particularDevice !== undefined) {
          const selectedChart = document.getElementById(`pieChart-${index}`);
          if (device?.id) {

            if (
              tempTabIndexPieChart[activeTabIndex].some(
                (device) => device.id === id
              )
            ) {
              if (particularDevice[index].id === id) {
                const originalDeviceIndex = tempImages.findIndex(
                  (data) => data.id === id
                );
                const newWidth = selectedChart.style.width;
                const newHeight = selectedChart.style.height;
                const updatedData = {
                  ...updatedTempImages[originalDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };
                updatedTempImages[originalDeviceIndex] = updatedData;
                setTempImages(updatedTempImages);
                setUpdatedImages(updatedTempImages)


                let oldtabIndexItem = [...updatedTabIndexPieChart[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexItem.findIndex(
                  (data) => data.id === id
                );
                const newUpdatedData = {
                  ...oldtabIndexItem[organizedDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };

                oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexItem,
                };

                setUpdatedTabIndexPieChart(newTempTabIndexDevices);
              }
            }
          }
        }
      }
      if (multiplePieChart.length > 1) {
        if (!device?.id) {
          const selectedChart = document.getElementById(`newPieChart-${index}`);
          const newWidth = selectedChart.style.width;
          const newHeight = selectedChart.style.height;
          const updatedDeviceName = [...multiplePieChart];
          const deviceToUpdate = multiplePieChart.find(
            (element, chartIndex) => chartIndex === index
          );
          deviceToUpdate.width = newWidth;
          deviceToUpdate.height = newHeight;

          updatedDeviceName[index] = deviceToUpdate;


          setMultiplePieChart(updatedDeviceName);
        }
      }
    }

    if (deviceType === "LineChart") {
      if (Object.keys(tempTabIndexLineChart).length > 0) {
        const particularDevice = tempTabIndexLineChart[activeTabIndex];
        if (particularDevice !== undefined) {
          const selectedChart = document.getElementById(`lineChart-${index}`);
          if (device?.id) {

            if (
              tempTabIndexLineChart[activeTabIndex].some(
                (device) => device.id === id
              )
            ) {
              if (particularDevice[index]?.id === id) {
                const originalDeviceIndex = tempImages.findIndex(
                  (data) => data.id === id
                );
                const newWidth = selectedChart.style.width;
                const newHeight = selectedChart.style.height;
                const updatedData = {
                  ...updatedTempImages[originalDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };
                updatedTempImages[originalDeviceIndex] = updatedData;
                setTempImages(updatedTempImages);
                setUpdatedImages(updatedTempImages)

                let oldtabIndexItem = [...tempTabIndexLineChart[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexItem.findIndex(
                  (data) => data.id === id
                );

                const newUpdatedData = {
                  ...oldtabIndexItem[organizedDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };

                oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexItem,
                };

                setUpdatedTabIndexLineChart(newTempTabIndexDevices);
              }
            }
          }
        }
      }

      if (multipleLineCharts.length > 1) {
        if (!device?.id) {
          const selectedChart = document.getElementById(`newLineChart-${index}`);
          const newWidth = selectedChart.style.width;
          const newHeight = selectedChart.style.height;
          const updatedDeviceName = multipleLineCharts;
          const deviceToUpdate = multipleLineCharts.find(
            (element, chartIndex) => chartIndex === index
          );
          deviceToUpdate.width = newWidth;
          deviceToUpdate.height = newHeight;

          setMultipleLineCharts(updatedDeviceName);
        }
      }

    }

    if (deviceType === "BarChart") {
      if (Object.keys(tempTabIndexBarChart).length > 0) {
        const particularDevice = tempTabIndexBarChart[activeTabIndex];
        if (particularDevice !== undefined) {
          const selectedChart = document.getElementById(`barChart-${index}`);
          if (device?.id) {

            if (
              tempTabIndexBarChart[activeTabIndex].some(
                (device) => device.id === id
              )
            ) {
              if (particularDevice[index].id === id) {
                const originalDeviceIndex = tempImages.findIndex(
                  (data) => data.id === id
                );

                const newWidth = selectedChart.style.width;
                const newHeight = selectedChart.style.height;
                const updatedData = {
                  ...updatedTempImages[originalDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };
                updatedTempImages[originalDeviceIndex] = updatedData;
                setTempImages(updatedTempImages);
                setUpdatedImages(updatedTempImages)

                let oldtabIndexItem = [...tempTabIndexBarChart[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexItem.findIndex(
                  (data) => data.id === id
                );
                const newUpdatedData = {
                  ...oldtabIndexItem[organizedDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };

                oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexItem,
                };

                setUpdatedTabIndexBarChart(newTempTabIndexDevices);
              }
            }
          }
        }
      }

      if (multipleBarChart.length > 1) {
        if (!device?.id) {
          const selectedChart = document.getElementById(`newBarChart-${index}`);
          const newWidth = selectedChart.style.width;
          const newHeight = selectedChart.style.height;
          const updatedDeviceName = multipleBarChart;
          const deviceToUpdate = multipleBarChart.find(
            (element, chartIndex) => chartIndex === index
          );
          deviceToUpdate.width = newWidth;
          deviceToUpdate.height = newHeight;

          setMultipleBarChart(updatedDeviceName);
        }
      }
    }

    if (deviceType === "Histogram") {
      if (Object.keys(tempTabIndexHistogram).length > 0) {
        const particularDevice = tempTabIndexHistogram[activeTabIndex];
        if (particularDevice !== undefined) {
          const selectedChart = document.getElementById(`histogram-${index}`);
          if (device?.id) {

            if (
              tempTabIndexHistogram[activeTabIndex].some(
                (device) => device.id === id
              )
            ) {
              if (particularDevice[index]?.id === id) {
                const originalDeviceIndex = tempImages.findIndex(
                  (data) => data.id === id
                );

                const newWidth = selectedChart.style.width;
                const newHeight = selectedChart.style.height;

                const updatedData = {
                  ...updatedTempImages[originalDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };
                updatedTempImages[originalDeviceIndex] = updatedData;
                setTempImages(updatedTempImages);
                setUpdatedImages(updatedTempImages)

                let oldtabIndexItem = [...tempTabIndexHistogram[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexItem.findIndex(
                  (data) => data.id === id
                );
                const newUpdatedData = {
                  ...oldtabIndexItem[organizedDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };

                oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexItem,
                };

                setUpdatedTabIndexHistogram(newTempTabIndexDevices);
              }
            }
          }
        }
      }

      if (multipleHistogram.length > 1) {
        if (!device?.id) {
          const selectedChart = document.getElementById(`newHistogram-${index}`);
          const newWidth = selectedChart.style.width;
          const newHeight = selectedChart.style.height;
          const updatedDeviceName = multipleHistogram;
          const deviceToUpdate = multipleHistogram.find(
            (element, chartIndex) => chartIndex === index
          );
          deviceToUpdate.width = newWidth;
          deviceToUpdate.height = newHeight;

          setMultipleHistogram(updatedDeviceName);
        }
      }

    }

    if (deviceType === "Gauge") {
      if (Object.keys(tempTabIndexGauge).length > 0) {
        const particularDevice = tempTabIndexGauge[activeTabIndex];
        if (particularDevice !== undefined) {
          if (device?.id) {

            if (
              tempTabIndexGauge[activeTabIndex].some(
                (device) => device.id === id
              )
            ) {
              if (particularDevice[index]?.id === id) {
                const selectedChart = document.getElementById(`gauge-${index}`);
                const newWidth = selectedChart.style.width;
                const newHeight = selectedChart.style.height;
                const originalDeviceIndex = tempImages.findIndex(
                  (data) => data.id === id
                );
                const updatedData = {
                  ...updatedTempImages[originalDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };
                updatedTempImages[originalDeviceIndex] = updatedData;
                setTempImages(updatedTempImages);
                setUpdatedImages(updatedTempImages)

                let oldtabIndexItem = [...tempTabIndexGauge[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexItem.findIndex(
                  (data) => data.id === id
                );
                const newUpdatedData = {
                  ...oldtabIndexItem[organizedDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };

                oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexItem,
                };

                setUpdatedTabIndexGauge(newTempTabIndexDevices);
              }
            }
          }
        }
      }

      if (multipleGauge.length > 1) {
        if (!device?.id) {
          const selectedChart = document.getElementById(`newGauge-${index}`);
          const newWidth = selectedChart.style.width;
          const newHeight = selectedChart.style.height;
          const updatedDeviceName = multipleGauge;
          const deviceToUpdate = multipleGauge.find(
            (element, chartIndex) => chartIndex === index
          );
          deviceToUpdate.width = newWidth;
          deviceToUpdate.height = newHeight;

          setMultipleGauge(updatedDeviceName);
        }
      }
    }

    if (deviceType === "ProgressBar") {
      if (Object.keys(tempTabIndexProgress).length > 0) {
        const particularDevice = tempTabIndexProgress[activeTabIndex];
        if (particularDevice !== undefined) {
          if (device?.id) {

            if (
              tempTabIndexProgress[activeTabIndex].some(
                (device) => device.id === id
              )
            ) {
              if (particularDevice[index]?.id === id) {
                const selectedChart = document.getElementById(`progressBar-${index}`);

                const newWidth = selectedChart.style.width;
                const newHeight = selectedChart.style.height;
                const originalDeviceIndex = tempImages.findIndex(
                  (data) => data.id === id
                );
                const updatedData = {
                  ...updatedTempImages[originalDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };
                updatedTempImages[originalDeviceIndex] = updatedData;
                setTempImages(updatedTempImages);
                setUpdatedImages(updatedTempImages)

                let oldtabIndexItem = [...tempTabIndexProgress[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexItem.findIndex(
                  (data) => data.id === id
                );
                const newUpdatedData = {
                  ...oldtabIndexItem[organizedDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };

                oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexItem,
                };

                setUpdatedTabIndexProgress(newTempTabIndexDevices);
              }
            }
          }
        }
      }

      if (multipleProgress.length > 1) {
        if (!device?.id) {
          const selectedChart = document.getElementById(
            `newProgressBar-${index}`
          );
          const newWidth = selectedChart.style.width;
          const newHeight = selectedChart.style.height;
          const updatedDeviceName = multipleProgress;
          const deviceToUpdate = multipleProgress.find(
            (element, chartIndex) => chartIndex === index
          );
          deviceToUpdate.width = newWidth;
          deviceToUpdate.height = newHeight;

          setMultipleProgress(updatedDeviceName);
        }
      }
    }

    if (deviceType === "Grid") {
      if (Object.keys(tempTabIndexGrid).length > 0) {
        const particularDevice = tempTabIndexGrid[activeTabIndex];
        if (particularDevice !== undefined) {
          const selectedChart = document.getElementById(`grid-${index}`);
          if (device?.id) {

            if (
              tempTabIndexGrid[activeTabIndex].some(
                (device) => device.id === id
              )
            ) {
              if (particularDevice[index].id === id) {
                const originalDeviceIndex = tempImages.findIndex(
                  (data) => data.id === id
                );
                const newWidth = selectedChart.style.width;
                const newHeight = selectedChart.style.height;
                const updatedData = {
                  ...updatedTempImages[originalDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };
                updatedTempImages[originalDeviceIndex] = updatedData;
                setTempImages(updatedTempImages);
                setUpdatedImages(updatedTempImages);

                let oldtabIndexItem = [...tempTabIndexGrid[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexItem.findIndex(
                  (data) => data.id === id
                );
                const newUpdatedData = {
                  ...oldtabIndexItem[organizedDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };

                oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexItem,
                };

                setUpdatedTabIndexGrid(newTempTabIndexDevices);
              }
            }
          }
        }
      }

      if (multipleGrid.length > 1) {
        if (!device?.id) {
          const selectedChart = document.getElementById(`newGrid-${index}`);
          const newWidth = selectedChart.style.width;
          const newHeight = selectedChart.style.height;
          const updatedDeviceName = multipleGrid;
          const deviceToUpdate = multipleGrid.find(
            (element, chartIndex) => chartIndex === index
          );
          deviceToUpdate.width = newWidth;
          deviceToUpdate.height = newHeight;

          setMultipleGrid(updatedDeviceName);
        }
      }
    }

    if (deviceType === "Table") {
      if (Object.keys(tempTabIndexTable).length > 0) {
        const particularDevice = tempTabIndexTable[activeTabIndex];
        if (particularDevice !== undefined) {
          const selectedChart = document.getElementById(`table-${index}`);

          if (device?.id) {

            if (
              tempTabIndexTable[activeTabIndex].some(
                (device) => device.id === id
              )
            ) {
              if (particularDevice[index].id === id) {
                const originalDeviceIndex = tempImages.findIndex(
                  (data) => data.id === id
                );

                const newWidth = selectedChart.style.width;
                const newHeight = selectedChart.style.height;

                const updatedData = {
                  ...updatedTempImages[originalDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };

                updatedTempImages[originalDeviceIndex] = updatedData;
                setTempImages(updatedTempImages);
                setUpdatedImages(updatedTempImages)

                let oldtabIndexItem = [...tempTabIndexTable[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexItem.findIndex(
                  (data) => data.id === id
                );

                const newUpdatedData = {
                  ...oldtabIndexItem[organizedDeviceIndex],
                  width: newWidth,
                  height: newHeight,
                };

                oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexItem,
                };

                setUpdatedTabIndexTable(newTempTabIndexDevices);
              }
            }
          }
        }
      }

      if (multipleTable.length > 1) {
        if (!device?.id) {
          const selectedChart = document.getElementById(`newTable-${index}`);
          const newWidth = selectedChart.style.width;
          const newHeight = selectedChart.style.height;
          const updatedDeviceName = multipleTable;
          const deviceToUpdate = multipleTable.find(
            (element, chartIndex) => chartIndex === index
          );
          deviceToUpdate.width = newWidth;
          deviceToUpdate.height = newHeight;

          setMultipleTable(updatedDeviceName);
        }
      }
    }
  };



  // Function get positions of new devices/charts
  const handleDeviceDrop = (e, data, deviceIndex, deviceType, x, y) => {
    if (deviceType === "Indicator") {
      const newPosition = { x: data.x, y: data.y };
      setDevicePosition((prevPositions) => {
        const newPositions = prevPositions.slice();
        newPositions[deviceIndex] = newPosition;
        return newPositions;
      });
    }

    if (deviceType === "Display") {
      const newPosition = { x: data.x, y: data.y };
      setDisplayPosition((prevPositions) => {
        const newPositions = prevPositions.slice();
        newPositions[deviceIndex] = newPosition;
        return newPositions;
      });
    }

    if (deviceType === "Controller") {
      const newPosition = { x: data.x, y: data.y };
      setControllerPosition((prevPositions) => {
        const newPositions = prevPositions.slice();
        newPositions[deviceIndex] = newPosition;
        return newPositions;
      });


    }

    if (deviceType === "DigitalIO") {
      const newPosition = { x: data.x, y: data.y };
      setIOPosition((prevPositions) => {
        const newPositions = prevPositions.slice();
        newPositions[deviceIndex] = newPosition;
        return newPositions;
      });
    }

    if (deviceType === "FlowMeter") {
      const newPosition = { x: data.x, y: data.y };
      setFlowMPosition((prevPositions) => {
        const newPositions = prevPositions.slice();
        newPositions[deviceIndex] = newPosition;
        return newPositions;
      });
    }

    if (deviceType === "EnergyMeter") {
      const newPosition = { x: data.x, y: data.y };
      setEnergyMPosition((prevPositions) => {
        const newPositions = prevPositions.slice();
        newPositions[deviceIndex] = newPosition;
        return newPositions;
      });
    }

    if (deviceType === "Motor") {
      const newPosition = { x: data.x, y: data.y };
      setMotorPosition((prevPositions) => {
        const newPositions = prevPositions.slice();
        newPositions[deviceIndex] = newPosition;
        return newPositions;
      });
    }

    if (deviceType === "Text") {
      const newPosition = { x: data.x, y: data.y };
      setTextPosition((prevPositions) => {
        const newPositions = prevPositions.slice();
        newPositions[deviceIndex] = newPosition;
        return newPositions;
      });
    }


    if (deviceType === "LineChart") {
      const newPosition = { x: data.x, y: data.y };
      setLineChartPosition((prevPositions) => {
        const newPositions = prevPositions.slice();
        newPositions[deviceIndex] = newPosition;
        return newPositions;
      });
    }

    if (deviceType === "PieChart") {
      const newPosition = { x: data.x, y: data.y };
      setPieChartPosition((prevPositions) => {
        const newPositions = prevPositions.slice();
        newPositions[deviceIndex] = newPosition;
        return newPositions;
      });
    }

    if (deviceType === "BarChart") {
      const newPosition = { x: data.x, y: data.y };
      setBarChartPosition((prevPositions) => {
        const newPositions = prevPositions.slice();
        newPositions[deviceIndex] = newPosition;
        return newPositions;
      });
    }

    if (deviceType === "Histogram") {
      const newPosition = { x: data.x, y: data.y };
      setHistogramPosition((prevPositions) => {
        const newPositions = prevPositions.slice();
        newPositions[deviceIndex] = newPosition;
        return newPositions;
      });
    }

    if (deviceType === "Gauge") {
      const newPosition = { x: data.x, y: data.y };
      setGaugePosition((prevPositions) => {
        const newPositions = prevPositions.slice();
        newPositions[deviceIndex] = newPosition;
        return newPositions;
      });
    }

    if (deviceType === "ProgressBar") {
      const newPosition = { x: data.x, y: data.y };
      setProgressPosition((prevPositions) => {
        const newPositions = prevPositions.slice();
        newPositions[deviceIndex] = newPosition;
        return newPositions;
      });
    }

    if (deviceType === "Grid") {
      const newPosition = { x: data.x, y: data.y };
      setGridPosition((prevPositions) => {
        const newPositions = prevPositions.slice();
        newPositions[deviceIndex] = newPosition;
        return newPositions;
      });
    }

    if (deviceType === "Table") {
      const newPosition = { x: data.x, y: data.y };
      setTablePosition((prevPositions) => {
        const newPositions = prevPositions.slice();
        newPositions[deviceIndex] = newPosition;
        return newPositions;
      });
    }
  };

  // Function to get new position of existed devices/charts
  const tempHandleDeviceDrop = (e, data, index, id, x, y, deviceType) => {

    setClickedDeviceIndex(index);
    setSelectedDevice(id);

    const updatedTempImages = [...tempImages];
    const newUpdatedImages = [...updatedImages];

    if (deviceType === "Indicator") {

      setIndexesOfDevicePosi(indexesOfDevicePosi.concat(index));

      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );

      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x + data.x,
        y: y + data.y,
      };

      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);

      const currentData = {
        ...newUpdatedImages[originalDeviceIndex],
        x: x,
        y: y,
      }

      newUpdatedImages[originalDeviceIndex] = currentData;
      setUpdatedImages(newUpdatedImages);

      let oldtabIndexItem = [...tempTabIndexDevices[activeTabIndex]];
      const organizedDeviceIndex = oldtabIndexItem.findIndex(
        (data) => data.id === id
      );

      let newXPosition = x + data.x;
      let newYPosition = y + data.y;

      const newUpdatedData = {
        ...oldtabIndexItem[organizedDeviceIndex],
        x: newXPosition,
        y: newYPosition,
      };

      oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
      const newTempTabIndexDevices = {
        [activeTabIndex]: oldtabIndexItem,
      };

      setUpdatedTabIndexDevices(newTempTabIndexDevices);
    }

    if (deviceType === "Display") {

      setIndexesOfDevicePosi(indexesOfDevicePosi.concat(index));

      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );

      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x + data.x,
        y: y + data.y,
      };

      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);

      const currentData = {
        ...newUpdatedImages[originalDeviceIndex],
        x: x,
        y: y,
      }

      newUpdatedImages[originalDeviceIndex] = currentData;
      setUpdatedImages(newUpdatedImages);

      let oldtabIndexItem = [...tempTabIndexDisplay[activeTabIndex]];
      const organizedDeviceIndex = oldtabIndexItem.findIndex(
        (data) => data.id === id
      );

      let newXPosition = x + data.x;
      let newYPosition = y + data.y;

      const newUpdatedData = {
        ...oldtabIndexItem[organizedDeviceIndex],
        x: newXPosition,
        y: newYPosition,
      };

      oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
      const newTempTabIndexDevices = {
        [activeTabIndex]: oldtabIndexItem,
      };

      setUpdatedTabIndexDisplay(newTempTabIndexDevices);
    }

    if (deviceType === "Controller") {

      setIndexesOfDevicePosi(indexesOfDevicePosi.concat(index));
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x + data.x,
        y: y + data.y,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;

      const currentData = {
        ...newUpdatedImages[originalDeviceIndex],
        x: x,
        y: y
      }


      newUpdatedImages[originalDeviceIndex] = currentData;
      setUpdatedImages(newUpdatedImages);
      setTempImages(updatedTempImages);

      let oldtabIndexItem = [...tempTabIndexController[activeTabIndex]];
      const organizedDeviceIndex = oldtabIndexItem.findIndex(
        (data) => data.id === id
      );

      let newXPosition = x + data.x;
      let newYPosition = y + data.y;

      const newUpdatedData = {
        ...oldtabIndexItem[organizedDeviceIndex],
        x: newXPosition,
        y: newYPosition,
      };

      oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
      const newTempTabIndexDevices = {
        [activeTabIndex]: oldtabIndexItem,
      };
      setUpdatedTabIndexController(newTempTabIndexDevices);
    }

    if (deviceType === "DigitalIO") {

      setIndexesOfDevicePosi(indexesOfDevicePosi.concat(index));
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x + data.x,
        y: y + data.y,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);

      const currentData = {
        ...newUpdatedImages[originalDeviceIndex],
        x: x,
        y: y,
      }


      newUpdatedImages[originalDeviceIndex] = currentData;
      setUpdatedImages(newUpdatedImages);

      let oldtabIndexItem = [...tempTabIndexIO[activeTabIndex]];
      const organizedDeviceIndex = oldtabIndexItem.findIndex(
        (data) => data.id === id
      );

      let newXPosition = x + data.x;
      let newYPosition = y + data.y;

      const newUpdatedData = {
        ...oldtabIndexItem[organizedDeviceIndex],
        x: newXPosition,
        y: newYPosition,
      };

      oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
      const newTempTabIndexDevices = {
        [activeTabIndex]: oldtabIndexItem,
      };

      setUpdatedTabIndexIO(newTempTabIndexDevices);
    }

    if (deviceType === "FlowMeter") {

      setIndexesOfDevicePosi(indexesOfDevicePosi.concat(index));
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x + data.x,
        y: y + data.y,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);

      const currentData = {
        ...newUpdatedImages[originalDeviceIndex],
        x: x,
        y: y,
      }

      newUpdatedImages[originalDeviceIndex] = currentData;
      setUpdatedImages(newUpdatedImages);

      let oldtabIndexItem = [...tempTabIndexFlowM[activeTabIndex]];
      const organizedDeviceIndex = oldtabIndexItem.findIndex(
        (data) => data.id === id
      );

      let newXPosition = x + data.x;
      let newYPosition = y + data.y;

      const newUpdatedData = {
        ...oldtabIndexItem[organizedDeviceIndex],
        x: newXPosition,
        y: newYPosition,
      };

      oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
      const newTempTabIndexDevices = {
        [activeTabIndex]: oldtabIndexItem,
      };

      setUpdatedTabIndexFlowM(newTempTabIndexDevices);
    }

    if (deviceType === "EnergyMeter") {

      setIndexesOfDevicePosi(indexesOfDevicePosi.concat(index));
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );

      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x + data.x,
        y: y + data.y,
      };

      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);

      const currentData = {
        ...newUpdatedImages[originalDeviceIndex],
        x: x,
        y: y,
      }

      newUpdatedImages[originalDeviceIndex] = currentData;
      setUpdatedImages(newUpdatedImages);

      let oldtabIndexItem = [...tempTabIndexEnergyM[activeTabIndex]];
      const organizedDeviceIndex = oldtabIndexItem.findIndex(
        (data) => data.id === id
      );

      let newXPosition = x + data.x;
      let newYPosition = y + data.y;

      const newUpdatedData = {
        ...oldtabIndexItem[organizedDeviceIndex],
        x: newXPosition,
        y: newYPosition,
      };

      oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
      const newTempTabIndexDevices = {
        [activeTabIndex]: oldtabIndexItem,
      };

      setUpdatedTabIndexEnergyM(newTempTabIndexDevices);
    }

    if (deviceType === "Text") {

      setIndexesOfDevicePosi(indexesOfDevicePosi.concat(index));

      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );

      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x + data.x,
        y: y + data.y,
      };

      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);

      const currentData = {
        ...newUpdatedImages[originalDeviceIndex],
        x: x,
        y: y,
      }

      newUpdatedImages[originalDeviceIndex] = currentData;
      setUpdatedImages(newUpdatedImages);

      let oldtabIndexItem = [...tempTabIndexText[activeTabIndex]];
      const organizedDeviceIndex = oldtabIndexItem.findIndex(
        (data) => data.id === id
      );

      let newXPosition = x + data.x;
      let newYPosition = y + data.y;

      const newUpdatedData = {
        ...oldtabIndexItem[organizedDeviceIndex],
        x: newXPosition,
        y: newYPosition,
      };

      oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
      const newTempTabIndexDevices = {
        [activeTabIndex]: oldtabIndexItem,
      };

      setUpdatedTabIndexText(newTempTabIndexDevices);
    }


    if (deviceType === "Motor") {

      setIndexesOfDevicePosi(indexesOfDevicePosi.concat(index));
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );

      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x + data.x,
        y: y + data.y,
      };

      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);

      const currentData = {
        ...newUpdatedImages[originalDeviceIndex],
        x: x,
        y: y,
      }

      newUpdatedImages[originalDeviceIndex] = currentData;
      setUpdatedImages(newUpdatedImages);

      let oldtabIndexItem = [...tempTabIndexMotor[activeTabIndex]];
      const organizedDeviceIndex = oldtabIndexItem.findIndex(
        (data) => data.id === id
      );

      let newXPosition = x + data.x;
      let newYPosition = y + data.y;

      const newUpdatedData = {
        ...oldtabIndexItem[organizedDeviceIndex],
        x: newXPosition,
        y: newYPosition,
      };

      oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
      const newTempTabIndexDevices = {
        [activeTabIndex]: oldtabIndexItem,
      };

      setUpdatedTabIndexMotor(newTempTabIndexDevices);
    }

    if (deviceType === "LineChart") {

      setIndexesOfDevicePosi(indexesOfDevicePosi.concat(index));
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x + data.x,
        y: y + data.y,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);

      const currentData = {
        ...newUpdatedImages[originalDeviceIndex],
        x: x,
        y: y,
      }

      newUpdatedImages[originalDeviceIndex] = currentData;
      setUpdatedImages(newUpdatedImages);

      let oldtabIndexItem = [...tempTabIndexLineChart[activeTabIndex]];
      const organizedDeviceIndex = oldtabIndexItem.findIndex(
        (data) => data.id === id
      );

      let newXPosition = x + data.x;
      let newYPosition = y + data.y;

      const newUpdatedData = {
        ...oldtabIndexItem[organizedDeviceIndex],
        x: newXPosition,
        y: newYPosition,
      };

      oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
      const newTempTabIndexDevices = {
        [activeTabIndex]: oldtabIndexItem,
      };

      setUpdatedTabIndexLineChart(newTempTabIndexDevices);
    }

    if (deviceType === "PieChart") {

      setIndexesOfDevicePosi(indexesOfDevicePosi.concat(index));
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x + data.x,
        y: y + data.y,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);

      const currentData = {
        ...newUpdatedImages[originalDeviceIndex],
        x: x,
        y: y,
      }

      newUpdatedImages[originalDeviceIndex] = currentData;
      setUpdatedImages(newUpdatedImages);

      let oldtabIndexItem = [...tempTabIndexPieChart[activeTabIndex]];
      const organizedDeviceIndex = oldtabIndexItem.findIndex(
        (data) => data.id === id
      );

      let newXPosition = x + data.x;
      let newYPosition = y + data.y;

      const newUpdatedData = {
        ...oldtabIndexItem[organizedDeviceIndex],
        x: newXPosition,
        y: newYPosition,
      };

      oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
      const newTempTabIndexDevices = {
        [activeTabIndex]: oldtabIndexItem,
      };

      setUpdatedTabIndexPieChart(newTempTabIndexDevices);
    }

    if (deviceType === "BarChart") {

      setIndexesOfDevicePosi(indexesOfDevicePosi.concat(index));
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x + data.x,
        y: y + data.y,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);

      const currentData = {
        ...newUpdatedImages[originalDeviceIndex],
        x: x,
        y: y,
      }

      newUpdatedImages[originalDeviceIndex] = currentData;
      setUpdatedImages(newUpdatedImages);

      let oldtabIndexItem = [...tempTabIndexBarChart[activeTabIndex]];
      const organizedDeviceIndex = oldtabIndexItem.findIndex(
        (data) => data.id === id
      );

      let newXPosition = x + data.x;
      let newYPosition = y + data.y;

      const newUpdatedData = {
        ...oldtabIndexItem[organizedDeviceIndex],
        x: newXPosition,
        y: newYPosition,
      };

      oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
      const newTempTabIndexDevices = {
        [activeTabIndex]: oldtabIndexItem,
      };

      setUpdatedTabIndexBarChart(newTempTabIndexDevices);
    }

    if (deviceType === "Histogram") {

      setIndexesOfDevicePosi(indexesOfDevicePosi.concat(index));
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x + data.x,
        y: y + data.y,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);

      const currentData = {
        ...newUpdatedImages[originalDeviceIndex],
        x: x,
        y: y,
      }

      newUpdatedImages[originalDeviceIndex] = currentData;
      setUpdatedImages(newUpdatedImages);

      let oldtabIndexItem = [...tempTabIndexHistogram[activeTabIndex]];
      const organizedDeviceIndex = oldtabIndexItem.findIndex(
        (data) => data.id === id
      );

      let newXPosition = x + data.x;
      let newYPosition = y + data.y;

      const newUpdatedData = {
        ...oldtabIndexItem[organizedDeviceIndex],
        x: newXPosition,
        y: newYPosition,
      };

      oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
      const newTempTabIndexDevices = {
        [activeTabIndex]: oldtabIndexItem,
      };

      setUpdatedTabIndexHistogram(newTempTabIndexDevices);
    }

    if (deviceType === "Gauge") {

      setIndexesOfDevicePosi(indexesOfDevicePosi.concat(index));
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x + data.x,
        y: y + data.y,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);

      const currentData = {
        ...newUpdatedImages[originalDeviceIndex],
        x: x,
        y: y,
      }

      newUpdatedImages[originalDeviceIndex] = currentData;
      setUpdatedImages(newUpdatedImages);

      let oldtabIndexItem = [...tempTabIndexGauge[activeTabIndex]];
      const organizedDeviceIndex = oldtabIndexItem.findIndex(
        (data) => data.id === id
      );

      let newXPosition = x + data.x;
      let newYPosition = y + data.y;

      const newUpdatedData = {
        ...oldtabIndexItem[organizedDeviceIndex],
        x: newXPosition,
        y: newYPosition,
      };

      oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
      const newTempTabIndexDevices = {
        [activeTabIndex]: oldtabIndexItem,
      };

      setUpdatedTabIndexGauge(newTempTabIndexDevices);
    }

    if (deviceType === "ProgressBar") {

      setIndexesOfDevicePosi(indexesOfDevicePosi.concat(index));
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x + data.x,
        y: y + data.y,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);

      const currentData = {
        ...newUpdatedImages[originalDeviceIndex],
        x: x,
        y: y,
      }

      newUpdatedImages[originalDeviceIndex] = currentData;
      setUpdatedImages(newUpdatedImages);

      let oldtabIndexItem = [...tempTabIndexProgress[activeTabIndex]];
      const organizedDeviceIndex = oldtabIndexItem.findIndex(
        (data) => data.id === id
      );

      let newXPosition = x + data.x;
      let newYPosition = y + data.y;

      const newUpdatedData = {
        ...oldtabIndexItem[organizedDeviceIndex],
        x: newXPosition,
        y: newYPosition,
      };

      oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
      const newTempTabIndexDevices = {
        [activeTabIndex]: oldtabIndexItem,
      };

      setUpdatedTabIndexProgress(newTempTabIndexDevices);
    }

    if (deviceType === "Grid") {

      setIndexesOfDevicePosi(indexesOfDevicePosi.concat(index));
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x + data.x,
        y: y + data.y,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);

      const currentData = {
        ...newUpdatedImages[originalDeviceIndex],
        x: x,
        y: y,
      }

      newUpdatedImages[originalDeviceIndex] = currentData;
      setUpdatedImages(newUpdatedImages);

      let oldtabIndexItem = [...tempTabIndexGrid[activeTabIndex]];
      const organizedDeviceIndex = oldtabIndexItem.findIndex(
        (data) => data.id === id
      );

      let newXPosition = x + data.x;
      let newYPosition = y + data.y;

      const newUpdatedData = {
        ...oldtabIndexItem[organizedDeviceIndex],
        x: newXPosition,
        y: newYPosition,
      };

      oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
      const newTempTabIndexDevices = {
        [activeTabIndex]: oldtabIndexItem,
      };

      setUpdatedTabIndexGrid(newTempTabIndexDevices);
    }

    if (deviceType === "Table") {

      setIndexesOfDevicePosi(indexesOfDevicePosi.concat(index));
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x + data.x,
        y: y + data.y,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);

      const currentData = {
        ...newUpdatedImages[originalDeviceIndex],
        x: x,
        y: y,
      }

      newUpdatedImages[originalDeviceIndex] = currentData;
      setUpdatedImages(newUpdatedImages);

      let oldtabIndexItem = [...tempTabIndexTable[activeTabIndex]];
      const organizedDeviceIndex = oldtabIndexItem.findIndex(
        (data) => data.id === id
      );

      let newXPosition = x + data.x;
      let newYPosition = y + data.y;

      const newUpdatedData = {
        ...oldtabIndexItem[organizedDeviceIndex],
        x: newXPosition,
        y: newYPosition,
      };

      oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
      const newTempTabIndexDevices = {
        [activeTabIndex]: oldtabIndexItem,
      };

      setUpdatedTabIndexTable(newTempTabIndexDevices);
    }
  };

  const handleChannelsBtn = () => {
    setIsDeviceMenu(false);
    if (currentDevice === "Grid") {
      setIsChannelCheckBox(true);
    } else {
      setIsChannels(true);
    }
  };

  // Save channel model
  const handleSaveChannel = () => {
    setIsChannels(false);
    if (currentDevice === "Indicator") {
      const pvChannelValue = document.getElementById("pvChannels").value;
      setCurrentPVChannel(pvChannelValue);

      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === selectedDevice.id
      );
      setPVSpecificChannel(pvSpecificChannel.concat(pvChannelValue));
      if (Object.keys(tempTabIndexDevices).length > 0) {
        const particularDevice = tempTabIndexDevices[activeTabIndex];
        if (particularDevice !== undefined) {
          if (selectedDevice?.id) {

            if (
              tempTabIndexDevices[activeTabIndex].some(
                (device) => device.id === selectedDevice.id
              )
            ) {
              if (particularDevice[clickedDeviceIndex]?.id === selectedDevice.id) {
                let temporaryWidgets = [...tempImages];
                const previousPV =
                  temporaryWidgets[originalDeviceIndex]?.channelName?.PV;

                const updatedData = {
                  ...temporaryWidgets[originalDeviceIndex],
                  channelName: {
                    ...temporaryWidgets[originalDeviceIndex].channelName,
                    PV: pvChannelValue !== null ? pvChannelValue : previousPV,
                  },
                };

                temporaryWidgets[originalDeviceIndex] = updatedData;
                setTempImages(temporaryWidgets);
                setUpdatedImages(temporaryWidgets)
                // storing new channel name to display data in main page

                let oldtabIndexDevices = [...tempTabIndexDevices[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexDevices.findIndex(
                  (data) => data.id === selectedDevice.id
                );
                if (organizedDeviceIndex !== -1) {
                  // Ensure the index is found
                  const updatedDevice = {
                    ...oldtabIndexDevices[organizedDeviceIndex],
                    channelName: {
                      ...oldtabIndexDevices[organizedDeviceIndex].channelName,
                      PV: pvChannelValue !== null ? pvChannelValue : previousPV,
                    },
                  };

                  oldtabIndexDevices[organizedDeviceIndex] = updatedDevice;
                  const newTempTabIndexDevices = {
                    [activeTabIndex]: oldtabIndexDevices,
                  };
                  setTempTabIndexDevices(newTempTabIndexDevices);
                  setUpdatedTabIndexDevices(newTempTabIndexDevices);
                }
              }
            }
          }
        }
      }

      if (multipleDevices.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = [...multipleDevices];
          const deviceToUpdate = multipleDevices.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.pvChannel = pvChannelValue;
          setMultipleDevices(updatedDeviceName);
        }
      }
    }

    if (currentDevice === "Display") {
      const pvChannelValue = document.getElementById("pvChannels").value;
      setCurrentPVChannel(pvChannelValue);

      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === selectedDevice.id
      );
      setPVSpecificChannel(pvSpecificChannel.concat(pvChannelValue));
      if (Object.keys(tempTabIndexDisplay).length > 0) {
        const particularDevice = tempTabIndexDisplay[activeTabIndex];
        if (particularDevice !== undefined) {
          if (selectedDevice?.id) {
            if (
              tempTabIndexDisplay[activeTabIndex].some(
                (device) => device.id === selectedDevice.id
              )
            ) {
              if (particularDevice[clickedDeviceIndex]?.id === selectedDevice.id) {
                let temporaryWidgets = [...tempImages];
                const previousPV =
                  temporaryWidgets[originalDeviceIndex]?.channelName?.PV;

                const updatedData = {
                  ...temporaryWidgets[originalDeviceIndex],
                  channelName: {
                    ...temporaryWidgets[originalDeviceIndex].channelName,
                    PV: pvChannelValue !== null ? pvChannelValue : previousPV,
                  },
                };

                temporaryWidgets[originalDeviceIndex] = updatedData;
                setTempImages(temporaryWidgets);
                setUpdatedImages(temporaryWidgets)
                // storing new channel name to display data in main page

                let oldtabIndexDevices = [...tempTabIndexDisplay[activeTabIndex]];

                const organizedDeviceIndex = oldtabIndexDevices.findIndex(
                  (data) => data.id === selectedDevice.id
                );

                if (organizedDeviceIndex !== -1) {
                  // Ensure the index is found
                  const updatedDevice = {
                    ...oldtabIndexDevices[organizedDeviceIndex],
                    channelName: {
                      ...oldtabIndexDevices[organizedDeviceIndex].channelName,
                      PV: pvChannelValue !== null ? pvChannelValue : previousPV,
                    },
                  };

                  oldtabIndexDevices[organizedDeviceIndex] = updatedDevice;
                  const newTempTabIndexDevices = {
                    [activeTabIndex]: oldtabIndexDevices,
                  };

                  setTempTabIndexDisplay(newTempTabIndexDevices);
                  setUpdatedTabIndexDisplay(newTempTabIndexDevices);
                }
              }
            }
          }
        }
      }

      if (multipleDisplay.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = [...multipleDisplay];
          const deviceToUpdate = multipleDisplay.find(
            (element, index) => index === clickedDeviceIndex
          );

          deviceToUpdate.pvChannel = pvChannelValue;
          setMultipleDisplay(updatedDeviceName);
        }
      }
    }


    if (currentDevice === "Controller") {
      const svChannelValue = document.getElementById("svChannels").value;
      const pvChannelValue = document.getElementById("pvChannels").value;
      setCurrentPVController(pvChannelValue);
      setCurrentSVController(svChannelValue);
      setPVControllerChannel(pvControllerChannel.concat(pvChannelValue));
      setSVControllerChannel(svControllerChannel.concat(svChannelValue));

      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === selectedDevice.id
      );


      if (Object.keys(tempTabIndexController).length > 0) {
        const particularDevice = tempTabIndexController[activeTabIndex];

        if (particularDevice !== undefined) {

          if (
            tempTabIndexController[activeTabIndex].some(
              (device) => device.id === selectedDevice.id
            )
          ) {

            if (particularDevice[clickedDeviceIndex]?.id === selectedDevice.id) {
              let temporaryWidgets = [...tempImages];

              const previousPV =
                temporaryWidgets[originalDeviceIndex]?.channelName?.PV;

              const previousSV =
                temporaryWidgets[originalDeviceIndex]?.channelName?.SV;

              const updatedData = {
                ...temporaryWidgets[originalDeviceIndex],
                channelName: {
                  ...temporaryWidgets[originalDeviceIndex].channelName,
                  PV: pvChannelValue !== null ? pvChannelValue : previousPV,
                  SV: svChannelValue !== null ? svChannelValue : previousSV,
                },
              };

              temporaryWidgets[originalDeviceIndex] = updatedData;

              setTempImages(temporaryWidgets);
              setUpdatedImages(temporaryWidgets)
              // storing new channel name to display data in main page

              let oldtabIndexDevices = [
                ...tempTabIndexController[activeTabIndex],
              ];
              const organizedDeviceIndex = oldtabIndexDevices.findIndex(
                (data) => data.id === selectedDevice.id
              );

              const newUpdatedData = {
                ...oldtabIndexDevices[organizedDeviceIndex],
                channelName: {
                  PV: pvChannelValue !== null ? pvChannelValue : previousPV,
                  SV: pvChannelValue !== null ? svChannelValue : previousSV,
                },
              };

              oldtabIndexDevices[organizedDeviceIndex] = newUpdatedData;
              const newTempTabIndexDevices = {
                [activeTabIndex]: oldtabIndexDevices,
              };

              setTempTabIndexController(newTempTabIndexDevices);
              setUpdatedTabIndexController(newTempTabIndexDevices);
            }
          }
        }
      }
      if (multipleController.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = multipleController;
          const deviceToUpdate = multipleController.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.pvChannel = pvChannelValue;
          deviceToUpdate.svChannel = svChannelValue;
          setMultipleController(updatedDeviceName);
        }
      }
    }

    if (currentDevice === "DigitalIO") {
      const pvChannelValue = document.getElementById("pvChannels").value;


      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === selectedDevice.id
      );
      setPVSpecificChannel(pvSpecificChannel.concat(pvChannelValue));
      if (Object.keys(tempTabIndexIO).length > 0) {
        const particularDevice = tempTabIndexIO[activeTabIndex];
        if (particularDevice !== undefined) {
          if (selectedDevice?.id) {

            if (
              tempTabIndexIO[activeTabIndex].some(
                (device) => device.id === selectedDevice.id
              )
            ) {
              if (particularDevice[clickedDeviceIndex]?.id === selectedDevice.id) {
                let temporaryWidgets = [...tempImages];
                const previousPV =
                  temporaryWidgets[originalDeviceIndex]?.channelName?.PV;

                const updatedData = {
                  ...temporaryWidgets[originalDeviceIndex],
                  channelName: {
                    ...temporaryWidgets[originalDeviceIndex].channelName,
                    PV: pvChannelValue !== null ? pvChannelValue : previousPV,
                  },
                };

                temporaryWidgets[originalDeviceIndex] = updatedData;
                setTempImages(temporaryWidgets);
                setUpdatedImages(temporaryWidgets)
                // storing new channel name to display data in main page

                let oldtabIndexDevices = [...tempTabIndexIO[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexDevices.findIndex(
                  (data) => data.id === selectedDevice.id
                );
                if (organizedDeviceIndex !== -1) {
                  // Ensure the index is found
                  const updatedDevice = {
                    ...oldtabIndexDevices[organizedDeviceIndex],
                    channelName: {
                      ...oldtabIndexDevices[organizedDeviceIndex].channelName,
                      PV: pvChannelValue !== null ? pvChannelValue : previousPV,
                    },
                  };

                  oldtabIndexDevices[organizedDeviceIndex] = updatedDevice;
                  const newTempTabIndexDevices = {
                    [activeTabIndex]: oldtabIndexDevices,
                  };
                  setTempTabIndexIO(newTempTabIndexDevices);
                  setUpdatedTabIndexIO(newTempTabIndexDevices);
                }
              }
            }
          } else {
            const updatedDeviceName = [...multipleIO];
            const deviceToUpdate = multipleIO.find(
              (element, index) => index === clickedDeviceIndex
            );
            deviceToUpdate.pvChannel = pvChannelValue;
            setMultipleIO(updatedDeviceName);
          }
        }
      }
      if (multipleIO.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = [...multipleIO];
          const deviceToUpdate = multipleIO.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.pvChannel = pvChannelValue;
          setMultipleIO(updatedDeviceName);
        }
      }
    }


    if (currentDevice === "FlowMeter") {
      const svChannelValue = document.getElementById("svChannels").value;
      const pvChannelValue = document.getElementById("pvChannels").value;
      setCurrentPVFlowM(pvChannelValue);
      setCurrentSVFlowM(svChannelValue);

      setPVFlowMChannels(pvFlowMChannels.concat(pvChannelValue));
      setSVFlowMChannels(svFlowMChannels.concat(svChannelValue));
      if (Object.keys(tempTabIndexFlowM).length > 0) {
        const particularDevice = tempTabIndexFlowM[activeTabIndex];
        if (particularDevice !== undefined) {
          const originalDeviceIndex = tempImages.findIndex(
            (data) => data.id === selectedDevice.id
          );

          if (selectedDevice?.id) {

            if (
              tempTabIndexFlowM[activeTabIndex].some(
                (device) => device.id === selectedDevice.id
              )
            ) {
              if (particularDevice[clickedDeviceIndex]?.id === selectedDevice.id) {
                let temporaryWidgets = [...tempImages];
                const previousPV =
                  temporaryWidgets[originalDeviceIndex]?.channelName?.PV;

                const previousSV =
                  temporaryWidgets[originalDeviceIndex]?.channelName?.SV;

                const updatedData = {
                  ...temporaryWidgets[originalDeviceIndex],
                  channelName: {
                    ...temporaryWidgets[originalDeviceIndex].channelName,
                    PV: pvChannelValue !== null ? pvChannelValue : previousPV,
                    SV: svChannelValue !== null ? svChannelValue : previousSV,
                  },
                };

                temporaryWidgets[originalDeviceIndex] = updatedData;
                setTempImages(temporaryWidgets);
                setUpdatedImages(temporaryWidgets)
                // storing new channel name to display data in main page

                let oldtabIndexDevices = [...tempTabIndexFlowM[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexDevices.findIndex(
                  (data) => data.id === selectedDevice.id
                );

                const newUpdatedData = {
                  ...oldtabIndexDevices[organizedDeviceIndex],
                  channelName: {
                    PV: pvChannelValue !== null ? pvChannelValue : previousPV,
                    SV: pvChannelValue !== null ? svChannelValue : previousSV,
                  },
                };
                oldtabIndexDevices[organizedDeviceIndex] = newUpdatedData;

                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexDevices,
                };
                setTempTabIndexFlowM(newTempTabIndexDevices);
                setUpdatedTabIndexFlowM(newTempTabIndexDevices);
              }
            }
          }
        }
      }

      if (multipleFlowM.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = multipleFlowM;
          const deviceToUpdate = multipleFlowM.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.pvChannel = pvChannelValue;
          deviceToUpdate.svChannel = svChannelValue;
          setMultipleFlowM(updatedDeviceName);
        }
      }
    }

    if (currentDevice === "EnergyMeter") {
      const svChannelValue = document.getElementById("svChannels").value;
      const svChannelValue2 = document.getElementById("svChannel2").value;
      const svChannelValue3 = document.getElementById("svChannel3").value;
      const pvChannelValue = document.getElementById("pvChannels").value;
      setCurrentPVEnergyM(pvChannelValue);
      setCurrentSVEnergyM(svChannelValue);
      setPVEnergyMChannels(pvEnergyMChannels.concat(pvChannelValue));
      setSVEnergyMChannels(svEnergyMChannels.concat(svChannelValue));
      if (Object.keys(tempTabIndexEnergyM).length > 0) {
        const particularDevice = tempTabIndexEnergyM[activeTabIndex];
        if (particularDevice !== undefined) {
          const originalDeviceIndex = tempImages.findIndex(
            (data) => data.id === selectedDevice.id
          );

          if (selectedDevice?.id) {

            if (
              tempTabIndexEnergyM[activeTabIndex].some(
                (device) => device.id === selectedDevice.id
              )
            ) {
              if (particularDevice[clickedDeviceIndex]?.id === selectedDevice.id) {
                let temporaryWidgets = [...tempImages];
                const previousPV =
                  temporaryWidgets[originalDeviceIndex]?.channelName?.PV;

                const previousSV =
                  temporaryWidgets[originalDeviceIndex]?.channelName?.SV;
                const previousSV2 =
                  temporaryWidgets[originalDeviceIndex]?.channelName?.SV2;
                const previousSV3 =
                  temporaryWidgets[originalDeviceIndex]?.channelName?.SV3;

                const updatedData = {
                  ...temporaryWidgets[originalDeviceIndex],
                  channelName: {
                    ...temporaryWidgets[originalDeviceIndex].channelName,
                    PV: pvChannelValue !== null ? pvChannelValue : previousPV,
                    SV: svChannelValue !== null ? svChannelValue : previousSV,
                    SV2: svChannelValue2 !== null ? svChannelValue2 : previousSV2,
                    SV3: svChannelValue3 !== null ? svChannelValue3 : previousSV3,
                  },
                };

                temporaryWidgets[originalDeviceIndex] = updatedData;
                setTempImages(temporaryWidgets);
                setUpdatedImages(temporaryWidgets)
                // storing new channel name to display data in main page

                let oldtabIndexDevices = [...tempTabIndexEnergyM[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexDevices.findIndex(
                  (data) => data.id === selectedDevice.id
                );

                const newUpdatedData = {
                  ...oldtabIndexDevices[organizedDeviceIndex],
                  channelName: {
                    PV: pvChannelValue !== null ? pvChannelValue : previousPV,
                    SV: svChannelValue !== null ? svChannelValue : previousSV,
                    SV2: svChannelValue2 !== null ? svChannelValue2 : previousSV2,
                    SV3: svChannelValue3 !== null ? svChannelValue3 : previousSV3,
                  },
                };
                oldtabIndexDevices[organizedDeviceIndex] = newUpdatedData;
                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexDevices,
                };
                setTempTabIndexEnergyM(newTempTabIndexDevices);
                setUpdatedTabIndexEnergyM(newTempTabIndexDevices);
              }
            }
          }
        }
      }
      if (multipleEnergyM.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = multipleEnergyM;
          const deviceToUpdate = multipleEnergyM.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.pvChannel = pvChannelValue;
          deviceToUpdate.svChannel = svChannelValue;
          deviceToUpdate.svChannel2 = svChannelValue2;
          deviceToUpdate.svChannel3 = svChannelValue3;
          setMultipleEnergyM(updatedDeviceName);
        }
      }
    }

    if (currentDevice === "Motor") {
      const pvChannelValue = document.getElementById("pvChannels").value;
      setCurrentPVChannel(pvChannelValue);

      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === selectedDevice.id
      );
      setPVSpecificChannel(pvSpecificChannel.concat(pvChannelValue));
      if (Object.keys(tempTabIndexMotor).length > 0) {
        const particularDevice = tempTabIndexMotor[activeTabIndex];
        if (particularDevice !== undefined) {

          if (selectedDevice?.id) {
            if (
              tempTabIndexMotor[activeTabIndex].some(
                (device) => device.id === selectedDevice.id
              )
            ) {
              if (particularDevice[clickedDeviceIndex]?.id === selectedDevice.id) {
                let temporaryWidgets = [...tempImages];
                const previousPV =
                  temporaryWidgets[originalDeviceIndex]?.channelName?.PV;

                const updatedData = {
                  ...temporaryWidgets[originalDeviceIndex],
                  channelName: {
                    ...temporaryWidgets[originalDeviceIndex].channelName,
                    PV: pvChannelValue !== null ? pvChannelValue : previousPV,
                  },
                };

                temporaryWidgets[originalDeviceIndex] = updatedData;
                setTempImages(temporaryWidgets);
                setUpdatedImages(temporaryWidgets)
                // storing new channel name to display data in main page

                let oldtabIndexDevices = [...tempTabIndexMotor[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexDevices.findIndex(
                  (data) => data.id === selectedDevice.id
                );
                if (organizedDeviceIndex !== -1) {
                  // Ensure the index is found
                  const updatedDevice = {
                    ...oldtabIndexDevices[organizedDeviceIndex],
                    channelName: {
                      ...oldtabIndexDevices[organizedDeviceIndex].channelName,
                      PV: pvChannelValue !== null ? pvChannelValue : previousPV,
                    },
                  };

                  oldtabIndexDevices[organizedDeviceIndex] = updatedDevice;
                  const newTempTabIndexDevices = {
                    [activeTabIndex]: oldtabIndexDevices,
                  };
                  setTempTabIndexMotor(newTempTabIndexDevices);
                  setUpdatedTabIndexMotor(newTempTabIndexDevices);
                }
              }
            }
          }
        }
      }

      if (multipleMotor.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = [...multipleMotor];
          const deviceToUpdate = multipleMotor.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.pvChannel = pvChannelValue;
          setMultipleMotor(updatedDeviceName);
        }
      }
    }

    if (currentDevice === "LineChart") {
      const channelValue = document.getElementById("pvChannels").value;
      if (Object.keys(tempTabIndexLineChart).length > 0) {
        const particularDevice = tempTabIndexLineChart[activeTabIndex];
        if (particularDevice !== undefined) {
          const originalDeviceIndex = tempImages.findIndex(
            (data) => data.id === selectedDevice.id
          );
          if (selectedDevice?.id) {

            if (
              tempTabIndexLineChart[activeTabIndex].some(
                (device) => device.id === selectedDevice.id
              )
            ) {
              if (particularDevice[clickedDeviceIndex]?.id === selectedDevice.id) {
                let temporaryWidgets = [...tempImages];
                const previousChannels =
                  temporaryWidgets[originalDeviceIndex]?.chartChannel;

                const updatedData = {
                  ...temporaryWidgets[originalDeviceIndex],
                  chartChannel:
                    channelValue !== null ? lineChartChannels : previousChannels,
                };
                temporaryWidgets[originalDeviceIndex] = updatedData;

                setTempImages(temporaryWidgets);
                setUpdatedImages(temporaryWidgets);
                // storing new channel name to display data in main page
                let oldtabIndexChart = [...tempTabIndexLineChart[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexChart.findIndex(
                  (data) => data.id === selectedDevice.id
                );
                const newUpdatedData = {
                  ...oldtabIndexChart[organizedDeviceIndex],
                  chartChannel:
                    lineChartChannels.length > 0
                      ? lineChartChannels
                      : previousChannels,
                };

                oldtabIndexChart[organizedDeviceIndex] = newUpdatedData;
                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexChart,
                };
                setTempTabIndexLineChart(newTempTabIndexDevices);
                setUpdatedTabIndexLineChart(newTempTabIndexDevices);
              }
            }
          }
        }
      }

      if (multipleLineCharts.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = multipleLineCharts;
          const deviceToUpdate = multipleLineCharts.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.chartChannel = lineChartChannels;
          setMultipleLineCharts(updatedDeviceName);
        }
      }
      setLineChartChannels([]);
    }

    if (currentDevice === "PieChart") {
      const channelValue = document.getElementById("pvChannels").value;
      if (Object.keys(tempTabIndexPieChart).length > 0) {
        const particularDevice = tempTabIndexPieChart[activeTabIndex];
        if (particularDevice !== undefined) {
          const originalDeviceIndex = tempImages.findIndex(
            (data) => data.id === selectedDevice.id
          );
          if (selectedDevice?.id) {

            if (
              tempTabIndexPieChart[activeTabIndex].some(
                (device) => device.id === selectedDevice.id
              )
            ) {
              if (particularDevice[clickedDeviceIndex]?.id === selectedDevice.id) {
                let temporaryWidgets = [...tempImages];
                const previousChannels =
                  temporaryWidgets[originalDeviceIndex]?.chartChannel;

                const updatedData = {
                  ...temporaryWidgets[originalDeviceIndex],
                  chartChannel:
                    channelValue !== null ? pieChartChannels : previousChannels,
                };
                temporaryWidgets[originalDeviceIndex] = updatedData;

                setTempImages(temporaryWidgets);
                setUpdatedImages(temporaryWidgets)

                // storing new channel name to display data in main page
                let oldtabIndexChart = [...tempTabIndexPieChart[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexChart.findIndex(
                  (data) => data.id === selectedDevice.id
                );
                const newUpdatedData = {
                  ...oldtabIndexChart[organizedDeviceIndex],
                  chartChannel:
                    pieChartChannels.length > 0
                      ? pieChartChannels
                      : previousChannels,
                };

                oldtabIndexChart[organizedDeviceIndex] = newUpdatedData;
                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexChart,
                };
                setTempTabIndexPieChart(newTempTabIndexDevices);
                setUpdatedTabIndexPieChart(newTempTabIndexDevices);
              }
            }
          }
        }
      }

      if (multiplePieChart.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = multiplePieChart;
          const deviceToUpdate = multiplePieChart.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.chartChannel = pieChartChannels;
          setMultiplePieChart(updatedDeviceName);
        }
      }
      setPieChartChannels([]);
    }

    if (currentDevice === "BarChart") {
      const channelValue = document.getElementById("pvChannels").value;
      if (Object.keys(tempTabIndexBarChart).length > 0) {
        const particularDevice = tempTabIndexBarChart[activeTabIndex];
        if (particularDevice !== undefined) {
          const originalDeviceIndex = tempImages.findIndex(
            (data) => data.id === selectedDevice.id
          );

          if (selectedDevice?.id) {
            if (
              tempTabIndexBarChart[activeTabIndex].some(
                (device) => device.id === selectedDevice.id
              )
            ) {
              if (particularDevice[clickedDeviceIndex]?.id === selectedDevice.id) {
                let temporaryWidgets = [...tempImages];
                const previousChannels =
                  temporaryWidgets[originalDeviceIndex]?.chartChannel;

                const updatedData = {
                  ...temporaryWidgets[originalDeviceIndex],
                  chartChannel:
                    channelValue !== null ? barChartChannels : previousChannels,
                };
                temporaryWidgets[originalDeviceIndex] = updatedData;

                setTempImages(temporaryWidgets);
                setUpdatedImages(temporaryWidgets);

                // storing new channel name to display data in main page
                let oldtabIndexChart = [...tempTabIndexBarChart[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexChart.findIndex(
                  (data) => data.id === selectedDevice.id
                );
                const newUpdatedData = {
                  ...oldtabIndexChart[organizedDeviceIndex],
                  chartChannel:
                    barChartChannels.length > 0
                      ? barChartChannels
                      : previousChannels,
                };

                oldtabIndexChart[organizedDeviceIndex] = newUpdatedData;
                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexChart,
                };
                setTempTabIndexBarChart(newTempTabIndexDevices);
                setUpdatedTabIndexBarChart(newTempTabIndexDevices);
              }
            }
          }
        }
      }

      if (multipleBarChart.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = multipleBarChart;
          const deviceToUpdate = multipleBarChart.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.chartChannel = barChartChannels;
          setMultipleBarChart(updatedDeviceName);
        }
      }
      setBarChartChannels([]);
    }

    if (currentDevice === "Histogram") {
      const channelValue = document.getElementById("pvChannels").value;
      if (Object.keys(tempTabIndexHistogram).length > 0) {
        const particularDevice = tempTabIndexHistogram[activeTabIndex];
        if (particularDevice !== undefined) {
          const originalDeviceIndex = tempImages.findIndex(
            (data) => data.id === selectedDevice.id
          );

          if (selectedDevice?.id) {

            if (
              tempTabIndexHistogram[activeTabIndex].some(
                (device) => device.id === selectedDevice.id
              )
            ) {
              if (particularDevice[clickedDeviceIndex]?.id === selectedDevice.id) {
                let temporaryWidgets = [...tempImages];
                const previousChannels =
                  temporaryWidgets[originalDeviceIndex]?.chartChannel;

                const updatedData = {
                  ...temporaryWidgets[originalDeviceIndex],
                  chartChannel:
                    channelValue !== null ? histogramChannels : previousChannels,
                };
                temporaryWidgets[originalDeviceIndex] = updatedData;

                setTempImages(temporaryWidgets);
                setUpdatedImages(temporaryWidgets);

                // storing new channel name to display data in main page
                let oldtabIndexChart = [...tempTabIndexHistogram[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexChart.findIndex(
                  (data) => data.id === selectedDevice.id
                );
                const newUpdatedData = {
                  ...oldtabIndexChart[organizedDeviceIndex],
                  chartChannel:
                    histogramChannels.length > 0
                      ? histogramChannels
                      : previousChannels,
                };

                oldtabIndexChart[organizedDeviceIndex] = newUpdatedData;

                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexChart,
                };
                setTempTabIndexHistogram(newTempTabIndexDevices);
                setUpdatedTabIndexHistogram(newTempTabIndexDevices);
              }
            }
          }
        }
      }
      if (multipleHistogram.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = multipleHistogram;
          const deviceToUpdate = multipleHistogram.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.chartChannel = histogramChannels;
          setMultipleHistogram(updatedDeviceName);
        }
      }
      setHistogramChannels([]);
    }

    if (currentDevice === "Gauge") {
      const channelValue = document.getElementById("pvChannels").value;
      setGaugeChannels([channelValue]);
      if (Object.keys(tempTabIndexGauge).length > 0) {
        const particularDevice = tempTabIndexGauge[activeTabIndex];
        if (particularDevice !== undefined) {
          const originalDeviceIndex = tempImages.findIndex(
            (data) => data.id === selectedDevice.id
          );
          if (selectedDevice?.id) {

            if (
              tempTabIndexGauge[activeTabIndex].some(
                (device) => device.id === selectedDevice.id
              )
            ) {
              if (particularDevice[clickedDeviceIndex]?.id === selectedDevice.id) {
                let temporaryWidgets = [...tempImages];
                const previousChannels =
                  temporaryWidgets[originalDeviceIndex]?.chartChannel;

                const updatedData = {
                  ...temporaryWidgets[originalDeviceIndex],
                  chartChannel:
                    channelValue !== null ? [channelValue] : previousChannels,
                };

                temporaryWidgets[originalDeviceIndex] = updatedData;
                setTempImages(temporaryWidgets);
                setUpdatedImages(temporaryWidgets)
                // storing new channel name to display data in main page
                let oldtabIndexGauge = [...tempTabIndexGauge[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexGauge.findIndex(
                  (data) => data.id === selectedDevice.id
                );

                const newUpdatedData = {
                  ...oldtabIndexGauge[organizedDeviceIndex],
                  chartChannel: gaugeChannels ? [channelValue] : previousChannels,
                };
                oldtabIndexGauge[organizedDeviceIndex] = newUpdatedData;
                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexGauge,
                };
                setTempTabIndexGauge(newTempTabIndexDevices);
                setUpdatedTabIndexGauge(newTempTabIndexDevices);
              }
            }
          }
        }
      }
      if (multipleGauge.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = multipleGauge;
          const deviceToUpdate = multipleGauge.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.chartChannel = channelValue;
          setMultipleGauge(updatedDeviceName);
        }
      }
    }

    if (currentDevice === "ProgressBar") {
      const channelValue = document.getElementById("pvChannels").value;
      setProgressChannels(progressChannels.concat(channelValue));
      if (Object.keys(tempTabIndexProgress).length > 0) {
        const particularDevice = tempTabIndexProgress[activeTabIndex];
        if (particularDevice !== undefined) {
          const originalDeviceIndex = tempImages.findIndex(
            (data) => data.id === selectedDevice.id
          );
          if (selectedDevice?.id) {

            if (
              tempTabIndexProgress[activeTabIndex].some(
                (device) => device.id === selectedDevice.id
              )
            ) {
              if (particularDevice[clickedDeviceIndex]?.id === selectedDevice.id) {
                let temporaryWidgets = [...tempImages];
                const previousChannels =
                  temporaryWidgets[originalDeviceIndex]?.chartChannel;

                const updatedData = {
                  ...temporaryWidgets[originalDeviceIndex],
                  chartChannel:
                    channelValue !== null ? [channelValue] : previousChannels,
                };

                temporaryWidgets[originalDeviceIndex] = updatedData;
                setTempImages(temporaryWidgets);
                setUpdatedImages(temporaryWidgets)
                // storing new channel name to display data in main page
                let oldtabIndexProgressBar = [
                  ...tempTabIndexProgress[activeTabIndex],
                ];
                const organizedDeviceIndex = oldtabIndexProgressBar.findIndex(
                  (data) => data.id === selectedDevice.id
                );

                const newUpdatedData = {
                  ...oldtabIndexProgressBar[organizedDeviceIndex],
                  chartChannel: progressChannels
                    ? [channelValue]
                    : previousChannels,
                };
                oldtabIndexProgressBar[organizedDeviceIndex] = newUpdatedData;
                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexProgressBar,
                };
                setTempTabIndexProgress(newTempTabIndexDevices);
                setUpdatedTabIndexProgress(newTempTabIndexDevices);
              }
            }
          }
        }
      }

      if (multipleProgress.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = multipleProgress;
          const deviceToUpdate = multipleProgress.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.chartChannel = channelValue;
          setMultipleProgress(updatedDeviceName);
        }
      }
    }

    if (currentDevice === "Table") {
      const channelValue = document.getElementById("pvChannels").value;
      if (Object.keys(tempTabIndexTable).length > 0) {
        const particularDevice = tempTabIndexTable[activeTabIndex];
        if (particularDevice !== undefined) {
          const originalDeviceIndex = tempImages.findIndex(
            (data) => data.id === selectedDevice.id
          );
          if (selectedDevice?.id) {

            if (
              tempTabIndexTable[activeTabIndex].some(
                (device) => device.id === selectedDevice.id
              )
            ) {
              if (particularDevice[clickedDeviceIndex]?.id === selectedDevice.id) {
                let temporaryWidgets = [...tempImages];
                const previousChannels =
                  temporaryWidgets[originalDeviceIndex]?.chartChannel;

                const updatedData = {
                  ...temporaryWidgets[originalDeviceIndex],
                  chartChannel:
                    channelValue !== null ? tableChannels : previousChannels,
                };
                temporaryWidgets[originalDeviceIndex] = updatedData;
                setTempImages(temporaryWidgets);
                setUpdatedImages(temporaryWidgets)
                // storing new channel name to display data in main page
                let oldtabIndexTable = [...tempTabIndexTable[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexTable.findIndex(
                  (data) => data.id === selectedDevice.id
                );
                const newUpdatedData = {
                  ...oldtabIndexTable[organizedDeviceIndex],
                  chartChannel:
                    tableChannels.length > 0 ? tableChannels : previousChannels,
                };

                oldtabIndexTable[organizedDeviceIndex] = newUpdatedData;
                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexTable,
                };
                setTempTabIndexTable(newTempTabIndexDevices);
                setUpdatedTabIndexTable(newTempTabIndexDevices);
              }
            }
          }
        }
      }

      if (multipleTable.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = multipleTable;
          const deviceToUpdate = multipleTable.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.chartChannel = tableChannels;
          setMultipleTable(updatedDeviceName);
        }
      }
      setTableChannels([]);
    }
  };

  // open modal to update device name
  const handleRenameDeviceModal = () => {
    setRenameDeviceModal(true);
    setAddNewTag(false);
    setIsRenameModel(false);
  };

  // Function to rename devices
  const handleSaveDevicename = () => {
    setRenameDeviceModal(false);
    // For Indicator
    if (currentDevice === "Indicator") {
      if (Object.keys(tempTabIndexDevices).length > 0) {
        const particularDevice = tempTabIndexDevices[activeTabIndex];
        // condition to check if device already exist or not
        if (particularDevice !== undefined) {
          if (selectedDevice?.id) {

            if (
              tempTabIndexDevices[activeTabIndex].some(
                (device) => device.id === selectedDevice.id
              )
            ) {
              if (particularDevice[clickedDeviceIndex]?.id === selectedDevice.id) {
                const updatedDeviceName = tempTabIndexDevices[activeTabIndex];
                updatedDeviceName[clickedDeviceIndex].name = tabNameInput;
                tempTabIndexDevices[activeTabIndex] = updatedDeviceName;

                const originalDeviceIndex = tempImages.findIndex(
                  (data) => data.id === selectedDevice.id
                );
                const updatedTempImages = [...tempImages];

                const updatedData = {
                  ...updatedTempImages[originalDeviceIndex],
                  name: tabNameInput,
                };
                updatedTempImages[originalDeviceIndex] = updatedData;
                setTempImages(updatedTempImages);
                setUpdatedImages(updatedTempImages);
              }
            }
          }
        }
      }

      if (multipleDevices.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = multipleDevices;
          updatedDeviceName[clickedDeviceIndex].deviceName = tabNameInput;
          setMultipleDevices(updatedDeviceName);
          setDevicesName(devicesName.concat(tabNameInput));
          setIndexesDeviceName(indexesDeviceName.concat(clickedDeviceIndex));
        }
      }
    }

    // For Controller
    if (currentDevice === "Controller") {
      if (Object.keys(tempTabIndexController).length > 0) {
        const particularDevice = tempTabIndexController[activeTabIndex];
        // condition to check if device already exist or not
        if (particularDevice !== undefined) {
          if (selectedDevice?.id) {

            if (
              tempTabIndexController[activeTabIndex].some(
                (device) => device.id === selectedDevice.id
              )
            ) {
              if (particularDevice[clickedDeviceIndex]?.id === selectedDevice.id) {
                const updatedDeviceName = tempTabIndexController[activeTabIndex];
                updatedDeviceName[clickedDeviceIndex].name = tabNameInput;
                tempTabIndexController[activeTabIndex] = updatedDeviceName;

                const originalDeviceIndex = tempImages.findIndex(
                  (data) => data.id === selectedDevice.id
                );
                const updatedTempImages = [...tempImages];

                const updatedData = {
                  ...updatedTempImages[originalDeviceIndex],
                  name: tabNameInput,
                };
                updatedTempImages[originalDeviceIndex] = updatedData;
                setTempImages(updatedTempImages);
                setUpdatedImages(updatedTempImages);
              }
            }
          }
        }
      }

      if (multipleController.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = [...multipleController];
          updatedDeviceName[clickedDeviceIndex].deviceName = tabNameInput;
          setMultipleController(updatedDeviceName);
        }
      }
    }

    if (currentDevice === "DigitalIO") {
      const particularDevice = tempTabIndexIO[activeTabIndex];
      if (Object.keys(tempTabIndexIO).length > 0) {
        if (particularDevice !== undefined) {
          if (selectedDevice?.id) {

            if (particularDevice[clickedDeviceIndex].id === selectedDevice.id) {
              const updatedDeviceName = tempTabIndexIO[activeTabIndex];
              updatedDeviceName[clickedDeviceIndex].name = tabNameInput;
              tempTabIndexIO[activeTabIndex] = updatedDeviceName;

              const originalDeviceIndex = tempImages.findIndex(
                (data) => data.id === selectedDevice.id
              );
              const updatedTempImages = [...tempImages];

              const updatedData = {
                ...updatedTempImages[originalDeviceIndex],
                name: tabNameInput,
              };
              updatedTempImages[originalDeviceIndex] = updatedData;
              setTempImages(updatedTempImages);
              setUpdatedImages(updatedTempImages);
            }
          }
        }
      }
      if (multipleIO.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = multipleIO;
          updatedDeviceName[clickedDeviceIndex].deviceName = tabNameInput;
          setMultipleIO(updatedDeviceName);
        }
      }
    }

    if (currentDevice === "FlowMeter") {
      const particularDevice = tempTabIndexFlowM[activeTabIndex];
      if (Object.keys(tempTabIndexFlowM).length > 0) {
        if (particularDevice !== undefined) {
          if (selectedDevice?.id) {

            if (particularDevice[clickedDeviceIndex].id === selectedDevice.id) {
              const updatedDeviceName = tempTabIndexFlowM[activeTabIndex];
              updatedDeviceName[clickedDeviceIndex].name = tabNameInput;
              tempTabIndexFlowM[activeTabIndex] = updatedDeviceName;

              const originalDeviceIndex = tempImages.findIndex(
                (data) => data.id === selectedDevice.id
              );
              const updatedTempImages = [...tempImages];

              const updatedData = {
                ...updatedTempImages[originalDeviceIndex],
                name: tabNameInput,
              };
              updatedTempImages[originalDeviceIndex] = updatedData;
              setTempImages(updatedTempImages);
              setUpdatedImages(updatedTempImages);
            }
          }
        }
      }

      if (multipleFlowM.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = multipleFlowM;
          updatedDeviceName[clickedDeviceIndex].deviceName = tabNameInput;
          setMultipleFlowM(updatedDeviceName);
        }
      }
    }

    if (currentDevice === "EnergyMeter") {
      if (Object.keys(tempTabIndexEnergyM).length > 0) {
        const particularDevice = tempTabIndexEnergyM[activeTabIndex];
        if (particularDevice !== undefined) {

          if (selectedDevice?.id) {

            if (particularDevice[clickedDeviceIndex].id === selectedDevice.id) {
              const updatedDeviceName = tempTabIndexEnergyM[activeTabIndex];
              updatedDeviceName[clickedDeviceIndex].name = tabNameInput;
              tempTabIndexEnergyM[activeTabIndex] = updatedDeviceName;

              const originalDeviceIndex = tempImages.findIndex(
                (data) => data.id === selectedDevice.id
              );
              const updatedTempImages = [...tempImages];

              const updatedData = {
                ...updatedTempImages[originalDeviceIndex],
                name: tabNameInput,
              };
              updatedTempImages[originalDeviceIndex] = updatedData;
              setTempImages(updatedTempImages);
              setUpdatedImages(updatedTempImages);
            }
          }
        }
      }

      if (multipleEnergyM.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = multipleEnergyM;
          updatedDeviceName[clickedDeviceIndex].deviceName = tabNameInput;
          setMultipleEnergyM(updatedDeviceName);
        }
      }
    }
  };

  // Function to delete existed devices/charts
  const handleDeviceDelete1 = () => {
    setIsDeviceMenu(false);

    // Perform null/undefined check on selectedDevice
    if (currentDevice === "Indicator") {

      const updatedDevices = tempTabIndexDevices[activeTabIndex].filter(
        (device) => device.id !== selectedDevice.id
      );


      const currentDeviceIndex = tempTabIndexDevices[activeTabIndex].findIndex((device) => device.id === selectedDevice.id);

      const newTabIndexDevices = { ...tempTabIndexDevices };
      newTabIndexDevices[activeTabIndex] = updatedDevices;

      setTempTabIndexDevices(newTabIndexDevices);
      setUpdatedTabIndexDevices(newTabIndexDevices);
      setTabIndexDevices(newTabIndexDevices);

      indicatorRefs.current[currentDeviceIndex].style.setProperty('transform', 'translate(0px, 0px)', 'important');

      const originalDeviceIndex = tempImages.findIndex(
        (data, index) => data.id === selectedDevice.id
      );

      const newUpdatedData = tempImages.filter(
        (data) => data.id !== selectedDevice.id
      );

      setTempImages(newUpdatedData);
      setUpdatedImages(newUpdatedData);

      setIndexesToDeleteImg(indexesToDeleteImg.concat(originalDeviceIndex));
    }

    if (currentDevice === "Display") {

      const updatedDevices = tempTabIndexDisplay[activeTabIndex].filter(
        (device) => device.id !== selectedDevice.id
      );

      const currentDeviceIndex = tempTabIndexDisplay[activeTabIndex].findIndex((device) => device.id === selectedDevice.id);

      const newTabIndexDevices = { ...tempTabIndexDisplay };
      newTabIndexDevices[activeTabIndex] = updatedDevices;

      setTempTabIndexDisplay(newTabIndexDevices);
      setUpdatedTabIndexDisplay(newTabIndexDevices);
      setTabIndexDisplay(newTabIndexDevices);

      displayRefs.current[currentDeviceIndex].style.setProperty('transform', 'translate(0px, 0px)', 'important');

      const originalDeviceIndex = tempImages.findIndex(
        (data, index) => data.id === selectedDevice.id
      );

      const newUpdatedData = tempImages.filter(
        (data) => data.id !== selectedDevice.id
      );

      setTempImages(newUpdatedData);
      setUpdatedImages(newUpdatedData);
      setIndexesToDeleteImg(indexesToDeleteImg.concat(originalDeviceIndex));
    }

    if (currentDevice === "Controller") {

      const updatedDevices = tempTabIndexController[activeTabIndex].filter(
        (device) => device.id !== selectedDevice.id
      );
      const newTabIndexDevices = { ...tempTabIndexController };

      newTabIndexDevices[activeTabIndex] = updatedDevices;

      const currentDeviceIndex = tempTabIndexController[activeTabIndex].findIndex((device) => device.id === selectedDevice.id);

      setTempTabIndexController(newTabIndexDevices);
      setUpdatedTabIndexController(newTabIndexDevices);
      setTabIndexController(newTabIndexDevices);

      controllerRefs.current[currentDeviceIndex].style.setProperty('transform', 'translate(0px, 0px)', 'important');

      const originalDeviceIndex = tempImages.findIndex(
        (data, index) => data.id === selectedDevice.id
      );
      const newUpdatedData = tempImages.filter(
        (data) => data.id !== selectedDevice.id
      );


      setTempImages(newUpdatedData);
      setUpdatedImages(newUpdatedData);
      setIndexesToDeleteImg(indexesToDeleteImg.concat(originalDeviceIndex));
    }

    if (currentDevice === "DigitalIO") {
      const updatedDevices = tempTabIndexIO[activeTabIndex].filter(
        (device) => device.id !== selectedDevice.id
      );
      const newTabIndexDevices = { ...tempTabIndexIO };
      newTabIndexDevices[activeTabIndex] = updatedDevices;

      const currentDeviceIndex = tempTabIndexIO[activeTabIndex].findIndex((device) => device.id === selectedDevice.id);

      setTempTabIndexIO(newTabIndexDevices);
      setUpdatedTabIndexIO(newTabIndexDevices);
      setTempTabIndexIO(newTabIndexDevices);

      ioRefs.current[currentDeviceIndex].style.setProperty('transform', 'translate(0px, 0px)', 'important');

      const originalDeviceIndex = tempImages.findIndex(
        (data, index) => data.id === selectedDevice.id
      );

      const newUpdatedData = tempImages.filter(
        (data) => data.id !== selectedDevice.id
      );
      setTempImages(newUpdatedData);
      setUpdatedImages(newUpdatedData);
      setIndexesToDeleteImg(indexesToDeleteImg.concat(originalDeviceIndex));
    }

    if (currentDevice === "FlowMeter") {
      const updatedDevices = tempTabIndexFlowM[activeTabIndex].filter(
        (device) => device.id !== selectedDevice.id
      );
      const newTabIndexDevices = { ...tempTabIndexFlowM };
      newTabIndexDevices[activeTabIndex] = updatedDevices;

      const currentDeviceIndex = tempTabIndexFlowM[activeTabIndex].findIndex((device) => device.id === selectedDevice.id);

      setTempTabIndexFlowM(newTabIndexDevices);
      setUpdatedTabIndexFlowM(newTabIndexDevices);
      setTabIndexFlowM(newTabIndexDevices);

      flowMRefs.current[currentDeviceIndex].style.setProperty('transform', 'translate(0px, 0px)', 'important');

      const originalDeviceIndex = tempImages.findIndex(
        (data, index) => data.id === selectedDevice.id
      );

      const newUpdatedData = tempImages.filter(
        (data) => data.id !== selectedDevice.id
      );

      setTempImages(newUpdatedData);
      setUpdatedImages(newUpdatedData);
      setIndexesToDeleteImg(indexesToDeleteImg.concat(originalDeviceIndex));
    }

    if (currentDevice === "EnergyMeter") {
      const updatedDevices = tempTabIndexEnergyM[activeTabIndex].filter(
        (device) => device.id !== selectedDevice.id
      );
      const newTabIndexDevices = { ...tempTabIndexEnergyM };
      newTabIndexDevices[activeTabIndex] = updatedDevices;

      const currentDeviceIndex = tempTabIndexEnergyM[activeTabIndex].findIndex((device) => device.id === selectedDevice.id);

      setTempTabIndexEnergyM(newTabIndexDevices);
      setUpdatedTabIndexEnergyM(newTabIndexDevices);
      setTabIndexFlowM(newTabIndexDevices);

      energyMRefs.current[currentDeviceIndex].style.setProperty('transform', 'translate(0px, 0px)', 'important');

      const originalDeviceIndex = tempImages.findIndex(
        (data, index) => data.id === selectedDevice.id
      );

      const newUpdatedData = tempImages.filter(
        (data) => data.id !== selectedDevice.id
      );
      setTempImages(newUpdatedData);
      setUpdatedImages(newUpdatedData);
      setIndexesToDeleteImg(indexesToDeleteImg.concat(originalDeviceIndex));
    }

    if (currentDevice === "Motor") {
      const updatedDevices = tempTabIndexMotor[activeTabIndex].filter(
        (device) => device.id !== selectedDevice.id
      );
      const newTabIndexDevices = { ...tempTabIndexMotor };
      newTabIndexDevices[activeTabIndex] = updatedDevices;

      const currentDeviceIndex = tempTabIndexMotor[activeTabIndex].findIndex((device) => device.id === selectedDevice.id);

      setTempTabIndexMotor(newTabIndexDevices);
      setUpdatedTabIndexMotor(newTabIndexDevices);
      setTabIndexMotor(newTabIndexDevices);

      motorRefs.current[currentDeviceIndex].style.setProperty('transform', 'translate(0px, 0px)', 'important');

      const originalDeviceIndex = tempImages.findIndex(
        (data, index) => data.id === selectedDevice.id
      );

      const newUpdatedData = tempImages.filter(
        (data) => data.id !== selectedDevice.id
      );

      setTempImages(newUpdatedData);
      setUpdatedImages(newUpdatedData);
      setIndexesToDeleteImg(indexesToDeleteImg.concat(originalDeviceIndex));
    }

    if (currentDevice === "Text") {

      const updatedDevices = tempTabIndexText[activeTabIndex].filter(
        (device) => device.id !== selectedDevice.id
      );
      const newTabIndexDevices = { ...tempTabIndexText };

      newTabIndexDevices[activeTabIndex] = updatedDevices;

      const currentDeviceIndex = tempTabIndexText[activeTabIndex].findIndex((device) => device.id === selectedDevice.id);

      setTempTabIndexText(newTabIndexDevices);
      setUpdatedTabIndexText(newTabIndexDevices);
      setTabIndexText(newTabIndexDevices);

      textRefs.current[currentDeviceIndex].style.setProperty('transform', 'translate(0px, 0px)', 'important');

      const originalDeviceIndex = tempImages.findIndex(
        (data, index) => data.id === selectedDevice.id
      );

      const newUpdatedData = tempImages.filter(
        (data) => data.id !== selectedDevice.id
      );


      setTempImages(newUpdatedData);
      setUpdatedImages(newUpdatedData);
      setIndexesToDeleteImg(indexesToDeleteImg.concat(originalDeviceIndex));
    }

    if (currentDevice === "LineChart") {
      const updatedDevices = tempTabIndexLineChart[activeTabIndex].filter(
        (device) => device.id !== selectedDevice.id
      );
      const newTabIndexDevices = { ...tempTabIndexLineChart };
      newTabIndexDevices[activeTabIndex] = updatedDevices;

      const currentDeviceIndex = tempTabIndexLineChart[activeTabIndex].findIndex((device) => device.id === selectedDevice.id);

      setTempTabIndexLineChart(newTabIndexDevices);
      setUpdatedTabIndexLineChart(newTabIndexDevices);
      setTabIndexLineChart(newTabIndexDevices);

      lineChartRefs.current[currentDeviceIndex].style.setProperty('transform', 'translate(0px, 0px)', 'important');

      const originalDeviceIndex = tempImages.findIndex(
        (data, index) => data.id === selectedDevice.id
      );
      const newUpdatedData = tempImages.filter(
        (data) => data.id !== selectedDevice.id
      );
      setTempImages(newUpdatedData);
      setUpdatedImages(newUpdatedData);
      setIndexesToDeleteImg(indexesToDeleteImg.concat(originalDeviceIndex));
    }

    if (currentDevice === "PieChart") {
      const updatedDevices = tempTabIndexPieChart[activeTabIndex].filter(
        (device) => device.id !== selectedDevice.id
      );
      const newTabIndexDevices = { ...tempTabIndexPieChart };
      newTabIndexDevices[activeTabIndex] = updatedDevices;

      const currentDeviceIndex = tempTabIndexPieChart[activeTabIndex].findIndex((device) => device.id === selectedDevice.id);

      setTempTabIndexPieChart(newTabIndexDevices);
      setUpdatedTabIndexPieChart(newTabIndexDevices);
      setTabIndexPieChart(newTabIndexDevices);

      pieChartRefs.current[currentDeviceIndex].style.setProperty('transform', 'translate(0px, 0px)', 'important');

      const originalDeviceIndex = tempImages.findIndex(
        (data, index) => data.id === selectedDevice.id
      );
      const newUpdatedData = tempImages.filter(
        (data) => data.id !== selectedDevice.id
      );
      setTempImages(newUpdatedData);
      setUpdatedImages(newUpdatedData);
      setIndexesToDeleteImg(indexesToDeleteImg.concat(originalDeviceIndex));
    }

    if (currentDevice === "BarChart") {
      const updatedDevices = tempTabIndexBarChart[activeTabIndex].filter(
        (device) => device.id !== selectedDevice.id
      );
      const newTabIndexDevices = { ...tempTabIndexBarChart };
      newTabIndexDevices[activeTabIndex] = updatedDevices;

      const currentDeviceIndex = tempTabIndexBarChart[activeTabIndex].findIndex((device) => device.id === selectedDevice.id);

      setTempTabIndexBarChart(newTabIndexDevices);
      setUpdatedTabIndexBarChart(newTabIndexDevices);
      setTabIndexBarChart(newTabIndexDevices);

      barChartRefs.current[currentDeviceIndex].style.setProperty('transform', 'translate(0px, 0px)', 'important');

      const originalDeviceIndex = tempImages.findIndex(
        (data, index) => data.id === selectedDevice.id
      );
      const newUpdatedData = tempImages.filter(
        (data) => data.id !== selectedDevice.id
      );
      setTempImages(newUpdatedData);
      setUpdatedImages(newUpdatedData);
      setIndexesToDeleteImg(indexesToDeleteImg.concat(originalDeviceIndex));
    }

    if (currentDevice === "Histogram") {
      const updatedDevices = tempTabIndexHistogram[activeTabIndex].filter(
        (device) => device.id !== selectedDevice.id
      );
      const newTabIndexDevices = { ...tempTabIndexHistogram };
      newTabIndexDevices[activeTabIndex] = updatedDevices;

      const currentDeviceIndex = tempTabIndexHistogram[activeTabIndex].findIndex((device) => device.id === selectedDevice.id);

      setTempTabIndexHistogram(newTabIndexDevices);
      setUpdatedTabIndexHistogram(newTabIndexDevices);
      setTabIndexHistogram(newTabIndexDevices);

      histogramRefs.current[currentDeviceIndex].style.setProperty('transform', 'translate(0px, 0px)', 'important');

      const originalDeviceIndex = tempImages.findIndex(
        (data, index) => data.id === selectedDevice.id
      );
      const newUpdatedData = tempImages.filter(
        (data) => data.id !== selectedDevice.id
      );
      setTempImages(newUpdatedData);
      setUpdatedImages(newUpdatedData);
      setIndexesToDeleteImg(indexesToDeleteImg.concat(originalDeviceIndex));
    }

    if (currentDevice === "Gauge") {
      const updatedDevices = tempTabIndexGauge[activeTabIndex].filter(
        (device) => device.id !== selectedDevice.id
      );
      const newTabIndexDevices = { ...tempTabIndexGauge };
      newTabIndexDevices[activeTabIndex] = updatedDevices;

      const currentDeviceIndex = tempTabIndexGauge[activeTabIndex].findIndex((device) => device.id === selectedDevice.id);

      setTempTabIndexGauge(newTabIndexDevices);
      setUpdatedTabIndexGauge(newTabIndexDevices);
      setTabIndexGauge(newTabIndexDevices);

      gaugeRefs.current[currentDeviceIndex].style.setProperty('transform', 'translate(0px, 0px)', 'important');

      const originalDeviceIndex = tempImages.findIndex(
        (data, index) => data.id === selectedDevice.id
      );
      const newUpdatedData = tempImages.filter(
        (data) => data.id !== selectedDevice.id
      );
      setTempImages(newUpdatedData);
      setUpdatedImages(newUpdatedData);

      setIndexesToDeleteImg(indexesToDeleteImg.concat(originalDeviceIndex));
    }

    if (currentDevice === "ProgressBar") {
      const updatedDevices = tempTabIndexProgress[activeTabIndex].filter(
        (device) => device.id !== selectedDevice.id
      );
      const newTabIndexDevices = { ...tempTabIndexProgress };
      newTabIndexDevices[activeTabIndex] = updatedDevices;

      const currentDeviceIndex = tempTabIndexProgress[activeTabIndex].findIndex((device) => device.id === selectedDevice.id);

      setTempTabIndexProgress(newTabIndexDevices);
      setUpdatedTabIndexProgress(newTabIndexDevices);
      setTabIndexProgress(newTabIndexDevices);

      progressBarRefs.current[currentDeviceIndex].style.setProperty('transform', 'translate(0px, 0px)', 'important');

      const originalDeviceIndex = tempImages.findIndex(
        (data, index) => data.id === selectedDevice.id
      );
      const newUpdatedData = tempImages.filter(
        (data) => data.id !== selectedDevice.id
      );
      setTempImages(newUpdatedData);
      setUpdatedImages(newUpdatedData);
      setIndexesToDeleteImg(indexesToDeleteImg.concat(originalDeviceIndex));
    }

    if (currentDevice === "Grid") {
      const updatedDevices = tempTabIndexGrid[activeTabIndex].filter(
        (device) => device.id !== selectedDevice.id
      );
      const newTabIndexDevices = { ...tempTabIndexGrid };
      newTabIndexDevices[activeTabIndex] = updatedDevices;

      const currentDeviceIndex = tempTabIndexGrid[activeTabIndex].findIndex((device) => device.id === selectedDevice.id);

      setTempTabIndexGrid(newTabIndexDevices);
      setUpdatedTabIndexGrid(newTabIndexDevices);
      setTabIndexGrid(newTabIndexDevices);

      gridRefs.current[currentDeviceIndex].style.setProperty('transform', 'translate(0px, 0px)', 'important');

      const originalDeviceIndex = tempImages.findIndex(
        (data, index) => data.id === selectedDevice.id
      );
      const newUpdatedData = tempImages.filter(
        (data) => data.id !== selectedDevice.id
      );
      setTempImages(newUpdatedData);
      setUpdatedImages(newUpdatedData);
      setIndexesToDeleteImg(indexesToDeleteImg.concat(originalDeviceIndex));
    }

    if (currentDevice === "Table") {
      const updatedDevices = tempTabIndexTable[activeTabIndex].filter(
        (device) => device.id !== selectedDevice.id
      );
      const newTabIndexDevices = { ...tempTabIndexTable };
      newTabIndexDevices[activeTabIndex] = updatedDevices;

      const currentDeviceIndex = tempTabIndexTable[activeTabIndex].findIndex((device) => device.id === selectedDevice.id);

      setTempTabIndexTable(newTabIndexDevices);
      setUpdatedTabIndexTable(newTabIndexDevices);
      setTabIndexTable(newTabIndexDevices);

      tableRefs.current[currentDeviceIndex].style.setProperty('transform', 'translate(0px, 0px)', 'important');

      const originalDeviceIndex = tempImages.findIndex(
        (data, index) => data.id === selectedDevice.id
      );
      const newUpdatedData = tempImages.filter(
        (data) => data.id !== selectedDevice.id
      );
      setTempImages(newUpdatedData);
      setUpdatedImages(newUpdatedData);
      setIndexesToDeleteImg(indexesToDeleteImg.concat(originalDeviceIndex));
    }
  };

  // Function to delete new devices/charts  
  const handleDeviceDelete2 = () => {
    setIsDeviceMenu(false);
    if (currentDevice === "Indicator") {
      const filteredDevices = multipleDevices.filter(
        (device, index) => clickedDeviceIndex !== index
      );

      setMultipleDevices(filteredDevices);
    }

    if (currentDevice === "Display") {
      const filteredDevices = multipleDisplay.filter(
        (device, index) => clickedDeviceIndex !== index
      );

      setMultipleDisplay(filteredDevices);
    }

    if (currentDevice === "Controller") {
      const filteredDevices = multipleController.filter(
        (device, index) => clickedDeviceIndex !== index
      );
      setMultipleController(filteredDevices);
    }

    if (currentDevice === "DigitalIO") {
      const filteredDevices = multipleIO.filter(
        (device, index) => clickedDeviceIndex !== index
      );
      setMultipleIO(filteredDevices);
    }

    if (currentDevice === "FlowMeter") {
      const filteredDevices = multipleFlowM.filter(
        (device, index) => clickedDeviceIndex !== index
      );
      setMultipleFlowM(filteredDevices);
    }

    if (currentDevice === "EnergyMeter") {
      const filteredDevices = multipleEnergyM.filter(
        (device, index) => clickedDeviceIndex !== index
      );
      setMultipleEnergyM(filteredDevices);
    }

    if (currentDevice === "Motor") {
      const filteredDevices = multipleMotor.filter(
        (device, index) => clickedDeviceIndex !== index
      );
      setMultipleMotor(filteredDevices);
    }

    if (currentDevice === "Text") {
      const filteredDevices = multipleText.filter(
        (device, index) => clickedDeviceIndex !== index
      );
      setMultipleText(filteredDevices);
    }

    if (currentDevice === "LineChart") {
      const filteredDevices = multipleLineCharts.filter(
        (device, index) => clickedDeviceIndex !== index
      );
      setMultipleLineCharts(filteredDevices);
    }

    if (currentDevice === "PieChart") {
      const filteredDevices = multiplePieChart.filter(
        (device, index) => clickedDeviceIndex !== index
      );
      setMultiplePieChart(filteredDevices);
    }

    if (currentDevice === "BarChart") {
      const filteredDevices = multipleBarChart.filter(
        (device, index) => clickedDeviceIndex !== index
      );
      setMultipleBarChart(filteredDevices);
    }

    if (currentDevice === "Histogram") {
      const filteredDevices = multipleHistogram.filter(
        (device, index) => clickedDeviceIndex !== index
      );
      setMultipleHistogram(filteredDevices);
    }

    if (currentDevice === "Gauge") {
      const filteredDevices = multipleGauge.filter(
        (device, index) => clickedDeviceIndex !== index
      );
      setMultipleGauge(filteredDevices);
    }

    if (currentDevice === "ProgressBar") {
      const filteredDevices = multipleProgress.filter(
        (device, index) => clickedDeviceIndex !== index
      );
      setMultipleProgress(filteredDevices);
    }

    if (currentDevice === "Grid") {
      const filteredDevices = multipleGrid.filter(
        (device, index) => clickedDeviceIndex !== index
      );
      setMultipleGrid(filteredDevices);
    }

    if (currentDevice === "Table") {
      const filteredDevices = multipleTable.filter(
        (device, index) => clickedDeviceIndex !== index
      );
      setMultipleTable(filteredDevices);
    }
  };

  // getting selected pv/sv channels
  const handleSelectChange = (e) => {
    if (currentDevice === "PieChart") {
      setPlusIcon(true);
      const graphChannel = pieChartChannels.concat(e.target.value);
      const newSetChannels = removeDuplicates(graphChannel);
      setPieChartChannels(newSetChannels);
    }

    if (currentDevice === "BarChart") {
      setPlusIcon(true);
      const graphChannel = barChartChannels.concat(e.target.value);
      const newSetChannels = removeDuplicates(graphChannel);
      setBarChartChannels(newSetChannels);
    }

    if (currentDevice === "LineChart") {
      setPlusIcon(true);
      const graphChannel = lineChartChannels.concat(e.target.value);
      const newSetChannels = removeDuplicates(graphChannel);
      setLineChartChannels(newSetChannels);
    }

    if (currentDevice === "Histogram") {
      setPlusIcon(true);
      const graphChannel = histogramChannels.concat(e.target.value);
      const newSetChannels = removeDuplicates(graphChannel);
      setHistogramChannels(newSetChannels);
    }

    if (currentDevice === "Grid") {
      setPlusIcon(true);
      const graphChannel = gridChannels.concat(e.target.value);
      const newSetChannels = removeDuplicates(graphChannel);
      setGridChannels(newSetChannels);
    }

    if (currentDevice === "Table") {
      setPlusIcon(true);
      const graphChannel = tableChannels.concat(e.target.value);
      const newSetChannels = removeDuplicates(graphChannel);
      setTableChannels(newSetChannels);
    }
  };

  const handlePlusIcon = () => {
    setDisplaySelectTag(displaySelectTag.concat("selectTag"));
  };

  // Function to handle min range and max range of progressbar and gauge
  const handleRangeSave = () => {
    setIsRangeModal(false);
    if (currentDevice === "Gauge") {
      setChangeRange(!changeRange);
      const minValue = document.getElementById("minValue").value;
      const maxValue = document.getElementById("maxValue").value;
      setStartPoint(startPoint.concat(minValue));
      setEndPoint(endPoint.concat(maxValue));
      setSpecificMaxVal(maxValue);
      setSpecificMinVal(minValue);
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === selectedDevice.id
      );

      if (Object.keys(tempTabIndexGauge).length > 0) {
        const particularDevice = tempTabIndexGauge[activeTabIndex];
        if (particularDevice !== undefined) {
          if (selectedDevice?.id) {

            if (
              tempTabIndexGauge[activeTabIndex].some(
                (device) => device.id === selectedDevice.id
              )
            ) {
              if (particularDevice[clickedDeviceIndex]?.id === selectedDevice.id) {
                let temporaryWidgets = [...tempImages];
                const previousMinVal =
                  temporaryWidgets[originalDeviceIndex].minval;
                const previousMaxValue =
                  temporaryWidgets[originalDeviceIndex].maxVal;
                const updatedData = {
                  ...temporaryWidgets[originalDeviceIndex],
                  minVal: minValue !== null ? minValue : previousMinVal,
                  maxVal: maxValue !== null ? maxValue : previousMaxValue,
                };
                temporaryWidgets[originalDeviceIndex] = updatedData;
                setTempImages(temporaryWidgets);
                setUpdatedImages(temporaryWidgets);

                let oldtabIndexGauge = [...tempTabIndexGauge[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexGauge.findIndex(
                  (data) => data.id === selectedDevice.id
                );

                if (organizedDeviceIndex !== -1) {
                  // Ensure the index is found
                  const updatedDevice = {
                    ...oldtabIndexGauge[organizedDeviceIndex],
                    minVal: minValue !== null ? minValue : previousMinVal,
                    maxVal: maxValue !== null ? maxValue : previousMaxValue,
                  };


                  oldtabIndexGauge[organizedDeviceIndex] = updatedDevice;
                  const newTempTabIndexGauge = {
                    [activeTabIndex]: oldtabIndexGauge,
                  };

                  setTempTabIndexGauge(newTempTabIndexGauge);
                  setUpdatedTabIndexGauge(newTempTabIndexGauge);
                }
              }
            }
          }
        }
      }

      if (multipleGauge.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = multipleGauge;
          const deviceToUpdate = multipleGauge.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.minVal = minValue;
          deviceToUpdate.maxVal = maxValue;
          setMultipleGauge(updatedDeviceName);
          setUpdatedMultipleGauge(updatedDeviceName);
        }
      }

    }


    if (currentDevice === "ProgressBar") {
      const minValue = document.getElementById("minValue").value;
      const maxValue = document.getElementById("maxValue").value;
      setMaximumVal(maximumVal.concat(maxValue));
      setMinimumVal(minimumVal.concat(minValue));
      if (multipleProgress.length <= 1) {
        if (selectedDevice?.id) {
          if (
            tabIndexProgress[activeTabIndex].some(
              (device) => device.id === selectedDevice.id
            )
          ) {
            const particularDevice = tempTabIndexProgress[activeTabIndex];
            const originalDeviceIndex = tempImages.findIndex(
              (data) => data.id === selectedDevice.id
            );
            if (particularDevice[clickedDeviceIndex].id === selectedDevice.id) {
              // storing new channel name to send data in widget
              let temporaryWidgets = tempImages;
              const previousMinValue =
                temporaryWidgets[originalDeviceIndex].minVal;
              const previousMaxValue =
                temporaryWidgets[originalDeviceIndex].maxVal;

              temporaryWidgets[originalDeviceIndex].minVal =
                minValue !== null ? minValue : previousMinValue;
              temporaryWidgets[originalDeviceIndex].maxVal =
                maxValue !== null ? maxValue : previousMaxValue;
              setTempImages(temporaryWidgets);
              setUpdatedImages(temporaryWidgets);
              setImages(temporaryWidgets);

              // storing new channel name to display data in main page

              let oldtabIndexProgressBar = tempTabIndexProgress[activeTabIndex];
              const organizedDeviceIndex = oldtabIndexProgressBar.findIndex(
                (data) => data.id === selectedDevice.id
              );
              oldtabIndexProgressBar[organizedDeviceIndex].minVal =
                minValue !== null ? minValue : previousMinValue;
              oldtabIndexProgressBar[organizedDeviceIndex].maxVal =
                maxValue !== null ? maxValue : previousMaxValue;
            }
          }
        }
      }

      if (multipleProgress.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = multipleProgress;
          const deviceToUpdate = multipleProgress.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.minVal = minValue;
          deviceToUpdate.maxVal = maxValue;
          setMultipleProgress(updatedDeviceName);
          setUpdatedMultipleProgress(updatedDeviceName);
        }
      }
    }
  };

  // Function to handle rotate progressbar

  const handleRotateBar = () => {
    setRotateBar(!rotateBar);

    if (Object.keys(tempTabIndexProgress).length > 0) {
      const particularDevice = tempTabIndexProgress[activeTabIndex];
      if (particularDevice !== undefined) {
        const originalDeviceIndex = tempImages.findIndex(
          (data) => data.id === selectedDevice.id
        );
        if (
          tempTabIndexProgress[activeTabIndex].some(
            (device) => device.id === selectedDevice.id
          )
        ) {
          if (particularDevice[clickedDeviceIndex].id === selectedDevice.id) {
            // storing new channel name to send data in widget
            let temporaryWidgets = [...tempImages];

            const previousRotate = temporaryWidgets[originalDeviceIndex].rotate;

            const updatedData = {
              ...temporaryWidgets[originalDeviceIndex],
              rotate: !previousRotate,
            };

            temporaryWidgets[originalDeviceIndex] = updatedData;

            setTempImages(temporaryWidgets);
            setUpdatedImages(temporaryWidgets);
            setImages(temporaryWidgets);

            // storing new channel name to display data in main page

            let oldtabIndexProgressBar = [
              ...tempTabIndexProgress[activeTabIndex],
            ];
            const organizedDeviceIndex = oldtabIndexProgressBar.findIndex(
              (data) => data.id === selectedDevice.id
            );

            const newUpdatedData = {
              ...oldtabIndexProgressBar[organizedDeviceIndex],
              rotate: !previousRotate,
            };

            oldtabIndexProgressBar[organizedDeviceIndex] = newUpdatedData;
            const newTempTabIndexProgress = {
              [activeTabIndex]: oldtabIndexProgressBar,
            };

            setTempTabIndexProgress(newTempTabIndexProgress);
            setUpdatedTabIndexProgress(newTempTabIndexProgress);
          }
        }
      }
    }
  };

  const handleAllSelect = () => {
    const selectAll = document.getElementById("selectAllOption").checked;
    const checkBoxes = document.getElementsByName("checkBox");

    for (let i = 0; i < checkBoxes.length; i++) {
      checkBoxes[i].checked = selectAll;
    }

    const selectedHeaders = selectAll
      ? tableData.map((data) => data.Header)
      : [];
    setGridChannels(selectedHeaders);
    setSelectAllCheck(selectAll);
  };

  const handleCheckBox = (event, index, header) => {
    setSelectAllCheck(false);
    const checkBox = event.target;
    const checkBoxValue = checkBox.checked;
    if (checkBoxValue) {
      setGridChannels((prevState) => [...prevState, header]);
    } else {
      setGridChannels((prevState) =>
        prevState.filter((item) => item !== header)
      );
    }
  };


  const handleSaveCheckBox = () => {
    setIsChannelCheckBox(false);
    if (currentDevice === "Grid") {
      if (Object.keys(tempTabIndexGrid).length > 0) {
        const particularDevice = tempTabIndexGrid[activeTabIndex];
        if (particularDevice !== undefined) {
          const originalDeviceIndex = tempImages.findIndex(
            (data) => data.id === selectedDevice.id
          );

          if (selectedDevice?.id) {

            if (tempTabIndexGrid[activeTabIndex].some((device) => device.id === selectedDevice.id)) {

              if (particularDevice[clickedDeviceIndex]?.id === selectedDevice.id) {
                let temporaryWidgets = [...tempImages];
                temporaryWidgets[originalDeviceIndex].chartChannel = gridChannels;

                const updatedData = {
                  ...temporaryWidgets[originalDeviceIndex],
                  chartChannel: gridChannels,
                };
                temporaryWidgets[originalDeviceIndex] = updatedData;
                setTempImages(temporaryWidgets);
                setUpdatedImages(temporaryWidgets);

                // storing new channel name to display data in main page
                let oldtabIndexGrid = [...tempTabIndexGrid[activeTabIndex]];
                const organizedDeviceIndex = oldtabIndexGrid.findIndex(
                  (data) => data.id === selectedDevice.id
                );

                const newUpdatedData = {
                  ...oldtabIndexGrid[organizedDeviceIndex],
                  chartChannel: gridChannels,
                };

                oldtabIndexGrid[organizedDeviceIndex] = newUpdatedData;
                const newTempTabIndexDevices = {
                  [activeTabIndex]: oldtabIndexGrid,
                };

                setTempTabIndexGrid(newTempTabIndexDevices);
                setUpdatedTabIndexGrid(newTempTabIndexDevices);
              }
            }
          }
        }
      }

      if (multipleGrid.length > 1) {
        if (!selectedDevice.id) {
          const updatedDeviceName = multipleGrid;
          const deviceToUpdate = multipleGrid.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.chartChannel = gridChannels;
          setMultipleGrid(updatedDeviceName);
        }
      }
    }
    setGridChannels([]);
  };

  const handleTimePeriod = () => {
    setIsTimePeriod(true);
  };

  const handleEndTimeSelect = () => {
    const timeValue = document.getElementById("endTime").value;
    setEndSelect(timeValue);
  };

  const handleEndChange = (e) => {
    if (endSelect === "Days") {
      setEndDays(parseInt(e.target.value));
    }
    if (endSelect === "Hours") {
      setEndHours(parseInt(e.target.value));
    }
    if (endSelect === "Minutes") {
      setEndMinutes(parseInt(e.target.value));
    }
  };


  const handleEndIncrease = (e) => {
    if (endSelect === "Days") {
      setEndDays(parseInt(endDays) + 1);
    }
    if (endSelect === "Hours") {
      setEndHours(parseInt(endHours) + 1);
    }
    if (endSelect === "Minutes") {
      setEndMinutes(parseInt(endMinutes) + 1);
    }
  };

  const handleEndDecrease = () => {
    if (endSelect === "Days") {
      if (endDays > 0) {
        setEndDays(parseInt(endDays) - 1);
      }
    }
    if (endSelect === "Hours") {
      if (endHours > 0) {
        setEndHours(parseInt(endHours) - 1);
      }
    }
    if (endSelect === "Minutes") {
      if (endMinutes > 0) {
        setEndMinutes(parseInt(endMinutes) - 1);
      }
    }
  };


  // Function to handle time period in line/histogram chart 
  const handleSaveTimePeriod = () => {
    setIsTimePeriod(false);

    if (currentDevice === "LineChart") {
      if (multipleLineCharts.length <= 1) {
        const particularDevice = [...tempTabIndexLineChart[activeTabIndex]];
        if (selectedDevice?.id) {

          if (particularDevice[clickedDeviceIndex].id === selectedDevice.id) {
            const tempData = [...tempImages];

            const originalDeviceIndex = tempImages.findIndex(
              (data) => data.id === selectedDevice.id
            );

            const updatedData = {
              ...tempData[originalDeviceIndex],
              hour: endHours,
              minute: endMinutes,
            };

            tempData[originalDeviceIndex] = updatedData;
            setTempImages(tempData);
            setUpdatedImages(tempData);
            // setImages(tempData);

            const organizedDeviceIndex = particularDevice.findIndex(
              (data) => data.id === selectedDevice.id
            );

            const newUpdatedData = {
              ...particularDevice[organizedDeviceIndex],
              hour: endHours,
              minute: endMinutes,
            };

            particularDevice[organizedDeviceIndex] = newUpdatedData;
            const newDeviceData = {
              [activeTabIndex]: particularDevice,
            };

            setTempTabIndexLineChart(newDeviceData);
            setUpdatedTabIndexLineChart(newDeviceData);
          }
        }
      }

      if (multipleLineCharts.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = multipleLineCharts;
          const deviceToUpdate = multipleLineCharts.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.hour = endHours;
          deviceToUpdate.minute = endMinutes;
          setMultipleLineCharts(updatedDeviceName);
        }
      }
    }

    if (currentDevice === "Histogram") {
      if (multipleHistogram.length <= 1) {
        const particularDevice = [...tempTabIndexHistogram[activeTabIndex]];
        if (selectedDevice?.id) {

          if (particularDevice[clickedDeviceIndex].id === selectedDevice.id) {
            const tempData = [...tempImages];

            const originalDeviceIndex = tempImages.findIndex(
              (data) => data.id === selectedDevice.id
            );
            const updatedData = {
              ...tempData[originalDeviceIndex],
              hour: endHours,
              minute: endMinutes,
            };

            tempData[originalDeviceIndex] = updatedData;
            setTempImages(tempData);
            setUpdatedImages(tempData);

            const organizedDeviceIndex = particularDevice.findIndex(
              (data) => data.id === selectedDevice.id
            );
            const newUpdatedData = {
              ...particularDevice[organizedDeviceIndex],
              hour: endHours,
              minute: endMinutes,
            };

            particularDevice[organizedDeviceIndex] = newUpdatedData;
            const newDeviceData = {
              [activeTabIndex]: particularDevice,
            };

            setTempTabIndexHistogram(newDeviceData);
            setUpdatedTabIndexHistogram(newDeviceData);
          }
        }
      }
      if (multipleHistogram.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = multipleHistogram;
          const deviceToUpdate = multipleHistogram.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.hour = endHours;
          deviceToUpdate.minute = endMinutes;
          setMultipleHistogram(updatedDeviceName);
        }
      }
    }

  };

  const handleCloseTimePeriod = () => {
    setIsTimePeriod(false);
    setEndHours(0);
    setEndMinutes(0);
  };

  // Function to handle format of table widget
  const handleChangeFormat = () => {
    setTableFormat(!tableFormat);
    if (Object.keys(tempTabIndexTable).length > 0) {
      const particularDevice = tempTabIndexTable[activeTabIndex];
      if (particularDevice !== undefined) {
        const originalDeviceIndex = tempImages.findIndex(
          (data) => data.id === selectedDevice.id
        );
        if (selectedDevice?.id) {

          if (particularDevice[clickedDeviceIndex].id === selectedDevice.id) {
            // storing new channel name to send data in widget
            let temporaryWidgets = [...tempImages];
            const previousFormat = temporaryWidgets[originalDeviceIndex].column;

            const updatedData = {
              ...temporaryWidgets[originalDeviceIndex],
              column: !previousFormat,
            };
            temporaryWidgets[originalDeviceIndex] = updatedData;

            setTempImages(temporaryWidgets);
            setUpdatedImages(temporaryWidgets);
            // storing new channel name to display data in main page

            let oldtabIndexTable = [...tempTabIndexTable[activeTabIndex]];
            const organizedDeviceIndex = oldtabIndexTable.findIndex(
              (data) => data.id === selectedDevice.id
            );
            const newUpdatedData = {
              ...oldtabIndexTable[organizedDeviceIndex],
              column: !previousFormat,
            };
            oldtabIndexTable[organizedDeviceIndex] = newUpdatedData;
            const newTempTabIndexDevices = {
              [activeTabIndex]: oldtabIndexTable,
            };
            setTempTabIndexTable(newTempTabIndexDevices);
            setUpdatedTabIndexTable(newTempTabIndexDevices);
          }
        }
      }
    }

    if (multipleTable.length > 1) {
      if (!selectedDevice?.id) {
        const updatedDeviceName = multipleTable;
        updatedDeviceName[clickedDeviceIndex].column = !tableFormat;
        setMultipleTable(updatedDeviceName);
      }
    }
  };

  // Function to handle the position of a info component in a overview tab 
  const handleOverviewInfoDrop = (e, data, id, x, y) => {
    const newPosition = { x: data.x, y: data.y };
    setOverviewInfoPosi(newPosition);
    if (Object.keys(tempTabIndexOverviewInfo).length > 0) {
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedTempImages = [...tempImages];
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x !== null ? x + data.x : 70,
        y: y !== null ? y + data.y : 20,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;
      console.log("overviewInfoFun:", updatedTempImages[originalDeviceIndex]);

      setTempImages(updatedTempImages);
      setUpdatedImages(updatedTempImages);
    }
  };

  // Function to handle the position of a graph component in a overview tab 
  const handleOverviewGraphDrop = (e, data, id, x, y) => {

    const newPosition = { x: data.x, y: data.y };
    setOverviewGraphPosi(newPosition);
    if (Object.keys(tempTabIndexOverviewGraph).length > 0) {
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedTempImages = [...tempImages];
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x !== null ? x + data.x : 570,
        y: y !== null ? y + data.y : 70,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;
      console.log("overviewGraphFun:", updatedTempImages[originalDeviceIndex]);

      setTempImages(updatedTempImages);
      setUpdatedImages(updatedTempImages);
    }
  };

  // Function to handle the width/height of a graph component in a overview tab 
  const handleOverviewGraphDimension = (e, id) => {
    e.preventDefault();
    const updatedTempImages = [...tempImages];
    const dimension = document.getElementById("overviewGraph");
    const newDimension = {
      width: dimension.style.width,
      height: dimension.style.height,
    };


    setOverviewGraphDimension(newDimension);
    if (Object.keys(tabIndexOverviewGraph).length > 0) {
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        width: newDimension.width,
        height: newDimension.height,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;

      setTempImages(updatedTempImages);
      setUpdatedImages(updatedTempImages);
    }
  };

  // Function to handle the position of a graph component in a livedata tab 

  const handleLiveDataGraphDrop = (e, data, id, x, y) => {
    const newPosition = { x: data.x, y: data.y };

    setLiveDataGraphPosi(newPosition);
    if (Object.keys(tempTabIndexLiveDataGraph).length > 0) {
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedTempImages = [...tempImages];
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x !== null ? x + data.x : 277,
        y: y !== null ? y + data.y : 60,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);
      setUpdatedImages(updatedTempImages);

    }
  };

  // Function to handle the width/height of a graph component in a livedata tab 

  const handleLiveDataDimension = (e, id) => {
    e.preventDefault();
    const updatedTempImages = [...tempImages];
    const dimension = document.getElementById("liveDataGraph");
    const newDimension = {
      width: dimension.style.width,
      height: dimension.style.height,
    };

    setLiveDataGraphDimension(newDimension);
    if (Object.keys(tabIndexLiveDataGraph).length > 0) {
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        width: newDimension.width,
        height: newDimension.height,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);
      setUpdatedImages(updatedTempImages);
    }
  };


  // Function to handle the position of a table component in a 24-hour tab 
  const handleLastDayTableDrop = (e, data, id, x, y) => {
    const newPosition = { x: data.x, y: data.y };
    setLastDayTablePosi(newPosition);
    if (Object.keys(tempTabIndexLastDayTable).length > 0) {
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedTempImages = [...tempImages];
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x !== null ? x + data.x : 200,
        y: y !== null ? y + data.y : 10,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);
      setUpdatedImages(updatedTempImages);
    }
  };

  // Function to handle the width/height of a table component in a 24-hour tab 
  const handleLastDayTableDimension = (e, id) => {
    e.preventDefault();
    const updatedTempImages = [...tempImages];
    const dimension = document.getElementById("lastDayTable");
    const newDimension = {
      width: dimension.style.width,
      height: dimension.style.height,
    };

    setLastDayTableDimension(newDimension);
    if (Object.keys(tabIndexLastDayTable).length > 0) {
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        width: newDimension.width,
        height: newDimension.height,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);
      setUpdatedImages(updatedTempImages);
    }
  };

  // Function to handle the position of a graph component in a 24-hour tab  
  const handleLastDayGraphDrop = (e, data, id, x, y) => {
    const newPosition = { x: data.x, y: data.y };
    setLastDayGraphPosi(newPosition);
    if (Object.keys(tempTabIndexLastDayGraph).length > 0) {
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedTempImages = [...tempImages];
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x !== null ? x + data.x : 500,
        y: y !== null ? y + data.y : 80,
      };

      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);
      setUpdatedImages(updatedTempImages);
    }
  };

  // Function to handle the width/height of a graph component in a 24-hour tab 
  const handleLastDayGraphDimension = (e, id) => {
    e.preventDefault();
    const updatedTempImages = [...tempImages];
    const dimension = document.getElementById("lastDayGraph");
    const newDimension = {
      width: dimension.style.width,
      height: dimension.style.height,
    };

    setLastDayGraphDimension(newDimension);
    if (Object.keys(tabIndexLastDayGraph).length > 0) {
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        width: newDimension.width,
        height: newDimension.height,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);
      setUpdatedImages(updatedTempImages);
    }
  };

  // Function to handle the width/height in a Gridview Tab 
  const handleDefaultGridDimension = (e, id) => {
    e.preventDefault();
    const updatedTempImages = [...tempImages];
    const dimension = document.getElementById("DefaultGrid");
    const newDimension = {
      width: dimension.style.width,
      height: dimension.style.height,
    };

    setDefaultGridDimension(newDimension);
    if (Object.keys(tabIndexDefaultGrid).length > 0) {
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        width: newDimension.width,
        height: newDimension.height,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);
      setUpdatedImages(updatedTempImages);
    }
  };

  // Function to handle position of grid view of tab
  const handleDefaultGridDrop = (e, data, id, x, y) => {
    const newPosition = { x: data.x, y: data.y };
    setDefaultGridPosi(newPosition);
    if (Object.keys(tempTabIndexDefaultGrid).length > 0) {
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );
      const updatedTempImages = [...tempImages];
      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        x: x + data.x,
        y: y + data.y,
      };
      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);
      setUpdatedImages(updatedTempImages);
    }
  };

  // Function to handle double click on device
  const handleDoubleClick = (e, deviceType, id, on) => {

    // V-Net Configuration api's
    const currentTime = new Date();
    const currentTimeMilliseconds = currentTime.getTime();

    const signString = `alias=${vNetUsername}&comid=${comid}&compvtkey=${compvtkey}&password=${vNetPassword}&ts=${currentTimeMilliseconds}&key=${key}`;

    const sign = CryptoJS.MD5(signString).toString();
    const data = {
      vNetUsername: vNetUsername,
      vNetPassword: vNetPassword,
      comid: comid,
      compvtkey: compvtkey,
      ts: currentTimeMilliseconds.toString(),
      sign: sign
    }

    //login Api
    dispatch(vNetLogin(data)).then((response) => {
      if (response?.payload?.code === 200) {
        const sid = response?.payload?.result?.sid;
        setLoginSid(sid)
      }
    })

    if (id) {
      setSelectedDeviceId(id);
    }

    setCurrentDevice(deviceType);

    if (deviceType === "Controller") {

      setIsChangeSV(true);

    }
    if (deviceType === "DigitalIO") {

      const updatedTempImages = [...tempImages];
      const originalDeviceIndex = tempImages.findIndex(
        (data) => data.id === id
      );

      const updatedData = {
        ...updatedTempImages[originalDeviceIndex],
        on: !on
      };

      updatedTempImages[originalDeviceIndex] = updatedData;
      setTempImages(updatedTempImages);
      setUpdatedImages(updatedTempImages);

      let oldtabIndexItem = [...tempTabIndexIO[activeTabIndex]];
      const organizedDeviceIndex = oldtabIndexItem.findIndex(
        (data) => data.id === id
      );

      const newUpdatedData = {
        ...oldtabIndexItem[organizedDeviceIndex],
        on: !on
      };

      oldtabIndexItem[organizedDeviceIndex] = newUpdatedData;
      const newTempTabIndexDevices = {
        [activeTabIndex]: oldtabIndexItem,
      };

      setUpdatedTabIndexIO(newTempTabIndexDevices);
      setTempTabIndexIO(newTempTabIndexDevices);
    }
  }


  // Function to handle change tag value of v-net
  const handleChangeSV = () => {
    if (currentDevice === "Controller") {
      const newSVValue = document.getElementById("changeSV").value;
      const currentPageRows = document.getElementById("currentPageRows").value;
      const currentPage = document.getElementById("currentPage").value;
      const tagName = document.getElementById("tagName").value;

      const currentTime = new Date();
      const currentTimeMilliseconds = currentTime.getTime();
      if (loginSid) {
        const boxesSignString = `comid=${comid}&compvtkey=${compvtkey}&sid=${loginSid}&ts=${currentTimeMilliseconds}&key=${key}`;
        const boxesSign = CryptoJS.MD5(boxesSignString).toString();

        const boxesApi = {
          comid: comid,
          compvtkey: compvtkey,
          ts: currentTimeMilliseconds.toString(),
          sign: boxesSign,
          sid: loginSid
        };
        // get groups and box from the v-net
        dispatch(getVnetBoxes(boxesApi)).then((responseBoxes) => {
          const boxsList = responseBoxes?.payload?.result?.list;
          return boxsList;
        }).then((boxesList) => {
          if (boxesList.length > 0) {
            const selectedCustomTag = boxesList.find((box) => box.groupName === deviceGroupName);
            const selectedBox = selectedCustomTag?.boxList.find((box) => box.boxName === deviceName);
            if (!selectedCustomTag) {
              toast({
                title: "Couldn't find the device group name with given name",
                status: "error",
                position: "top-right",
                duration: 3000,
                isClosable: true,
              })
            }
            if (!selectedBox) {
              toast({
                title: "Couldn't find the box with given name",
                status: "error",
                position: "top-right",
                duration: 3000,
                isClosable: true,
              })
            }
            return { selectedCustomTag, selectedBox };
          } else {
            toast({
              title: "No device group are available",
              status: "error",
              position: "top-right",
              duration: 3000,
              isClosable: true,
            })
          }
          // throw new Error('No boxes found');
        }).then(({ selectedCustomTag, selectedBox }) => {
          // get real time tags from v-net

          if (selectedBox) {
            const realGroupSignString = `boxId=${selectedBox?.boxId}&comid=${comid}&compvtkey=${compvtkey}&sid=${loginSid}&ts=${currentTimeMilliseconds}&key=${key}`
            const realGroupSign = CryptoJS.MD5(realGroupSignString).toString();

            const realGroupApi = {
              boxId: selectedBox?.boxId,
              sid: loginSid,
              comid: comid,
              compvtkey: compvtkey,
              ts: currentTimeMilliseconds,
              sign: realGroupSign
            }

            dispatch(getVnetRealGroups(realGroupApi)).then((responseRealGroups) => {
              const groupList = responseRealGroups?.payload?.result?.list;
              if (groupList) {
                const selectedGroup = groupList.find((group) => group.groupName === tagGroupName);
                if (!selectedGroup) {
                  toast({
                    title: "Couldn't find tag group with given name",
                    status: "error",
                    position: "top-right",
                    duration: 3000,
                    isClosable: true,
                  })
                }
                return { selectedGroup, selectedBox }
              }
            }).then(({ selectedGroup, selectedBox }) => {

              if (selectedBox && selectedGroup) {
                const realTimeTagsSignString = `boxId=${selectedBox?.boxId}&comid=${comid}&compvtkey=${compvtkey}&groupId=${selectedGroup?.groupId}&pageIndex=${currentPage}&pageSize=${currentPageRows}&sid=${loginSid}&ts=${currentTimeMilliseconds}&key=${key}`;
                const realTimeTagsSign = CryptoJS.MD5(realTimeTagsSignString).toString();
                const realTimeTagsApi = {
                  boxId: selectedBox.boxId.toString(),
                  groupId: selectedGroup.groupId.toString(),
                  pageSize: currentPageRows.toString(),
                  pageIndex: currentPage.toString(),
                  sid: loginSid,
                  comid: comid,
                  compvtkey: compvtkey,
                  ts: currentTimeMilliseconds.toString(),
                  sign: realTimeTagsSign
                };

                dispatch(getRealTimeTags(realTimeTagsApi)).then((responseRealTimeTags) => {
                  const tagList = responseRealTimeTags?.payload?.result?.list;
                  if (tagList) {
                    if (tagList.length > 0) {
                      const tagInfo = tagList.find((tag) => tag.monitorName === tagName);
                      if (!tagInfo) {
                        toast({
                          title: "Couldn't find tag with given name",
                          status: "error",
                          position: "top-right",
                          duration: 3000,
                          isClosable: true,
                        })
                      }
                      return tagInfo;
                    }
                  }

                }).then((tagInfo) => {
                  if (tagInfo) {
                    const updatedTagSignString = `comid=${comid}&compvtkey=${compvtkey}&monitorId=${tagInfo?.monitorId}&sid=${loginSid}&ts=${currentTimeMilliseconds}&value=${newSVValue}&key=${key}`;
                    const updatedTagSign = CryptoJS.MD5(updatedTagSignString).toString();
                    const updateTagApi = {
                      monitorId: tagInfo?.monitorId,
                      value: newSVValue,
                      comid: comid,
                      compvtkey: compvtkey,
                      ts: currentTimeMilliseconds,
                      sign: updatedTagSign,
                      sid: loginSid
                    }

                    dispatch(updateTagValue(updateTagApi)).then((response) => {
                      if (response.payload.code === 200) {
                        toast({
                          title: `Successfully updated ${tagInfo?.monitorName} tag value`,
                          status: "success",
                          position: "top-right",
                          duration: 3000,
                          isClosable: true,
                        })
                      }
                    })
                  }
                })
              }
            })
          }
        }).catch((error) => {
          console.error('Error:', error.message);
        });
      } else {
        toast({
          title: "Something is wrong with V-net credentials",
          status: "error",
          position: "top-right",
          duration: 3000,
          isClosable: true,
        })
      }
    }

    setIsChangeSV(false);
  }

  const handlePageClick = () => {
    if (isMotorOptions) {
      setIsMotorOptions(false)
    }

    if (isDeviceOption) {
      setIsDeviceOption(false);
    }
  }

  // Handle text size , bg color , text color, text
  const handleChangeBg = () => {

    // Applying bgColor, textColor and font to display

    if (currentDevice === "Display") {
      if (Object.keys(tempTabIndexDisplay).length > 0) {
        const particularDevice = tempTabIndexDisplay[activeTabIndex];
        if (particularDevice !== undefined) {
          const originalDeviceIndex = tempImages.findIndex(
            (data) => data.id === selectedDevice.id
          );
          if (particularDevice[clickedDeviceIndex]?.id === selectedDevice.id) {
            // storing new channel name to send data in widget
            let temporaryWidgets = [...tempImages];


            const updatedData = {
              ...temporaryWidgets[originalDeviceIndex],
              bgColor: bgColor,
              textColor: textColor,
              fontSize: fontSize
            };

            temporaryWidgets[originalDeviceIndex] = updatedData;

            setTempImages(temporaryWidgets);
            setUpdatedImages(temporaryWidgets);

            // storing new channel name to display data in main page

            let oldtabIndexTable = [...tempTabIndexDisplay[activeTabIndex]];

            const organizedDeviceIndex = oldtabIndexTable.findIndex(
              (data) => data.id === selectedDevice.id
            );

            const newUpdatedData = {
              ...oldtabIndexTable[organizedDeviceIndex],
              bgColor: bgColor,
              textColor: textColor,
              fontSize: fontSize
            };

            oldtabIndexTable[organizedDeviceIndex] = newUpdatedData;

            const newTempTabIndexDevices = {
              [activeTabIndex]: oldtabIndexTable,
            };

            setTempTabIndexDisplay(newTempTabIndexDevices);
            setUpdatedTabIndexDisplay(newTempTabIndexDevices);
          }
        }
      }

      if (multipleDisplay.length > 1) {
        if (!selectedDevice?.id) {

          const updatedDeviceName = [...multipleDisplay];
          const deviceToUpdate = multipleDisplay.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.bgColor = bgColor;
          deviceToUpdate.fontSize = fontSize;
          deviceToUpdate.textColor = textColor;
          setMultipleDisplay(updatedDeviceName);
        }
      }

    }

    // Applying bgColor, textColor and font to text widget
    if (currentDevice === "Text") {

      if (Object.keys(tempTabIndexText).length > 0) {
        const particularDevice = tempTabIndexText[activeTabIndex];
        if (particularDevice !== undefined) {
          const originalDeviceIndex = tempImages.findIndex(
            (data) => data.id === selectedDevice.id
          );

          if (selectedDevice?.id) {

            if (particularDevice[clickedDeviceIndex]?.id === selectedDevice?.id) {

              // storing new channel name to send data in widget
              let temporaryWidgets = [...tempImages];

              const updatedData = {
                ...temporaryWidgets[originalDeviceIndex],
                bgColor: textBgColor,
                textColor: widgetTextColor,
                fontSize: textFontSize,
                text: addedText,
                alignVertically: textAlignVertically,
                alignHorizontally: textAlignHorizontally
              };

              temporaryWidgets[originalDeviceIndex] = updatedData;

              setTempImages(temporaryWidgets);
              setUpdatedImages(temporaryWidgets);

              // storing new channel name to display data in main page

              let oldtabIndexTable = [...tempTabIndexText[activeTabIndex]];

              const organizedDeviceIndex = oldtabIndexTable.findIndex(
                (data) => data.id === selectedDevice.id
              );

              const newUpdatedData = {
                ...oldtabIndexTable[organizedDeviceIndex],
                bgColor: textBgColor,
                textColor: widgetTextColor,
                fontSize: textFontSize,
                text: addedText,
                alignVertically: textAlignVertically,
                alignHorizontally: textAlignHorizontally
              };

              oldtabIndexTable[organizedDeviceIndex] = newUpdatedData;

              const newTempTabIndexDevices = {
                [activeTabIndex]: oldtabIndexTable,
              };

              setTempTabIndexText(newTempTabIndexDevices);
              setUpdatedTabIndexText(newTempTabIndexDevices);
            }
          }
        }
      }

      if (multipleText.length > 1) {
        if (!selectedDevice?.id) {
          const updatedDeviceName = [...multipleText];
          const deviceToUpdate = multipleText.find(
            (element, index) => index === clickedDeviceIndex
          );
          deviceToUpdate.bgColor = textBgColor;
          deviceToUpdate.fontSize = textFontSize;
          deviceToUpdate.textColor = widgetTextColor;
          deviceToUpdate.text = addedText;
          deviceToUpdate.alignVertically = textAlignVertically;
          deviceToUpdate.alignHorizontally = textAlignHorizontally;


          setMultipleText(updatedDeviceName);
        }
      }

    }
    setIsBgColor(false);
  }



  const handleFontSizeInputChange = (e) => {
    const value = Math.max(7, parseInt(e.target.value));
    if (currentDevice === "Display") {
      setFontSize(value);
    } else if (currentDevice === "Text") {
      setTextFontSize(value);
    }
  }

  // Function Increase font size

  const handleIncreaseFontSize = () => {
    if (currentDevice === "Display") {
      setFontSize(fontSize + 1);
    } else if (currentDevice === "Text") {
      setTextFontSize(textFontSize + 1);
    }
  }


  // Function Decrease font size
  const handleDecreaseFontSize = () => {
    if (currentDevice === "Display") {
      if (fontSize > 1) {
        setFontSize(fontSize - 1);
      }
    } else if (currentDevice === "Text") {
      if (textFontSize > 7) {
        setTextFontSize(textFontSize - 1);
      }
    }
  }

  return (
    <>
      {/* onClick={isMotorOptions ? () => setIsMotorOptions(false) : undefined} */}
      <div className={styles.super} onClick={handlePageClick}>
        <div className={styles.wrap}>Dashboard &gt; ViewData &gt; {isPath}</div>
        <div className={styles.newPage}>
          <div className={styles.superWrapper}>
            {/* <Navbar /> */}
            <div className={styles.wrapperRight}>
              {/* <Titlebar title="Project Data" /> */}

              <div className={styles.wrapper}>
                {/* Saved Widgets */}
                {TempGenerateImage()}
                {TempGenerateDevice()}
                {TempGenerateDisplay()}
                {TempGenerateController()}
                {tempGenerateIO()}
                {TempGenerateFlowMeter()}
                {TempGenerateEnergyNeter()}
                {TempGenerateMotor()}
                {TempGenerateLineChart()}
                {TempGeneratePieChart()}
                {TempGenerateBarChart()}
                {TempGenerateHistogram()}
                {TempGenerateGauge()}
                {TempGenerateProgressBar()}
                {TempGenerateGrid()}
                {TempGenerateTable()}
                {TempGenerateText()}

                {/* New Widgets */}

                {multipleImages.map((image, imgIndex) => {
                  const imagePositions = imagePosition[imgIndex];
                  const imageStyle = {
                    resize: "both",
                    width: "100px",
                    height: "100px",
                  };
                  return (
                    <Draggble
                      onStop={(e, data) => handleDragStop(e, data, imgIndex)}
                      position={imagePositions}
                    >
                      <div
                        className={`${styles.imgToDisplay} ${styles.addImg}`}
                        id={`displayImage-${imgIndex}`}
                        style={imageStyle}
                        onContextMenu={(e) => getDimensions(e, imgIndex)}
                      >
                        <img
                          src={image}
                          alt=""
                          className={styles.addImg}
                          onContextMenu={(event) =>
                            HandleDeleteContextMenu(event, imgIndex)
                          }
                        />
                      </div>
                    </Draggble>
                  );
                })}

                {multipleDisplay.length > 1 &&
                  multipleDisplay.slice(1).map((device, index) => {
                    // Check if the device belongs to the active tab
                    if (device.tabIndex === activeTabIndex) {

                      const deviceStyle = {
                        position: "absolute",
                        left: device.x === undefined ? "0%" : device.x,
                        top: device.y === undefined ? "40px" : device.y,
                        fontSize: device.fontSize ? device.fontSize + "px" : "30px",
                        color: device.textColor ? device.textColor : "#D90211",
                        backgroundColor: device.bgColor ? device.bgColor : "#542A2A"
                      };

                      return (
                        <Draggble
                          key={index} // Add a unique key prop for each draggable element
                          bounds="parent"
                          onStop={(e, data) =>
                            handleDeviceDrop(e, data, index + 1, device.type)
                          }
                        >
                          <div
                            className={`${styles.displayContainer} ${styles.addImg}`}
                            style={deviceStyle}
                            onContextMenu={(event) =>
                              handleDeviceMenu(
                                event,
                                index + 1,
                                device.deviceName,
                                device.type,
                                device.id,
                                null,
                                device.bgColor,
                                device.textColor,
                                device.fontSize,

                              )
                            }
                            id={`newDisplay-${index + 1}`}
                          >
                            <Display
                              device={device.deviceName}
                              tableData={tableData}
                              pvSpecificChannel={pvSpecificChannel[index]}
                              multipleDisplay={multipleDisplay}
                              openNewPage={openNewPage}
                              pvChannel={device.pvChannel}
                              index={index + 1}
                              currentPVChannel={currentPVChannel}
                              setCurrentPVChannel={setCurrentPVChannel}
                            />
                          </div>
                        </Draggble>
                      );
                    } else {
                      return null; // Don't render if the device doesn't belong to the active tab
                    }
                  })}

                {/* New Indicators */}

                {multipleDevices.length > 1 &&
                  multipleDevices.slice(1).map((device, index) => {
                    // Check if the device belongs to the active tab
                    if (device.tabIndex === activeTabIndex) {

                      const deviceStyle = {
                        position: "absolute",
                        left: device.x === undefined ? "0%" : device.x,
                        top: device.y === undefined ? "40px" : device.y,
                      };

                      return (
                        <Draggble
                          key={index}
                          bounds="parent"
                          onStop={(e, data) =>
                            handleDeviceDrop(e, data, index + 1, device.type)
                          }
                        >
                          <div
                            className={`${styles.deviceContainer} ${styles.addImg}`}
                            style={deviceStyle}
                            onContextMenu={(event) =>
                              handleDeviceMenu(
                                event,
                                index + 1,
                                device.deviceName,
                                device.type,
                                device.id
                              )
                            }
                          >
                            <Indicator
                              device={device.deviceName}
                              tableData={tableData}
                              pvSpecificChannel={pvSpecificChannel[index]}
                              multipleDevices={multipleDevices}
                              openNewPage={openNewPage}
                              pvChannel={device.pvChannel}
                              index={index + 1}
                              currentPVChannel={currentPVChannel}
                              setCurrentPVChannel={setCurrentPVChannel}
                            />
                          </div>
                        </Draggble>
                      );
                    } else {
                      return null;
                    }
                  })}

                {/* New Controllers */}
                {multipleController.length > 1 &&
                  multipleController.slice(1).map((device, index) => {
                    if (device.tabIndex === activeTabIndex) {
                      const deviceStyle = {
                        position: "absolute",
                        left: device.x === undefined ? "0%" : device.x,
                        top: device.y === undefined ? "40px" : device.y,
                      };
                      return (
                        <Draggble
                          bounds="parent"
                          onStop={(e, data) =>
                            handleDeviceDrop(e, data, index + 1, device.type)
                          }
                        >
                          <div
                            className={`${styles.deviceContainer} ${styles.addImg}`}
                            style={deviceStyle}
                            ref={el => newControllerRefs.current[index] = el}
                            onContextMenu={(event) =>
                              handleDeviceMenu(
                                event,
                                index + 1,
                                device.deviceName,
                                device.type
                              )
                            }
                          >
                            <Controller
                              device={device.deviceName}
                              tableData={tableData}
                              multipleController={multipleController}
                              setMultipleController={setMultipleController}
                              currentPVChannel={currentPVController}
                              currentSVChannel={currentSVContoller}
                              pvControllerChannel={pvControllerChannel}
                              svControllerChannel={svControllerChannel}
                              index={index + 1}
                              pvChannel={device.pvChannel}
                              svChannel={device.svChannel}
                            />
                          </div>
                        </Draggble>
                      );
                    } else {
                      return null;
                    }
                  })}
                {/* New DigitalI/O */}

                {multipleIO.length > 1 &&
                  multipleIO.slice(1).map((device, index) => {
                    if (device.tabIndex === activeTabIndex) {
                      const deviceStyle = {
                        position: "absolute",
                        left: device.x === undefined ? "0%" : device.x,
                        top: device.y === undefined ? "40px" : device.y,
                      };
                      return (
                        <Draggble
                          bounds="parent"
                          onStop={(e, data) =>
                            handleDeviceDrop(e, data, index + 1, device.type)
                          }
                        >
                          <div
                            className={`${styles.ioContainer} ${styles.addImg}`}
                            style={deviceStyle}
                            onContextMenu={(event) =>
                              handleDeviceMenu(
                                event,
                                index + 1,
                                device.deviceName,
                                device.type
                              )
                            }
                          >
                            <DigitalIO device={device.deviceName} />
                          </div>
                        </Draggble>
                      );
                    } else {
                      return null;
                    }
                  })}
                {/* New FlowMeter */}

                {multipleFlowM.length > 1 &&
                  multipleFlowM.slice(1).map((device, index) => {
                    if (device.tabIndex === activeTabIndex) {
                      const deviceStyle = {
                        position: "absolute",
                        left: device.x === undefined ? "0%" : device.x,
                        top: device.y === undefined ? "40px" : device.y,
                      };
                      return (
                        <Draggble
                          bounds="parent"
                          onStop={(e, data) =>
                            handleDeviceDrop(e, data, index + 1, device.type)
                          }
                        >
                          <div
                            className={`${styles.flowMContainer} ${styles.addImg}`}
                            style={deviceStyle}
                            onContextMenu={(event) =>
                              handleDeviceMenu(
                                event,
                                index + 1,
                                device.deviceName,
                                device.type
                              )
                            }
                          >
                            <FlowMeter
                              device={device.deviceName}
                              tableData={tableData}
                              currentPVChannel={currentPVFlowM}
                              currentSVChannel={currentSVFlowM}
                              pvFlowMChannels={pvFlowMChannels[index]}
                              svFlowMChannels={svFlowMChannels[index]}
                              pvChannel={device.pvChannel}
                              svChannel={device.svChannel}
                              multipleFlowM={multipleFlowM}
                              index={index + 1}
                            />
                          </div>
                        </Draggble>
                      );
                    } else {
                      return null;
                    }
                  })}
                {/* New EnergyMeter */}

                {multipleEnergyM.length > 1 &&
                  multipleEnergyM.slice(1).map((device, index) => {
                    if (device.tabIndex === activeTabIndex) {
                      const deviceStyle = {
                        position: "absolute",
                        left: device.x === undefined ? "0%" : device.x,
                        top: device.y === undefined ? "40px" : device.y,
                      };
                      return (
                        <Draggble
                          bounds="parent"
                          onStop={(e, data) =>
                            handleDeviceDrop(e, data, index + 1, device.type)
                          }
                        >
                          <div
                            className={`${styles.energyMContainer} ${styles.addImg}`}
                            style={deviceStyle}
                            onContextMenu={(event) =>
                              handleDeviceMenu(
                                event,
                                index + 1,
                                device.deviceName,
                                device.type
                              )
                            }
                          >
                            <EnergyMeter
                              device={device.deviceName}
                              tableData={tableData}
                              currentPVChannel={currentPVEnergyM}
                              currentSVChannel={currentSVEnergyM}
                              pvEnergyMChannels={pvEnergyMChannels}
                              svEnergyMChannels={svEnergyMChannels}
                              pvChannel={device.pvChannel}
                              svChannel={device.svChannel}
                              svChannel2={device.svChannel2}
                              svChannel3={device.svChannel3}
                              multipleEnergyM={multipleEnergyM}
                              index={index + 1}
                            />
                          </div>
                        </Draggble>
                      );
                    } else {
                      return null;
                    }
                  })}

                {/* New Text   */}
                {multipleText.length > 1 &&
                  multipleText.slice(1).map((device, index) => {

                    if (device.tabIndex === activeTabIndex) {
                      const deviceStyle = {
                        position: "absolute",
                        left: device.x === undefined ? "0%" : device.x,
                        top: device.y === undefined ? "40px" : device.y,
                        fontSize: device.fontSize ? device.fontSize + "px" : "30px",
                        color: device.textColor ? device.textColor : "#000000",
                        backgroundColor: device.bgColor ? device.bgColor : "#ffffff",
                        resize: "both",
                        padding: "0.6rem 1rem",
                        display: "flex",
                        wordBreak: "break-word",
                        justifyContent: device.alignHorizontally ? device.alignHorizontally : "start",
                        alignItems: device.alignVertically ? device.alignVertically : "start"
                      };
                      return (
                        <Draggble
                          bounds="parent"
                          onStop={(e, data) =>
                            handleDeviceDrop(e, data, index + 1, device.type)
                          }
                        >
                          <div
                            className={`${styles.addImg}`}
                            style={deviceStyle}
                            onContextMenu={(event) =>
                              handleDeviceMenu(
                                event,
                                index + 1,
                                device.deviceName,
                                device.type,
                                null,
                                device.bgColor,
                                device.textColor,
                                device.fontSize,
                                device.text,
                                device.alignVertically,
                                device.alignHorizontally,
                              )
                            }
                            id={`newText-${index + 1}`}
                          >
                            {device.text}
                          </div>
                        </Draggble>
                      );
                    } else {
                      return null;
                    }
                  })}

                {/* New Motors */}
                {multipleMotor?.length > 1 &&
                  multipleMotor?.slice(1).map((image, imgIndex) => {
                    if (image.tabIndex === activeTabIndex) {

                      const imageStyle = {
                        resize: "both",
                        width: "250px",
                        height: "150px",
                      };

                      return (
                        <Draggble
                          key={imgIndex} // Add a unique key prop for each draggable element
                          bounds="parent"
                          onStop={(e, data) => handleDeviceDrop(e, data, imgIndex + 1, image.type)}

                        >
                          <div
                            className={`${styles.imgToDisplay} ${styles.addImg}`}
                            style={imageStyle}
                            onContextMenu={(event) =>
                              handleDeviceMenu(
                                event,
                                imgIndex + 1,
                                image.deviceName,
                                image.type,
                                image.id
                              )
                            }
                            id={`motor-${imgIndex + 1}`}
                          >

                            <img
                              src={image.image}
                              alt=""
                              className={styles.addImg}

                            />
                          </div>
                        </Draggble>
                      );
                    } else {
                      return null; // Don't render if the device doesn't belong to the active tab
                    }
                  })
                }

                {multipleLineCharts.length > 1 &&
                  multipleLineCharts.slice(1).map((device, index) => {
                    if (device.tabIndex === activeTabIndex) {
                      const deviceStyle = {
                        position: "absolute",
                        left: device.x === undefined ? "0%" : device.x,
                        top: device.y === undefined ? "40px" : device.y,
                      };
                      return (
                        <Draggble
                          bounds="parent"
                          onStop={(e, data) =>
                            handleDeviceDrop(e, data, index + 1, device.type)
                          }
                        >
                          <div
                            className={`${styles.lineChartContainer} ${styles.addImg}`}
                            style={deviceStyle}
                            onContextMenu={(event) =>
                              handleDeviceMenu(
                                event,
                                index + 1,
                                device.deviceName,
                                device.type,
                                device
                              )
                            }
                            id={`newLineChart-${index + 1}`}
                          >
                            <NewLineChart
                              dateTimesLive={dateTimesLive}
                              channelNames={channelNames}
                              dataArrLive={dataArrLive}
                              getRandomColor={getRandomColor}
                              tabIndexPieChart={tabIndexPieChart}
                              selectedTabIndex={activeTabIndex}
                              openNewPage={openNewPage}
                              chartChannel={device.chartChannel}
                              data={data}
                              endHours={endHours}
                              endMinutes={endMinutes}
                              oneDayDataTs={oneDayDataTs}
                            />
                          </div>
                        </Draggble>
                      );
                    } else {
                      return null;
                    }
                  })}

                {multiplePieChart.length > 1 &&
                  multiplePieChart.slice(1).map((device, index) => {
                    if (device.tabIndex === activeTabIndex) {
                      const deviceStyle = {
                        position: "absolute",
                        left: device.x === 0 ? "0%" : device.x,
                        top: device.y === 0 ? "40px" : device.y,
                        width:
                          device.width === undefined ? "27%" : device.width,

                      };
                      return (
                        <Draggble
                          bounds="parent"
                          onStop={(e, data) =>
                            handleDeviceDrop(
                              e,
                              data,
                              index + 1,
                              device.type,
                              device.x,
                              device.y
                            )
                          }
                        >
                          <div
                            className={`${styles.pieChartContainer} ${styles.addImg}`}
                            style={deviceStyle}
                            onContextMenu={(event) =>
                              handleDeviceMenu(
                                event,
                                index + 1,
                                device.deviceName,
                                device.type,
                                device
                              )
                            }
                            id={`newPieChart-${index + 1}`}
                          >
                            <NewPieChart
                              dateTimesLive={dateTimesLive}
                              channelNames={channelNames}
                              dataArrLive={dataArrLive}
                              getRandomColor={getRandomColor}
                              tabIndexPieChart={tabIndexPieChart}
                              selectedTabIndex={activeTabIndex}
                              chartChannel={device.chartChannel}
                              openNewPage={openNewPage}
                              pieChartChannels={pieChartChannels}
                            />
                          </div>
                        </Draggble>
                      );
                    } else {
                      return null;
                    }
                  })}

                {multipleBarChart.length > 1 &&
                  multipleBarChart.slice(1).map((device, index) => {
                    if (device.tabIndex === activeTabIndex) {
                      const deviceStyle = {
                        position: "absolute",
                        left: device.x === undefined ? "0%" : device.x,
                        top: device.y === undefined ? "40px" : device.y,

                      };
                      return (
                        <Draggble
                          bounds="parent"
                          onStop={(e, data) =>
                            handleDeviceDrop(e, data, index + 1, device.type)
                          }
                        >
                          <div
                            className={`${styles.barChartContainer} ${styles.addImg}`}
                            style={deviceStyle}
                            onContextMenu={(event) =>
                              handleDeviceMenu(
                                event,
                                index + 1,
                                device.deviceName,
                                device.type,
                                device
                              )
                            }
                            id={`newBarChart-${index + 1}`}
                          >
                            <NewBarChart
                              dateTimesLive={dateTimesLive}
                              channelNames={channelNames}
                              dataArrLive={dataArrLive}
                              getRandomColor={getRandomColor}
                              tabIndexPieChart={tabIndexPieChart}
                              selectedTabIndex={activeTabIndex}
                              openNewPage={openNewPage}
                              pieChartChannels={pieChartChannels}
                              chartChannel={device.chartChannel}
                            />
                          </div>
                        </Draggble>
                      );
                    } else {
                      return null;
                    }
                  })}

                {multipleHistogram.length > 1 &&
                  multipleHistogram.slice(1).map((device, index) => {
                    if (device.tabIndex === activeTabIndex) {
                      const deviceStyle = {
                        position: "absolute",
                        left: device.x === undefined ? "0%" : device.x,
                        top: device.y === undefined ? "40px" : device.y,
                      };
                      return (
                        <Draggble
                          bounds="parent"
                          onStop={(e, data) =>
                            handleDeviceDrop(e, data, index + 1, device.type)
                          }
                        >
                          <div
                            className={`${styles.barChartContainer} ${styles.addImg}`}
                            style={deviceStyle}
                            onContextMenu={(event) =>
                              handleDeviceMenu(
                                event,
                                index + 1,
                                device.deviceName,
                                device.type,
                                device
                              )
                            }
                            id={`newHistogram-${index + 1}`}
                          >
                            <NewHistogram
                              dateTimesLive={dateTimesLive}
                              channelNames={channelNames}
                              dataArrLive={dataArrLive}
                              getRandomColor={getRandomColor}
                              tabIndexPieChart={tabIndexPieChart}
                              selectedTabIndex={activeTabIndex}
                              openNewPage={openNewPage}
                              chartChannel={device.chartChannel}
                              endHours={endHours}
                              endMinutes={endMinutes}
                              oneDayDataTs={oneDayDataTs}
                            />
                          </div>
                        </Draggble>
                      );
                    } else {
                      return null;
                    }
                  })}

                {multipleGauge.length > 1 &&
                  multipleGauge.slice(1).map((device, index) => {
                    if (device.tabIndex === activeTabIndex) {
                      const deviceStyle = {
                        position: "absolute",
                        left: device.x === undefined ? "0%" : device.x,
                        top: device.y === undefined ? "40px" : device.y,
                      };
                      return (
                        <Draggble
                          bounds="parent"
                          onStop={(e, data) =>
                            handleDeviceDrop(e, data, index + 1, device.type)
                          }
                        >
                          <div
                            className={`${styles.gaugeContainer} ${styles.addImg}`}
                            style={deviceStyle}
                            onContextMenu={(event) =>
                              handleDeviceMenu(
                                event,
                                index + 1,
                                device.deviceName,
                                device.type,
                                device
                              )
                            }
                            id={`newGauge-${index + 1}`}
                          >
                            <NewGauge
                              dateTimesLive={dateTimesLive}
                              channelNames={channelNames}
                              dataArrLive={dataArrLive}
                              getRandomColor={getRandomColor}
                              tabIndexPieChart={tabIndexPieChart}
                              selectedTabIndex={activeTabIndex}
                              openNewPage={openNewPage}
                              chartChannel={device.chartChannel}
                              minVal={startPoint}
                              maxVal={endPoint}
                              index={index}
                            />
                          </div>
                        </Draggble>
                      );
                    } else {
                      return null;
                    }
                  })}

                {multipleProgress.length > 1 &&
                  multipleProgress.slice(1).map((device, index) => {
                    if (device.tabIndex === activeTabIndex) {
                      return (
                        <Draggble
                          bounds="parent"
                          onStop={(e, data) =>
                            handleDeviceDrop(e, data, index + 1, device.type)
                          }
                        >
                          <div
                            className={`${styles.progressContainer} ${styles.addImg}`}
                            style={{
                              display: rotateBar ? "flex" : null,
                              justifyContent: rotateBar ? "center" : null,
                              alignItems: rotateBar ? "center" : null,
                              height: rotateBar ? "37vh" : null,
                              position: "absolute",
                              left: device.x === undefined ? "0%" : device.x,
                              top: device.y === undefined ? "40px" : device.y,
                            }}
                            onContextMenu={(event) =>
                              handleDeviceMenu(
                                event,
                                index + 1,
                                device.deviceName,
                                device.type,
                                device
                              )
                            }
                            id={`newProgressBar-${index + 1}`}
                          >
                            <NewProgressBar
                              dateTimesLive={dateTimesLive}
                              channelNames={channelNames}
                              dataArrLive={dataArrLive}
                              getRandomColor={getRandomColor}
                              tabIndexPieChart={tabIndexPieChart}
                              selectedTabIndex={activeTabIndex}
                              openNewPage={openNewPage}
                              minVal={device.minVal}
                              maxVal={device.maxVal}
                              chartChannel={[device.chartChannel]}
                              chartIndex={index}
                              rotateBar={rotateBar}
                            />
                          </div>
                        </Draggble>
                      );
                    } else {
                      return null;
                    }
                  })}

                {multipleTable.length > 1 &&
                  multipleTable.slice(1).map((device, index) => {
                    if (device.tabIndex === activeTabIndex) {
                      const deviceStyle = {
                        position: "absolute",
                        left: device.x === undefined ? "0%" : device.x,
                        top: device.y === undefined ? "40px" : device.y,
                      };
                      return (
                        <Draggble
                          bounds="parent"
                          onStop={(e, data) =>
                            handleDeviceDrop(e, data, index + 1, device.type)
                          }
                        >
                          <div
                            className={`${styles.tableContainer} ${styles.addImg}`}
                            style={deviceStyle}
                            onContextMenu={(event) =>
                              handleDeviceMenu(
                                event,
                                index + 1,
                                device.deviceName,
                                device.type,
                                device
                              )
                            }
                            id={`newTable-${index + 1}`}
                          >
                            <NewMovableTable
                              channelNames={channelNames}
                              dataArrLive={dataArrLive}
                              chartChannel={device.chartChannel}
                              isColumn={device.column}
                            />
                          </div>
                        </Draggble>
                      );
                    } else {
                      return null;
                    }
                  })}

                {multipleGrid.length > 1 &&
                  multipleGrid.slice(1).map((device, index) => {
                    if (device.tabIndex === activeTabIndex) {
                      const deviceStyle = {
                        position: "absolute",
                        left: device.x === undefined ? "0%" : device.x,
                        top: device.y === undefined ? "40px" : device.y,
                      };
                      return (
                        <Draggble
                          bounds="parent"
                          onStop={(e, data) =>
                            handleDeviceDrop(e, data, index + 1, device.type)
                          }
                        >
                          <div
                            className={`${styles.gridContainer} ${styles.addImg}`}
                            style={deviceStyle}
                            onContextMenu={(event) =>
                              handleDeviceMenu(
                                event,
                                index + 1,
                                device.deviceName,
                                device.type,
                                device
                              )
                            }
                            id={`newGrid-${index + 1}`}
                          >
                            <NewToolBarGrid
                              tableData={tableData}
                              gridIndex={index + 1}
                              chartChannel={device.chartChannel}
                            />
                          </div>
                        </Draggble>
                      );
                    } else {
                      return null;
                    }
                  })}


                <Tabs
                  isFitted
                  size="sm"
                  variant="enclosed"
                  defaultIndex={selectedTabIndex}
                >
                  <div className={styles.tabList}>
                    <TabList
                      style={
                        isMobile
                          ? {
                            border: "none",
                            display: "flex",
                            flexWrap: "wrap",
                          }
                          : { border: "none" }
                      }
                    >
                      {tempTabNames.map((name, tabIndex) => (
                        <Tab
                          id="selectedTab"
                          key={tabIndex}
                          style={{ border: "none" }}
                          onContextMenu={(event) =>
                            handleContextMenu(event, tabIndex, name)
                          }
                          onClick={() => handleTabClick(tabIndex, name)}
                        >
                          <p id="clickedTab">{name}</p>
                        </Tab>
                      ))}
                      <button
                        onClick={handleAddNewTab}
                        className={styles.addNewTagBtn}
                      >
                        <AiOutlinePlus /> New Tab
                      </button>
                    </TabList>
                    <div className={styles.gridBg}>
                    </div>
                  </div>
                  <TabPanels>
                    <TabPanel>
                      <Overview
                        uniqueProjectId={uniqueProjectId}
                        data={data}
                        socketData={socketData}
                        openNewPage={openNewPage}
                        handleOverviewInfoDrop={handleOverviewInfoDrop}
                        handleOverviewGraphDrop={handleOverviewGraphDrop}
                        tabIndexOverviewInfo={tabIndexOverviewInfo}
                        selectedTabIndex={activeTabIndex}
                        tabIndexOverviewGraph={tabIndexOverviewGraph}
                        handleOverviewGraphDimension={
                          handleOverviewGraphDimension
                        }
                        tempTabIndexOverviewInfo={tempTabIndexOverviewInfo}
                        tempTabIndexOverviewGraph={tempTabIndexOverviewGraph}
                        isMobile={isMobile}
                        scaleValue={scaleValue}
                      />
                    </TabPanel>
                    <TabPanel style={{ height: "75vh" }}>
                      <Channels
                        data={data}
                        socketData={socketData}
                        handleDefaultGridDimension={handleDefaultGridDimension}
                        handleDefaultGridDrop={handleDefaultGridDrop}
                        openNewPage={openNewPage}
                        selectedTabIndex={activeTabIndex}
                        tabIndexDefaultGrid={tabIndexDefaultGrid}
                        tempTabIndexDefaultGrid={tempTabIndexDefaultGrid}
                        isMobile={isMobile}
                        scaleValue={scaleValue}
                      />
                    </TabPanel>
                    <TabPanel style={{ height: "75vh" }}>
                      <LiveData
                        uniqueProjectId={uniqueProjectId}
                        data={data}
                        socketData={socketData}
                        openNewPage={openNewPage}
                        selectedTabIndex={activeTabIndex}
                        tabIndexLiveDataGraph={tabIndexLiveDataGraph}
                        setTabIndexLiveDataGraph={setTabIndexLiveDataGraph}
                        handleLiveDataGraphDrop={handleLiveDataGraphDrop}
                        handleLiveDataDimension={handleLiveDataDimension}
                        tempTabIndexLiveDataGraph={tempTabIndexLiveDataGraph}
                        isMobile={isMobile}
                        scaleValue={scaleValue}
                      />
                    </TabPanel>
                    <TabPanel>
                      <Day1
                        data={data}
                        socketData={socketData}
                        tabIndexLastDayTable={tabIndexLastDayTable}
                        tabIndexLastDayGraph={tabIndexLastDayGraph}
                        handleLastDayTableDrop={handleLastDayTableDrop}
                        selectedTabIndex={activeTabIndex}
                        openNewPage={openNewPage}
                        handleLastDayTableDimension={
                          handleLastDayTableDimension
                        }
                        handleLastDayGraphDrop={handleLastDayGraphDrop}
                        handleLastDayGraphDimension={
                          handleLastDayGraphDimension
                        }
                        tempTabIndexLastDayGraph={tempTabIndexLastDayGraph}
                        tempTabIndexLastDayTable={tempTabIndexLastDayTable}
                        scaleValue={scaleValue}
                        isMobile={isMobile}
                      />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </div>
            </div>
          </div>
          <ToolBar
            setDisplayImage={setDisplayImage}
            multipleImages={multipleImages}
            setMultipleImages={setMultipleImages}
            tempImages={tempImages}
            setTempImages={setTempImages}
            setActiveTabIndex={setActiveTabIndex}
            activeTabIndex={activeTabIndex}
            setStoreTabIndexes={setStoreTabIndexes}
            storeTabIndexes={storeTabIndexes}
            setMultipleDevices={setMultipleDevices}
            multipleDevices={multipleDevices}
            setTabIndexForDevices={setTabIndexForDevices}
            tabIndexForDevice={tabIndexForDevice}
            setMultipleController={setMultipleController}
            multipleController={multipleController}
            multipleIO={multipleIO}
            setMultipleIO={setMultipleIO}
            setMultipleFlowM={setMultipleFlowM}
            multipleFlowM={multipleFlowM}
            multipleEnergyM={multipleEnergyM}
            setMultipleEnergyM={setMultipleEnergyM}
            multipleLineCharts={multipleLineCharts}
            setMultipleLineCharts={setMultipleLineCharts}
            setMultiplePieChart={setMultiplePieChart}
            multiplePieChart={multiplePieChart}
            multipleBarChart={multipleBarChart}
            setMultipleBarChart={setMultipleBarChart}
            multipleHistogram={multipleHistogram}
            setMultipleHistogram={setMultipleHistogram}
            multipleGauge={multipleGauge}
            setMultipleGauge={setMultipleGauge}
            setMultipleProgress={setMultipleProgress}
            multipleProgress={multipleProgress}
            setCurrentPVChannel={setCurrentPVChannel}
            setMultipleGrid={setMultipleGrid}
            multipleGrid={multipleGrid}
            multipleTable={multipleTable}
            setMultipleTable={setMultipleTable}
            updatedMultipleDevices={updatedMultipleDevices}
            setUpdatedMultipleDevices={setUpdatedMultipleDevices}
            updatedMultipleController={updatedMultipleController}
            setUpdatedMultipleController={setUpdatedMultipleController}
            updatedMultipleIO={updatedMultipleIO}
            setUpdatedMultipleIO={setUpdatedMultipleIO}
            updatedMultipleFLowM={updatedMultipleFlowM}
            setUpdatedMultipleFlowM={setUpdatedMultipleFlowM}
            updatedMultipleEnergyM={updatedMultipleEnergyM}
            setUpdatedMultipleEnergyM={setUpdatedMultipleEnergyM}
            updatedMultipleLineChart={updatedMultipleLineChart}
            setUpdatedMultipleLineChart={setUpdatedMultipleLineChart}
            updatedMultiplePieChart={updatedMultiplePieChart}
            setUpdatedMultiplePieChart={setUpdatedMultiplePieChart}
            updatedMultipleBarChart={updatedMultipleBarChart}
            setUpdatedMultipleBarChart={setUpdatedMultipleBarChart}
            updatedMultipleHistogram={updatedMultipleHistogram}
            setUpdatedMultipleHistogram={setUpdatedMultipleHistogram}
            updatedMultipleGauge={updatedMultipleGauge}
            setUpdatedMultipleGauge={setUpdatedMultipleGauge}
            updatedMultipleProgress={updatedMultipleProgress}
            setUpdatedMultipleProgress={setUpdatedMultipleProgress}
            updatedMultipleGrid={updatedMultipleGrid}
            setUpdatedMultipleGrid={setUpdatedMultipleGrid}
            updatedMultipleTable={updatedMultipleTable}
            setUpdatedMultipleTable={setUpdatedMultipleTable}
            multipleMotor={multipleMotor}
            setMultipleMotor={setMultipleMotor}
            updatedMultipleMotor={updatedMultipleMotor}
            setUpdatedMultipleMotor={setUpdatedMultipleMotor}
            isMotorOptions={isMotorOptions}
            setIsMotorOptions={setIsMotorOptions}
            isDeviceOption={isDeviceOption}
            setIsDeviceOption={setIsDeviceOption}
            multipleDisplay={multipleDisplay}
            setMultipleDisplay={setMultipleDisplay}
            updatedMultipleDisplay={updatedMultipleDisplay}
            setUpdatedMultipleDisplay={setUpdatedMultipleDisplay}
            multipleText={multipleText}
            setMultipleText={setMultipleText}
            updatedMultipleText={updatedMultipleText}
            setUpdatedMultipleText={setUpdatedMultipleText}
          />

          <button className={styles.discardBtn} onClick={handleDiscardPage}>
            Discard
          </button>
          <button className={styles.closeButton} onClick={handleClosePage}>
            Close
          </button>
          <button className={styles.floppyDisk} onClick={handleSavePage}>
            <img src={floopyDisk} alt="" className={styles.floppyDiskImg} />
          </button>
          {deleteContextMenu && (
            <div
              className={styles.deleteContextMenu}
              style={{
                top: deleteContextPosi.y,
                left: deleteContextPosi.x,
                boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
              }}
            >
              <button
                className={styles.deleteImage}
                onClick={
                  multipleImages.length > 0
                    ? handleDeleteImage2
                    : handleDeleteImage1
                }
              >
                Delete
              </button>
            </div>
          )}

          {isDeviceMenu && (
            <div
              className={styles.deviceMenu}
              style={{
                top: deviceMenuPosition.y,
                left: deviceMenuPosition.x,
                boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
              }}
            >
              {currentDevice === "DigitalIO" ||
                currentDevice === "Controller" ||
                currentDevice === "Indicator" ||
                currentDevice === "EnergyMeter" ||
                currentDevice === "FlowMeter" ? (
                <button
                  className={styles.deviceRenameBtn}
                  onClick={handleRenameDeviceModal}
                >
                  Rename
                </button>
              ) : null}
              <button
                className={styles.deviceDeleteBtn}
                onClick={
                  multipleDevices.length > 1 ||
                    multipleController.length > 1 ||
                    multipleIO.length > 1 ||
                    multipleFlowM.length > 1 ||
                    multipleEnergyM.length > 1 ||
                    multipleLineCharts.length > 1 ||
                    multiplePieChart.length > 1 ||
                    multipleBarChart.length > 1 ||
                    multipleHistogram.length > 1 ||
                    multipleGauge.length > 1 ||
                    multipleProgress.length > 1 ||
                    multipleTable.length > 1 ||
                    multipleGrid.length > 1 ||
                    multipleMotor.length > 1 ||
                    multipleDisplay.length > 1 ||
                    multipleText.length > 1
                    ? handleDeviceDelete2
                    : handleDeviceDelete1
                }
              >
                Delete
              </button>
              {currentDevice === "Controller" ||
                currentDevice === "Indicator" ||
                currentDevice === "DigitalIO" ||
                currentDevice === "EnergyMeter" ||
                currentDevice === "FlowMeter" ||
                currentDevice === "ProgressBar" ||
                currentDevice === "LineChart" ||
                currentDevice === "PieChart" ||
                currentDevice === "BarChart" ||
                currentDevice === "Histogram" ||
                currentDevice === "Gauge" ||
                currentDevice === "Grid" ||
                currentDevice === "Table" ||
                currentDevice === "Motor" ||
                currentDevice === "Display"
                ? (
                  <button
                    className={styles.channelsBtn}
                    onClick={handleChannelsBtn}
                  >
                    Channels
                  </button>
                ) : null}

              {tabIndexGauge[activeTabIndex]
                ?.map((chart) =>
                  chart?.deviceType.includes(selectedDevice?.deviceType)
                )
                .includes(true) || currentDevice === "ProgressBar" ? (
                <button
                  className={styles.rangeBtn}
                  onClick={() => setIsRangeModal(true)}
                >
                  Range
                </button>
              ) : null}

              {currentDevice === "ProgressBar" ? (
                <button className={styles.rangeBtn} onClick={handleRotateBar}>
                  Rotate
                </button>
              ) : null}

              {currentDevice === "LineChart" ||
                currentDevice === "Histogram" ? (
                <buttton
                  className={styles.timePeriodBtn}
                  onClick={handleTimePeriod}
                >
                  Time Period
                </buttton>
              ) : null}

              {currentDevice === "Table" ? (
                <button
                  className={styles.rangeBtn}
                  onClick={handleChangeFormat}
                >
                  Change Format
                </button>
              ) : null}

              {currentDevice === "Display" ||
                currentDevice === "Text"
                ? (
                  <button
                    className={styles.rangeBtn}
                    onClick={() => setIsBgColor(true)}
                  >
                    Properties
                  </button>
                ) : null}
            </div>
          )}

          {/* To display range model */}
          {isRangeModel && (
            <div className={styles.addNewTag}>
              <div className={styles.range}>
                <img
                  src={closeSvg}
                  className={styles.closeImages}
                  onClick={() => setIsRangeModal(false)}
                  alt=""
                />
                <div className={styles.rangeContainer}>
                  <input
                    type="number"
                    className={styles.rangeInput}
                    placeholder="Minimum Value"
                    id="minValue"
                  />
                  <input
                    type="number"
                    className={styles.rangeInput}
                    placeholder="Maximum Value"
                    id="maxValue"
                  />
                </div>
                <div className={styles.saveWrapper} onClick={handleRangeSave}>
                  <button className={styles.saveChannelBtn}>Save</button>
                </div>
              </div>
            </div>
          )}

          {/* To display channels model */}
          {isChannels && (
            <div className={styles.addNewTag}>
              <div className={styles.channels}>
                <img
                  src={closeSvg}
                  className={styles.closeImages}
                  alt=""
                  srcset=""
                  onClick={() => setIsChannels(false)}
                />
                <div className={styles.selectChannel}>
                  <div
                    className={
                      plusIcon
                        ? `${styles.pvSection} ${styles.newPV}`
                        : styles.pvSection
                    }
                  >
                    {currentDevice === "PieChart" ||
                      currentDevice === "LineChart" ||
                      currentDevice === "BarChart" ||
                      currentDevice === "Histogram" ||
                      currentDevice === "Gauge" ||
                      currentDevice === "ProgressBar" ||
                      currentDevice === "Grid" ||
                      currentDevice === "Table" ||
                      currentDevice === "Motor" ||
                      currentDevice === "Display"
                      ? null : (
                        <div className={styles.pv}>PV :</div>
                      )}
                    <select
                      id="pvChannels"
                      className={styles.availableChannels}
                      onChange={(e) => handleSelectChange(e)}
                    >
                      <option value="" selected>
                        Select Channel
                      </option>
                      {tableData.map((data, dataIndex) => (
                        <option key={dataIndex}>{data.Header}</option>
                      ))}
                    </select>
                    {plusIcon &&
                      displaySelectTag.map((tag, TabIndex) => (
                        <select
                          id={`chartChannels${TabIndex}`}
                          className={styles.availableChannels}
                          onChange={(e) => handleSelectChange(e)}
                        >
                          <option value="" selected>
                            Select Channel
                          </option>
                          {tableData.map((data, dataIndex) => (
                            <option key={dataIndex}>{data.Header}</option>
                          ))}
                        </select>
                      ))}
                    {plusIcon && (
                      <button
                        className={styles.plusBtn}
                        onClick={handlePlusIcon}
                      >
                        <AiOutlinePlus />
                      </button>
                    )}
                  </div>
                  {currentDevice === "Controller" ||
                    currentDevice === "FlowMeter" ||
                    currentDevice === "EnergyMeter" ? (
                    <div className={styles.svSection}>
                      <div className={styles.sv}>SV :</div>
                      <select
                        id="svChannels"
                        className={styles.availableChannels}
                      >
                        <option value="" selected>
                          Select Channel
                        </option>
                        {tableData.map((data, dataIndex) => (
                          <option key={dataIndex}>{data.Header}</option>
                        ))}
                      </select>
                    </div>
                  ) : null}

                  {currentDevice === "EnergyMeter" ? (
                    <div className={styles.svSection2}>
                      <div className={styles.sv}></div>
                      <select
                        id="svChannel2"
                        className={styles.availableChannels}
                      >
                        <option value="" selected>
                          Select Channel
                        </option>
                        {tableData.map((data, dataIndex) => (
                          <option key={dataIndex}>{data.Header}</option>
                        ))}
                      </select>
                    </div>
                  ) : null}
                  {currentDevice === "EnergyMeter" ? (
                    <div className={styles.svSection3}>
                      <div className={styles.sv}></div>
                      <select
                        id="svChannel3"
                        className={styles.availableChannels}
                      >
                        <option value="" selected>
                          Select Channel
                        </option>
                        {tableData.map((data, dataIndex) => (
                          <option key={dataIndex}>{data.Header}</option>
                        ))}
                      </select>
                    </div>
                  ) : null}

                  <div className={styles.saveWrapper}>
                    <button
                      className={styles.saveChannelBtn}
                      onClick={handleSaveChannel}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {isChannelCheckBox ? (
            <div className={styles.checkBoxParent}>
              <Draggble bounds="parent">
                <div className={styles.channelCheckBoxContainer}>
                  <button
                    className={styles.closeImages}
                    onClick={() => setIsChannelCheckBox(false)}
                  >
                    <img src={closeSvg} alt="" srcset="" />
                  </button>
                  <div className={styles.selectAllOption}>
                    <input
                      type="checkbox"
                      id="selectAllOption"
                      onChange={handleAllSelect}
                      checked={selectAllCheck}
                    />
                    <span>Select All</span>
                  </div>
                  <div className={styles.checkBoxesWrapper}>
                    {tableData.map((data, index) => (
                      <div className={styles.checkBoxes}>
                        <input
                          type="checkbox"
                          name="checkBox"
                          onChange={(event) =>
                            handleCheckBox(event, index, data.Header)
                          }
                          className={styles.checkBoxInput}
                          id={`checkBox${index}`}
                        />
                        <span>{data.Header}</span>
                      </div>
                    ))}
                  </div>
                  <div className={styles.saveWrapper}>
                    <button
                      className={styles.saveChannelBtn}
                      onClick={handleSaveCheckBox}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Draggble>
            </div>
          ) : null}

          {/* Opening Context Menu */}
          {openContextMenu && (
            <ContextMenu
              x={contextMenuPosition.x}
              y={contextMenuPosition.y}
              setIsRenameModel={setIsRenameModel}
              handleRenameTab={handleRenameTab}
              handleDeleteTab={handleDeleteTab}
              clickedTabIndex={clickedTabIndex}
            />
          )}
        </div>
        {/* Opening Modal to Add New Tab*/}
        {isRenameModel || addNewTag || renameDeviceModal ? (
          <div className={styles.addNewTag}>
            <div className={styles.newTabContainer}>
              {isRenameModel && (
                <button
                  onClick={() => setIsRenameModel(false)}
                  className={styles.closeBtn}
                >
                  <img src={close} alt="" className={styles.closeImg} />
                </button>
              )}
              {addNewTag && (
                <button
                  onClick={() => setAddNewTag(false)}
                  className={styles.closeBtn}
                >
                  <img src={close} alt="" className={styles.closeImg} />
                </button>
              )}
              {renameDeviceModal && (
                <button
                  onClick={() => setRenameDeviceModal(false)}
                  className={styles.closeBtn}
                >
                  <img src={close} alt="" className={styles.closeImg} />
                </button>
              )}
              {isRenameModel && (
                <>
                  <input
                    type="text"
                    placeholder="Enter New Tab Name"
                    className={styles.tabNameInput}
                    onChange={(e) => setTabNameInput(e.target.value)}
                    defaultValue={isRenameModel && selectedTab}
                  />
                  <button
                    className={styles.saveModel}
                    onClick={handleSaveRenameTab}
                    disabled={!tabNameInput}
                  >
                    Save
                  </button>
                </>
              )}
              {addNewTag && (
                <>
                  <input
                    type="text"
                    placeholder="Tab Name"
                    className={styles.tabNameInput}
                    onChange={(e) => setTabNameInput(e.target.value)}
                  />
                  <button
                    className={styles.saveModel}
                    onClick={handleSaveTab}
                    disabled={!tabNameInput}
                  >
                    Save
                  </button>
                </>
              )}
              {renameDeviceModal && (
                <>
                  <input
                    type="text"
                    placeholder="Device Name"
                    className={styles.tabNameInput}
                    onChange={(e) => setTabNameInput(e.target.value)}
                  />
                  <button
                    className={styles.saveModel}
                    disabled={!tabNameInput}
                    onClick={handleSaveDevicename}
                  >
                    Save
                  </button>
                </>
              )}
            </div>
          </div>
        ) : null}

        {/* Modal for the time period */}

        {isTimePeriod && (
          <div className={styles.addNewTag}>
            <div className={styles.timePeriodModal}>
              <img
                src={closeSvg}
                alt=""
                srcset=""
                className={styles.closeImages}
                onClick={handleCloseTimePeriod}
              />
              <div className={styles.endTime}>
                <select
                  name=""
                  id="endTime"
                  onClick={handleEndTimeSelect}
                  className={styles.timePeriodSelect}
                >
                  <option>Hours</option>
                  <option>Minutes</option>
                </select>

                {endSelect === "Hours" && (
                  <div className={styles.timeWrapper}>
                    <input
                      type="number"
                      className={styles.time}
                      value={endHours}
                      onChange={handleEndChange}
                    />
                    <div className={styles.upDown}>
                      <img
                        src={increase}
                        alt=""
                        className={styles.upDownImg}
                        onClick={handleEndIncrease}
                      />
                      <img
                        src={decrease}
                        alt=""
                        className={styles.upDownImg}
                        onClick={handleEndDecrease}
                      />
                    </div>
                  </div>
                )}
                {endSelect === "Minutes" && (
                  <div className={styles.timeWrapper}>
                    <input
                      type="number"
                      className={styles.time}
                      value={endMinutes}
                      onChange={handleEndChange}
                    />
                    <div className={styles.upDown}>
                      <img
                        src={increase}
                        alt=""
                        className={styles.upDownImg}
                        onClick={handleEndIncrease}
                      />
                      <img
                        src={decrease}
                        alt=""
                        className={styles.upDownImg}
                        onClick={handleEndDecrease}
                      />
                    </div>
                  </div>
                )}
              </div>
              <button
                className={styles.saveTimePeriod}
                onClick={handleSaveTimePeriod}
              >
                Save
              </button>
            </div>
          </div>
        )}

        {isChangeSV && (
          <div className={styles.addNewTag}>
            <div className={styles.changeSVContainer}>
              <button className={styles.closeImages} onClick={() => setIsChangeSV(false)}>
                <img
                  src={closeSvg}
                  alt=""
                  srcset=""
                />
              </button>
              <h2 className={styles.modalHeader}>Properties</h2>
              <input type="number" className={styles.changeSVInput} placeholder="Current Page Rows" id="currentPageRows" required />
              <input type="number" className={styles.changeSVInput} placeholder="Current Page" id="currentPage" required />
              <input type="text" className={styles.changeSVInput} placeholder="Tag Name (Case Sensitive)" id="tagName" required />
              <input type="number" className={styles.changeSVInput} placeholder="Value" id="changeSV" required />
              <button className={styles.saveTimePeriod} onClick={handleChangeSV}>save</button>
            </div>
          </div>
        )}

        {isBgColor && (
          <div className={styles.addNewTag}>
            <div className={styles.bgColorContainer}>
              <button className={styles.closeImages} onClick={() => setIsBgColor(false)}>
                <img
                  src={closeSvg}
                  alt=""
                  srcset=""
                />
              </button>

              <h2 className={styles.modalHeader}>Properties</h2>
              {currentDevice === "Text" &&
                <input type="text" placeholder="Add Text" defaultValue={addedText} className={styles.addTextInput} onChange={(e) => setAddedText(e.target.value)} />
              }
              <div className={styles.bgWrapper}>
                <span className={styles.bgColorText}>Background-color : </span>
                <input type="color" className={styles.colorInput} defaultValue={currentDevice === "Display" ? bgColor : textBgColor} onChange={currentDevice === "Display" ? (e) => setBgColor(e.target.value) : (e) => setTextBgColor(e.target.value)} />
              </div>

              <div className={styles.bgWrapper}>
                <span className={styles.bgColorText}>Text-color : </span>
                <input
                  type="color"
                  className={styles.colorInput}
                  defaultValue={currentDevice === "Display" ? textColor : widgetTextColor}
                  onChange={currentDevice === "Display" ? (e) => setTextColor(e.target.value) : (e) => setWidgetTextColor(e.target.value)}
                />
              </div>

              <div className={styles.bgWrapper}>
                <span className={styles.bgColorText}>Font-size : </span>
                <input
                  type="number"
                  className={styles.time}
                  value={currentDevice === "Display" ? fontSize : textFontSize}
                  min="7"

                  onChange={handleFontSizeInputChange}
                />
                <div className={styles.upDown}>
                  <img
                    src={increase}
                    alt=""
                    className={styles.upDownImg}
                    onClick={handleIncreaseFontSize}
                  />
                  <img
                    src={decrease}
                    alt=""
                    className={styles.upDownImg}
                    onClick={handleDecreaseFontSize}
                  />
                </div>
              </div>

              {currentDevice === "Text" && (
                <>
                  <div className={styles.upDown}>
                    <span>Align Vertically : </span>
                    <select
                      style={{ background: "transparent", border: "1px solid black", borderRadius: "0.625rem", padding: "0.3rem 0.7rem", outline: "none", cursor: "pointer" }}
                      onChange={(e) => setTextAlignVertically(e.target.value)}
                      value={textAlignVertically}
                    >
                      <option value="" selected>select alignment</option>
                      <option value="start">Start</option>
                      <option value="center">Center</option>
                      <option value="end">End</option>
                    </select>
                  </div>

                  <div className={styles.upDown}>
                    <span>Align Horizontally : </span>
                    <select
                      style={{ background: "transparent", border: "1px solid black", borderRadius: "0.625rem", padding: "0.3rem 0.7rem", outline: "none", cursor: "pointer" }}
                      onChange={(e) => setTextAlignHorizontally(e.target.value)}
                      value={textAlignHorizontally}
                    >
                      <option value="" selected>select alignment</option>
                      <option value="start">Start</option>
                      <option value="center">Center</option>
                      <option value="end">End</option>
                    </select>
                  </div>
                </>
              )}
              <button className={styles.saveTimePeriod} onClick={handleChangeBg}>save</button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default NewPage;


