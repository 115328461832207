import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "./Management.module.css";
import DeleteImg from "assets/Delete.svg";
import CreateRole from "./components/CreateRole";
import RemoveRole from "./components/RemoveRole";
import RemovePeople from "./components/RemovePeople";
import TransferOrg from "./components/TransferOrg";
import DeleteOrg from "./components/DeleteOrg";
import { useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsers,
  updateRoles,
} from "../../../../redux/slices/organizationSlice.js";
import InviteMember from "./components/InviteMember";

const Management = ({
  deleteRole,
  setDeleteRole,
  roleList,
  createRole,
  setCreateRole,
  removeRoleModel,
  setRemoveRoleModel,
}) => {
  const toast = useToast();

  const [createRoleModel, setCreateRoleModel] = useState(false); // State to handle display of create new role model
  const [remove, setRemove] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const [deleteOrg, setDeleteOrg] = useState(false);
  const [removeMemberEmail, setRemoveMemberEmail] = useState(); // State to store values of email to be removed
  const [memberList, setMemberList] = useState([]);// State to store saved member of org
  const [isSmartphone, setIsSmartphone] = useState(false); // State to decide mobile view
  const [newMemberEmail, setNewMemberEmail] = useState(""); // State to store value of member to invite
  const [newMemberRole, setNewMemberRole] = useState(""); // State to store value of role of member to invite

  const [selectedMembers, setSelectedMembers] = useState([]); // State to store values of selected members
  const [pageRefresh, setPageRefresh] = useState(true);
  const [isInvite, setIsInvite] = useState(false); // State to display invite model
  const dispatch = useDispatch();
  const orgMembers = useSelector((state) => state.organization.orgMembers);

  const roleRefs = useRef({});
  const organizationEmail = localStorage.getItem(`organizationEmail`);
  const adminEmail = localStorage.getItem(`organizationEmail`);
  const userEmail = localStorage.getItem(`email`);
  const token = localStorage.getItem(`token`);

  // Deciding the mobile view on page load
  useEffect(() => {
    const handleResize = () => {
      setIsSmartphone(window.innerWidth <= 768); // Adjust the width as needed
    };

    handleResize(); // Call it initially
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Calling get users api on page load
  useEffect(() => {
    const apiBody = {
      userEmail: userEmail,
      organizationEmail: organizationEmail,
    };

    try {
      dispatch(getUsers(apiBody));
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Storing value of members on page load and as per dependencies
  useEffect(() => {
    setMemberList(orgMembers);
  }, [removeRoleModel, pageRefresh, orgMembers]);


  const handleRemoveModel = useCallback((email) => {
    setRemove(true);
    setRemoveMemberEmail([email]);
  }, []);

  // Function to add and remove member from state
  const handleMemberSelect = useCallback((email) => {
    if (selectedMembers.includes(email)) {
      setSelectedMembers(selectedMembers.filter((member) => member !== email));
    } else {
      setSelectedMembers([...selectedMembers, email]);
    }
  }, []);

  // Function to store values of member to be delete
  const handleDeleteSelectedMembers = useCallback(() => {
    if (selectedMembers.length >= 2) {
      setRemove(true);
      setRemoveMemberEmail(selectedMembers);
    }
  }, []);

  // Function to handle Change Role
  const roleChange = async function (e, member) {
    const newRole = e.target.value;
    const apiBody = {
      updateUserEmail: member.email,
      userEmail: userEmail,
      updateRole: e.target.value,
      organizationEmail: organizationEmail,
    };

    try {
      dispatch(updateRoles(apiBody)).then((response) => {

        if (response.payload.status === 200) {
          toast({
            title: "Successful",
            description: response.payload.message,
            status: "success",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });

          roleRefs.current[member.email].value = newRole;
        } else {
          toast({
            title: "Error",
            description: response.payload.message,
            status: "error",
            position: "top-right",
            duration: 5000,
            isClosable: true,
          });
          roleRefs.current[member.email].value = member.role;

          setPageRefresh(false);
        }
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Internal Server Error",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      })
    }
  };

  // Function to store new member info in states
  const handleInvite = useCallback(() => {
    setIsInvite(true);
    const newMemberEmail = document.querySelector("#inviteEmail").value;
    const inviteRole = document.querySelector("#inviteRole").value;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newMemberEmail)) {
      toast({
        title: "Error",
        description: "Please enter a valid email address.",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
      return; // Stop further execution
    }

    setNewMemberEmail(newMemberEmail);
    setNewMemberRole(inviteRole);
  }, []);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.bodyContainer}>
        <div className={styles.header}>
          <h1 className={styles.heading1}>
            {isSmartphone ? "Role:" : "Create/Remove Role:"}
          </h1>
          <button
            className={styles.blueBtn}
            onClick={() => setCreateRoleModel(true)}
          >
            Create
          </button>
          <button
            className={styles.redBtn}
            onClick={() => setRemoveRoleModel(true)}
          >
            Remove
          </button>
        </div>
        <div className={styles.body}>
          <div>
            <h3 className={styles.heading3}>Invite Members</h3>
            <p>Enter email address of the member you want to invite</p>
          </div>
          <div className={styles.inviteSection}>
            <input
              className={styles.inviteEmail}
              id="inviteEmail"
              type="email"
              placeholder="Enter Email ID"
            />
            <div className={styles.inviteSelectRole}>
              <select className={styles.inviteSelectRoleInner} id="inviteRole">
                {roleList.map((role, key) => (
                  <option key={key}>{role}</option>
                ))}
              </select>
            </div>
            <button className={styles.blueBtn} onClick={() => handleInvite()}>
              Send Invite
            </button>
          </div>
          <div className={styles.people}>
            <div className={styles.peopleHeader}>
              <h3 className={styles.heading3}>Manage Members</h3>
              <div>
                {selectedMembers.length >= 2 && (
                  <button
                    className={styles.redBtn}
                    onClick={handleDeleteSelectedMembers}
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>
            <form action="#" className={styles.managePeopleForm}>
              {memberList?.length > 0 &&
                memberList?.map((member) => (
                  <div className={styles.peopleList} key={member.email}>
                    <div className={styles.peopleCheckBox}>
                      <input
                        type="checkbox"
                        checked={selectedMembers.includes(member.email)}
                        onChange={() => handleMemberSelect(member.email)}
                      />

                      <div className={styles.peopleDetails}>
                        <div> {member.name}</div>
                        <div className={styles.peopleEmail}>{member.email}</div>
                      </div>
                    </div>

                    <div className={styles.roleSelectDropdown}>
                      <select
                        className={styles.select}
                        ref={(el) => (roleRefs.current[member.email] = el)} // Set ref for each member
                        defaultValue={member.role}
                        onChange={(e) => roleChange(e, member)}
                      >
                        <option selected>{member.role}</option>

                        {roleList
                          .filter((roleName) => roleName !== member.role)
                          .map((roleName, key) => (
                            <option key={key}>{roleName}</option>
                          ))}
                      </select>
                    </div>
                    {selectedMembers.length < 2 && (
                      <button
                        type="button" // Specify the type as "button" to prevent form submission
                        onClick={() => handleRemoveModel(member.email)}
                      >
                        <img src={DeleteImg} alt="Delete Icon" />
                      </button>
                    )}
                  </div>
                ))}
            </form>
          </div>
          <div className={styles.danger}>
            <div>
              <h3 className={styles.heading3}>Danger Zone</h3>
            </div>
            <div className={styles.dangerDiv}>
              <div>
                <h2 className={styles.heading2}>Transfer Owner</h2>
                <p>
                  Transfer this organization to another user where you have
                  Owner rights.
                </p>
              </div>
              <div>
                <button
                  className={styles.blueBtn}
                  onClick={() => setTransfer(true)}
                >
                  Transfer
                </button>
              </div>
            </div>
            <div className={styles.dangerDiv}>
              <div>
                <h2 className={styles.heading2}>Delete Organization</h2>
                <p>
                  Once you delete an organization, there is no going back.
                  Please be certain.
                </p>
              </div>
              <div>
                <button
                  className={styles.redBtn}
                  onClick={() => setDeleteOrg(true)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {createRoleModel && (
        <CreateRole
          setCreateRoleModel={setCreateRoleModel}
          createRole={createRole}
          setCreateRole={setCreateRole}
          adminEmail={organizationEmail}
        />
      )}
      {removeRoleModel && (
        <RemoveRole
          deleteRole={deleteRole}
          setDeleteRole={setDeleteRole}
          roleList={roleList}
          setRemoveRoleModel={setRemoveRoleModel}
        />
      )}
      {remove && (
        <RemovePeople
          removeMemberEmail={removeMemberEmail}
          setRemove={setRemove}
          memberList={memberList}
          setMemberList={setMemberList}
          setSelectedMembers={setSelectedMembers}
        />
      )}
      {transfer && <TransferOrg setTransfer={setTransfer} />}
      {deleteOrg && <DeleteOrg setDeleteOrg={setDeleteOrg} />}
      {isInvite && <InviteMember setIsInvite={setIsInvite} token={token} adminEmail={adminEmail} userEmail={userEmail} newMemberEmail={newMemberEmail} newMemberRole={newMemberRole} />}
    </div>
  );
};

export default Management;
